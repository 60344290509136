import _ from 'lodash'
import { enTranslator as intl } from 'intl.js'

import { PATHS } from 'constants/router'

import { setActiveAccount } from 'sessionStorage/idActiveAccount'

export const handleRouteError = () => {
	console.log('handleRouteError no-op')
}

/**
 * returns true if current location === public share presentation location
 */
export const getIsInPresentationSharePath = () => {
	return window.location.pathname.startsWith(`${PATHS.SHARE_PRESENTATION}/`)
}

export const getIsInStudyPath = () => {
	return window.location.pathname.startsWith(PATHS.STUDY)
}

export const getIsInAdminPath = () => {
	return window.location.pathname.startsWith(PATHS.ADMIN)
}

export const getIsInAccountPath = () => {
	return window.location.pathname.startsWith(PATHS.ACCOUNT)
}

export const getIsInUpgradePath = () => {
	return window.location.pathname.startsWith(PATHS.UPGRADE)
}

/**
 * set active account if url search contains idAccount
 */
export const setAccountFromUrl = () => {
	/**
	 * TODO: Study list itself does not update properly
	 * and still shows study list from previous account!
	 *
	 * This worked correctly with react-router@3
	 */
	const query = new URLSearchParams(window.location.search)
	const idActiveAccount = query.get('idAccount')

	if (idActiveAccount !== null) {
		setActiveAccount(idActiveAccount)
	}
}

/**
 * returns idSlide from url search string or id of the first slide
 * if search string does not contain idSlide
 */
export const getDefaultIdSlide = (slides, searchParams) => {
	const slideFromQuery = slides.find(slide => slide.idSlide === searchParams.get('idSlide'))

	return _.get(slideFromQuery, 'idSlide', slides[0].idSlide)
}

/**
 * returns true in presentation editor, view and share routes
 */
export const getIsPresentationRoute = () =>
	window.location.pathname.startsWith(`${PATHS.PRESENTATION}/`) === true ||
	getIsInPresentationSharePath() === true

/**
 * returns true in account level routes (active account is selected there)
 */
export const getIsAccountLevelRoute = () => {
	const { pathname } = window.location

	return (
		pathname === '/dashboard' ||
		pathname === '/libraries' ||
		pathname === '/presentations' ||
		pathname === '/account'
	)
}

/**
 * return path to report slide
 */
export const getReportSlidePath = (idStudy, slideType, idSlide) => {
	return `${PATHS.STUDY}/${idStudy}${PATHS.REPORT}?slideType=${slideType}&idSlide=${idSlide}`
}

export const getQuantQualSlidePath = (idStudy, idSlide) =>
	`${PATHS.STUDY}/${idStudy}${PATHS.QUANT_QUAL}?idSlide=${idSlide}`

const PATHS_ARRAY = _.orderBy(Object.values(PATHS), path => path.length, 'desc')

export const getRouteIdTranslation = (pathname, options = {}) => {
	const activePath = PATHS_ARRAY.find(path => pathname.includes(path) === true)
	const activePathTranslation = activePath ?? PATHS.HOME

	const idTranslation =
		options.shouldOverrideActivePath === true ? 'upgrade.title' : activePathTranslation

	return intl.formatMessage({ id: idTranslation })
}

export const getSegmentationTypingToolPath = (idStudy, idSegmentationTypingTool) => {
	return `${PATHS.STUDY}/${idStudy}${PATHS.SEGMENTATION_TYPING_TOOL}?idSegmentationTypingTool=${idSegmentationTypingTool}`
}
