import { SET_FN_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/SET_VARIABLE_CONSTANTS'

const _validateSetVariableVariableName = (variableName, setFn) => {
	if (variableName.length === 0) {
		return false
	}

	if (variableName.includes(' ')) {
		return false
	}

	if (setFn === SET_FN_TYPES.SET && variableName.includes('.')) {
		return false
	}

	if (setFn === SET_FN_TYPES.SET_LITERAL && variableName.includes('.')) {
		return false
	}

	return true
}

export default _validateSetVariableVariableName
