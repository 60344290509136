import PropTypes from 'prop-types'

export const CONDITION_SELECTION_TYPES = {
	ANY_OF: 'anyOf',
	EVERY_OF: 'everyOf',
	EQUAL_TO: 'equalTo',
	EQUAL: 'eq',
	AFTER: 'after',
	BEFORE: 'before',
	BETWEEN: 'between',
	DATE_EQUALS: 'dateEquals',
	NUMBER_EQUAL_TO: 'numberEqualTo',
	GREATER_THAN: 'greaterThan',
	LESS_THAN: 'lessThan',
	GREATER_THAN_OR_EQUAL_TO: 'greaterThanOrEqualTo',
	LESS_THAN_OR_EQUAL_TO: 'lessThanOrEqualTo',
	STRING_EQUAL_TO: 'stringEqualTo',
	CONTAINS: 'contains',
	STARTS_WITH: 'startsWith',
	ENDS_WITH: 'endsWith',
	REGEXP: 'regexp',
	STRING_ANY_OF: 'stringAnyOf',
	IN_RANGE: 'range',
	LIST_EMPTY: 'listEmpty',
	RESPONDENT_SOURCE_MATCH: 'respondentSourceMatch',
}

export const CONDITION_CASE_TYPES = {
	AND: 'and',
	OR: 'or',
}

export const CONDITION_TYPES = {
	ALLOCATION: 'allocation',
	CONDITION: 'condition',
	DATE: 'date',
	NUMBER: 'number',
	OEQ_EVALUATION: 'oeqEvaluation',
	OEQ_FOLLOW_UP: 'oeqFollowUp',
	QUOTA_RESULT: 'quotaResult',
	RANKING: 'ranking',
	STRING: 'string',
	TRACK: 'track',
	LIST_LENGTH: 'listLength',
	RESPONDENT_SOURCE: 'respondentSource',
}

export const RESPONDENT_DATES = {
	START_DATE: 'START_DATE',
}

export const CONDITION_RESPONDENT_SOURCE = 'CONDITION_RESPONDENT_SOURCE'

export const DATE_TIME_OBJECT_SHAPE = PropTypes.shape({
	dateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	timezone: PropTypes.string.isRequired,
})

export const EVALUATION_POSITION = {
	AGREE: 'AGREE',
	DISAGREE: 'DISAGREE',
	INDIFFERENT: 'INDIFFERENT',
	UNCLEAR: 'UNCLEAR_STATEMENT',
}
