import { prepareQuery } from 'store/tools/graphql'
import { gql } from '@apollo/client'

import { userDetail, userInfo, accountUserInformation } from 'gql/fragments/user'

export const ADMIN_USER_LIST = () =>
	prepareQuery(
		{
			query: gql`
				query ADMIN_USER_LIST {
					users {
						...userInfo
						idUser
						isInternalEmployee
						featureFlags {
							enableAdvancedFlowFeatures
							enableLiveSlidesAiDescription
						}
						isSystemAdmin
						isActive
					}
				}
			`,
		},
		[userInfo],
	)

export const ADMIN_USER_LIST_NO_DETAIL = () =>
	prepareQuery(
		{
			query: gql`
				query ADMIN_USER_LIST_NO_DETAIL {
					users {
						...userInfo
						idUser
					}
				}
			`,
		},
		[userInfo],
	)

export const ADMIN_GET_USER = idUser =>
	prepareQuery(
		{
			variables: { idUser },
			query: gql`
				query ADMIN_GET_USER($idUser: UUID!) {
					user(idUser: $idUser) {
						...userDetail
						idUser
						isActive
						isEmailVerified
						isInternalEmployee
						featureFlags {
							enableAdvancedFlowFeatures
							enableLiveSlidesAiDescription
						}
						isSystemAdmin
						platformPreset
					}
				}
			`,
		},
		[userDetail],
	)

export const ADMIN_USER_ACCOUNT_LIST = idUser =>
	prepareQuery(
		{
			variables: { idUser },
			query: gql`
				query ADMIN_USER_ACCOUNT_LIST($idUser: UUID!) {
					user(idUser: $idUser) {
						...userInfo
						idUser
						accounts {
							idAccount
							accountName
							contactPerson {
								...accountUserInformation
							}
						}
					}
				}
			`,
		},
		[userInfo, accountUserInformation],
	)

export const ADMIN_GET_USERS_BY_EMAIL = email =>
	prepareQuery({
		variables: { email },
		query: gql`
			query ADMIN_GET_USERS_BY_EMAIL($email: String!) {
				usersByEmail(email: $email) {
					email
					idUser
				}
			}
		`,
	})

export const IS_EMAIL_REGISTERED = email =>
	prepareQuery({
		variables: { email },
		query: gql`
			query IS_EMAIL_REGISTERED($email: String!) {
				isEmailRegistered(email: $email)
			}
		`,
	})
