export const PREPARATION_TYPES = {
	CONDITION: 'CONDITION',
	QUOTA: 'QUOTA',
	HISTORY: 'HISTORY',
	PROCEED: 'PROCEED',
	ENDPOINT: 'ENDPOINT',
	BLOCK: 'BLOCK',
	RANDOMIZER: 'RANDOMIZER',
	TRACK_RANDOMIZER: 'TRACK_RANDOMIZER',
	LOOP: 'LOOP',
}
