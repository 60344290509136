import { links } from 'constants/links'
import { THUMBNAIL_EXTENSION, FILE_EXTENSIONS } from 'constants/mediaObject'

export const getMediaThumbnailUrl = url => {
	if (url === '') {
		return null
	}

	// logo does not have thumbnail
	if (url === links.GROUPSOLVER_LOGO) {
		return url
	}

	// dont use thumbnail for gif files
	if (url.endsWith(FILE_EXTENSIONS.GIF) === true) {
		return url
	}

	const urlName = url.substr(0, url.lastIndexOf('.'))
	return urlName + THUMBNAIL_EXTENSION
}
