import React from 'react'
import PropTypes from 'prop-types'

import StaticPortalOverlay from '../StaticPortalOverlay/StaticPortalOverlay'
import BlockingBackground from '../BlockingBackground'
import BlockingContent from '../BlockingContent'

const StaticBlockingOverlay = ({ children, contentClassName }) => {
	return (
		<StaticPortalOverlay>
			<BlockingBackground>
				<BlockingContent className={contentClassName}>{children}</BlockingContent>
			</BlockingBackground>
		</StaticPortalOverlay>
	)
}

StaticBlockingOverlay.defaultProps = {
	contentClassName: null,
}

StaticBlockingOverlay.propTypes = {
	children: PropTypes.node.isRequired,
	contentClassName: PropTypes.string,
}
export default StaticBlockingOverlay
