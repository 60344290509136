import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form, Field } from 'formik'
import { useIntl } from 'react-intl'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'

import _validateSetVariableVariableName from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/_validateSetVariableVariableName'
import { SET_FN_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/SET_VARIABLE_CONSTANTS'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'

import classes from './SetVariable.module.scss'
import FunctionArgument from './_components/FunctionArgument'
import { proFlowFnOptions } from './proFlowFnOptions'

const addTranslationsToOptions = (options, intl) =>
	options.map(o => ({
		...o,
		label: intl.formatMessage({ id: o.label }),
	}))

const handleFunctionSelectChange = setFieldValue => (_, option) => {
	setFieldValue('proFlowFn', option.value)
	setFieldValue('fnArguments', option.fnArguments)
}

const renderVariableNameTitle = (definition, intl) => {
	if (definition.setFn === SET_FN_TYPES.SET || definition.setFn === SET_FN_TYPES.SET_LITERAL) {
		return intl.formatMessage({ id: 'set_variable.variable_name' })
	}

	return intl.formatMessage({ id: 'set_variable.path_name' })
}

const renderDescription = (values, intl) => {
	const selectedOption = proFlowFnOptions.find(o => o.value === values.proFlowFn)

	if (selectedOption === undefined) {
		return null
	}

	return intl.formatMessage({ id: selectedOption.description })
}

const shouldRenderFnSelect = definition => {
	return definition.setFn === SET_FN_TYPES.SET || definition.setFn === SET_FN_TYPES.SET_PATH
}

const SetVariable = props => {
	const intl = useIntl()

	const renderVariableNameError = (variableName, setFn) => {
		if (variableName.includes(' ')) {
			return intl.formatMessage({ id: 'set_variable.variable_name_contains_space' })
		}

		if (
			(setFn === SET_FN_TYPES.SET || setFn === SET_FN_TYPES.SET_LITERAL) &&
			variableName.includes('.')
		) {
			return intl.formatMessage({ id: 'set_variable.variable_name_contains_dot' })
		}

		return intl.formatMessage({ id: 'set_variable.enter_variable_name' })
	}

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setFieldValue }) => (
				<Fragment>
					<Header
						closeModuleDetail={props.closeModuleDetail}
						generalDefinition={props.generalDefinition}
						disabled={props.disabled}
						moduleDefinition={values}
						isFlowChanged={props.isFlowChanged}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						showCopyToLibrary={false}
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={isSubmitting => (
								<Form>
									<Label label={renderVariableNameTitle(props.initialValues, intl)} />
									<Field
										component={Input}
										disabled={props.disabled || props.shouldShowAdvancedFeatures === false}
										name="variableName"
										type="text"
									/>
									{_validateSetVariableVariableName(values.variableName, values.setFn) ===
										false && (
										<span className="title-error">
											{renderVariableNameError(values.variableName, values.setFn)}
										</span>
									)}
									{shouldRenderFnSelect(props.initialValues) === true && (
										<Fragment>
											<Label label={intl.formatMessage({ id: 'set_variable.function' })} />
											<Field
												component={Select}
												disabled={props.disabled || props.shouldShowAdvancedFeatures === false}
												name="proFlowFn"
												onChange={handleFunctionSelectChange(setFieldValue)}
												options={addTranslationsToOptions(proFlowFnOptions, intl)}
											/>
											<div className={classes.description}>{renderDescription(values, intl)}</div>
										</Fragment>
									)}
									<Label
										label={intl.formatMessage({ id: 'set_variable.arguments' })}
										className={classes.arguments}
									/>
									{Object.entries(values.fnArguments).map(([key, value]) => (
										<FunctionArgument
											setFn={props.initialValues.setFn}
											argumentValue={value}
											argumentKey={key}
											disabled={props.disabled || props.shouldShowAdvancedFeatures === false}
											key={key}
										/>
									))}
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

SetVariable.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	shouldShowAdvancedFeatures: PropTypes.bool.isRequired,
	saveModule: PropTypes.func.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
}

export default SetVariable
