import { fnList } from 'libs/gs3-libs-flow-validation/src/validators/evaluator/_fnList'
import { fnMap } from 'libs/gs3-libs-flow-validation/src/validators/evaluator/_fnMap'
import { SET_FN_CUSTOM_VARIABLES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/SET_VARIABLE_CONSTANTS'

const createFnArgumentsValues = fnArguments =>
	fnArguments.reduce(
		(acc, fnArgument) => ({
			...acc,
			[fnArgument]: '',
		}),
		{},
	)

export const proFlowFnOptions = [
	{
		value: fnList.add,
		label: 'set_variable.functions.add',
		description: 'set_variable.functions.add.description',
		fnArguments: {
			number1: '',
			number2: '',
		},
	},
	{
		value: fnList.append,
		label: 'set_variable.functions.append',
		description: 'set_variable.functions.append.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.append].required),
	},
	{
		value: fnList.arrayLength,
		label: 'set_variable.functions.arrayLength',
		description: 'set_variable.functions.arrayLength.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.arrayLength].required),
	},
	{
		value: fnList.choose,
		label: 'set_variable.functions.choose',
		description: 'set_variable.functions.choose.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.choose].required),
	},
	{
		value: fnList.concatenate,
		label: 'set_variable.functions.concatenate',
		description: 'set_variable.functions.concatenate.description',
		fnArguments: {
			string1: '',
			string2: '',
		},
	},
	{
		value: fnList.divide,
		label: 'set_variable.functions.divide',
		description: 'set_variable.functions.divide.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.divide].required),
	},
	{
		value: fnList.firstElement,
		label: 'set_variable.functions.firstElement',
		description: 'set_variable.functions.firstElement.description',
		fnArguments: {
			var: '',
		},
	},
	{
		value: fnList.multiply,
		label: 'set_variable.functions.multiply',
		description: 'set_variable.functions.multiply.description',
		fnArguments: {
			number1: '',
			number2: '',
		},
	},
	{
		value: fnList.randomElement,
		label: 'set_variable.functions.randomElement',
		description: 'set_variable.functions.randomElement.description',
		fnArguments: {
			var: '',
		},
	},
	{
		value: fnList.randomFromRange,
		label: 'set_variable.functions.randomFromRange',
		description: 'set_variable.functions.randomFromRange.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.randomFromRange].required),
	},
	{
		value: fnList.removeElement,
		label: 'set_variable.functions.removeElement',
		description: 'set_variable.functions.removeElement.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.removeElement].required),
	},
	{
		value: fnList.round,
		label: 'set_variable.functions.round',
		description: 'set_variable.functions.round.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.round].required),
	},
	{
		value: fnList.set,
		label: 'set_variable.functions.set',
		description: 'set_variable.functions.set.description',
		fnArguments: {
			[SET_FN_CUSTOM_VARIABLES.VARIABLE_VALUE]: '',
		},
	},
	{
		value: fnList.split,
		label: 'set_variable.functions.split',
		description: 'set_variable.functions.split.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.split].required),
	},
	{
		value: fnList.subString,
		label: 'set_variable.functions.subString',
		description: 'set_variable.functions.subString.description',
		fnArguments: createFnArgumentsValues(fnMap[fnList.subString].required),
	},
	{
		value: fnList.varOrDefault,
		label: 'set_variable.functions.var_or_default',
		fnArguments: createFnArgumentsValues(fnMap[fnList.varOrDefault].required),
		description: 'set_variable.functions.var_or_default.description',
	},
]
