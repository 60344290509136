import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './BlockingBackground.module.scss'

const BlockingBackground = ({ children, isOpaque, withoutZIndex }) => {
	return (
		<div
			className={classnames(classes.holder, {
				[classes['holder--is-opaque']]: isOpaque,
				[classes['holder--without-z-index']]: withoutZIndex,
			})}
		>
			{children}
		</div>
	)
}

BlockingBackground.propTypes = {
	children: PropTypes.object.isRequired,
	isOpaque: PropTypes.bool.isRequired,
	withoutZIndex: PropTypes.bool.isRequired,
}

BlockingBackground.defaultProps = {
	isOpaque: false,
	withoutZIndex: false,
}

export default BlockingBackground
