import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import { getDefaultDynamicOptionsSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/CHOICE'

export const matrixQuestionToChoice = (matrixModule, question) => ({
	type: MODULE_DEFINITIONS.A_CHOICE.type,
	definition: {
		id: question.id,
		shortName: `${matrixModule.definition.shortName} - ${question.shortName}`,
		options: matrixModule.definition.options,
		minSelection: matrixModule.definition.minSelection,
		maxSelection: matrixModule.definition.maxSelection,
		isMatrixChoice: true,
		dynamicOptionsSettings: getDefaultDynamicOptionsSettings(),
	},
})
