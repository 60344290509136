import _ from 'lodash'
import { enTranslator as intl } from 'intl.js'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'
import { CHOICE_SUBTYPE, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { MATRIX_QUESTION as matriQuestionGenerator } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MATRIX_QUESTION'

import Choice from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Choice'

export const MATRIX_QUESTION = (index, shortNameIndex, shortName, text) => {
	const questionShortName =
		_.isNil(shortName) === true
			? `${intl.formatMessage({ id: 'question' })} ${shortNameIndex || index + 1}`
			: shortName

	const questionText =
		_.isNil(text) === true ? intl.formatMessage({ id: 'message.question.default' }) : text

	return matriQuestionGenerator(questionShortName, questionText)
}

const getTitle = definition =>
	definition.subtype === CHOICE_SUBTYPE.IMAGE ? 'matrixImageChoice.title' : 'matrixChoice.title'

const getDescription = definition =>
	definition.subtype === CHOICE_SUBTYPE.IMAGE
		? 'matrixImageChoice.dataset.description'
		: 'matrixChoice.dataset.description'

const getQuestionsDescription = definition =>
	definition.subtype === CHOICE_SUBTYPE.IMAGE
		? 'matrixImageChoice.question.description'
		: 'matrixChoice.question.description'

const getShortName = (subtype, modules) => {
	const title =
		subtype === CHOICE_SUBTYPE.IMAGE
			? intl.formatMessage({ id: 'matrixImageChoice.title' })
			: intl.formatMessage({ id: 'matrixChoice.title' })

	return getDatasetShortName(modules, title, VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE, subtype)
}

export default subtype => ({
	type: VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE,
	style: subtype === CHOICE_SUBTYPE.CHECKBOX ? 'choice' : 'image-choice',
	title: getTitle,
	description: getDescription,
	questionsDescription: getQuestionsDescription,
	component: Choice,
	generator: (modules, additionalProperties) => ({
		questions: [MATRIX_QUESTION(0), MATRIX_QUESTION(1)],
		options: [
			CHOICE_OPTION(
				subtype,
				false,
				1,
				intl.formatMessage({ id: 'choice.option.default_label' }, { index: 1 }),
				false,
			),
			CHOICE_OPTION(
				subtype,
				false,
				2,
				intl.formatMessage({ id: 'choice.option.default_label' }, { index: 2 }),
				false,
			),
		],
		shortName: getShortName(subtype, modules),
		isSharedMessageEnabled: true,
		sharedMessage: MATRIX_QUESTION(
			'',
			null,
			'shared-message',
			intl.formatMessage({ id: 'matrixChoice.matrixQuestion' }),
		),
		// filter for displayed questions
		moduleFilter: null,
		// filter for options
		filter: null,
		subtype,
		mandatory: true,
		multiple: false,
		minSelection: 1,
		maxSelection: 1,
		randomize: false,
		randomizerCount: 2,
		randomizerShowAll: true,
		randomizerAllowSkip: true,
		hasCenteredOptions: false,
		optionsShownLimit: null,
		listSettings: {
			isActive: false,
			idInputList: '',
			idMainList: '',
			question: matriQuestionGenerator(
				'[[item]]',
				'Make sure to include [[item]] in question text to present unique question for every list item',
			),
			maxIterations: null,
			listAllowSkip: true,
			attributeShortNameColumn: null,
		},
	}),
})
