import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

import AccountDisabledRoute from './_components/AccountDisabled'
import AccountInvitationRoute from './_components/AccountInvitation'
import ChangePasswordRoute from './_components/ChangePassword'
import CheckDomainRoute from './_components/CheckDomain'
import CreateUserRoute from './_components/CreateUser'
import CreatedUserRoute from './_components/CreatedUser'
import EmailNotVerifyRoute from './_components/EmailNotVerify'
import EmailVerifiedRoute from './_components/EmailVerified'
import EmailVerifyWasSendRoute from './_components/EmailVerifyWasSend'
import ForgottenPasswordRoute from './_components/ForgottenPassword'
import LoginRoute from './_components/Login'
import NewPasswordRoute from './_components/NewPassword'
import PasswordChangedRoute from './_components/PasswordChanged'
import VerifyPhoneCodeRoute from './_components/VerifyPhoneCode'
import VerifyPhoneFailedRoute from './_components/VerifyPhoneFailed'

const EntryContainer = lazy(() => import('./EntryContainer'))
const WrappedEntryContainer = errorWrapper(EntryContainer)

export default () => ({
	path: 'auth',
	element: <WrappedEntryContainer />,
	children: [
		AccountDisabledRoute(),
		AccountInvitationRoute(),
		ChangePasswordRoute(),
		CheckDomainRoute(),
		CreateUserRoute(),
		CreatedUserRoute(),
		EmailNotVerifyRoute(),
		EmailVerifiedRoute(),
		EmailVerifyWasSendRoute(),
		ForgottenPasswordRoute(),
		LoginRoute(),
		NewPasswordRoute(),
		PasswordChangedRoute(),
		VerifyPhoneCodeRoute(),
		VerifyPhoneFailedRoute(),
	],
})
