import _ from 'lodash'

export const getDatasetRows = (idDataset, datasets) =>
	_.get(
		datasets.find(dataset => dataset.value === idDataset),
		'module.questions',
		[],
	).map(row => ({
		...row,
		value: row.id,
		label: row.text,
	}))
