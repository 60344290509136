import React from 'react'
import PropTypes from 'prop-types'

import { fieldShape } from 'components/_formik/_constants'

import BaseCheckmarkCheckbox from 'components/_scaffolding/_input/CheckmarkCheckbox'

const CheckmarkCheckbox = ({ field, className, labelClassName, ...props }) => {
	const onChange = () => {
		if (props.disabled === true) return

		const newValue = field.value === false

		if (props.onChange !== undefined) {
			props.onChange(newValue)
		} else {
			props.form.setFieldValue(field.name, newValue)
		}
	}

	return (
		<BaseCheckmarkCheckbox
			checkboxWrapperClassName={props.checkboxWrapperClassName}
			checked={field.value}
			className={className}
			disabled={props.disabled}
			label={props.componentProps.label}
			labelClassName={labelClassName}
			markOnLabelClick={props.markOnLabelClick}
			name={field.name}
			onChange={onChange}
			tooltip={props.tooltip}
		/>
	)
}

CheckmarkCheckbox.defaultProps = {
	disabled: false,
	className: null,
	labelClassName: null,
	markOnLabelClick: true,
	tooltip: null,
	checkboxWrapperClassName: null,
}

CheckmarkCheckbox.propTypes = {
	disabled: PropTypes.bool.isRequired,
	field: fieldShape.isRequired,
	componentProps: PropTypes.shape({
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	}).isRequired,
	markOnLabelClick: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
	tooltip: PropTypes.string,
	checkboxWrapperClassName: PropTypes.string,
	// formik bag
	form: PropTypes.object.isRequired,
}

export default CheckmarkCheckbox
