import validateModules from './flowValidation/validateModules'
import getNonUniqueShortNames from './flowValidation/getNonUniqueShortNames'

const getInvalidModules = (
	modules,
	order,
	flatOrder,
	communityModules,
	proFlow,
	respondentSources,
	studyTags,
) => {
	const duplicateShortNamesObject = getNonUniqueShortNames(modules)
	const moduleValidationResult = validateModules(
		modules,
		order,
		flatOrder,
		communityModules,
		proFlow,
		respondentSources,
		studyTags,
		duplicateShortNamesObject,
	)

	return moduleValidationResult
}

export default getInvalidModules
