import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import { copyToClipboard } from 'helpers'
import { COLORS } from 'constants/colors'
import classes from './CopyableString.module.scss'

const CopyableString = props => {
	const { fontSize, whiteSpace, stringToDisplay, linkToCopy, className, showColor, color } = props
	const intl = useIntl()
	const [isCopied, setIsCopied] = useState(false)
	const copiedMessage = intl.formatMessage({ id: 'copied' })
	const defaultStyle = {
		fontSize,
		whiteSpace,
	}

	const copyIdToClipboard = event => {
		copyToClipboard(linkToCopy || stringToDisplay)
		setIsCopied(true)
		setTimeout(() => {
			setIsCopied(false)
		}, 2000)
		event.stopPropagation()
	}

	return (
		<div
			className={classnames(
				classes['id-holder'],
				{
					[classes['id-holder--copied']]: isCopied === true,
				},
				className,
			)}
			onClick={copyIdToClipboard}
			style={showColor === true ? { ...defaultStyle, color } : { ...defaultStyle }}
		>
			{isCopied ? copiedMessage : stringToDisplay}
		</div>
	)
}

CopyableString.propTypes = {
	color: PropTypes.string.isRequired,
	fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	whiteSpace: PropTypes.string.isRequired,
	showColor: PropTypes.bool.isRequired,
	stringToDisplay: PropTypes.string.isRequired,
	linkToCopy: PropTypes.string,
	className: PropTypes.string,
}

CopyableString.defaultProps = {
	color: COLORS.PRIMARY_45,
	showColor: false,
	fontSize: 10,
	whiteSpace: 'nowrap',
	className: '',
}

export default CopyableString
