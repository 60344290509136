import React from 'react'
import _ from 'lodash'

const defaultValues = {
	hasUnsavedChanges: false,
	setHasUnsavedChanges: _.noop,
}

export const UnsavedChangesContext = React.createContext(defaultValues)

UnsavedChangesContext.displayName = 'UnsavedChangesContext'
