import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'

import classes from './Textarea.module.scss'

const PADDING = 7
const BORDER_WIDTH = 1
const LINE_HEIGHT = 14

const getValueWithBorderAndPadding = (value, withBorder, withPadding) => {
	let newValue = value

	if (withBorder === true) {
		newValue = newValue + 2 * BORDER_WIDTH
	}

	if (withPadding === true) {
		newValue = newValue + 2 * PADDING
	}

	return newValue
}

const Textarea = ({ isAutoResize, isTextareaBig, value, withBorder, withPadding, ...props }) => {
	const textareaRef = useRef(null)

	useEffect(() => {
		if (isAutoResize === false) {
			return
		}

		// It is necessary for correct behavior of textarea during deleting
		textareaRef.current.style.height = `${getValueWithBorderAndPadding(
			LINE_HEIGHT,
			withBorder,
			withPadding,
		)}px`

		const maxContentHeight = isTextareaBig === true ? 6 * LINE_HEIGHT : 3 * LINE_HEIGHT
		const maxHeight = getValueWithBorderAndPadding(maxContentHeight, withBorder, withPadding)
		textareaRef.current.style.maxHeight = `${maxHeight}px`

		const scrollHeight = textareaRef.current.scrollHeight

		const height = getValueWithBorderAndPadding(
			scrollHeight,
			withBorder,
			// scrollheight contains padding
			false,
		)
		textareaRef.current.style.height = `${height}px`
	}, [value, isAutoResize, isTextareaBig, withBorder, withPadding])

	const style = {
		borderWidth: withBorder === true ? BORDER_WIDTH : 0,
		padding: withPadding === true ? PADDING : 0,
		lineHeight: `${LINE_HEIGHT}px`,
	}

	return (
		<textarea
			{...props}
			value={value}
			ref={textareaRef}
			style={style}
			className={classnames(classes.textarea, 'scrollbar-thin', props.className, {
				[classes.enabled]: props.disabled !== true,
				[classes.disabled]: props.disabled === true,
			})}
		/>
	)
}

Textarea.defaultProps = {
	disabled: false,
	isAutoResize: false,
	isTextareaBig: true,
	withBorder: true,
	withPadding: true,
}

Textarea.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	isAutoResize: PropTypes.bool.isRequired,
	isTextareaBig: PropTypes.bool.isRequired,
	withPadding: PropTypes.bool.isRequired,
}

export default Textarea
