import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'
import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import { FREE_TEXT_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/FREE_TEXT_TYPES'

export const isFreetextModule = module =>
	module.type === MODULE_DEFINITIONS.A_FREE_TEXT.type ||
	(module.type === MODULE_DEFINITIONS.A_SNIPPET.type &&
		module.definition.type === MODULE_DEFINITIONS.A_FREE_TEXT.type)

export const isFreetextDateType = module =>
	isFreetextModule(module) === true && module.definition.freetextType === FREE_TEXT_TYPES.DATE

export const isFreetextNumberType = module =>
	isFreetextModule(module) === true &&
	(module.definition.freetextType === FREE_TEXT_TYPES.INTEGER ||
		module.definition.freetextType === FREE_TEXT_TYPES.NUMBER ||
		module.definition.freetextType === FREE_TEXT_TYPES.AMOUNT)

export const isFreetextStringType = module =>
	isFreetextModule(module) === true &&
	isFreetextNumberType(module) === false &&
	isFreetextDateType(module) === false

export const isFreetextSelectionTypeStringAnyOf = condition =>
	condition.selection.type === CONDITION_SELECTION_TYPES.STRING_ANY_OF
