const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	title: 'evaluator',
	type: 'object',
	$id: 'http://groupsolver.com/v1/EVALUATOR.json',
	properties: {
		logic: {
			type: 'array',
		},
	},
	required: ['logic'],
}

export default schema
