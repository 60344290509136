export const validate = (module, flow, config = null) => {
	const hasDuplicateOptionLabels =
		module.definition.options.find(option => {
			return module.definition.options.filter(opt => opt.label === option.label).length > 1
		}) !== undefined

	return hasDuplicateOptionLabels === true
		? {
				infos: null,
				warnings: null,
				errors: ['Choice option Labels have to be unique.'],
		  }
		: null
}

export default validate
