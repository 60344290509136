import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import AdvancedFeatureLabel from 'components/_scaffolding/AdvancedFeatureLabel'
import Icon from 'components/_scaffolding/Icon'

import classes from './ModuleAdvancedOptionsToggle.module.scss'

const ModuleAdvancedOptionsToggle = ({
	className = null,
	id,
	isAdvancedFeature = false,
	isExpanded,
	onClick,
	label = null,
	isInvalid = false,
}) => {
	const intl = useIntl()

	const labelToDisplay = label !== null ? label : intl.formatMessage({ id: 'advanced_options' })

	return (
		<div
			id={id}
			onClick={onClick}
			className={classnames(classes.expandable, {
				[className]: className !== null,
			})}
		>
			<div className={classes.icon}>
				<Icon name={isExpanded === true ? Icon.NAMES.CHEVRON_UP : Icon.NAMES.CHEVRON_DOWN} />
			</div>
			<div title={labelToDisplay} className={classes.label}>
				{labelToDisplay}
				{isInvalid === true && <div className={classes.invalid} />}
			</div>
			{isAdvancedFeature === true && (
				<div className={classes.icon}>
					<AdvancedFeatureLabel />
				</div>
			)}
		</div>
	)
}

ModuleAdvancedOptionsToggle.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	isAdvancedFeature: PropTypes.bool,
	isExpanded: PropTypes.bool.isRequired,
	isInvalid: PropTypes.bool,
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired,
}

export default ModuleAdvancedOptionsToggle
