import _ from 'lodash'
import { tools } from 'constants/tools'
import { enTranslator as intl } from 'intl.js'

export const translateSegmentLabel = segment => {
	if (_.isNil(segment) === true) {
		return intl.formatMessage({ id: 'segment_no_longer_exists' })
	}

	const { idSegment, label } = segment

	return idSegment === tools.TOTAL_SEGMENT_UUID
		? intl.formatMessage({ id: 'all_completes' })
		: label
}
