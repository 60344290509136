import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form, Field } from 'formik'
import { useIntl } from 'react-intl'

import { LIST_INPUT_TYPE } from 'constants/studyDesign'

import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Tooltip from 'components/_scaffolding/Tooltip'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'

import InputListSettings from './_components/InputListSettings'
import ListFileSettings from './_components/ListFileSettings'
import ListFilterSettings from './_components/ListFilterSettings'

import classes from './List.module.scss'

const List = props => {
	const intl = useIntl()

	const isStudyEditable = getIsStudyEditable(props.studyState)

	const listInputTypeOptions = [
		{
			label: intl.formatMessage({ id: 'list.detail.input_type.file' }),
			value: LIST_INPUT_TYPE.FILE,
		},
		{
			label: intl.formatMessage({ id: 'list.detail.input_type.list' }),
			value: LIST_INPUT_TYPE.LIST,
		},
	]

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setFieldValue, setValues }) => (
				<Fragment>
					<Header
						closeModuleDetail={props.closeModuleDetail}
						copyModule={null}
						copyModuleButtonParams={props.copyModuleButtonParams}
						disabled={props.disabled}
						generalDefinition={props.generalDefinition}
						isFlowChanged={props.isFlowChanged}
						isRelatedModuleInvalid={false}
						moduleDefinition={values}
						openCopyToLibraryForm={() => {}}
						showCopyToLibrary
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => (
								<Form>
									<DatasetShortName
										disabled={props.disabled}
										errorInvalidSimpleName={props.errorInvalidSimpleName}
										errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
										values={values}
									/>
									<Label
										className={classes['section-label']}
										label={intl.formatMessage({ id: 'dataset' })}
									/>
									<div
										data-for={'input-type-tooltip'}
										data-tip={intl.formatMessage({ id: 'list.detail.input_type.cannot_change' })}
									>
										<Field
											component={Select}
											disabled={props.disabled || values.inputType !== null}
											name={'inputType'}
											options={listInputTypeOptions}
										/>
									</div>
									{values.inputType === null && (
										<div className="title-error">
											{intl.formatMessage({ id: 'list.detail.input_type.no_type_error' })}
										</div>
									)}
									{values.inputType !== null && <Tooltip id="input-type-tooltip" />}
									{values.inputType === LIST_INPUT_TYPE.FILE && (
										<ListFileSettings
											disabled={props.disabled}
											isStudyEditable={isStudyEditable}
											isUnsaved={props.isUnsaved}
											setFieldValue={setFieldValue}
											setValues={setValues}
											values={values}
										/>
									)}
									{values.inputType === LIST_INPUT_TYPE.LIST && (
										<InputListSettings
											disabled={props.disabled}
											isStudyEditable={isStudyEditable}
											isUnsaved={props.isUnsaved}
											moduleErrors={props.moduleErrors}
											values={values}
										/>
									)}
									{(values.items.length !== 0 || values.idInputList !== '') && (
										<Label
											className={classes['section-label']}
											label={intl.formatMessage({ id: 'filter' })}
										/>
									)}
									<ListFilterSettings
										disabled={props.disabled}
										setFieldValue={setFieldValue}
										values={values}
									/>
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

List.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	moduleErrors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	saveModule: PropTypes.func.isRequired,
	studyState: PropTypes.string.isRequired,
}

export default List
