import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import classes from './IconButton.module.scss'

const IconButton = ({ iconClassName, label, onClick }) => (
	<div className={classes.wrapper} onClick={onClick}>
		<div className={classes.holder}>
			<div className={classnames(classes['icon-holder'], iconClassName)} />
			{label}
		</div>
	</div>
)

IconButton.propTypes = {
	iconClassName: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
}

export default IconButton
