import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import { VISUAL_FLOW_REMOVE_TYPES } from 'constants/studyDesign'

const removeFromBranch = (idModule, path, removeType, branch) => {
	const updatedBranch = [...branch]

	const indexToRemove = branch.findIndex(module => module.id === idModule && module.path === path)

	if (indexToRemove === -1) {
		branch.forEach(module => {
			if (module.type === MODULE_DEFINITIONS.UI_COMMAND.type && module.then.length > 0) {
				module.then = removeFromBranch(idModule, path, removeType, module.then)
			}
		})
	} else {
		if (removeType === VISUAL_FLOW_REMOVE_TYPES.REMOVE_BRANCH) {
			const moduleToRemove = updatedBranch[indexToRemove]

			updatedBranch.splice(
				indexToRemove,
				1,
				...moduleToRemove.then.filter(m => m.type !== MODULE_DEFINITIONS.A_END_STUDY.type),
			)
		} else {
			updatedBranch.splice(indexToRemove, 1)
		}
	}

	return updatedBranch
}

const removeFromOrder = (idModule, path, removeType, order) => {
	const updatedOrder = removeFromBranch(idModule, path, removeType, order)

	return updatedOrder
}

export default removeFromOrder
