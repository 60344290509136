import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'

import { getHasInvalidInstruction } from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateAllocation'

const AllocationInstructionSettings = ({ disabled, values }) => {
	const intl = useIntl()

	return (
		<Fragment>
			<Field
				component={Checkbox}
				componentProps={{
					label: intl.formatMessage({ id: 'allocation.detail.instruction.isVisible' }),
				}}
				disabled={disabled}
				name="instructionSettings.isVisible"
			/>
			<Field
				component={Checkbox}
				componentProps={{
					label: intl.formatMessage({ id: 'allocation.detail.instruction.isCustom' }),
				}}
				disabled={disabled || values.instructionSettings.isVisible === false}
				name="instructionSettings.isCustom"
			/>
			{values.instructionSettings.isCustom === true && (
				<Fragment>
					<Label label={intl.formatMessage({ id: 'allocation.detail.instruction.text' })} />
					<Field
						component={Input}
						disabled={disabled || values.instructionSettings.isVisible === false}
						name={'instructionSettings.text'}
					/>
					{getHasInvalidInstruction({ definition: values }) === true && (
						<span className="title-error">
							{intl.formatMessage({ id: 'allocation.detail.instruction.text.error' })}
						</span>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

AllocationInstructionSettings.propTypes = {
	disabled: PropTypes.bool.isRequired,
}

export default AllocationInstructionSettings
