import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const UpgradePage = lazy(() => import('./UpgradePage'))
const WrappedAccount = errorWrapper(UpgradePage)

export default () => ({
	path: 'upgrade',
	element: <WrappedAccount />,
})
