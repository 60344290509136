/**
 * Ensures that string is at most `maximum` characters long.
 *
 * @param  {Integer} maximum 	Upper bound of length of the string
 * @param  {String} value   	String to limit
 *
 * @return {String}         	Limited string
 */
const takeMaximumAllowedStr = (maximum, value) => {
	if (value.length <= maximum) return value
	return (
		value
			.toString()
			.substr(0, maximum)
			.trim() + '\u2026'
	)
}

const splitStringOnNewLine = string => {
	const normalizedString = string.replace(/\n|\r|[\r\n]/g, '\n')

	return normalizedString.split('\n')
}

export default { splitStringOnNewLine, takeMaximumAllowedStr }
