export const RESPONDENT_SOURCE_TYPE = {
	COMMUNITY_RESPONDENT_SOURCE: 'community',
	DEFAULT_RESPONDENT_SOURCE: 'dashboard_default',
	PANEL_RESPONDENT_SOURCE: 'panel',
}

export const GS_PREVIEW_MATCH_PARAMS = {
	gs_preview: true,
}

export const INDUSTRY_OTHER_OPTION_VALUE = 'other'

export const industryOptions = [
	{
		label: 'explicit_content',
		value: 'explicit_content',
	},
	{
		label: 'sensitive_content',
		value: 'sensitive_content',
	},
	{
		label: 'general_population',
		value: INDUSTRY_OTHER_OPTION_VALUE,
	},
	{
		label: 'websites_internet_ecommerce',
		value: 'websites_internet_ecommerce',
	},
	{
		label: 'video_games',
		value: 'video_games',
	},
	{
		label: 'travel',
		value: 'travel',
	},
	{
		label: 'transportation_shipping',
		value: 'transportation_shipping',
	},
	{
		label: 'toys',
		value: 'toys',
	},
	{
		label: 'tobacco_smokers',
		value: 'tobacco_smokers',
	},
	{
		label: 'telecommunications_phone_cell_phone_cable',
		value: 'telecommunications_phone_cell_phone_cable',
	},
	{
		label: 'sports',
		value: 'sports',
	},
	{
		label: 'restaurants',
		value: 'restaurants',
	},
	{
		label: 'publishing_newspaper_magazines_books',
		value: 'publishing_newspaper_magazines_books',
	},
	{
		label: 'politics',
		value: 'politics',
	},
	{
		label: 'pets',
		value: 'pets',
	},
	{
		label: 'personal_care_toiletries',
		value: 'personal_care_toiletries',
	},
	{
		label: 'it_servers_databases_etc',
		value: 'it_servers_databases_etc',
	},
	{
		label: 'home_improvement_real_estate_construction',
		value: 'home_improvement_real_estate_construction',
	},
	{
		label: 'home_entertainment_dvd_vhs',
		value: 'home_entertainment_dvd_vhs',
	},
	{
		label: 'home_utilities_appliances',
		value: 'home_utilities_appliances',
	},
	{
		label: 'healthcare_pharmaceuticals',
		value: 'healthcare_pharmaceuticals',
	},
	{
		label: 'gambling_lottery',
		value: 'gambling_lottery',
	},
	{
		label: 'food_snacks',
		value: 'food_snacks',
	},
	{
		label: 'financial_services_insurance',
		value: 'financial_services_insurance',
	},
	{
		label: 'fashion_clothing',
		value: 'fashion_clothing',
	},
	{
		label: 'entertainment_movies_music_tv_etc',
		value: 'entertainment_movies_music_tv_etc',
	},
	{
		label: 'electronics_computer_software',
		value: 'electronics_computer_software',
	},
	{
		label: 'education',
		value: 'education',
	},
	{
		label: 'beverages_non_alcoholic',
		value: 'beverages_non_alcoholic',
	},
	{
		label: 'beverages_alcoholic',
		value: 'beverages_alcoholic',
	},
	{
		label: 'beauty_cosmetics',
		value: 'beauty_cosmetics',
	},
	{
		label: 'automotive',
		value: 'automotive',
	},
]
