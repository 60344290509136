import { enTranslator as intl } from 'intl.js'

import Allocation from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Allocation'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_OPTION } from './helpers/CHOICE_OPTION'
import {
	VISUAL_FLOW_MODULE_TYPES,
	ALLOCATION_SUBTYPE,
	ALLOCATION_LIMIT_TYPES,
} from 'constants/studyDesign'
import { GET_QUESTION_MESSAGE } from './MESSAGE'
import { getDefaultDynamicOptionsSettings } from './CHOICE'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
	style: 'allocation',
	title: () => 'allocation.title',
	description: () => 'allocation.description',
	component: Allocation,
	generator: (modules, additionalProperties) => ({
		filter: '',
		idRelatedMessage: null,
		limit: {
			isEnabled: true,
			useLimitAsMin: true,
			value: {
				type: ALLOCATION_LIMIT_TYPES.VALUE,
				value: 100,
			},
		},
		hintValueSettings: {
			addSpace: false,
			isLeft: false,
			unitLabel: '',
		},
		instructionSettings: {
			isVisible: false,
			isCustom: false,
			text: '',
		},
		mandatory: true,
		dynamicOptionsSettings: getDefaultDynamicOptionsSettings(),
		options: [
			CHOICE_OPTION(
				ALLOCATION_SUBTYPE.INPUT,
				false,
				1,
				intl.formatMessage({ id: 'allocation.option.default_label' }, { index: 1 }),
				true,
			),
			CHOICE_OPTION(
				ALLOCATION_SUBTYPE.INPUT,
				false,
				2,
				intl.formatMessage({ id: 'allocation.option.default_label' }, { index: 2 }),
				true,
			),
		],
		range: {
			max: '',
			min: 0,
			step: 0, // will be used if we'll implement slider subtype
		},
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'allocation.title' }),
			VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
		),
		subtype: ALLOCATION_SUBTYPE.INPUT,
		messages: [GET_QUESTION_MESSAGE()],
	}),
}
