import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import classes from './FormHolder.module.scss'

const FormHolder = ({ children }) => (
	<div className={classnames('test-flow-detail', classes['wrapper-detail'])}>{children}</div>
)

FormHolder.propTypes = {
	children: PropTypes.node.isRequired,
}

export default FormHolder
