export const makeStatementsObject = (statements, statementGroups) => {
	return [...statements, ...statementGroups].reduce((acc, statementOrGroup) => {
		const idStatement = statementOrGroup.idStatement || statementOrGroup.idStatementGroup

		return {
			...acc,
			[idStatement]: {
				idStatement,
				label: statementOrGroup.label,
				groupedStatements: statementOrGroup.groupedStatements || null,
				totalSegmentSupport: statementOrGroup.totalSegmentSupport,
			},
		}
	}, {})
}
