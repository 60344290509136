import React from 'react'
import PropTypes from 'prop-types'
import { ICONS } from 'constants/icons'

import classes from './Icon.module.scss'
import classnames from 'classnames'

const iconNamesList = Object.values(ICONS)

const Icon = ({ customClassName = null, name, size = 20, ...restOfProps }) => {
	if (iconNamesList.includes(name) === false) {
		throw new Error(`Icon named ${name} is not included in icon list`)
	}

	// eslint-disable-next-line import/no-webpack-loader-syntax
	const SvgIcon = require(`!@svgr/webpack!styles/icons/svg/${name}.svg`).default

	const iconClassName = classnames(classes.icon, {
		[customClassName]: customClassName !== null,
	})

	return (
		<SvgIcon
			{...restOfProps}
			data-icon-name={name}
			className={iconClassName}
			height={size}
			width={size}
		/>
	)
}

Icon.NAMES = ICONS

Icon.propTypes = {
	customClassName: PropTypes.string,
	name: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Icon
