import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

const getModuleNameByType = type => {
	switch (type) {
		case MODULE_DEFINITIONS.A_MESSAGE.type:
			return 'Message'
		case MODULE_DEFINITIONS.A_CHOICE.type:
			return 'Choice'
		case MODULE_DEFINITIONS.A_IMAGE_CHOICE.type:
			return 'Image Choice'
		case MODULE_DEFINITIONS.A_FREE_TEXT.type:
			return 'Freetext'
		case MODULE_DEFINITIONS.A_OEQ.type:
			return 'AI Open-End™'
		case MODULE_DEFINITIONS.UI_COMMAND.type:
			return 'Checkpoint'
		case MODULE_DEFINITIONS.A_END_STUDY.type:
			return 'Endpoint'
		case MODULE_DEFINITIONS.A_SNIPPET.type:
			return 'Snippet'
		default:
			return 'Invalid module type'
	}
}

export default getModuleNameByType
