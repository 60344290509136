import FreeText from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/FreeText'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { FREE_TEXT_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/FREE_TEXT_TYPES'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { GET_QUESTION_MESSAGE } from './MESSAGE'

const defaultTextSettings = {
	validationPattern: '.*',
	placeholder: 'Enter your answer',
	inputHint: null,
	title: 'freetext.text.title',
	description: 'freetext.text.description',
	datasetShortName: 'Text',
	freetextType: FREE_TEXT_TYPES.TEXT,
}

export const getSettings = freetextType => {
	switch (freetextType) {
		case FREE_TEXT_TYPES.TEXT:
			return defaultTextSettings
		case FREE_TEXT_TYPES.DATE:
			return {
				validationPattern: '^(0?[1-9]|1[0-2])\\/(0?[1-9]|[1,2]\\d|3[0,1])\\/\\d{4}$',
				placeholder: 'Enter a date',
				inputHint: 'e.g. mm/dd/year',
				title: 'freetext.date.title',
				description: 'freetext.date.description',
				datasetShortName: 'US Date',
				freetextType,
			}
		case FREE_TEXT_TYPES.PHONE:
			return {
				validationPattern: '^(\\([0-9]{3}\\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$',
				placeholder: 'Enter a phone number',
				inputHint: 'e.g. 000-123-4567',
				title: 'freetext.phone.title',
				description: 'freetext.phone.description',
				datasetShortName: 'US Phone',
				freetextType,
			}
		case FREE_TEXT_TYPES.EMAIL:
			return {
				validationPattern: '\\S{1,}@\\S{1,}\\.\\S{2,}',
				placeholder: 'Enter your email',
				inputHint: 'e.g. my@mail.com',
				title: 'freetext.email.title',
				description: 'freetext.email.description',
				datasetShortName: 'Email',
				freetextType,
			}
		case FREE_TEXT_TYPES.ZIP:
			return {
				validationPattern: '^\\d{5}(-\\d{4})?$',
				placeholder: 'Enter your ZIP code',
				inputHint: 'e.g. 12345',
				title: 'freetext.zip.title',
				description: 'freetext.zip.description',
				datasetShortName: 'ZIP code',
				freetextType,
			}
		case FREE_TEXT_TYPES.INTEGER:
			return {
				validationPattern: '^-?\\d+$',
				placeholder: 'Enter your answer',
				inputHint: 'e.g. 123',
				title: 'freetext.integer.title',
				description: 'freetext.integer.description',
				datasetShortName: 'Integer',
				freetextType,
			}
		case FREE_TEXT_TYPES.NUMBER:
			return {
				validationPattern: '^-?\\d+((\\.|\\,)\\d+)?$',
				placeholder: 'Enter your answer',
				inputHint: 'e.g. 1.5',
				title: 'freetext.number.title',
				description: 'freetext.number.description',
				datasetShortName: 'Number',
				freetextType,
			}
		case FREE_TEXT_TYPES.AMOUNT:
			return {
				validationPattern: '^-?\\d+((\\.|\\,)\\d{1,2})?$',
				placeholder: 'Enter amount',
				inputHint: 'e.g. 1000.00',
				title: 'freetext.amount.title',
				description: 'freetext.amount.description',
				datasetShortName: 'Amount',
				freetextType,
			}
		default:
			return defaultTextSettings
	}
}

const getValidationPattern = additionalProperties =>
	getSettings(additionalProperties.freetextType).validationPattern

const getPlaceholder = additionalProperties =>
	getSettings(additionalProperties.freetextType).placeholder

const getInputHint = additionalProperties =>
	getSettings(additionalProperties.freetextType).inputHint

const getTitle = definition => getSettings(definition.freetextType).title

const getDescription = definition => getSettings(definition.freetextType).description

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT,
	style: 'free-text',
	title: definition => getTitle(definition),
	description: definition => getDescription(definition),
	component: FreeText,
	generator: (modules, additionalProperties) => ({
		shortName: getDatasetShortName(
			modules,
			getSettings(additionalProperties.freetextType).datasetShortName,
			VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT,
			additionalProperties.freetextType,
		),
		freetextType: additionalProperties.freetextType,
		idRelatedMessage: null,
		textareaForm: false,
		skip: false,
		placeholder: getPlaceholder(additionalProperties),
		inputHint: getInputHint(additionalProperties),
		maxInputLength: 100,
		validationPattern: getValidationPattern(additionalProperties),
		messages: [GET_QUESTION_MESSAGE()],
	}),
}
