import Papa from 'papaparse'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { hasCsvError } from 'helpers/csv'
import { getNewListColumn } from 'helpers/visualFlowModules/getNewListColumn'

import { showNotification } from 'store/notification'
import { ALLOWED_TEXT_FORMATS } from 'constants/mediaObject'

import Icon from 'components/_scaffolding/Icon'

import classes from './ListFileUploader.module.scss'

const ListFileUploader = props => {
	const dispatch = useDispatch()
	const intl = useIntl()

	const [inputKey, setInputKey] = useState(0)
	const forceUpdate = () => setInputKey(inputKey + 1)

	const idInput = 'list-file-uploader'

	const showErrorNotification = text =>
		dispatch(
			showNotification(
				{
					id: 'list-upload-failed',
					type: 'error',
					message: text,
					buttons: ['dismiss'],
				},
				true,
			),
		)

	const onParseComplete = filename => results => {
		const { data: uploadedData, meta, errors } = results

		if (hasCsvError(errors, meta.fields.length === 1)) {
			showErrorNotification(
				intl.formatMessage(
					{ id: 'data_importer.parse_error' },
					{ row: errors[0].row + 1, error: errors[0].message },
				),
			)

			forceUpdate()
			return
		}

		const columnNames = meta.fields.filter(fieldName => fieldName.trim() !== '')

		const data = uploadedData.filter(row => columnNames.some(column => row[column] !== ''))

		const columns = columnNames.map(fieldName => {
			const fieldValues = data.map(d => d[fieldName])

			const isUnique = new Set(fieldValues).size === fieldValues.length

			return getNewListColumn(fieldName, isUnique)
		})

		const uniqueColumns = columns.filter(column => column.isUnique === true)

		if (uniqueColumns.length === 0) {
			showErrorNotification(intl.formatMessage({ id: 'data_importer.no_unique_column_error' }))
			forceUpdate()
			return
		}

		const hasDuplicateColumnNames = new Set(meta.fields).size !== meta.fields.length

		if (hasDuplicateColumnNames === true) {
			showErrorNotification(intl.formatMessage({ id: 'data_importer.duplicate_column_error' }))
			forceUpdate()
			return
		}

		if (props.isStudyEditable === false && props.isUnsaved !== true) {
			// validate that identifier, every item and every column exist in the updated list
			const currentIdentifier = props.values.identifier
			const currentItems = props.values.items
			const currentColumns = props.values.columns

			const newIdentifierColumn = columns.find(column => column.key === currentIdentifier)

			if (newIdentifierColumn === undefined) {
				showErrorNotification(intl.formatMessage({ id: 'list.live_upload.identifier.missing' }))
				forceUpdate()
				return
			}

			if (newIdentifierColumn.isUnique === false) {
				showErrorNotification(intl.formatMessage({ id: 'list.live_upload.identifier.not_unique' }))
				forceUpdate()
				return
			}

			const hasMissingColumns = currentColumns.some(
				column => columns.find(newColumn => newColumn.key === column.key) === undefined,
			)

			if (hasMissingColumns === true) {
				showErrorNotification(intl.formatMessage({ id: 'list.live_upload.missing_columns' }))
				forceUpdate()
				return
			}

			const hasMissingItems = currentItems.some(
				item => data.find(row => row[currentIdentifier] === item[currentIdentifier]) === undefined,
			)

			if (hasMissingItems === true) {
				showErrorNotification(intl.formatMessage({ id: 'list.live_upload.missing_items' }))
				forceUpdate()
				return
			}
		}

		const formattedData = data.map(row => ({
			..._.omit(row, ['']),
			gsTagIsHidden: false,
			gsTagIsAlwaysCarryForward: false,
			gsTagIsRandomized: false,
		}))

		props.handleFileUpload(columns, formattedData, filename)
		forceUpdate()
	}

	const parseFile = (filename, file) => {
		Papa.parse(file, {
			header: true,
			dynamicTyping: false,
			skipEmptyLines: true,
			complete: onParseComplete(filename),
			transform: value => {
				return _.isString(value) === true ? value.trim() : value
			},
		})
	}

	const handleOnLoad = (fileReader, filename) => event => {
		const file = fileReader.result
		parseFile(filename, file)
	}

	const handleFileAdd = event => {
		const file = event.currentTarget.files[0]

		if (file.type !== ALLOWED_TEXT_FORMATS) {
			showErrorNotification(intl.formatMessage({ id: 'data_importer.invalid_file_format' }))

			return
		}

		const { name: filename } = file
		const fileReader = new FileReader()
		fileReader.onloadend = handleOnLoad(fileReader, filename)
		fileReader.readAsText(file)
	}

	const handleManageClick = () => {
		if (props.disabled === true) {
			return
		}

		props.openItemsManager()
	}

	return (
		<div className={classes.upload__holder}>
			<div className={classes['upload__file-label']}>
				<Icon name={Icon.NAMES.FILE} />
				{props.isEmpty === true
					? intl.formatMessage({ id: 'list.detail.upload.no_file' })
					: props.filename}
			</div>
			{props.disabled !== true && (
				<div className={classes.upload__actions}>
					{props.isEmpty === false && (
						<label className={classes.upload__manage} onClick={handleManageClick}>
							{intl.formatMessage({ id: 'list.detail.manage_items' })}
						</label>
					)}
					<label htmlFor={idInput} className={classes.upload__label}>
						{intl.formatMessage({
							id: props.isEmpty === true ? 'list.detail.upload' : 'list.detail.replace_file',
						})}
					</label>
				</div>
			)}
			<input
				accept={ALLOWED_TEXT_FORMATS}
				className={classes.uploader}
				disabled={props.disabled}
				id={idInput}
				onChange={handleFileAdd}
				type="file"
				key={inputKey}
			/>
		</div>
	)
}

ListFileUploader.propTypes = {
	disabled: PropTypes.bool.isRequired,
	filename: PropTypes.string.isRequired,
	handleFileUpload: PropTypes.func.isRequired,
	isEmpty: PropTypes.bool.isRequired,
	isStudyEditable: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	values: PropTypes.object.isRequired,
}

export default ListFileUploader
