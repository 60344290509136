import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'

import {
	isAllocationLimitValueValid,
	isAllocationLimitInputValid,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateAllocation'

import { ALLOCATION_LIMIT_TYPES } from 'constants/studyDesign'

const AllocationLimit = ({
	availabableFreeTextModules,
	disabled,
	shouldShowAdvancedFeatures,
	setFieldValue,
	values,
}) => {
	const intl = useIntl()

	const handleLimitValueTypeChange = value => {
		setFieldValue('limit', {
			...values.limit,
			value: {
				...values.limit.value,
				type: value,
				value: '',
			},
		})
	}

	const canEditLimit =
		disabled === false &&
		(shouldShowAdvancedFeatures === true ||
			values.limit.value.type !== ALLOCATION_LIMIT_TYPES.VARIABLE)

	const renderLimitValueValue = () => {
		if (values.limit.value.type === ALLOCATION_LIMIT_TYPES.FREE_TEXT_ANSWER) {
			const options = availabableFreeTextModules.map(module => ({
				label: intl.formatMessage(
					{ id: 'allocation.detail.limit.value.type.freetext.option_label' },
					{ shortName: module.definition.shortName },
				),
				value: module.definition.id,
			}))

			if (options.length === 0) {
				return (
					<span className="title-error">
						{intl.formatMessage({ id: 'allocation.detail.limit.value.type.freetext.no_options' })}
					</span>
				)
			}

			const idSelectedModule = values.limit.value.value
			const showSelectedModuleNotAvailableError =
				idSelectedModule !== '' && options.every(option => option.value !== idSelectedModule)

			return (
				<div>
					<Field
						component={Select}
						disabled={disabled}
						name={'limit.value.value'}
						options={options}
					/>
					{showSelectedModuleNotAvailableError === true && (
						<span className="title-error">
							{intl.formatMessage({
								id: 'allocation.detail.limit.value.type.freetext.question_changed',
							})}
						</span>
					)}
				</div>
			)
		}

		const inputType = values.limit.value.type === ALLOCATION_LIMIT_TYPES.VALUE ? 'number' : 'text'

		return (
			<Field
				component={Input}
				disabled={canEditLimit === false}
				name="limit.value.value"
				type={inputType}
			/>
		)
	}

	const renderLimitValue = () => {
		if (values.limit.isEnabled === false) {
			return null
		}

		const limitValueTypeOptions = [
			{
				value: ALLOCATION_LIMIT_TYPES.VALUE,
				label: intl.formatMessage({ id: 'allocation.detail.limit.value.type.option.value' }),
			},
			{
				value: ALLOCATION_LIMIT_TYPES.FREE_TEXT_ANSWER,
				label: intl.formatMessage({ id: 'allocation.detail.limit.value.type.option.freetext' }),
			},
		]

		if (
			shouldShowAdvancedFeatures === true ||
			values.limit.value.type === ALLOCATION_LIMIT_TYPES.VARIABLE
		) {
			limitValueTypeOptions.push({
				value: ALLOCATION_LIMIT_TYPES.VARIABLE,
				label: intl.formatMessage({ id: 'allocation.detail.limit.value.type.option.variable' }),
				disabled: shouldShowAdvancedFeatures === false,
			})
		}

		return (
			<div>
				<Label label={intl.formatMessage({ id: 'allocation.detail.limit.value.type.label' })} />
				<Field
					component={Select}
					disabled={canEditLimit === false}
					name={'limit.value.type'}
					onChange={handleLimitValueTypeChange}
					options={limitValueTypeOptions}
				/>
				<Label label={intl.formatMessage({ id: 'allocation.detail.limit.value.value.label' })} />
				{renderLimitValueValue()}
				{isAllocationLimitInputValid({ definition: values }) === false && (
					<span className="title-error">
						{intl.formatMessage({ id: 'allocation.detail.limit.value.value.invalid' })}
					</span>
				)}
				{isAllocationLimitValueValid({ definition: values }) === false && (
					<span className="title-error">
						{intl.formatMessage({ id: 'allocation.detail.limit.value.value.invalid_number' })}
					</span>
				)}
			</div>
		)
	}

	return (
		<div>
			<Field
				component={Checkbox}
				componentProps={{
					label: intl.formatMessage({ id: 'allocation.detail.limit.enable' }),
				}}
				disabled={disabled}
				name="limit.isEnabled"
			/>
			{values.limit.isEnabled === true && (
				<Field
					component={Checkbox}
					componentProps={{
						label: intl.formatMessage({ id: 'allocation.detail.limit.force_min' }),
					}}
					disabled={disabled}
					name="limit.useLimitAsMin"
				/>
			)}
			{renderLimitValue()}
		</div>
	)
}

AllocationLimit.propTypes = {
	availabableFreeTextModules: PropTypes.arrayOf(
		PropTypes.shape({
			definition: PropTypes.shape({
				id: PropTypes.string.isRequired,
				shortName: PropTypes.string.isRequired,
			}).isRequired,
		}),
	).isRequired,
	disabled: PropTypes.bool.isRequired,
	shouldShowAdvancedFeatures: PropTypes.bool.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	values: PropTypes.shape({
		limit: PropTypes.shape({
			isEnabled: PropTypes.bool.isRequired,
			value: PropTypes.shape({
				type: PropTypes.oneOf([
					ALLOCATION_LIMIT_TYPES.FREE_TEXT_ANSWER,
					ALLOCATION_LIMIT_TYPES.VALUE,
					ALLOCATION_LIMIT_TYPES.VARIABLE,
				]).isRequired,
				value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export default AllocationLimit
