import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import { useIntl } from 'react-intl'

import classes from './Badge.module.scss'

const badgePresets = {
	beta: 'beta',
}

const COLORS = {
	grey: 'grey',
	green: 'green',
	red: 'red',
	yellow: 'yellow',
	blue: 'blue',
}

const getLabel = (preset, label, intl) => {
	// when we do not get preset, or we want to override default label
	if (label !== null) {
		return label
	}

	const labelFromPreset = badgePresets[preset]

	if (labelFromPreset === undefined) {
		throw new Error(`Unknown preset: ${preset}`)
	}

	return intl.formatMessage({ id: labelFromPreset })
}

const Badge = ({
	color = COLORS.grey,
	badgeClassName = null,
	preset = null,
	label = null,
	withBorder = false,
}) => {
	const intl = useIntl()

	return (
		<div
			className={classnames(classes.badge, classes[`badge--${color}`], {
				[classes[`badge--${preset}`]]: preset !== null,
				[classes[`badge--with-border`]]: withBorder === true,
				[badgeClassName]: badgeClassName !== null,
			})}
		>
			{getLabel(preset, label, intl)}
		</div>
	)
}

Badge.PRESETS = badgePresets

Badge.COLORS = COLORS

Badge.propTypes = {
	badgeClassName: PropTypes.string,
	color: PropTypes.oneOf(Object.values(Badge.COLORS)),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	preset: PropTypes.oneOf(Object.values(Badge.PRESETS)),
	withBorder: PropTypes.bool,
}

export default Badge
