import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Label from 'components/_formik/_base/Label'
import Input from 'components/_formik/_base/Input'
import Select from 'components/_formik/_base/Select'
import ListFilterIncludeNoData from 'components/_formik/_complex/StudyDesign/ListFilterIncludeNoData'

import {
	getNumberConditionSelectionTypeOptions,
	numberConditionSelectionTypes,
} from 'helpers/conditionBuilder/getNumberConditionSelectionTypeOptions'

import { LIST_ALLOCATION_FILTER_FUNCTIONS, LIST_FILTER_RULE_TYPES } from 'constants/studyDesign'

import classes from './AllocationRankingQuestionRule.module.scss'

const AllocationRankingQuestionRule = props => {
	const intl = useIntl()

	const { ruleIndex } = props

	const availableModules =
		props.rule.ruleType === LIST_FILTER_RULE_TYPES.ALLOCATION_QUESTION
			? props.upperAllocations
			: props.upperRankingQuestions

	const flowModuleOptions = availableModules
		.filter(module => {
			const { dynamicOptionsSettings } = module.definition

			if (dynamicOptionsSettings.isActive === false) {
				return false
			}

			return dynamicOptionsSettings.idMainList === props.selectedList.definition.id
		})
		.map(module => ({
			value: module.definition.id,
			label: module.definition.shortName,
		}))

	const negationSelectOptions = [
		{
			value: true,
			label: intl.formatMessage({ id: 'is' }),
		},
		{
			value: false,
			label: intl.formatMessage({ id: 'is_not' }),
		},
	]

	const selectedModule = availableModules.find(
		module => module.definition.id === props.rule.idModule,
	)

	const functionSelectOptions = [
		{
			value: LIST_ALLOCATION_FILTER_FUNCTIONS.MAX,
			label: intl.formatMessage({ id: 'list_filter.detail.rule.allocation.max' }),
		},
		{
			value: LIST_ALLOCATION_FILTER_FUNCTIONS.MIN,
			label: intl.formatMessage({ id: 'list_filter.detail.rule.allocation.min' }),
		},
		...getNumberConditionSelectionTypeOptions(intl),
	]

	return (
		<div id={`rule-${ruleIndex}`}>
			<Label
				label={intl.formatMessage({
					id: 'list_filter.detail.rule.question.question',
				})}
			/>
			<Field
				component={Select}
				disabled={props.disabled}
				name={`filterRules[${ruleIndex}].idModule`}
				options={flowModuleOptions}
			/>
			{props.rule.idModule === '' && (
				<div className="title-info">
					{intl.formatMessage({ id: 'list_filter.detail.rule.question.select_question' })}
				</div>
			)}
			{props.rule.idModule !== '' && selectedModule === undefined && (
				<div className="title-error">
					{intl.formatMessage({ id: 'list_filter.detail.rule.question.missing_question' })}
				</div>
			)}
			<Label label={intl.formatMessage({ id: 'comparison_rule' })} />
			<div className={classes.split}>
				<Field
					component={Select}
					disabled={props.disabled}
					name={`filterRules[${ruleIndex}].is`}
					options={negationSelectOptions}
				/>
				<Field
					component={Select}
					disabled={props.disabled}
					name={`filterRules[${ruleIndex}].function`}
					options={functionSelectOptions}
				/>
			</div>
			{numberConditionSelectionTypes.some(
				selectionType => selectionType.value === props.rule.function,
			) && (
				<div className={classes.value}>
					<Field
						component={Input}
						disabled={props.disabled}
						name={`filterRules[${ruleIndex}].value`}
						type="number"
					/>
					{props.rule.value === '' && (
						<div className="title-error">
							{intl.formatMessage({ id: 'list_filter.detail.rule.allocation.value_empty' })}
						</div>
					)}
				</div>
			)}
			<ListFilterIncludeNoData
				disabled={props.disabled}
				ruleIndex={ruleIndex}
				ruleType={props.rule.ruleType}
			/>
		</div>
	)
}

AllocationRankingQuestionRule.propTypes = {
	disabled: PropTypes.bool.isRequired,
	upperAllocations: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	upperRankingQuestions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	rule: PropTypes.object.isRequired,
	ruleIndex: PropTypes.number.isRequired,
	selectedList: PropTypes.object.isRequired,
}

export default AllocationRankingQuestionRule
