import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Presentations = lazy(() => import('./Presentations'))
const WrappedPresentations = errorWrapper(Presentations)

export default () => ({
	path: 'presentations',
	element: <WrappedPresentations />,
})
