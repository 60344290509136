import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import classes from './StatementOption.module.scss'

const StatementOption = props => {
	const { data, getValue, selectOption } = props

	const handleClick = () => {
		selectOption(data)
	}

	const selectValue = getValue()
	const isSelected = selectValue.some(option => option.value === data.value)

	return (
		<div className={classes.wrapper} onClick={handleClick}>
			<div
				className={classnames(classes.checkbox, {
					[classes['checkbox--checked']]: isSelected === true,
				})}
			/>
			<div className={classes.label}>{data.label}</div>
		</div>
	)
}

StatementOption.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	}).isRequired,
	getValue: PropTypes.func.isRequired,
	selectOption: PropTypes.func.isRequired,
}

export default StatementOption
