import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'

import { INTERACTIVE_SIZE_PRESETS } from 'constants/interactiveSizePresets'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import SelectAction from 'components/_scaffolding/_input/SelectAction'

const OptionActions = props => {
	const intl = useIntl()

	const baseOptions = [
		{
			label: intl.formatMessage({
				id:
					props.moduleType !== VISUAL_FLOW_MODULE_TYPES.MAXDIFF
						? 'choice.options.add_above'
						: 'maxDiff.options.add_above',
			}),
			value: props.addOptionAbove,
		},
		{
			label: intl.formatMessage({
				id:
					props.moduleType !== VISUAL_FLOW_MODULE_TYPES.MAXDIFF
						? 'choice.options.add_below'
						: 'maxDiff.options.add_below',
			}),
			value: props.addOptionBelow,
		},
	]

	const options =
		props.moduleType !== VISUAL_FLOW_MODULE_TYPES.MAXDIFF
			? [
					{
						label: intl.formatMessage({
							id:
								props.isRandomized === true
									? 'choice.options.pin_option'
									: 'choice.options.randomize_option',
						}),
						value: props.toggleRandomize,
					},
					...baseOptions,
			  ]
			: baseOptions

	if (props.canRemove === true) {
		options.push({
			label: intl.formatMessage({ id: 'remove' }),
			value: props.removeOption,
		})
	}

	if (props.canRestore === true) {
		options.push({
			label: intl.formatMessage({ id: 'restore' }),
			value: props.restoreOption,
		})
	}

	const onChange = option => {
		option.value()
	}

	return (
		<SelectAction
			id={`${props.idOption}-option-actions`}
			isDisabled={props.disabled}
			onChange={onChange}
			options={options}
			sizePreset={INTERACTIVE_SIZE_PRESETS.SMALL}
		/>
	)
}

OptionActions.propTypes = {
	addOptionAbove: PropTypes.func.isRequired,
	addOptionBelow: PropTypes.func.isRequired,
	canRemove: PropTypes.bool.isRequired,
	canRestore: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	idOption: PropTypes.string.isRequired,
	isRandomized: PropTypes.bool.isRequired,
	moduleType: PropTypes.string.isRequired,
	removeOption: PropTypes.func.isRequired,
	restoreOption: PropTypes.func.isRequired,
	toggleRandomize: PropTypes.func.isRequired,
}

export default OptionActions
