import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { enTranslator as intl } from 'intl.js'
import moment from 'moment'

import { API, actions as ApiActions } from 'store/api'
import { showNotification } from 'store/notification'

import addToOrder from 'routes/_study/StudyDesign/_store/helpers/addModule/_toOrder.js'
import { ENDSTUDY_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/END_STUDY'
import { PREPARATION_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/PREPARATION_TYPES'
import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'
import DropZoneType from 'routes/_study/StudyDesign/_dnd/DropZone/DropZoneType'

import { fromStudyDesign } from 'selectors'

import { COLORS } from 'constants/colors'
import { links } from 'constants/links'
import { tools } from 'constants/tools'
import { CONDITION_SELECTION_TYPES, CONDITION_TYPES } from 'constants/conditionBuilder'
import {
	ADD_MODULE_TYPES,
	VISUAL_FLOW_MODULE_TYPES,
	LIST_INPUT_TYPE,
	VISUAL_FLOW_REMOVE_TYPES,
} from 'constants/studyDesign'

import helpers from './helpers'
import refreshPaths from 'routes/_study/StudyDesign/_store/helpers/refreshPaths.js'
import removeFromModules from 'routes/_study/StudyDesign/_store/helpers/removeModule/removeFromModules'
import removeFromOrder from 'routes/_study/StudyDesign/_store/helpers/removeModule/removeFromOrder'
import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import { canBeUsedAsDefaultConditionStudyObject } from 'helpers/conditionBuilder/canBeUsedAsDefaultConditionStudyObject'
import { createChoiceOptionsFromList } from 'helpers/visualFlowModules/createChoiceOptionsFromList'
import { createMatrixAttributesFromList } from 'helpers/visualFlowModules/createMatrixAttributesFromList'
import { findSelectedList } from 'helpers/visualFlowModules/findSelectedList'
import { flattenOrder } from 'helpers/visualFlowModules/flattenOrder'
import { getConditions } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/UI_COMMAND'
import { getIsSoftModuleRemove } from 'helpers/visualFlowModules/getIsSoftModuleRemove'
import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'
import { getStudyObjectDefinition } from 'helpers/conditionBuilder/getStudyObjectDefinition'
import { replaceItemInText } from 'helpers/visualFlowModules/replaceItemInText'
import { sanitizeMatrixAttributesShortName } from 'helpers/visualFlowModules/sanitizeMatrixAttributesShortName'
import { getNewListColumn } from 'helpers/visualFlowModules/getNewListColumn'

// import { getLOIEstimate } from 'helpers/getLOIEstimate/getLOIEstimate'
import { matrixQuestionToChoice } from 'helpers/visualFlowModules/matrixQuestionToChoice'
import { validate } from 'helpers/visualFlowModules/snippet'

// ------------------------------------
// Constants
// ------------------------------------
export const FLOW_CHANGED = 'flow.changed.order'
export const SET_DELETING_MODULE = 'flow.set.deleting.module'
export const REMOVE_APPROVE = 'flow.remove.approve'
export const INITIALIZE = 'flow.initialize'

export const SET_ADD_MODULE_PATH = 'flow.set.add.module.path'
export const ADD_MODULE = 'flow.module.detail.add.module'
export const SET_ACTIVE = 'flow.module.detail.set.active'
export const SET_DISPLAY_STUDY_NOTE = 'flow.module.display.study.note'
export const CLOSE_STUDY_NOTE = 'flow.module.close.study.note'
export const SET_SHOW_RELATED_MESSAGE = 'flow.module.detail.set.show.related.message'
export const CLOSE_MODULE_DETAIL = 'flow.module.detail.close'
export const UPDATE_MODULE_DETAIL = 'flow.update.module.detail'
export const TOGGLE_BLOCK_MODULE_EXPAND = 'flow.toggle.block.module.expand'
export const FLOW_LIBRARY_ADD_ITEM = 'flow.library.add.item'
export const SET_IS_PROFLOW_VISIBLE = 'flow.set.is.proflow.visible'
export const COPY_TO_LIBRARY = 'flow.copy.to.library'
export const CLOSE_LIBRARY_FORM_OVERLAY = 'flow.close.library.form.overlay'
export const RESTORE_MODULE = 'flow.restore.module'

export const TOGGLE_STUDY_SETTINGS_DETAIL = 'flow.toggle.study.settings.detail'
export const UPDATE_STUDY_SETTINGS = 'flow.update.study.settings'

export const REMOVE_MEDIA_URL = 'flow.update.modules.definition.media.url'

export const SET_UPDATE_LOADING = 'flow.set.update.loading'

export const SET_IMPORTED_MODULES = 'flow.set.imported.modules'

export const ADD_RESPONDENT_SOURCE = 'flow.add.respondent.source'
export const UPDATE_RESPONDENT_SOURCE = 'flow.update.respondent.source'
export const REMOVE_RESPONDENT_SOURCE = 'flow.remove.respondent.source'

export const ADD_LIST_COLUMN = 'flow.add.list.column'

export const SET_STUDY_TAGS = 'flow.set.study.tags'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	idActiveStudy: null,
	currentModuleId: null,
	studyNoteModuleId: null,
	idScrollToTask: null,
	isRelatedMessageShown: false,
	deletingModule: null,
	openedAddModulePath: null,
	isFlowChanged: false,
	isStudySettingsDetailOpen: false,
	proFlow: [],
	importedModules: [],
	isProFlowVisible: false,
	isProFlowPrimarySource: false,
	modules: {},
	order: [],
	flatOrder: [],
	flowStats: {
		all: {},
		completes: {},
	},
	estimatedLOI: 0,
	invalidModules: [],
	upperModulesIds: [],
	idsMediaToDelete: [],
	librarySegmentsToBeAdded: [],
	idsMediaToCopy: [],
	studySettings: {
		publicLabel: '',
		studyDescription: '',
		language: 'en',
		logo: {
			id: uuidv4(),
			url: '',
		},
		custom: {
			theme: {
				baseColor: COLORS.PRIMARY_45,
			},
		},
	},
	isCopyToLibraryFormVisible: false,
	copyToLibraryFormData: { modules: {}, order: [] },
	isCreateLibraryFromStudy: false,
	isStudyCreate: false,
	flowDetailRerenderRegister: {},
	isStudyLoaded: false,
	isSaveLoading: false,
	respondentSources: [],
	tags: {},
}

// ------------------------------------
// Helpers
// ------------------------------------
// TODO
// LOI estimator is not visible in visual flow for now so we don't have to
// compute the LOI on every flow change
const getLOIEstimate = () => 0

const findModulesFromOrder = order => {
	const ids = order.map(module => {
		if (module.type !== MODULE_DEFINITIONS.UI_COMMAND.type) {
			return module.id
		}

		const idsChildren = findModulesFromOrder(module.then)

		return [module.id, ...idsChildren]
	})

	return _.flattenDeep(ids)
}

const makeStudySettings = respondentSourceData => ({
	publicLabel: _.get(respondentSourceData, 'loginScreen.publicLabel', ''),
	studyDescription: _.get(respondentSourceData, 'loginScreen.studyDescription', ''),
	logo: _.get(respondentSourceData, 'loginScreen.logo', {
		id: tools.DEFAULT_STUDY_IMAGE_UUID,
		url: links.GROUPSOLVER_LOGO,
	}),
})

const getLibrarySegmentLabel = (segment, modules) => {
	const shortNames = segment.conditions
		.map(condition => _.get(modules, `${condition.studyObject.id}.definition.shortName`, ''))
		.filter(shortName => shortName !== '')
	const joinedShortNames = _.uniq(shortNames).join(', ')

	return `${segment.label} (${joinedShortNames})`
}

const resolveAddModulePath = (idAddModule, addType, order) => {
	const idOrderModule =
		addType === ADD_MODULE_TYPES.BRANCH_END
			? idAddModule.replace(`-${ADD_MODULE_TYPES.BRANCH_END}`, '')
			: idAddModule

	const orderModule = helpers.getOrderModuleById(idOrderModule, order)

	if (addType === ADD_MODULE_TYPES.MODULE) {
		return orderModule.path
	}

	if (addType === ADD_MODULE_TYPES.COPY) {
		const currentModulePathArray = orderModule.path.split('_')

		currentModulePathArray[currentModulePathArray.length - 1] =
			Number(currentModulePathArray[currentModulePathArray.length - 1]) + 1

		return currentModulePathArray.join('_')
	}

	return `${orderModule.path}_then_${orderModule.then.length}`
}

const shouldComputeUpperModuleIds = activeModule => {
	if (_.isNil(activeModule) === true) {
		return false
	}

	return (
		activeModule.type === VISUAL_FLOW_MODULE_TYPES.ALLOCATION ||
		activeModule.type === VISUAL_FLOW_MODULE_TYPES.A_CHOICE ||
		activeModule.type === VISUAL_FLOW_MODULE_TYPES.LIST ||
		activeModule.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE ||
		activeModule.type === VISUAL_FLOW_MODULE_TYPES.RANKING ||
		activeModule.type === VISUAL_FLOW_MODULE_TYPES.UI_COMMAND
	)
}

const formatDateTime = dateTime => moment(dateTime).format('YYYY-MM-DDTHH:mm:ss.SSS')

const getIsRelatedMessageShown = (idModule, modules) => {
	const selectedModule = modules[idModule]
	const selectedModuleType = _.get(selectedModule, 'type', null)
	const idRelatedMessage = _.get(selectedModule, 'definition.idRelatedMessage', null)
	const relatedMessage =
		_.get(selectedModule, 'definition.messages[0]') ?? modules[idRelatedMessage]
	const relatedMessageText = _.get(relatedMessage, 'definition.text', null)

	const isRelatedMessageUnchanged =
		relatedMessageText === intl.formatMessage({ id: 'message.question.default' })

	return (
		selectedModuleType === MODULE_DEFINITIONS.MATRIX_CHOICE.type ||
		isRelatedMessageUnchanged === true
	)
}

const getValidListIdentifier = (uniqueColumns, defaultIdentifier, identifier) =>
	uniqueColumns.find(column => column.key === identifier) === undefined
		? defaultIdentifier
		: identifier

const formatVisualFlowModulesFromNetwork = modules => {
	const modulesArray = Object.entries(modules)
	const formattedModules = {}

	modulesArray.forEach(([idModule, module]) => {
		if (
			module.type !== MODULE_DEFINITIONS.UI_COMMAND.type &&
			module.type !== MODULE_DEFINITIONS.A_OEQ.type
		) {
			formattedModules[idModule] = module
			return
		}

		if (module.type === MODULE_DEFINITIONS.A_OEQ.type) {
			formattedModules[idModule] = {
				...module,
				definition: {
					...module.definition,
					preseededStatements: module.definition.preseededStatements.map(statement => ({
						label: statement,
					})),
				},
			}

			return
		}

		formattedModules[idModule] = {
			...module,
			definition: {
				...module.definition,
				conditions: module.definition.conditions.map(condition => {
					if (condition.type !== CONDITION_TYPES.DATE) {
						return condition
					}

					if (condition.selection.type === CONDITION_SELECTION_TYPES.BETWEEN) {
						return {
							...condition,
							left: {
								...condition.left,
								from: {
									...condition.left.from,
									dateTime: moment.utc(condition.left.from.dateTime),
								},
								to: {
									...condition.left.to,
									dateTime: moment.utc(condition.left.to.dateTime),
								},
							},
						}
					}

					return {
						...condition,
						left: {
							...condition.left,
							dateTime: moment.utc(condition.left.dateTime),
						},
					}
				}),
			},
		}
	})

	return formattedModules
}

export const formatVisualFlowModulesForNetwork = modules => {
	const modulesArray = Object.values(modules)
	const formattedModules = {}

	modulesArray.forEach(module => {
		const formattedModule = _.omit(module, ['isUnsaved'])

		if (formattedModule.definition.options !== undefined) {
			formattedModule.definition.options = formattedModule.definition.options.map(option =>
				_.omit(option, ['isUnsaved']),
			)
		}

		if (formattedModule.definition.questions !== undefined) {
			formattedModule.definition.questions = formattedModule.definition.questions.map(question =>
				_.omit(question, ['isUnsaved']),
			)
		}

		if (
			formattedModule.type !== MODULE_DEFINITIONS.UI_COMMAND.type &&
			formattedModule.type !== MODULE_DEFINITIONS.A_OEQ.type
		) {
			formattedModules[formattedModule.definition.id] = formattedModule
			return
		}

		if (formattedModule.type === MODULE_DEFINITIONS.A_OEQ.type) {
			formattedModules[formattedModule.definition.id] = {
				...formattedModule,
				definition: {
					...formattedModule.definition,
					preseededStatements: formattedModule.definition.preseededStatements.map(
						statement => statement.label,
					),
				},
			}

			return
		}

		formattedModules[formattedModule.definition.id] = {
			...formattedModule,
			definition: {
				...formattedModule.definition,
				conditions: formattedModule.definition.conditions.map(condition => {
					if (condition.type !== CONDITION_TYPES.DATE) {
						return condition
					}

					if (condition.selection.type === CONDITION_SELECTION_TYPES.BETWEEN) {
						return {
							...condition,
							left: {
								...condition.left,
								from: {
									...condition.left.from,
									dateTime: formatDateTime(condition.left.from.dateTime),
								},
								to: {
									...condition.left.to,
									dateTime: formatDateTime(condition.left.to.dateTime),
								},
							},
						}
					}

					return {
						...condition,
						left: {
							...condition.left,
							dateTime: formatDateTime(condition.left.dateTime),
						},
					}
				}),
			},
		}
	})

	return formattedModules
}

// ------------------------------------
// Actions
// ------------------------------------
export const closeStudySettingsDetail = () => (dispatch, getState) => {
	dispatch({ type: TOGGLE_STUDY_SETTINGS_DETAIL, value: false })
}

export const setActive = (moduleId, pathTo) => (dispatch, getState) => {
	dispatch({ type: SET_ACTIVE, moduleId, path: pathTo })
}

export const displayNoteWindow = (studyNoteModuleId, idScrollToTask = null) => (
	dispatch,
	getState,
) => {
	const idActiveNote = fromStudyDesign.getStudyNoteModuleId(getState())

	if (idActiveNote === studyNoteModuleId) {
		closeNoteWindow()(dispatch, getState)

		return
	}

	dispatch({ type: SET_DISPLAY_STUDY_NOTE, studyNoteModuleId, idScrollToTask })
}

export const closeNoteWindow = () => (dispatch, getState) => {
	dispatch({ type: CLOSE_STUDY_NOTE })
}

export const showRelatedMessage = () => (dispatch, getState) => {
	dispatch({ type: SET_SHOW_RELATED_MESSAGE, isRelatedMessageShown: true })
}

export const hideRelatedMessage = () => (dispatch, getState) => {
	dispatch({ type: SET_SHOW_RELATED_MESSAGE, isRelatedMessageShown: false })
}

export const openStudySettingsDetail = () => (dispatch, getState) => {
	dispatch({ type: TOGGLE_STUDY_SETTINGS_DETAIL, value: true })
}

export const saveStudySettings = studySettings => (dispatch, getState) => {
	dispatch({ type: UPDATE_STUDY_SETTINGS, studySettings })
}

export const setImportedModules = importedModules => ({
	type: SET_IMPORTED_MODULES,
	importedModules,
})

export const addModule = (
	{ idModule, addType },
	moduleType,
	additionalProperties = {},
	definition = null,
) => (dispatch, getState) => {
	const isEndStudyModule = moduleType === VISUAL_FLOW_MODULE_TYPES.A_END_STUDY
	const isUICommandModule = moduleType === VISUAL_FLOW_MODULE_TYPES.UI_COMMAND
	const newModuleId = uuidv4()

	// we don't want to include other conditions and choices generated in matrix here
	// because we don't want to use them in conditions automatically
	const state = getState()

	const order = fromStudyDesign.getOrder(state)

	const path = resolveAddModulePath(idModule, addType, order)

	const modulesToAdd = [
		{
			path,
			moduleType,
			newModuleId,
			definition,
			additionalProperties,
		},
	]

	if (isEndStudyModule === true) {
		if (additionalProperties.endstudyType === ENDSTUDY_TYPES.COMPLETE) {
			// add endstudy messages for complete
			modulesToAdd.push(
				{
					path,
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({
							id: 'flow_message_module_end_study_companion_text_participation',
						}),
					},
				},
				{
					path,
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({
							id: 'flow_message_module_end_study_companion_text_conclude',
						}),
					},
				},
			)
		} else {
			// add endstudy message for terminate + history blur
			modulesToAdd.push(
				{
					path,
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({ id: 'flow_terminate_overquota_message' }),
					},
				},
				{
					path,
					moduleType: MODULE_DEFINITIONS.UI_COMMAND.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: { preparationType: PREPARATION_TYPES.HISTORY },
				},
			)
		}
	}

	if (isUICommandModule && additionalProperties.preparationType === PREPARATION_TYPES.QUOTA) {
		// add overquota message and endpoint + history blur
		const thenPath = `${path}_then_0`

		modulesToAdd.push(
			{
				path: thenPath,
				moduleType: MODULE_DEFINITIONS.A_END_STUDY.type,
				newModuleId: uuidv4(),
				definition: null,
				additionalProperties: { endstudyType: ENDSTUDY_TYPES.OVERQUOTA },
			},
			{
				path: thenPath,
				moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
				newModuleId: uuidv4(),
				definition: null,
				additionalProperties: {
					text: intl.formatMessage({ id: 'flow_terminate_overquota_message' }),
				},
			},
			{
				path: thenPath,
				moduleType: MODULE_DEFINITIONS.UI_COMMAND.type,
				newModuleId: uuidv4(),
				definition: null,
				additionalProperties: { preparationType: PREPARATION_TYPES.HISTORY },
			},
		)
	}

	dispatch({
		type: ADD_MODULE,
		modulesToAdd,
		idActiveModule: newModuleId,
		activeModulePath: path,
		activeModuleType: moduleType,
		additionalProperties,
	})
}

export const saveFlow = studyState => (dispatch, getState) => {
	const state = getState()

	const idStudy = state.studyDesign.idActiveStudy

	const order = fromStudyDesign.getOrder(state)
	const modules = fromStudyDesign.getModules(state)
	const studySettings = fromStudyDesign.getStudySettings(state)
	const segmentsToSave = fromStudyDesign.getLibrarySegmentsToBeAdded(state)
	const idsMediaToDelete = fromStudyDesign.getIdsMediaToDelete(state)

	// remove library media that are no longer used in flow
	const stringifiedModules = JSON.stringify(modules)
	const stringifiedStudySettings = JSON.stringify(studySettings)
	const idsMediaToCopy = fromStudyDesign
		.getIdsMediaToCopy(state)
		.filter(
			idMediaObject =>
				stringifiedModules.includes(idMediaObject) === true ||
				stringifiedStudySettings.includes(idMediaObject) === true,
		)

	const formattedModules = formatVisualFlowModulesForNetwork(modules)

	dispatch({ type: SET_UPDATE_LOADING })

	const data = {
		visualFlow: {
			order: order
				.filter(m => m.type !== MODULE_DEFINITIONS.PLACEHOLDER.type)
				.map(helpers.normalizeOrderModule),
			modules: formattedModules,
		},
		idsMediaToCopy,
		idsMediaToDelete,
		studySettings,
		segmentsToCreate: segmentsToSave.map(segment => ({
			idSegment: uuidv4(),
			label: getLibrarySegmentLabel(segment, modules),
			conditions: segment.conditions,
		})),
	}

	// graphql network request
	return ApiActions.saveVisualFlow(studyState, idStudy, data, dispatch, getState)
		.then(res => {
			if (res.type !== API.VISUAL_FLOW_UPDATE.SUCCESS) {
				dispatch(
					showNotification(
						{
							id: 'save-flow-error',
							type: 'error',
							message: 'Failed to complete your request. Please try again.',
							buttons: ['dismiss'],
						},
						true,
					),
				)

				return false
			}

			if (segmentsToSave.length > 0) {
				dispatch(
					showNotification(
						{
							id: 'save-flow-success',
							type: 'message',
							message: intl.formatMessage(
								{ id: 'segments_added' },
								{ count: segmentsToSave.length },
							),
							buttons: ['okay'],
						},
						true,
					),
				)
			}

			return true
		})
		.catch(error => {
			// this error is already logged by apollo client

			return false
		})
}

export const openAddModule = openParams => (dispatch, getState) => {
	if (openParams === null) {
		dispatch({
			type: SET_ADD_MODULE_PATH,
			addModulePath: null,
		})

		return
	}

	const { idModule } = openParams

	const openedAddModulePath = fromStudyDesign.getOpenedAddModulePath(getState())

	if (openedAddModulePath !== idModule) {
		dispatch({
			type: SET_ADD_MODULE_PATH,
			addModulePath: idModule,
		})
	}
}

export const deleteMediaObject = (idMediaObject, url) => (dispatch, getState) => {
	dispatch({ type: REMOVE_MEDIA_URL, idMediaObject, url })
}

export const copyModule = (addParams, moduleType, idModule) => (dispatch, getState) => {
	const modules = fromStudyDesign.getModules(getState())
	const moduleToCopy = modules[idModule]
	// preparationType is set to null because we do not support copying of UI_COMMAND modules
	addModule(
		addParams,
		moduleType,
		{
			isPreviewOnly: moduleToCopy.isPreviewOnly,
		},
		moduleToCopy.definition,
	)(dispatch, getState)
}

export const handleDragEnd = (to, item) => (dispatch, getState) => {
	const order = fromStudyDesign.getOrder(getState())
	const modules = fromStudyDesign.getModules(getState())

	const dropzoneModule = helpers.getOrderModuleById(to.dropzone.id, order)
	const placeHolderPath =
		to.dropzone.type === DropZoneType.CONTAINER || to.dropzone.type === DropZoneType.BLOCK
			? `${dropzoneModule.path}_then_${dropzoneModule.then.length}`
			: dropzoneModule.path

	const draggedModule = helpers.getOrderModuleById(item.id, order)

	const newItem = {
		newModuleId: item.moduleDetail.id,
		thenModules: item.moduleDetail.then,
		moduleType: item.moduleDetail.type,
		path: placeHolderPath,
	}

	const newOrder = addToOrder(order, newItem)

	const orderWithoutModule = removeFromOrder(
		item.id,
		draggedModule.path,
		VISUAL_FLOW_REMOVE_TYPES.REMOVE_ALL,
		newOrder,
	)

	const refreshPathsResult = refreshPaths(orderWithoutModule, modules)

	dispatch({
		type: FLOW_CHANGED,
		newFlow: refreshPathsResult.order,
		modules: refreshPathsResult.modules,
	})
}

export const closeModuleDetail = () => (dispatch, getState) => {
	dispatch({ type: CLOSE_MODULE_DETAIL })
}

export const removeModuleIntent = id => (dispatch, getState) => {
	const moduleId = fromStudyDesign.getDeletingModule(getState()) === id ? undefined : id

	dispatch({ type: SET_DELETING_MODULE, moduleId })
}

export const removeModuleApprove = (removeType, idModule) => (dispatch, getState) => {
	const order = fromStudyDesign.getOrder(getState())
	const orderModule = helpers.getOrderModuleById(idModule, order)

	dispatch({ type: REMOVE_APPROVE, removeType, path: orderModule.path })
}

export const saveModule = definition => (dispatch, getState) => {
	dispatch({ type: UPDATE_MODULE_DETAIL, definition })
}

export const switchIsProFlowVisible = () => (dispatch, getState) => {
	const isProFlowVisible = fromStudyDesign.getIsProFlowVisible(getState())

	dispatch({ type: SET_IS_PROFLOW_VISIBLE, isProFlowVisible: isProFlowVisible === false })
}

export const toggleBlockModuleExpand = (idModule, studyState) => (dispatch, getState) => {
	dispatch({ type: TOGGLE_BLOCK_MODULE_EXPAND, idModule, studyState })
}

// Library
export const addLibraryItemToFlow = (addType, library) => (dispatch, getState) => {
	dispatch({ type: FLOW_LIBRARY_ADD_ITEM, addType, library })
}

export const closeLibraryFormOverlay = () => (dispatch, getState) => {
	dispatch({ type: CLOSE_LIBRARY_FORM_OVERLAY })
}

export const openCopyToLibraryForm = () => (dispatch, getState) => {
	const actionPayload = {
		order: [],
		modules: {},
		isCopyToLibraryFormVisible: true,
		isCreateLibraryFromStudy: false,
		isStudyCreate: false,
	}

	const currentModuleId = fromStudyDesign.getCurrentModuleId(getState())
	const order = fromStudyDesign.getOrder(getState())
	const currentOrderModule = helpers.getOrderModuleById(currentModuleId, order)

	actionPayload.order = [currentOrderModule]

	const idsModules = findModulesFromOrder([currentOrderModule])
	actionPayload.modules = {}

	const modules = fromStudyDesign.getModules(getState())

	idsModules.forEach(idModule => {
		const module = modules[idModule]

		actionPayload.modules[idModule] = module

		const idRelatedMessage = module.definition.idRelatedMessage
		const relatedMessage = modules[idRelatedMessage]

		if (idRelatedMessage !== null && relatedMessage !== undefined) {
			actionPayload.modules[idRelatedMessage] = relatedMessage
		}

		if (module.type === MODULE_DEFINITIONS.MATRIX_CHOICE.type) {
			module.definition.questions.forEach(question => {
				actionPayload.modules[question.id] = modules[question.id]
			})
		}
	})

	dispatch({
		type: COPY_TO_LIBRARY,
		payload: actionPayload,
	})
}

export const createLibraryFromStudy = isStudyCreate => (dispatch, getState) => {
	const actionPayload = {
		order: fromStudyDesign.getOrder(getState()),
		modules: fromStudyDesign.getModules(getState()),
		isCopyToLibraryFormVisible: true,
		isCreateLibraryFromStudy: true,
		isStudyCreate,
	}

	dispatch({
		type: COPY_TO_LIBRARY,
		payload: actionPayload,
	})
}

export const initialize = () => ({ type: INITIALIZE })

export const actions = {
	openAddModule,
}

export const resetChanges = study => (dispatch, getState) => {
	dispatch({
		type: API.LOAD_STUDY_DETAIL.SUCCESS,
		response: {
			data: study,
		},
		isResetChanges: true,
	})
}

const preValidateSnippetModules = modules => {
	const newModules = {
		...modules,
	}

	Object.values(modules)
		.filter(module => module.type === VISUAL_FLOW_MODULE_TYPES.A_SNIPPET)
		.forEach(module => {
			const { type, code, nextSteps, quotas, id } = module.definition

			newModules[id] = {
				...module,
				definition: {
					...module.definition,
					...validate(type, code, nextSteps, quotas),
				},
			}
		})

	return newModules
}

export const restoreModule = idModule => (dispatch, getState) => {
	dispatch({
		type: RESTORE_MODULE,
		idModule,
	})
}

export const addRespondentSource = respondentSource => {
	return {
		type: ADD_RESPONDENT_SOURCE,
		respondentSource,
	}
}

export const updateRespondentSource = respondentSource => {
	return {
		type: UPDATE_RESPONDENT_SOURCE,
		respondentSource,
	}
}

export const removeRespondentSource = respondentSource => {
	return {
		type: REMOVE_RESPONDENT_SOURCE,
		respondentSource,
	}
}

export const addListColumn = ({ idModule, columnName, columnValues }) => ({
	type: ADD_LIST_COLUMN,
	idModule,
	columnName,
	columnValues,
})

export const setStudyTags = tags => ({
	type: SET_STUDY_TAGS,
	tags,
})

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[INITIALIZE]: (state, action) => ({ ...initialState }),

	// API COMMUNICATION HANDLERS
	[API.LOAD_STUDY_DETAIL.SUCCESS]: (state, action) => {
		if (
			state.isStudyLoaded === true &&
			state.idActiveStudy === action.response.data.idStudy &&
			action.isResetChanges !== true
		) {
			return state
		}

		const newState = _.cloneDeep(initialState)

		newState.importedModules = [...action.response.data.importedModules]

		const studySettings = makeStudySettings(action.response.data.respondentSources[0])

		newState.tags = action.response.data.tags

		newState.respondentSources = action.response.data.respondentSources
		newState.studySettings = {
			publicLabel: studySettings.publicLabel || action.response.data.privateLabel,
			studyDescription: studySettings.studyDescription,
			logo: {
				id: studySettings.logo.id,
				url: studySettings.logo.url,
			},
			// values from study definition
			language: action.response.data.language,
			theme: {
				baseColor: _.get(action, 'response.data.custom.theme.baseColor', COLORS.PRIMARY_45),
			},
		}

		const visualFlow = { ...action.response.data.flow.visualFlow }

		if (visualFlow.order.length === 0 && _.isEqual(visualFlow.modules, {}) === true) {
			// generate default flow
			const defaultFlowDefinitions = [
				{
					path: '0_0',
					moduleType: MODULE_DEFINITIONS.UI_COMMAND.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: { preparationType: PREPARATION_TYPES.BLOCK, shortName: 'Intro' },
				},
				{
					path: '0_0_then_0',
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({ id: 'flow_message_module_disclaimer_text' }),
					},
				},
				{
					path: '0_0_then_1',
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({ id: 'flow_message_module_proceed_text' }),
					},
				},
				{
					path: '0_0_then_2',
					moduleType: MODULE_DEFINITIONS.UI_COMMAND.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						preparationType: PREPARATION_TYPES.PROCEED,
						actionButtonLabel: intl.formatMessage({ id: 'flow_button_module_disclaimer_text' }),
					},
				},
				{
					path: '0_1',
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({
							id: 'flow_message_module_end_study_companion_text_conclude',
						}),
					},
				},
				{
					path: '0_2',
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({
							id: 'flow_message_module_end_study_companion_text_participation',
						}),
					},
				},
				{
					path: '0_3',
					moduleType: MODULE_DEFINITIONS.A_MESSAGE.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: {
						text: intl.formatMessage({
							id: 'flow_message_module_powered_by',
						}),
					},
				},
				{
					path: '0_4',
					moduleType: MODULE_DEFINITIONS.A_END_STUDY.type,
					newModuleId: uuidv4(),
					definition: null,
					additionalProperties: { endstudyType: ENDSTUDY_TYPES.COMPLETE },
				},
			]

			let order = []
			let modules = {}

			defaultFlowDefinitions.forEach(moduleProps => {
				const addModuleResult = helpers.addModule(order, modules, moduleProps)

				order = addModuleResult.newOrder
				modules = addModuleResult.newModules
			})

			newState.order = order
			newState.modules = modules
			newState.openedAddModulePath = order[1].id
		} else {
			newState.order = visualFlow.order ? _.cloneDeep(visualFlow.order) : []
			newState.modules = formatVisualFlowModulesFromNetwork(
				_.cloneDeep(action.response.data.flow.visualFlow.modules),
			)
		}

		newState.flatOrder = flattenOrder(newState.order)

		newState.idActiveStudy = action.response.data.idStudy

		newState.estimatedLOI = getLOIEstimate(newState.modules, newState.order)

		newState.proFlow = action.response.data.flow.proFlow
		newState.isProFlowPrimarySource = action.response.data.flow.activeFlow !== 'VISUAL'
		newState.isProFlowVisible = newState.isProFlowPrimarySource

		const communityModules = newState.importedModules.filter(module => module.isCommunity === true)

		newState.modules = preValidateSnippetModules(newState.modules)

		newState.flowStats = action.response.data.flow.stats
		newState.invalidModules = helpers.getInvalidModules(
			newState.modules,
			newState.order,
			newState.flatOrder,
			communityModules,
			newState.proFlow,
			action.response.data.respondentSources,
			newState.tags,
		)

		const refreshPathsResult = refreshPaths(newState.order, newState.modules)

		newState.order = refreshPathsResult.order
		newState.modules = refreshPathsResult.modules

		newState.isStudyLoaded = true
		return newState
	},
	[API.VISUAL_FLOW_UPDATE.SUCCESS]: (state, action) => {
		const newFlow =
			action.response.study.updateVisualFlow?.flow ?? action.response.study.updateLiveFlow.flow

		const flowDetailRerenderRegister =
			state.currentModuleId === null
				? state.flowDetailRerenderRegister
				: {
						...state.flowDetailRerenderRegister,
						[state.currentModuleId]: uuidv4(),
				  }

		return {
			...state,
			isFlowChanged: false,
			proFlow: newFlow.proFlow,
			modules: formatVisualFlowModulesFromNetwork(newFlow.visualFlow.modules),
			idsMediaToDelete: [],
			idsMediaToCopy: [],
			librarySegmentsToBeAdded: [],
			isSaveLoading: false,
			flowDetailRerenderRegister,
		}
	},
	[API.VISUAL_FLOW_UPDATE.ERROR]: (state, action) => ({
		...state,
		isSaveLoading: false,
	}),
	[SET_UPDATE_LOADING]: (state, action) => ({
		...state,
		isSaveLoading: true,
	}),

	// needed for conditions validation
	[SET_IMPORTED_MODULES]: (state, action) => {
		return {
			...state,
			importedModules: action.importedModules,
		}
	},

	// FLOW DETAIL HANDLERS
	[SET_ACTIVE]: (state, action) => {
		const { modules } = state
		const selectedModule = modules[action.moduleId]

		const upperModulesIds =
			shouldComputeUpperModuleIds(selectedModule) === true
				? helpers.getUpperModuleIds(action.path, state.flatOrder, state.modules)
				: []

		return {
			...state,
			isRelatedMessageShown: getIsRelatedMessageShown(action.moduleId, modules),
			currentModuleId: action.moduleId,
			isStudySettingsDetailOpen: false,
			deletingModule: null,
			upperModulesIds,
		}
	},
	[SET_DISPLAY_STUDY_NOTE]: (state, action) => {
		return {
			...state,
			displayedNoteModuleId: action.studyNoteModuleId,
			idScrollToTask: action.idScrollToTask,
		}
	},
	[CLOSE_STUDY_NOTE]: (state, action) => {
		return {
			...state,
			displayedNoteModuleId: null,
		}
	},
	[SET_SHOW_RELATED_MESSAGE]: (state, action) => ({
		...state,
		isRelatedMessageShown: action.isRelatedMessageShown,
	}),
	[SET_ADD_MODULE_PATH]: (state, action) => ({
		...state,
		deletingModule: null,
		openedAddModulePath: action.addModulePath,
	}),
	[SET_DELETING_MODULE]: (state, action) => ({
		...state,
		deletingModule: action.moduleId,
		openedAddModulePath: null,
	}),
	[REMOVE_APPROVE]: (state, action) => {
		const newModules = removeFromModules(
			state.deletingModule,
			action.path,
			action.removeType,
			state.modules,
			state.order,
		)

		const newOrder =
			getIsSoftModuleRemove(action.removeType) === true
				? state.order
				: removeFromOrder(state.deletingModule, action.path, action.removeType, state.order)

		const { modules, order } =
			getIsSoftModuleRemove(action.removeType) === true
				? { modules: newModules, order: newOrder }
				: refreshPaths(newOrder, newModules)

		const flatOrder = flattenOrder(order)

		const currentModule = newModules[state.currentModuleId]
		const doesCurrentModuleExist = currentModule !== undefined
		const currentOrderModule = helpers.getOrderModuleById(state.currentModuleId, newOrder)
		// open AddModule if there is only Endpoint in flow
		const shouldOpenAddModule = newOrder.length === 1

		const communityModules = state.importedModules.filter(module => module.isCommunity === true)

		return {
			...state,
			modules,
			order,
			flatOrder,
			currentModuleId: doesCurrentModuleExist === true ? state.currentModuleId : null,
			deletingModule: null,
			librarySegmentsToBeAdded: helpers.filterSegmentsToBeAdded(state, modules),
			isFlowChanged: true,
			invalidModules: helpers.getInvalidModules(
				modules,
				order,
				flatOrder,
				communityModules,
				state.proFlow,
				state.respondentSources,
				state.tags,
			),
			estimatedLOI: getLOIEstimate(modules, order),
			upperModulesIds:
				shouldComputeUpperModuleIds(currentModule) === true
					? helpers.getUpperModuleIds(currentOrderModule.path, flatOrder, modules)
					: [],
			openedAddModulePath: shouldOpenAddModule === true ? order[0].id : state.openedAddModulePath,
		}
	},
	[ADD_MODULE]: (state, action) => {
		let newState = { ...state }

		const {
			activeModuleType,
			activeModulePath,
			additionalProperties,
			modulesToAdd,
			idActiveModule,
		} = action

		let newOrder = [...state.order]
		let newModules = { ...state.modules }

		modulesToAdd.forEach(addDefinition => {
			const addResult = helpers.addModule(newOrder, newModules, addDefinition)

			newOrder = addResult.newOrder
			newModules = addResult.newModules
		})

		newState.flatOrder = flattenOrder(newOrder)

		const upperModuleIds =
			shouldComputeUpperModuleIds({ type: activeModuleType }) === false
				? []
				: helpers.getUpperModuleIds(activeModulePath, newState.flatOrder, newModules)

		const upperChoiceModules = upperModuleIds
			.map(idModule => newModules[idModule])
			.filter(canBeUsedAsDefaultConditionStudyObject)
			.map(module => ({
				definition: {
					...getStudyObjectDefinition(module),
					id: module.definition.id,
				},
			}))

		if (newModules[idActiveModule].type === MODULE_DEFINITIONS.UI_COMMAND.type) {
			const updatedConditionModule = _.cloneDeep(newModules[idActiveModule])

			updatedConditionModule.definition.conditions = getConditions(
				newModules,
				additionalProperties,
				upperChoiceModules,
			)

			newModules[idActiveModule] = updatedConditionModule
		}

		newState.order = newOrder
		newState.modules = { ...newModules }

		const communityModules = newState.importedModules.filter(module => module.isCommunity === true)

		newState.addModulePath = null
		newState.currentModuleId = idActiveModule
		newState.deletingModule = null
		newState.estimatedLOI = getLOIEstimate(newState.modules, newState.order)
		newState.invalidModules = helpers.getInvalidModules(
			newState.modules,
			newState.order,
			newState.flatOrder,
			communityModules,
			newState.proFlow,
			newState.respondentSources,
			newState.tags,
		)
		newState.isFlowChanged = true
		newState.isRelatedMessageShown = getIsRelatedMessageShown(idActiveModule, newState.modules)
		newState.isStudySettingsDetailOpen = false
		newState.openedAddModulePath = null
		newState.upperModulesIds = upperModuleIds

		return newState
	},
	[CLOSE_MODULE_DETAIL]: (state, action) => {
		return {
			...state,
			isRelatedMessageShown: false,
			currentModuleId: null,
			isStudySettingsDetailOpen: false,
		}
	},
	[ADD_LIST_COLUMN]: (state, action) => {
		const newState = { ...state }

		const { idModule, columnName, columnValues } = action

		const modifiedList = _.cloneDeep(state.modules[idModule])

		if (modifiedList.type !== MODULE_DEFINITIONS.LIST.type) {
			throw new Error(`cannot call ADD_LIST_COLUMN on ${modifiedList.type} module`)
		}

		if (modifiedList.definition.inputType !== LIST_INPUT_TYPE.FILE) {
			throw new Error(
				`cannot call ADD_LIST_COLUMN in list with ${modifiedList.definition.inputType} inputType`,
			)
		}

		if (modifiedList.definition.columns.some(c => c.key === columnName.trim())) {
			throw new Error(`${columnName} already exists in list ${idModule}`)
		}

		const identifier = modifiedList.definition.identifier

		const valuesArray = modifiedList.definition.items.map(item =>
			columnValues[item[identifier]].value.trim(),
		)

		if (valuesArray.some(value => value === '')) {
			throw new Error('column value cannot be empty string')
		}

		const isUnique = new Set(valuesArray).size === valuesArray.length

		const column = getNewListColumn(columnName.trim(), isUnique)

		modifiedList.definition.columns = [...modifiedList.definition.columns, column]

		modifiedList.definition.items = modifiedList.definition.items.map(item => ({
			...item,
			[columnName]: columnValues[item[identifier]].value.trim(),
		}))

		newState.flowDetailRerenderRegister = {
			...state.flowDetailRerenderRegister,
			[state.currentModuleId]: uuidv4(),
		}
		newState.isFlowChanged = true
		newState.modules[idModule] = modifiedList

		return newState
	},
	[UPDATE_MODULE_DETAIL]: (state, action) => {
		const { definition } = action
		const updatedModule = state.modules[definition.id]

		let newModules = { ...state.modules }

		// update choice modules generated by matrix choice
		if (
			updatedModule.type === MODULE_DEFINITIONS.MATRIX_CHOICE.type ||
			updatedModule.type === MODULE_DEFINITIONS.MATRIX_IMAGE_CHOICE.type
		) {
			const oldDefinition = updatedModule.definition
			newModules = _.omit(
				newModules,
				oldDefinition.questions.map(q => q.id),
			)

			definition.questions.forEach(question => {
				newModules[question.id] = matrixQuestionToChoice({ definition }, question)
			})
		}

		const modulesToSet = {
			...newModules,
			[definition.id]: {
				...newModules[definition.id],
				definition,
			},
		}

		if (updatedModule.type === MODULE_DEFINITIONS.LIST.type) {
			const hasNewItems = _.isEqual(updatedModule.definition.items, definition.items) === false

			if (definition.inputType === LIST_INPUT_TYPE.FILE && hasNewItems === true) {
				const modulesArray = Object.values(state.modules)

				const relatedModules = modulesArray.filter(
					existingModule =>
						[
							VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
							VISUAL_FLOW_MODULE_TYPES.A_CHOICE,
							VISUAL_FLOW_MODULE_TYPES.RANKING,
						].includes(existingModule.type) &&
						existingModule.isMatrixChoice !== true &&
						existingModule.definition.dynamicOptionsSettings.isActive === true &&
						existingModule.definition.dynamicOptionsSettings.idMainList === definition.id,
				)

				const uniqueListColumns = definition.columns.filter(column => column.isUnique === true)

				relatedModules.forEach(relatedModule => {
					const { optionLabelColumn } = relatedModule.definition.dynamicOptionsSettings

					const newOptionLabelColumn = getValidListIdentifier(
						uniqueListColumns,
						definition.identifier,
						optionLabelColumn,
					)

					const newOptions =
						definition.items.length === 0
							? relatedModule.definition.options
							: createChoiceOptionsFromList(
									{ definition },
									newOptionLabelColumn,
									relatedModule.definition.subtype,
							  )

					// make sure to keep existing ids of options
					newOptions.forEach(option => {
						const existingOption = relatedModule.definition.options.find(
							o => o.label === option.label,
						)

						if (existingOption !== undefined) {
							option.id = existingOption.id
							option.isHidden = existingOption.isHidden
						}
					})

					const oldNoneOfTheseOptions = relatedModule.definition.options.filter(
						o => o.isNoneOfThese === true,
					)

					const newRelatedModule = {
						...relatedModule,
						definition: {
							...relatedModule.definition,
							options: [...newOptions, ...oldNoneOfTheseOptions],
							dynamicOptionsSettings: {
								...relatedModule.definition.dynamicOptionsSettings,
								optionLabelColumn: newOptionLabelColumn,
							},
						},
					}

					modulesToSet[newRelatedModule.definition.id] = newRelatedModule
				})

				const relatedMatrixModules = modulesArray.filter(
					existingModule =>
						existingModule.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE &&
						existingModule.definition.listSettings.isActive === true &&
						existingModule.definition.listSettings.idMainList === definition.id,
				)

				relatedMatrixModules.forEach(matrixModule => {
					const { attributeShortNameColumn } = matrixModule.definition.listSettings

					const newAttributeShortNameColumn = getValidListIdentifier(
						uniqueListColumns,
						definition.identifier,
						attributeShortNameColumn,
					)

					const newAttributes =
						definition.items.length === 0
							? matrixModule.definition.questions
							: createMatrixAttributesFromList(
									{ definition },
									newAttributeShortNameColumn,
									matrixModule.definition.listSettings.question.text,
							  )

					newAttributes.forEach(attribute => {
						const existingAttribute = matrixModule.definition.questions.find(
							q => q.shortName === attribute.shortName,
						)

						if (existingAttribute !== undefined) {
							attribute.id = existingAttribute.id
							attribute.isHidden = existingAttribute.isHidden
						}
					})

					const newMatrixModule = {
						...matrixModule,
						definition: {
							...matrixModule.definition,
							questions: newAttributes,
							listSettings: {
								...matrixModule.definition.listSettings,
								attributeShortNameColumn: newAttributeShortNameColumn,
							},
						},
					}

					matrixModule.definition.questions.forEach(question => {
						delete modulesToSet[question.id]
					})

					newMatrixModule.definition.questions.forEach(question => {
						modulesToSet[question.id] = matrixQuestionToChoice(newMatrixModule, question)
					})

					modulesToSet[newMatrixModule.definition.id] = newMatrixModule
				})
			}

			if (
				definition.inputType === LIST_INPUT_TYPE.FILE &&
				hasNewItems === false &&
				updatedModule.definition.identifier !== definition.identifier
			) {
				const modulesArray = Object.values(state.modules)

				const relatedModules = modulesArray.filter(
					existingModule =>
						[
							VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
							VISUAL_FLOW_MODULE_TYPES.A_CHOICE,
							VISUAL_FLOW_MODULE_TYPES.RANKING,
						].includes(existingModule.type) &&
						existingModule.isMatrixChoice !== true &&
						existingModule.definition.dynamicOptionsSettings.isActive === true &&
						existingModule.definition.dynamicOptionsSettings.idMainList === definition.id,
				)

				relatedModules.forEach(relatedModule => {
					const newRelatedModule = {
						...relatedModule,
						definition: {
							...relatedModule.definition,
							options: relatedModule.definition.options.map((option, index) => {
								const item = definition.items[index]

								if (option.isNoneOfThese === true) {
									return option
								}

								return {
									...option,
									label: item[definition.identifier],
								}
							}),
							dynamicOptionsSettings: {
								...relatedModule.definition.dynamicOptionsSettings,
								optionLabelColumn: definition.identifier,
							},
						},
					}

					modulesToSet[newRelatedModule.definition.id] = newRelatedModule
				})

				const relatedMatrixModules = modulesArray.filter(
					existingModule =>
						existingModule.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE &&
						existingModule.definition.listSettings.isActive === true &&
						existingModule.definition.listSettings.idMainList === definition.id,
				)

				relatedMatrixModules.forEach(matrixModule => {
					const newMatrixModule = {
						...matrixModule,
						definition: {
							...matrixModule.definition,
							questions: matrixModule.definition.questions.map((question, index) => {
								const item = definition.items[index]
								const itemLabel = item[definition.identifier]

								return {
									...question,
									shortName: sanitizeMatrixAttributesShortName(itemLabel),
									text: replaceItemInText(
										matrixModule.definition.listSettings.question.text,
										itemLabel,
										item,
										definition.columns,
									),
								}
							}),
							listSettings: {
								...matrixModule.definition.listSettings,
								attributeShortNameColumn: definition.identifier,
							},
						},
					}

					matrixModule.definition.questions.forEach(question => {
						delete modulesToSet[question.id]
					})

					newMatrixModule.definition.questions.forEach(question => {
						modulesToSet[question.id] = matrixQuestionToChoice(newMatrixModule, question)
					})

					modulesToSet[newMatrixModule.definition.id] = newMatrixModule
				})
			}

			if (
				definition.inputType === LIST_INPUT_TYPE.LIST &&
				updatedModule.definition.idInputList !== definition.idInputList
			) {
				const modulesArray = Object.values(state.modules)

				const relatedModules = modulesArray.filter(
					existingModule =>
						[
							VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
							VISUAL_FLOW_MODULE_TYPES.A_CHOICE,
							VISUAL_FLOW_MODULE_TYPES.RANKING,
						].includes(existingModule.type) &&
						existingModule.isMatrixChoice !== true &&
						existingModule.definition.dynamicOptionsSettings.isActive === true &&
						existingModule.definition.dynamicOptionsSettings.idInputList === definition.id,
				)

				const mainList = findSelectedList(definition.idInputList, modulesArray)

				const modulesToChange = relatedModules.filter(
					realtedModule =>
						realtedModule.definition.dynamicOptionsSettings.idMainList !== mainList.definition.id,
				)

				const uniqueListColumns = mainList.definition.columns.filter(
					column => column.isUnique === true,
				)

				modulesToChange.forEach(relatedModule => {
					const { optionLabelColumn } = relatedModule.definition.dynamicOptionsSettings

					const newOptionLabelColumn = getValidListIdentifier(
						uniqueListColumns,
						mainList.definition.identifier,
						optionLabelColumn,
					)

					const newOptions =
						mainList.definition.items.length === 0
							? relatedModule.definition.options
							: [
									...createChoiceOptionsFromList(
										mainList,
										newOptionLabelColumn,
										relatedModule.definition.subtype,
									),
									...relatedModule.definition.options.filter(o => o.isNoneOfThese === true),
							  ]

					const newModule = {
						...relatedModule,
						definition: {
							...relatedModule.definition,
							options: newOptions,
							dynamicOptionsSettings: {
								...relatedModule.definition.dynamicOptionsSettings,
								idMainList: mainList.definition.id,
								idInputList: definition.id,
								optionLabelColumn: newOptionLabelColumn,
							},
						},
					}

					modulesToSet[newModule.definition.id] = newModule
				})

				const relatedMatrixModules = modulesArray.filter(
					existingModule =>
						existingModule.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE &&
						existingModule.definition.listSettings.isActive === true &&
						existingModule.definition.listSettings.idInputList === definition.id,
				)

				const matrixModulesToChange = relatedMatrixModules.filter(
					matrixModule =>
						matrixModule.definition.listSettings.idMainList !== mainList.definition.id,
				)

				matrixModulesToChange.forEach(matrixModule => {
					const { attributeShortNameColumn } = matrixModule.definition.listSettings

					const newAttributeShortNameColumn = getValidListIdentifier(
						uniqueListColumns,
						mainList.definition.identifier,
						attributeShortNameColumn,
					)

					const newAttributes =
						mainList.definition.items.length === 0
							? matrixModule.definition.questions
							: createMatrixAttributesFromList(
									mainList,
									newAttributeShortNameColumn,
									matrixModule.definition.listSettings.question.text,
							  )

					const newMatrixModule = {
						...matrixModule,
						definition: {
							...matrixModule.definition,
							questions: newAttributes,
							listSettings: {
								...matrixModule.definition.listSettings,
								idMainList: mainList.definition.id,
								idInputList: definition.id,
								attributeShortNameColumn: newAttributeShortNameColumn,
							},
						},
					}

					matrixModule.definition.questions.forEach(question => {
						delete modulesToSet[question.id]
					})

					newMatrixModule.definition.questions.forEach(question => {
						modulesToSet[question.id] = matrixQuestionToChoice(newMatrixModule, question)
					})

					modulesToSet[newMatrixModule.definition.id] = newMatrixModule
				})
			}
		}

		const communityModules = state.importedModules.filter(module => module.isCommunity === true)

		return {
			...state,
			isFlowChanged: true,
			invalidModules: helpers.getInvalidModules(
				modulesToSet,
				state.order,
				state.flatOrder,
				communityModules,
				state.proFlow,
				state.respondentSources,
				state.tags,
			),
			estimatedLOI: getLOIEstimate(modulesToSet, state.order),
			modules: modulesToSet,
			librarySegmentsToBeAdded: helpers.filterSegmentsToBeAdded(state, state.modules),
		}
	},
	[FLOW_CHANGED]: (state, action) => {
		// drag and drop change
		let newState = Object.assign({}, state)
		newState.order = action.newFlow.slice()
		newState.modules = action.modules

		newState.flatOrder = flattenOrder(newState.order)

		const communityModules = newState.importedModules.filter(module => module.isCommunity === true)

		newState.isFlowChanged = true
		newState.invalidModules = helpers.getInvalidModules(
			newState.modules,
			newState.order,
			newState.flatOrder,
			communityModules,
			newState.proFlow,
			newState.respondentSources,
			newState.tags,
		)

		// check if we have to recompute upperModulesIds
		const currentModuleId = state.currentModuleId

		// we don't have an active module so we end the execution
		if (_.isNil(currentModuleId) === true) {
			return newState
		}

		const currentModule = newState.modules[currentModuleId]

		// active module is not UI_COMMAND or ALLOCATION so we end the execution
		if (shouldComputeUpperModuleIds(currentModule) === false) {
			return newState
		}

		const moduleInOrder = helpers.getOrderModuleById(currentModuleId, newState.order)

		newState.upperModulesIds = helpers.getUpperModuleIds(
			moduleInOrder.path,
			newState.flatOrder,
			newState.modules,
		)

		return newState
	},
	[RESTORE_MODULE]: (state, action) => {
		const newModules = {
			...state.modules,
			[action.idModule]: {
				...state.modules[action.idModule],
				isHidden: false,
			},
		}

		const communityModules = state.importedModules.filter(module => module.isCommunity === true)

		return {
			...state,
			modules: newModules,
			isFlowChanged: true,
			invalidModules: helpers.getInvalidModules(
				newModules,
				state.order,
				state.flatOrder,
				communityModules,
				state.proFlow,
				state.respondentSources,
				state.tags,
			),
			estimatedLOI: getLOIEstimate(newModules, state.order),
		}
	},
	[TOGGLE_BLOCK_MODULE_EXPAND]: (state, action) => {
		const { idModule, studyState } = action

		const newModules = {
			...state.modules,
			[idModule]: {
				...state.modules[idModule],
				definition: {
					...state.modules[idModule].definition,
					isBlockExpanded: state.modules[idModule].definition.isBlockExpanded === false,
				},
			},
		}

		const communityModules = state.importedModules.filter(module => module.isCommunity === true)

		return {
			...state,
			invalidModules: helpers.getInvalidModules(
				newModules,
				state.order,
				state.flatOrder,
				communityModules,
				state.proFlow,
				state.respondentSources,
				state.tags,
			),
			isFlowChanged: getIsStudyEditable(studyState),
			modules: newModules,
		}
	},
	[TOGGLE_STUDY_SETTINGS_DETAIL]: (state, action) => {
		return {
			...state,
			deletingModule: null,
			isStudySettingsDetailOpen: action.value,
			currentModuleId: null,
		}
	},
	[UPDATE_STUDY_SETTINGS]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.studySettings = { ...action.studySettings }
		newState.isFlowChanged = true
		return newState
	},
	[SET_IS_PROFLOW_VISIBLE]: (state, action) => {
		return {
			...state,
			currentModuleId: null,
			isProFlowVisible: action.isProFlowVisible,
		}
	},
	[SET_STUDY_TAGS]: (state, action) => ({
		...state,
		tags: action.tags,
		invalidModules: helpers.getInvalidModules(
			state.modules,
			state.order,
			state.flatOrder,
			state.importedModules.filter(module => module.isCommunity === true),
			state.proFlow,
			state.respondentSources,
			action.tags,
		),
	}),

	// Library
	[COPY_TO_LIBRARY]: (state, action) => {
		return {
			...state,
			isCopyToLibraryFormVisible: action.payload.isCopyToLibraryFormVisible,
			isCreateLibraryFromStudy: action.payload.isCreateLibraryFromStudy,
			copyToLibraryFormData: { modules: action.payload.modules, order: action.payload.order },
			isStudyCreate: action.payload.isStudyCreate,
		}
	},
	[CLOSE_LIBRARY_FORM_OVERLAY]: (state, action) => {
		return {
			...state,
			isCopyToLibraryFormVisible: false,
			copyToLibraryFormData: { modules: {}, order: [] },
		}
	},
	[FLOW_LIBRARY_ADD_ITEM]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.order = _.cloneDeep(newState.order)
		newState.librarySegmentsToBeAdded = newState.librarySegmentsToBeAdded.slice()

		const { addType, library } = action

		// Add modules
		newState.modules = {
			...newState.modules,
			...formatVisualFlowModulesFromNetwork(library.flow.modules),
		}

		// Add order
		const reversedLibraryOrder = library.flow.order.slice().reverse()

		const path = resolveAddModulePath(state.openedAddModulePath, addType, state.order)

		reversedLibraryOrder.forEach(module => {
			newState.order = addToOrder(newState.order, {
				path: path,
				moduleType: module.type,
				newModuleId: module.id,
				thenModules: module.then,
			})

			const refreshPathsResult = refreshPaths(newState.order, newState.modules)

			newState.order = refreshPathsResult.order
			newState.modules = refreshPathsResult.modules
		})

		newState.flatOrder = flattenOrder(newState.order)

		// Set segments to be added
		if (library.segments) {
			newState.librarySegmentsToBeAdded.push(...library.segments)
		}

		// set idsMediaToCopy
		newState.idsMediaToCopy = _.uniq([...state.idsMediaToCopy, ...library.idsMediaToCopy])

		const communityModules = newState.importedModules.filter(module => module.isCommunity === true)

		newState.deletingModule = null
		newState.openedAddModulePath = null
		newState.isFlowChanged = true
		newState.invalidModules = helpers.getInvalidModules(
			newState.modules,
			newState.order,
			newState.flatOrder,
			communityModules,
			newState.proFlow,
			newState.respondentSources,
			newState.tags,
		)
		newState.estimatedLOI = getLOIEstimate(newState.modules, newState.order)

		return newState
	},

	[REMOVE_MEDIA_URL]: (state, action) => {
		const newState = {
			...state,
			idsMediaToDelete: _.uniq([...state.idsMediaToDelete, action.idMediaObject]),
			// we're setting isFlowChanged to true because we need to save flow to really delete the media
			isFlowChanged: true,
			// clone properties that will be mutated
			modules: _.cloneDeep(state.modules),
			studySettings: _.cloneDeep(state.studySettings),
			flowDetailRerenderRegister: { ...state.flowDetailRerenderRegister },
		}

		// reset study settings logo if needed
		if (state.studySettings.logo.url === action.url) {
			_.set(newState, 'studySettings.logo.id', tools.DEFAULT_STUDY_IMAGE_UUID)
			_.set(newState, 'studySettings.logo.url', links.GROUPSOLVER_LOGO)
			_.set(newState, 'flowDetailRerenderRegister.studySettings', uuidv4())
		}

		// reset deleted media objects in visual flow
		const idsOfModulesUsingIdMedia = Object.values(state.modules)
			.filter(module => JSON.stringify(module.definition).includes(action.url) === true)
			.map(module => module.definition.id)

		idsOfModulesUsingIdMedia.forEach(idModule => {
			const module = newState.modules[idModule]

			// add module to list of modules that should have flow detail rerendered
			_.set(newState, `flowDetailRerenderRegister.${idModule}`, uuidv4())

			// reset deleted media objects in message modules
			if (module.type === MODULE_DEFINITIONS.A_MESSAGE.type) {
				_.set(newState, `modules.${idModule}.definition.media`, MEDIA_OBJECT())
			}

			// reset deleted media in choice/matrix choice options
			if (
				module.type === MODULE_DEFINITIONS.A_CHOICE.type ||
				module.type === MODULE_DEFINITIONS.MATRIX_CHOICE.type
			) {
				const newOptions = module.definition.options.map(option =>
					_.get(option, 'media.url', null) !== action.url
						? option
						: {
								...option,
								media: MEDIA_OBJECT(),
						  },
				)

				_.set(newState, `modules.${idModule}.definition.options`, newOptions)
			}

			// reset deleted media in matrix questions/attributes
			if (module.type === MODULE_DEFINITIONS.MATRIX_CHOICE.type) {
				if (module.definition.sharedMessage.media.url === action.url) {
					_.set(newState, `modules.${idModule}.definition.sharedMessage.media`, MEDIA_OBJECT())
				}

				if (JSON.stringify(module.definition.questions).includes(action.url) === true) {
					const newQuestions = module.definition.questions.map(question =>
						_.get(question, 'media.url', null) !== action.url
							? question
							: {
									...question,
									media: MEDIA_OBJECT(),
							  },
					)

					_.set(newState, `modules.${idModule}.definition.questions`, newQuestions)
				}
			}

			// reset image in heatmap
			if (module.type === MODULE_DEFINITIONS.HEATMAP.type) {
				_.set(newState, `modules.${idModule}.definition.imageUrl`, '')
				_.set(newState, `modules.${idModule}.definition.thumbnailUrl`, '')
			}

			// reset deleted media in definition.messages of dataset modules
			if (module.definition.messages !== undefined) {
				module.definition.messages.forEach((message, index) => {
					_.set(
						newState,
						`modules.${idModule}.definition.messages[${index}].definition.media`,
						MEDIA_OBJECT(),
					)
				})
			}
		})

		const communityModules = newState.importedModules.filter(module => module.isCommunity === true)

		newState.invalidModules = helpers.getInvalidModules(
			newState.modules,
			newState.order,
			newState.flatOrder,
			communityModules,
			newState.proFlow,
			state.respondentSources,
			newState.tags,
		)

		return newState
	},

	[ADD_RESPONDENT_SOURCE]: (state, action) => {
		return {
			...state,
			respondentSources: [...state.respondentSources, action.respondentSource],
		}
	},
	[UPDATE_RESPONDENT_SOURCE]: (state, action) => {
		return {
			...state,
			respondentSources: state.respondentSources.map(r =>
				r.idRespondentSource === action.respondentSource.idRespondentSource
					? action.respondentSource
					: r,
			),
		}
	},
	[REMOVE_RESPONDENT_SOURCE]: (state, action) => {
		return {
			...state,
			respondentSources: state.respondentSources.filter(
				({ idRespondentSource }) =>
					idRespondentSource !== action.respondentSource.idRespondentSource,
			),
		}
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function studyDesign(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
