import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from 'react-tooltip'
import classnames from 'classnames'

import { COLORS } from 'constants/colors'

import classes from './Tooltip.module.scss'

const DefaultTooltip = props => (
	<Tooltip
		backgroundColor={props.backgroundColor}
		textColor={COLORS.WHITE}
		className={classnames(classes.tooltip, {
			[props.className]: props.className !== null,
		})}
		id={props.id}
		{...props}
	/>
)

DefaultTooltip.defaultProps = {
	backgroundColor: COLORS.GREY_45,
	className: null,
}

DefaultTooltip.propTypes = {
	id: PropTypes.string.isRequired,
	backgroundColor: PropTypes.string.isRequired,
	className: PropTypes.string,
}

export default DefaultTooltip
