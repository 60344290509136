const filterSegmentsToBeAdded = (state, newModules) => {
	return state.librarySegmentsToBeAdded.filter(segment => {
		const shouldSegmentExist = Object.keys(newModules).find(moduleId => {
			const module = newModules[moduleId]
			if (module.type === 'A_CHOICE') {
				return (
					segment.conditions.find(condition => {
						return condition.left.every(idOption =>
							module.definition.options.find(moduleOption => moduleOption.id === idOption),
						)
					}) !== undefined
				)
			}

			return false
		})

		return shouldSegmentExist || false
	})
}

export default filterSegmentsToBeAdded
