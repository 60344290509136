import PropTypes from 'prop-types'

export const fieldShape = PropTypes.shape({
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
		PropTypes.number,
		PropTypes.object,
	]),
})
