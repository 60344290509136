import moment from 'moment-timezone'

export const getTimezoneOffset = timezoneName =>
	moment()
		.tz(timezoneName)
		.format('Z')

export const formatTimezoneName = (timezoneName, offset) => {
	const zoneLabel = timezoneName.replace(/\//g, ' - ').replace(/_/g, ' ')

	return `${zoneLabel} (UTC ${offset})`
}
