import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Inbox = lazy(() => import('./Inbox'))
const WrappedInbox = errorWrapper(Inbox)

export default () => ({
	path: 'inbox',
	element: <WrappedInbox />,
})
