import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import { fieldShape } from 'components/_formik/_constants'

import BaseSelect from 'components/_scaffolding/_input/Select'

const flattenOptions = options => {
	const flatOptions = []

	options.forEach(option => {
		if (option.options === undefined) {
			flatOptions.push(option)
		} else {
			flatOptions.push(...option.options)
		}
	})

	return flatOptions
}

const Select = ({ field, ...props }) => {
	const onChange = option => {
		if (props.disabled === true) {
			return
		}

		if (props.onChange !== undefined) {
			return props.onChange(option.value, option)
		}

		props.form.setFieldValue(field.name, option.value)
	}

	const onBlur = event => {
		if (props.disabled === true) {
			return
		}

		if (props.onBlur !== undefined) {
			return props.onBlur(event)
		}

		if (props.form.setFieldTouched === undefined) {
			// TODO: We have couple of places where this component is used badly
			// so `props.form` is just an empty object. Eventually, those places should
			// be fixed, but for now, we'll return early.
			return
		}

		props.form.setFieldTouched(field.name, true)
	}

	const isValid = _.get(props.form.errors, field.name, null) === null

	const getSelectedOption = () =>
		flattenOptions(props.options).find(option => option.value === field.value) || null

	return (
		<BaseSelect
			shouldValidate={false}
			isDisabled={props.disabled}
			value={getSelectedOption()}
			id={field.name}
			isValid={isValid}
			{...props}
			onChange={onChange}
			onBlur={onBlur}
			styles={props.styles}
		/>
	)
}

Select.defaultProps = {
	disabled: false,
}

Select.propTypes = {
	disabled: PropTypes.bool.isRequired,
	field: fieldShape.isRequired,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	// formik bag
	form: PropTypes.object.isRequired,
}

export default Select
