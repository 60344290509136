import fnMap from './_fnMap'
import forbiddenFnList from './_forbiddenFnList'
import validateFnObject from './_validateFnObject'

export const validateFnArray = (path, fnName, code, errors) => {
	const thisPath = `${path}.${fnName}`

	code.forEach((element, index) => {
		if (Array.isArray(element) === true) {
			validateFnArray(thisPath, fnName, element, errors)
			return
		}

		if (typeof element === 'object') {
			const fnWrapper = { ...element }
			const functions = Object.keys(fnWrapper)

			// there should be only 1 key in every object
			if (functions.length > 1) {
				errors.push({ message: `${thisPath}: too many functions in object at index ${index}` })
				return
			}

			// get function name
			const [fn] = functions

			if (forbiddenFnList[fn] !== undefined) {
				errors.push({
					message: `${thisPath}: globally forbidden function "${fn}" in object at index ${index}`,
				})
				return
			}

			// check array for unsupported commands
			const functionDefinition = fnMap[fnName]
			if (
				functionDefinition !== undefined &&
				functionDefinition.allowed.length > 0 &&
				functionDefinition.allowed.includes(fn) === false
			) {
				// unsupported function
				errors.push({
					message: `${thisPath}: unknown function "${fn}" in object at index ${index}`,
				})
				return
			}

			// check code of array element
			if (Array.isArray(fnWrapper[fn])) {
				validateFnArray(`${thisPath}[${index}]`, fn, fnWrapper[fn], errors)
				return
			}

			if (typeof fnWrapper[fn] === 'object') {
				validateFnObject(`${thisPath}[${index}]`, fn, fnWrapper[fn], errors)
			}
		}
	})
}

export default validateFnArray
