import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'
import _validateSetVariableArgumentLength from './_validateSetVariableArgumentLength'
import _validateSetVariableVariableName from './_validateSetVariableVariableName'
import _validateSetVariableFnArguments from './_validateSetVariableFnArguments'
import { SET_FN_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/SET_VARIABLE_CONSTANTS'

const validateSetVariable = module => {
	const errors = []

	const { definition } = module
	const { fnArguments, id, variableName, setFn } = definition

	if (_validateSetVariableVariableName(variableName, setFn) === false) {
		errors.push({
			id: id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.set_variable_invalid_variable_name,
		})
	}

	Object.entries(fnArguments).forEach(([key, value]) => {
		if (_validateSetVariableArgumentLength(key, value) === false) {
			errors.push({
				id: id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.set_variable_empty_arg,
			})
		}
	})

	if (setFn === SET_FN_TYPES.SET_LITERAL || setFn === SET_FN_TYPES.SET_PATH_LITERAL) {
		try {
			const variableValue = JSON.parse(fnArguments.variableValue)

			if (_validateSetVariableFnArguments(variableValue) === false) {
				errors.push({
					id: id,
					type: VISUAL_FLOW_INVALID_MODULE_TYPES.set_variable_invalid_json_argument,
				})
			}
		} catch (e) {
			errors.push({
				id: id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.set_variable_invalid_json_argument,
			})
		}
	}

	return errors
}

export default validateSetVariable
