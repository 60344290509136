import {
	atLeast,
	atMost,
	getIsValidEmailTopLevelDomain,
	getIsValidPassword,
	matchEmail,
	matchNumeric,
	matchUrl,
	matchValue,
	matches,
	numAtLeast,
	numAtMost,
	required,
	validMomentDate,
	validVariableName,
} from 'components/_formik/validation'

export const getValidate = (validationRules, label) => value => {
	if (
		validationRules.formatter !== undefined &&
		(typeof validationRules.formatter !== 'function' ||
			typeof validationRules.formatter('test') === 'undefined')
	) {
		throw new Error('Formatter has to be function returning value')
	}

	const valueToValidate =
		validationRules.formatter !== undefined ? validationRules.formatter(value) : value

	if (validationRules.required === false && required(valueToValidate)) {
		return undefined
	}

	if (validationRules.required === true && required(valueToValidate)) {
		return `${label} is required`
	}

	if (
		validationRules.maxLength !== undefined &&
		atMost(validationRules.maxLength, valueToValidate)
	) {
		return `${label} is too long`
	}

	if (
		validationRules.minLength !== undefined &&
		atLeast(validationRules.minLength, valueToValidate)
	) {
		return `${label} is too short`
	}

	if (validationRules.validEmail === true && matchEmail(valueToValidate)) {
		return `${label} must be a valid email address`
	}

	if (
		validationRules.validEmail === true &&
		getIsValidEmailTopLevelDomain(valueToValidate) === false
	) {
		return `${label} domain is not valid`
	}

	if (validationRules.validPassword === true && getIsValidPassword(valueToValidate) === false) {
		return `${label} has to contain at least 8 characters, lowercase character, uppercase character and a number`
	}

	if (
		validationRules.validUrl === true &&
		valueToValidate.length > 0 &&
		matchUrl(valueToValidate)
	) {
		return `${label} must be a valid URL address`
	}

	if (validationRules.validVariableName === true && validVariableName(valueToValidate)) {
		return `${label} is invalid`
	}

	if (validationRules.validString === true && matches(/.*/mu, valueToValidate)) {
		return `${label} is invalid`
	}

	if (validationRules.validFileName === true && matches(/^[\w\-. ]+$/, valueToValidate)) {
		return `${label} is invalid`
	}

	if (validationRules.validNumber === true && matchNumeric(valueToValidate)) {
		return `${label} must be a number`
	}

	if (validationRules.validFloat === true && isNaN(Number(valueToValidate)) === true) {
		return `${label} must be a number`
	}

	if (validationRules.validMomentDate === true && validMomentDate(valueToValidate)) {
		return `${label} is invalid`
	}

	if (validationRules.max !== undefined && numAtMost(validationRules.max, valueToValidate)) {
		return `${label} cannot be bigger than ${validationRules.max}`
	}

	if (validationRules.min !== undefined && numAtLeast(validationRules.min, valueToValidate)) {
		return `${label} cannot be smaller than ${validationRules.min}`
	}

	if (validationRules.matchValue && matchValue(validationRules.matchValue, valueToValidate)) {
		return `${label} has to match ${validationRules.matchValue}`
	}

	return undefined
}
