const KEY = 'idActiveAccount'

export const setActiveAccount = idActiveAccount => {
	window.sessionStorage.setItem(KEY, idActiveAccount)
}

export const getIdActiveAccount = () => {
	return window.sessionStorage.getItem(KEY) ?? null
}

export const removeIdActiveAccount = () => {
	window.sessionStorage.removeItem(KEY)
}
