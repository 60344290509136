import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import config from 'config'

import StaticInteractiveOverlay from 'components/_overlay/StaticInteractiveOverlay'
import OverlayTitle from 'components/_overlay/OverlayTitle'
import OverlayMessage from 'components/_overlay/OverlayMessage'
import Icon from 'components/_scaffolding/Icon'
import ContactUsForm from 'components/_scaffolding/ContactUsForm'
import getUser from 'components/_hoc/getUser'

import classes from './ContactUsOverlay.module.scss'

const ContactUsOverlay = ({ handleCloseClick, user }) => {
	const intl = useIntl()

	const { email, fullName } = user

	return (
		<StaticInteractiveOverlay
			contentClassName={classes.wrapper}
			handleCloseClick={handleCloseClick}
		>
			<Icon name={Icon.NAMES.MAIL} size={40} customClassName={classes.icon} />
			<OverlayTitle>{_.capitalize(intl.formatMessage({ id: 'contact_us' }))}</OverlayTitle>
			<OverlayMessage>
				{intl.formatMessage({ id: 'contact_us_form.message' })}{' '}
				<a href={`mailto:${config.platformPreset.supportEmail}`} className={classes.link}>
					{config.platformPreset.supportEmail}
				</a>
			</OverlayMessage>

			<ContactUsForm email={email} fullName={fullName} handleCloseClick={handleCloseClick} />
		</StaticInteractiveOverlay>
	)
}

ContactUsOverlay.propTypes = {
	handleCloseClick: PropTypes.func.isRequired,
	user: PropTypes.shape({
		email: PropTypes.string.isRequired,
		fullName: PropTypes.string.isRequired,
	}).isRequired,
}

export default getUser(ContactUsOverlay)
