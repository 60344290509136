const KEY = 'api.set.token'

export const setToken = token => {
	window.localStorage.setItem(KEY, token)
}

export const removeToken = () => {
	window.localStorage.removeItem(KEY)
}

export const getToken = () => {
	return window.localStorage.getItem(KEY) ?? null
}

export const getIsLoggedIn = () => getToken() !== null
