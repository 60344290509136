import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'

export const numberConditionSelectionTypes = [
	{
		value: CONDITION_SELECTION_TYPES.NUMBER_EQUAL_TO,
		idTranslation: 'condition.equal_to',
	},
	{
		value: CONDITION_SELECTION_TYPES.GREATER_THAN,
		idTranslation: 'condition.greater_than',
	},
	{
		value: CONDITION_SELECTION_TYPES.GREATER_THAN_OR_EQUAL_TO,
		idTranslation: 'condition.greater_than_or_equal_to',
	},
	{
		value: CONDITION_SELECTION_TYPES.LESS_THAN_OR_EQUAL_TO,
		idTranslation: 'condition.less_than_or_equal_to',
	},
	{
		value: CONDITION_SELECTION_TYPES.LESS_THAN,
		idTranslation: 'condition.less_than',
	},
]

export const getNumberConditionSelectionTypeOptions = intl =>
	numberConditionSelectionTypes.map(rule => ({
		value: rule.value,
		label: intl.formatMessage({ id: rule.idTranslation }),
	}))
