import { COLORS } from 'constants/colors'

const getOptionBackground = (state, isHover) => {
	if (state.data.disabled === true) {
		return COLORS.GREY_95
	}

	if (state.isMulti === true && state.isSelected === true) {
		return COLORS.PRIMARY_90
	}

	if (isHover === true) {
		return COLORS.GREY_95
	}

	return COLORS.WHITE
}

export const reactSelectStyles = {
	control: (base, state, isValid = true) => {
		const validBorderColor =
			state.menuIsOpen === true || state.isFocused === true ? COLORS.PRIMARY_50 : COLORS.GREY_90

		const borderColor = isValid === true ? validBorderColor : COLORS.RED_40

		return {
			...base,
			background: COLORS.WHITE,
			border: `1px solid ${borderColor}`,
			borderColor: borderColor,
			borderRadius: '5px',
			boxShadow: 'none',
			cursor: 'pointer',
			fontSize: '12px',
			height: 30,
			minHeight: 0,
			'&:hover': {
				borderColor: borderColor,
			},
			'&:focus': {
				borderColor: COLORS.PRIMARY_50,
			},
		}
	},
	valueContainer: base => ({
		...base,
		flexWrap: 'nowrap',
	}),
	singleValue: base => ({
		...base,
		color: COLORS.GREY_10,
		overflow: 'hidden',
		position: 'relative',
		textOverflow: 'ellipsis',
		top: 'initial',
		transform: 'initial',
	}),
	menu: base => ({
		...base,
		background: COLORS.WHITE,
		borderRadius: '5px',
		margin: '0px',
		overflow: 'hidden',
		maxWidth: '100%',
		minWidth: '100%',
		width: 'auto',
	}),
	menuList: base => ({ ...base, padding: '0px', width: 'auto' }),
	option: (base, state) => ({
		...base,
		background: getOptionBackground(state, false),
		color: COLORS.GREY_10,
		cursor: state.data.disabled === true ? 'not-allowed' : 'pointer',
		fontSize: '12px',
		overflow: 'hidden',
		padding: '10px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: 'auto',
		'&:hover': {
			background: getOptionBackground(state, true),
		},
	}),
}
