import PropTypes from 'prop-types'
import React from 'react'

import classes from './CodeErrors.module.scss'

const CodeErrors = ({ errors, root }) =>
	errors.length === 0 ? null : (
		<div className={classes['condition-errors-wrapper']}>
			<div className="title-error">
				{errors.map((error, i) => {
					return (
						<div key={i}>
							{error.dataPath || root}: {error.message || error}
						</div>
					)
				})}
			</div>
		</div>
	)

CodeErrors.propTypes = {
	errors: PropTypes.array.isRequired,
	root: PropTypes.string.isRequired,
}

export default CodeErrors
