const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/RANKING.json',
	required: [
		'filter',
		'idRelatedMessage',
		'mandatory',
		'options',
		'requireUniqueValues',
		'subtype',
		'messages',
	],
	properties: {
		idRelatedMessage: {
			$id: '/properties/idRelatedMessage',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: ['string', 'null'],
		},
		mandatory: {
			default: true,
			$id: '/properties/mandatory',
			type: 'boolean',
		},
		filter: {
			$id: '/properties/filter',
			type: ['string', 'null'],
		},
		subtype: {
			$id: '/properties/subtype',
			enum: ['RANKING_DRAG_AND_DROP', 'RANKING_SELECT'],
			type: 'string',
		},
		requireUniqueValues: {
			$id: '/properties/requireUniqueValues',
			type: 'boolean',
			default: true,
		},
		options: {
			additionalItems: false,
			$id: '/properties/options',
			items: {
				additionalProperties: false,
				$id: '/properties/options/items',
				required: [
					'code',
					'id',
					'isNoneOfThese',
					'label',
					'media',
					'randomize',
					'showLabel',
					'simpleName',
					'isHidden',
				],
				type: 'object',
				properties: {
					id: {
						$id: '/properties/options/items/properties/id',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
						type: 'string',
					},
					code: {
						$id: '/properties/options/items/properties/code',
						type: 'integer',
					},
					showLabel: {
						$id: '/properties/options/items/properties/showLabel',
						type: 'boolean',
						default: true,
					},
					label: {
						$id: '/properties/options/items/properties/label',
						type: 'string',
					},
					media: {
						$id: '/properties/options/items/properties/media',
						type: 'null',
					},
					randomize: {
						$id: '/properties/options/items/properties/randomize',
						type: 'boolean',
						default: false,
					},
					isNoneOfThese: {
						default: false,
						$id: '/properties/options/items/properties/isNoneOfThese',
						type: 'boolean',
						enum: [false],
					},
					simpleName: {
						$id: '/properties/options/items/properties/simpleName',
						type: ['string', 'null'],
					},
					isHidden: {
						default: false,
						$id: '/properties/options/items/properties/isHidden',
						type: 'boolean',
					},
				},
			},
			minItems: 2,
			type: 'array',
			uniqueItems: true,
		},
		messages: {
			$id: '/properties/messages',
			type: 'array',
			additionalItems: false,
			uniqueItems: true,
			items: {
				$id: '/properties/messages/items',
				type: 'object',
				additionalProperties: false,
				required: ['id', 'definition', 'type'],
				properties: {
					id: {
						$id: '/properties/messages/items/id',
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					type: {
						$id: '/properties/messages/items/type',
						type: 'string',
						enum: ['A_MESSAGE'],
					},
					definition: {
						$id: '/properties/messages/items/definition',
						$ref: 'http://groupsolver.com/v1/MESSAGE.json',
					},
				},
			},
		},
		dynamicOptionsSettings: {
			$id: '/properties/dynamicOptionsSettings',
			type: 'object',
			required: ['isActive', 'idMainList', 'idInputList', 'optionLabelColumn', 'optionsOrderMode'],
			additionalProperties: false,
			properties: {
				isActive: {
					$id: '/properties/dynamicOptionsSettings/isActive',
					type: 'boolean',
				},
				idMainList: {
					$id: '/properties/dynamicOptionsSettings/idMainList',
					type: ['string', 'null'],
				},
				idInputList: {
					$id: '/properties/dynamicOptionsSettings/idInputList',
					type: ['string', 'null'],
				},
				optionLabelColumn: {
					$id: '/properties/dynamicOptionsSettings/optionLabelColumn',
					type: ['string', 'null'],
				},
				optionsOrderMode: {
					$id: '/properties/dynamicOptionsSettings/optionsOrderMode',
					type: 'string',
					enum: ['QUESTION_ORDER', 'INPUT_LIST_ORDER'],
				},
			},
		},
	},
	type: 'object',
}

export default schema
