import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import DotsLoader from 'components/_scaffolding/DotsLoader'
import { COLORS } from 'constants/colors'

import classes from './LoadingStatus.module.scss'

const LoadingStatus = props => {
	let type = props.type
	let title = props.titleSingle
	let description = props.descriptionSingle
	let dotLoaderbackgroundColor = props.dotLoaderbackgroundColor

	if (!type) {
		if (props.offline === true) {
			type = 'offline'
			title = props.titleLoading || 'You seem to be offline'
			description = props.descriptionLoading || 'Please check your internet connection'
		} else if (props.loading === true) {
			type = 'loading'
			title = props.titleLoading || 'Loading'
			description = props.descriptionLoading || 'Please wait'
			dotLoaderbackgroundColor = props.dotLoaderbackgroundColor || COLORS.PRIMARY_45
		} else if (props.error === true) {
			type = 'failed'
			title = props.titleFailed || 'Failed to load data'
			description = props.descriptionFailed || 'Try to refresh your page'
		} else if (props.notSelected) {
			type = 'notselected'
			title = props.titleNotSelected || 'There are no slides in this report yet.'
			description = props.descriptionNotSelected || ''
		} else if (!props.hideNoData) {
			type = 'nodata'
			title = props.titleNodata || 'No data received'
			description =
				props.descriptionNodata || 'Your request seems to not fit any data in this study'
		} else {
			type = ''
			title = ''
			description = ''
		}
	}
	const testLoadingClass = type === 'loading' ? 'test-loading-status ' : 'test-loaded-status '
	return (
		<div
			className={
				testLoadingClass +
				classes['holder-status'] +
				' ' +
				classes[type ? 'holder-status-' + type : '']
			}
		>
			<div className={classes['wrapper-status']}>
				{type === 'loading' ? (
					<div className={classes['wrapper-loader']}>
						<DotsLoader backgroundColor={dotLoaderbackgroundColor} size={props.size} />
					</div>
				) : null}
				<div>
					<span
						className={classnames(classes['title-status'], {
							[classes['title-status--big']]: props.size === DotsLoader.SIZES.BIG,
						})}
					>
						{title}
					</span>
					<span
						className={classnames(classes['description-status'], {
							[classes['description-status--big']]: props.size === DotsLoader.SIZES.BIG,
						})}
					>
						{description}
					</span>
				</div>
			</div>
		</div>
	)
}

LoadingStatus.SIZES = DotsLoader.SIZES

LoadingStatus.propTypes = {
	type: PropTypes.string,
	dotLoaderbackgroundColor: PropTypes.string,
	titleSingle: PropTypes.string,
	descriptionSingle: PropTypes.string,
	titleLoading: PropTypes.string,
	descriptionLoading: PropTypes.string,
	titleFailed: PropTypes.string,
	descriptionFailed: PropTypes.string,
	titleNodata: PropTypes.string,
	titleNotSelected: PropTypes.string,
	descriptionNotSelected: PropTypes.string,
	descriptionNodata: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	loading: PropTypes.bool,
	offline: PropTypes.bool,
	error: PropTypes.bool,
	hideNoData: PropTypes.bool,
	notSelected: PropTypes.bool,
	size: PropTypes.string,
}

export default LoadingStatus
