import { connect } from 'react-redux'
import Allocation from './Allocation'
import { fromStudyDesign } from 'selectors'
import { filterModulesForAllocation } from 'helpers/visualFlowModules/filterModulesForAllocation'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

const mapStateToProps = state => {
	const idsAvailableModules = fromStudyDesign.getUpperModulesIds(state)
	const modules = fromStudyDesign.getModules(state)

	const availabableFreeTextModules = filterModulesForAllocation(
		idsAvailableModules.map(id => fromStudyDesign.getModuleById(id, state)),
	)

	const upperLists = idsAvailableModules
		.filter(
			id => VISUAL_FLOW_MODULE_TYPES.LIST === modules[id].type && modules[id].isHidden !== true,
		)
		.map(id => modules[id])

	return {
		availabableFreeTextModules,
		upperLists,
	}
}

export default connect(mapStateToProps)(Allocation)
