import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Formik, Form, Field } from 'formik'
import { injectIntl } from 'react-intl'

import {
	CHOICE_SUBTYPE,
	VISUAL_FLOW_INVALID_MODULE_TYPES,
	VISUAL_FLOW_MODULE_TYPES,
} from 'constants/studyDesign'
import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'

import getAllChoiceOptionsRandomized from 'helpers/visualFlowModules/getAllChoiceOptionsRandomized'
import setChoiceOptionsIsRandomized from 'helpers/visualFlowModules/setChoiceOptionsIsRandomized'
import { getNormalOptionsCount } from 'helpers/visualFlowModules/getNormalOptionsCount'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Label from 'components/_formik/_base/Label'
import FormikSelect from 'components/_formik/_base/Select'
import Slider from 'components/_formik/_base/Slider'

import Select from 'components/_scaffolding/_input/Select'

import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'

import ChoiceOptions from 'components/_formik/_custom/_studyDesign/ChoiceOptions'
import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import OptionsFilter from 'components/_formik/_custom/_studyDesign/OptionsFilter'
import TrapQuestionSettings from './_components/TrapQuestionSettings'

import classes from './Choice.module.scss'

const getAreAllCollapsed = state => Object.values(state).find(value => value === true) === undefined

const getAreAllRandomized = getAllChoiceOptionsRandomized

const createState = (options, isExpanded) => {
	const newState = {}
	options.forEach(o => (newState[o.id] = isExpanded))
	return newState
}

// question formats are different than choice subtype
// because we have 2 formats for IMAGE subtype
const CHOICE_QUESTION_FORMATS = {
	CHECKBOX: 'CHECKBOX',
	SELECT: 'SELECT',
	IMAGE: 'IMAGE',
	IMAGE_NO_LABELS: 'IMAGE_NO_LABELS',
}

const CHOICE_QUESTION_FORMATS_TO_SUBTYPE = {
	[CHOICE_QUESTION_FORMATS.CHECKBOX]: CHOICE_SUBTYPE.CHECKBOX,
	[CHOICE_QUESTION_FORMATS.SELECT]: CHOICE_SUBTYPE.SELECT,
	[CHOICE_QUESTION_FORMATS.IMAGE]: CHOICE_SUBTYPE.IMAGE,
	[CHOICE_QUESTION_FORMATS.IMAGE_NO_LABELS]: CHOICE_SUBTYPE.IMAGE,
}

class Choice extends Component {
	static propTypes = {
		closeModuleDetail: PropTypes.func.isRequired,
		copyModule: PropTypes.func.isRequired,
		copyModuleButtonParams: PropTypes.object.isRequired,
		disabled: PropTypes.bool.isRequired,
		errorInvalidSimpleName: PropTypes.bool.isRequired,
		errorNonUniqueSimpleName: PropTypes.bool.isRequired,
		generalDefinition: PropTypes.object.isRequired,
		initialValues: PropTypes.object.isRequired,
		intl: PropTypes.object.isRequired,
		isFlowChanged: PropTypes.bool.isRequired,
		isMatrixChoice: PropTypes.bool.isRequired,
		isRelatedModuleInvalid: PropTypes.bool.isRequired,
		isUnsaved: PropTypes.bool,
		isInternalEmployee: PropTypes.bool.isRequired,
		moduleErrors: PropTypes.array.isRequired,
		openCopyToLibraryForm: PropTypes.func.isRequired,
		openMediaManager: PropTypes.func.isRequired,
		saveModule: PropTypes.func.isRequired,
		upperLists: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	}

	state = { ...createState(this.props.initialValues.options, false) }

	expandOption = idOption => {
		this.setState({ [idOption]: true })
	}

	collapseOption = idOption => {
		this.setState({ [idOption]: false })
	}

	toggleOption = idOption => {
		this.setState(current => ({
			...current,
			[idOption]: current[idOption] !== true,
		}))
	}

	expandAll = () => {
		this.setState({ ...createState(this.props.initialValues.options, true) })
	}

	collapseAll = () => {
		this.setState({ ...createState(this.props.initialValues.options, false) })
	}

	toggleAll = () => {
		getAreAllCollapsed(this.state) === false ? this.collapseAll() : this.expandAll()
	}

	getIsRelatedModuleInvalid = () => {
		if (this.props.isMatrixChoice === false) {
			return this.props.isRelatedModuleInvalid
		}

		return this.props.moduleErrors.some(
			error =>
				error.type === VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_nonUniqueSimpleName ||
				error.type === VISUAL_FLOW_INVALID_MODULE_TYPES.matrix_choice_nonUniqueShortNames ||
				error.type === VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_requiredSimpleName ||
				error.type === VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_simpleNametooLong ||
				error.type === VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_invalid,
		)
	}

	render() {
		return (
			<Formik initialValues={{ ...this.props.initialValues }} onSubmit={() => {}}>
				{({ values, setFieldValue, setValues }) => {
					const setRandomizeForAllOptions = value => {
						setValues({
							...values,
							options: setChoiceOptionsIsRandomized(values.options, value),
						})
					}
					const randomizeAll = () => setRandomizeForAllOptions(true)
					const pinAll = () => setRandomizeForAllOptions(false)

					const regenerateCodeValues = () => {
						setValues({
							...values,
							options: values.options.map((o, i) => ({ ...o, code: i + 1 })),
						})
					}

					const additionalActions = [
						{
							isDisabled: false,
							label:
								getAreAllCollapsed(this.state) === false
									? this.props.intl.formatMessage({ id: 'choice.detail.collapse_all' })
									: this.props.intl.formatMessage({ id: 'choice.detail.expand_all' }),
							tooltipText: '',
							value: this.toggleAll,
						},
					]

					if (this.props.disabled === false) {
						additionalActions.push(
							{
								isDisabled: false,
								label:
									getAreAllRandomized(values.options) === true
										? this.props.intl.formatMessage({ id: 'choice.detail.pin_options' })
										: this.props.intl.formatMessage({ id: 'choice.detail.randomize_all' }),
								tooltipText: '',
								value: getAreAllRandomized(values.options) === true ? pinAll : randomizeAll,
							},
							{
								isDisabled: false,
								label: this.props.intl.formatMessage({ id: 'choice.detail.reset_code_values' }),
								tooltipText: '',
								value: regenerateCodeValues,
							},
						)
					}

					const handleQuestionFormatChange = selectedOption => {
						const newSubtype = CHOICE_QUESTION_FORMATS_TO_SUBTYPE[selectedOption.value]

						const newOptions = values.options.map(option => ({
							...option,
							showLabel: selectedOption.value === CHOICE_QUESTION_FORMATS.IMAGE,
							media:
								newSubtype === CHOICE_SUBTYPE.IMAGE && option.media === null
									? MEDIA_OBJECT(option.isNoneOfThese)
									: option.media,
						}))

						if (newSubtype === undefined) {
							throw new Error(`Unknown choice question format value: ${selectedOption.value}`)
						}

						setValues({
							...values,
							options: newOptions,
							subtype: newSubtype,
						})
					}

					const questionFormatOptions = [
						{
							label: this.props.intl.formatMessage({ id: 'choice.question.format.checkbox' }),
							value: CHOICE_QUESTION_FORMATS.CHECKBOX,
						},
						{
							label: this.props.intl.formatMessage({ id: 'choice.question.format.select' }),
							value: CHOICE_QUESTION_FORMATS.SELECT,
						},
						values.dynamicOptionsSettings?.isActive !== true
							? {
									label: this.props.intl.formatMessage({
										id: 'choice.question.format.image',
									}),
									value: CHOICE_QUESTION_FORMATS.IMAGE,
							  }
							: null,
						values.dynamicOptionsSettings?.isActive !== true
							? {
									label: this.props.intl.formatMessage({
										id: 'choice.question.format.image_no_labels',
									}),
									value: CHOICE_QUESTION_FORMATS.IMAGE_NO_LABELS,
							  }
							: null,
					].filter(option => option !== null)

					const getQuestionFormatValue = () => {
						if (values.subtype === CHOICE_SUBTYPE.IMAGE) {
							const questionFormat = values.options.every(option => option.showLabel === true)
								? CHOICE_QUESTION_FORMATS.IMAGE
								: CHOICE_QUESTION_FORMATS.IMAGE_NO_LABELS

							return questionFormatOptions.find(option => option.value === questionFormat)
						}

						if (values.subtype === CHOICE_SUBTYPE.SELECT) {
							return questionFormatOptions.find(
								option => option.value === CHOICE_QUESTION_FORMATS.SELECT,
							)
						}

						if (values.subtype === CHOICE_SUBTYPE.CHECKBOX) {
							return questionFormatOptions.find(
								option => option.value === CHOICE_QUESTION_FORMATS.CHECKBOX,
							)
						}

						throw new Error(`Unknown CHOICE_SUBTYPE: ${values.subtype}`)
					}

					const optionAlignOptions = [
						{
							value: false,
							label: this.props.intl.formatMessage({ id: 'choice.detail.align_options.text_end' }),
						},
						{
							value: true,
							label: this.props.intl.formatMessage({
								id: 'choice.detail.align_options.text_start',
							}),
						},
					]

					return (
						<div className={classes.wrapper}>
							<Header
								additionalActions={additionalActions}
								closeModuleDetail={this.props.closeModuleDetail}
								copyModule={this.props.copyModule}
								copyModuleButtonParams={this.props.copyModuleButtonParams}
								disabled={this.props.disabled}
								generalDefinition={this.props.generalDefinition}
								isFlowChanged={this.props.isFlowChanged}
								isMatrixChoice={this.props.isMatrixChoice}
								isRelatedModuleInvalid={this.getIsRelatedModuleInvalid()}
								moduleDefinition={values}
								openCopyToLibraryForm={this.props.openCopyToLibraryForm}
								showCopyToLibrary
							/>
							<FormHolder>
								<AutoSubmit
									values={values}
									onSave={this.props.saveModule}
									formComponent={() => (
										<Form>
											{this.props.isMatrixChoice === false && (
												<DatasetShortName
													disabled={this.props.disabled}
													errorInvalidSimpleName={this.props.errorInvalidSimpleName}
													errorNonUniqueSimpleName={this.props.errorNonUniqueSimpleName}
													values={values}
												/>
											)}
											<Label
												label={this.props.intl.formatMessage({ id: 'choice.question.format' })}
											/>
											<Select
												id={'question-format-select'}
												isSearchable={false}
												isDisabled={this.props.disabled}
												onChange={handleQuestionFormatChange}
												options={questionFormatOptions}
												value={getQuestionFormatValue()}
											/>
											{values.subtype !== CHOICE_SUBTYPE.IMAGE && (
												<Fragment>
													<Label
														label={this.props.intl.formatMessage({
															id: 'choice.detail.align_options',
														})}
													/>
													<Field
														component={FormikSelect}
														name="hasCenteredOptions"
														options={optionAlignOptions}
													/>
												</Fragment>
											)}

											<ChoiceOptions
												disabled={this.props.disabled}
												expandOption={this.expandOption}
												getNormalOptionsCount={getNormalOptionsCount}
												isMatrixChoice={this.props.isMatrixChoice}
												isUnsaved={this.props.isUnsaved}
												isInternalEmployee={this.props.isInternalEmployee}
												listState={this.state}
												moduleType={VISUAL_FLOW_MODULE_TYPES.A_CHOICE}
												openMediaManager={this.props.openMediaManager}
												regenerateCodeValues={regenerateCodeValues}
												setFieldValue={setFieldValue}
												setValues={setValues}
												showNoneOfTheseOption
												toggleOption={this.toggleOption}
												upperLists={this.props.upperLists}
												values={values}
											/>
											{values.dynamicOptionsSettings?.isActive !== true && (
												<OptionsFilter
													disabled={this.props.disabled}
													isInternalEmployee={this.props.isInternalEmployee}
													moduleType={VISUAL_FLOW_MODULE_TYPES.A_CHOICE}
													values={values}
												/>
											)}
											<Label
												label={this.props.intl.formatMessage(
													{ id: 'choice.detail.max_options_label' },
													{ count: values.maxSelection },
												)}
											/>
											<Field
												component={Slider}
												disabled={this.props.disabled}
												isSpacerNeeded
												max={getNormalOptionsCount(values.options)}
												min={values.minSelection}
												name="maxSelection"
												onChange={value => {
													setFieldValue('maxSelection', value)
													setFieldValue('multiple', value > 1)
												}}
												step={1}
												{...Slider.STYLE_PRESETS.dark}
											/>
											{getNormalOptionsCount(values.options) > 2 && (
												<Fragment>
													<Label
														label={this.props.intl.formatMessage(
															{ id: 'choice.detail.min_options_label' },
															{ count: values.minSelection },
														)}
													/>
													<Field
														component={Slider}
														disabled={this.props.disabled}
														isSpacerNeeded
														max={getNormalOptionsCount(values.options) - 1}
														min={1}
														name="minSelection"
														onChange={value => {
															setFieldValue('minSelection', value)
															if (value > values.maxSelection) setFieldValue('maxSelection', value)
															if (value > 1) setFieldValue('multiple', true)
														}}
														{...Slider.STYLE_PRESETS.dark}
														step={1}
													/>
												</Fragment>
											)}
											<Label
												label={this.props.intl.formatMessage(
													{ id: 'choice.detail.options_shown_limit' },
													{
														count:
															values.optionsShownLimit ??
															this.props.intl.formatMessage({ id: 'all' }),
													},
												)}
											/>
											<Field
												component={Slider}
												disabled={this.props.disabled}
												isSpacerNeeded
												max={getNormalOptionsCount(values.options)}
												min={1}
												name="optionsShownLimit"
												step={1}
												{...Slider.STYLE_PRESETS.dark}
												onChange={value => {
													setFieldValue(
														'optionsShownLimit',
														value === getNormalOptionsCount(values.options) ? null : value,
													)
												}}
												value={values.optionsShownLimit ?? getNormalOptionsCount(values.options)}
											/>
											{this.props.isMatrixChoice === false &&
												values.dynamicOptionsSettings.isActive !== true && (
													<TrapQuestionSettings
														disabled={this.props.disabled}
														setFieldValue={setFieldValue}
														setValues={setValues}
														values={values}
													/>
												)}
										</Form>
									)}
								/>
							</FormHolder>
						</div>
					)
				}}
			</Formik>
		)
	}
}

export default injectIntl(Choice)
