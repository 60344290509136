import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const AnswerManager = lazy(() => import('./AnswerManagerContainer'))
const WrappedAnswerManager = errorWrapper(AnswerManager)

export default () => ({
	path: 'answermanager',
	element: <WrappedAnswerManager />,
})
