import { connect } from 'react-redux'

import ListFilterSettings from './ListFilterSettings'

import { fromStudyDesign } from 'selectors'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

const mapStateToProps = state => {
	const upperModulesIds = fromStudyDesign.getUpperModulesIds(state)
	const modules = fromStudyDesign.getModules(state)

	const upperMatrixModules = []
	const upperLists = []
	const upperChoices = []
	const upperAllocations = []
	const upperRankingQuestions = []

	upperModulesIds.forEach(id => {
		const flowModule = modules[id]

		if (flowModule.isHidden === true) {
			return
		}

		if (flowModule.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE) {
			upperMatrixModules.push(flowModule)

			return
		}

		if (VISUAL_FLOW_MODULE_TYPES.LIST === flowModule.type) {
			upperLists.push(flowModule)

			return
		}

		if (VISUAL_FLOW_MODULE_TYPES.A_CHOICE === flowModule.type) {
			upperChoices.push(flowModule)

			return
		}

		if (VISUAL_FLOW_MODULE_TYPES.ALLOCATION === flowModule.type) {
			upperAllocations.push(flowModule)
		}

		if (VISUAL_FLOW_MODULE_TYPES.RANKING === flowModule.type) {
			upperRankingQuestions.push(flowModule)
		}
	})

	return {
		upperLists,
		upperMatrixModules,
		upperChoices,
		upperAllocations,
		upperRankingQuestions,
	}
}

export default connect(mapStateToProps)(ListFilterSettings)
