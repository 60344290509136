import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { addListColumn } from 'routes/_study/StudyDesign/_store/studyDesign'

import Input from 'components/_scaffolding/_input/Input'
import StaticInteractiveOverlay from 'components/_overlay/StaticInteractiveOverlay'
import OverlayHeader from 'components/_scaffolding/OverlayHeader'
import Button from 'components/_scaffolding/_input/Button'

import classes from './AddListColumnOverlay.module.scss'

const AddListColumnOverlay = ({ close, values }) => {
	const dispatch = useDispatch()
	const intl = useIntl()

	const { columns, items, identifier } = values

	const [columnName, setColumnName] = useState('')
	const [columnValues, setColumnValues] = useState(() =>
		items.reduce(
			(acc, item) => ({
				...acc,
				[item[identifier]]: {
					idItem: item[identifier],
					value: '',
				},
			}),
			{},
		),
	)

	const handleColumnNameChange = event => {
		const newValue = event.currentTarget.value

		setColumnName(newValue)
	}

	const handleInputChange = (event, key) => {
		const newValue = event.currentTarget.value

		setColumnValues(current => ({
			...current,
			[key]: {
				...current[key],
				value: newValue,
			},
		}))
	}

	const isColumnNameValid =
		columnName.trim() !== '' && columns.every(c => c.key !== columnName.trim())

	const hasEmptyValues = items.some(item => {
		const key = item[identifier]

		return columnValues[key].value.trim() === ''
	})

	const canSubmit = isColumnNameValid === true && hasEmptyValues === false

	const handleSubmit = () => {
		dispatch(
			addListColumn({
				idModule: values.id,
				columnName,
				columnValues,
			}),
		)
		close()
	}

	const renderError = message => (
		<div className={classnames(classes.error, 'title-error')}>{message}</div>
	)

	return (
		<StaticInteractiveOverlay
			handleCloseClick={close}
			contentClassName={classes.overlay}
			isCloseButtonHidden
		>
			<OverlayHeader
				handleCloseClick={close}
				handleTabClick={_.noop}
				tabs={[]}
				title={intl.formatMessage({ id: 'list.detail.manage_items.add_column' })}
			/>
			<div className={classes.content}>
				<div className={classes.grid}>
					<div className={classes.label}>
						{intl.formatMessage({ id: 'list.detail.manage_items.column_name' })}
					</div>
					<Input
						name={`add-column-column-name`}
						value={columnName}
						onChange={handleColumnNameChange}
						isValid={isColumnNameValid}
					/>
					{columnName.trim() === '' &&
						renderError(intl.formatMessage({ id: 'list.detail.manage_items.column_name_empty' }))}
					{columnName.trim() !== '' &&
						isColumnNameValid === false &&
						renderError(
							intl.formatMessage(
								{ id: 'list.detail.manage_items.column_name_exists' },
								{ columnName },
							),
						)}
					{items.map(item => {
						const key = item[identifier]
						const value = columnValues[item[identifier]].value

						return (
							<Fragment key={key}>
								<div className={classes.label}>{key}</div>
								<Input
									name={`add-column-${key}`}
									value={value}
									onChange={event => handleInputChange(event, key)}
									isValid={value.trim() !== ''}
								/>
							</Fragment>
						)
					})}
					{hasEmptyValues === true &&
						renderError(
							intl.formatMessage({ id: 'list.detail.manage_items.values_empty' }, { columnName }),
						)}
				</div>
				<div className={classes.buttons}>
					<Button
						type="button"
						title={intl.formatMessage({ id: 'close' })}
						secondary
						onClick={close}
					/>
					<Button
						type="button"
						title={intl.formatMessage({ id: 'list.detail.manage_items.add_column' })}
						primary
						disabled={canSubmit === false}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</StaticInteractiveOverlay>
	)
}

AddListColumnOverlay.propTypes = {
	close: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
}

export default AddListColumnOverlay
