import * as Sentry from '@sentry/react'
import config from 'config'

export const logError = (error, other, fingerprint = null) => {
	const extra = {
		other,
		fingerprint,
	}

	// eslint-disable-next-line no-console
	console.warn(
		config.logger === true
			? 'Sentry error logged'
			: 'Sentry error not loggged (its turned off in your enviroment config)',
		error,
		extra,
	)
	Sentry.captureException(error, { extra })
}
