/**
 * Create/Update Pages
 */
import UserList from './UserList'
import CreateUser from './CreateUser'
import UpdateUser from './UpdateUser'
import AssignAccount from './AssignAccount'
import CreateTestUser from './CreateTestUser'

export default () => ({
	path: 'user',
	children: [UserList(), CreateUser(), UpdateUser(), AssignAccount(), CreateTestUser()],
})
