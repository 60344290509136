import { connect } from 'react-redux'
import Snippet from './Snippet'
import { fromStudyDesign } from 'selectors'

const mapActionCreators = {}

const mapStateToProps = state => ({
	modules: fromStudyDesign.getModules(state),
})

export default connect(mapStateToProps, mapActionCreators)(Snippet)
