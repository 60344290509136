import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'

import { findSelectedList } from 'helpers/visualFlowModules/findSelectedList'
import { getAvailableInputListOptions } from 'helpers/visualFlowModules/getAvailableInputListOptions'

import Select from 'components/_formik/_base/Select'

import classes from './InputListSettings.module.scss'

const ERRORS = {
	[VISUAL_FLOW_INVALID_MODULE_TYPES.list_empty]: 'list_filter.detail.empty_name',
	[VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_selected]:
		'list_filter.detail.list_does_not_exist',
}

const InputListSettings = props => {
	const intl = useIntl()

	const renderModuleLevelError = ({ type }) => {
		const idTranslation = ERRORS[type]

		if (idTranslation === undefined) {
			return null
		}

		return <div className="title-error">{intl.formatMessage({ id: ERRORS[type] })}</div>
	}

	const selectedMainList = findSelectedList(props.values.idInputList, props.upperLists)

	const inputListOptions = getAvailableInputListOptions(
		props.isStudyEditable,
		props.isUnsaved,
		selectedMainList,
		props.upperLists,
	)

	return (
		<div className={classes.holder}>
			<Field
				component={Select}
				disabled={props.disabled}
				name={'idInputList'}
				options={inputListOptions}
				placeholder={intl.formatMessage({ id: 'select_list_placeholder' })}
			/>
			{props.moduleErrors.length > 0 && renderModuleLevelError(props.moduleErrors[0])}
		</div>
	)
}

InputListSettings.propTypes = {
	disabled: PropTypes.bool.isRequired,
	isStudyEditable: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	moduleErrors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	upperLists: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	values: PropTypes.object.isRequired,
}

export default InputListSettings
