import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'
import ListFilterIncludeNoData from 'components/_formik/_complex/StudyDesign/ListFilterIncludeNoData'

import { getConditionSelectionTypeSelectOptions } from 'helpers/conditionBuilder/getConditionSelectionTypeSelectOptions'
import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'

import classes from './CommunityRule.module.scss'

const CommunityRule = props => {
	const intl = useIntl()

	const { ruleIndex } = props

	const communityModules = props.importedModules.filter(module => module.isCommunity === true)

	const communityRulesOptions = communityModules.map(module => ({
		label: module.simpleName,
		value: module.id,
	}))

	const selectedListColumnOptions =
		props.selectedList?.definition.columns.map(column => ({
			label: column.key,
			value: column.key,
		})) ?? []

	const negationSelectOptions = [
		{
			value: true,
			label: intl.formatMessage({ id: 'is' }),
		},
		{
			value: false,
			label: intl.formatMessage({ id: 'is_not' }),
		},
	]

	const functionOptions = getConditionSelectionTypeSelectOptions(intl).filter(
		option => option.value !== CONDITION_SELECTION_TYPES.EVERY_OF,
	)

	const selectedModule = communityModules.find(module => module.id === props.rule.communityKey)

	const selectedListColumn = props.selectedList?.definition.columns.find(
		column => column.key === props.rule.listKey,
	)

	return (
		<div id={`rule-${ruleIndex}`}>
			<Label
				label={intl.formatMessage({
					id: 'list_filter.detail.rule.community.respondent_information',
				})}
			/>
			<Field
				component={Select}
				disabled={props.disabled}
				name={`filterRules[${ruleIndex}].communityKey`}
				options={communityRulesOptions}
			/>
			{props.rule.communityKey === '' && (
				<div className="title-info">
					{intl.formatMessage({ id: 'list_filter.detail.rule.community.select_module' })}
				</div>
			)}
			{props.rule.communityKey !== '' && selectedModule === undefined && (
				<div className="title-error">
					{intl.formatMessage({ id: 'list_filter.detail.rule.community.missing_module' })}
				</div>
			)}
			<Label label={intl.formatMessage({ id: 'comparison_rule' })} />
			<div className={classes.split}>
				<Field
					component={Select}
					disabled={props.disabled}
					name={`filterRules[${ruleIndex}].is`}
					options={negationSelectOptions}
				/>
				<Field
					component={Select}
					disabled={props.disabled}
					name={`filterRules[${ruleIndex}].function`}
					options={functionOptions}
				/>
			</div>
			<Label
				label={intl.formatMessage({ id: 'list_filter.detail.rule.community.list_item_param' })}
			/>
			<Field
				component={Select}
				disabled={props.disabled}
				name={`filterRules[${ruleIndex}].listKey`}
				options={selectedListColumnOptions}
			/>
			{props.rule.listKey === '' && (
				<div className="title-info">
					{intl.formatMessage({ id: 'list_filter.detail.rule.community.select_list_key' })}
				</div>
			)}
			{props.rule.listKey !== '' && selectedListColumn === undefined && (
				<div className="title-error">
					{intl.formatMessage({ id: 'list_filter.detail.rule.community.missing_list_key' })}
				</div>
			)}
			<ListFilterIncludeNoData
				disabled={props.disabled}
				ruleIndex={ruleIndex}
				ruleType={props.rule.ruleType}
			/>
		</div>
	)
}

CommunityRule.propTypes = {
	disabled: PropTypes.bool.isRequired,
	importedModules: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	rule: PropTypes.object.isRequired,
	ruleIndex: PropTypes.number.isRequired,
	selectedList: PropTypes.object.isRequired,
}

export default CommunityRule
