import { omit } from 'lodash'
import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

const normalizeOrderModule = module => {
	const normalized = omit(module, ['description', 'headerTitle', 'style', 'title'])

	if (module.type === MODULE_DEFINITIONS.UI_COMMAND.type) {
		normalized.then = module.then.map(normalizeOrderModule)
		normalized.else = module.else.map(normalizeOrderModule)
	}

	return normalized
}

export default normalizeOrderModule
