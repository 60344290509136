import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import Icon from 'components/_scaffolding/Icon'
import Label from 'components/_formik/_base/Label'

import { CHOICE_SUBTYPE, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import classes from './OptionsHeader.module.scss'

const OptionsHeader = props => {
	const intl = useIntl()

	if (props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF) {
		return null
	}

	const regenerateCodeValues = () => {
		if (props.disabled === true) {
			return
		}

		props.regenerateCodeValues()
	}

	const isMatrixChoice = props.isMatrixChoice ?? false
	const isChoice = props.moduleType === VISUAL_FLOW_MODULE_TYPES.A_CHOICE
	const isImageChoice = props.subtype === CHOICE_SUBTYPE.IMAGE

	return (
		<div
			className={classnames(classes.row, {
				[classes['row--first']]: isChoice === true && isMatrixChoice === false,
				[classes['row--image-choice']]: isImageChoice === true,
			})}
		>
			<div
				className={classnames(classes.row__label, {
					[classes['row__label--image-choice']]: isImageChoice === true,
				})}
			>
				<Label
					className={classnames({ [classes.label]: isChoice === true && isMatrixChoice === false })}
					label={intl.formatMessage({ id: 'choice.option.label' })}
				/>
			</div>
			<div
				className={classnames(classes.row__code, {
					[classes['row__code--image-choice']]: isImageChoice === true,
				})}
				onClick={regenerateCodeValues}
				title={intl.formatMessage({ id: 'choice.detail.reset_code_values' })}
			>
				<Label
					className={classnames({ [classes.label]: isChoice === true && isMatrixChoice === false })}
					label={intl.formatMessage({ id: 'choice.option.code' })}
				/>
				<div className={classes.row__refresh}>
					<Icon name={Icon.NAMES.REFRESH} size={16} />
				</div>
			</div>
		</div>
	)
}

OptionsHeader.propTypes = {
	disabled: PropTypes.bool.isRequired,
	isMatrixChoice: PropTypes.bool,
	moduleType: PropTypes.string.isRequired,
	regenerateCodeValues: PropTypes.func.isRequired,
	subtype: PropTypes.string.isRequired,
}

export default OptionsHeader
