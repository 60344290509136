import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const DataDownloader = lazy(() => import('./DataDownloader'))
const WrappedDataDownloader = errorWrapper(DataDownloader)

export default () => ({
	path: 'datadownloader',
	element: <WrappedDataDownloader />,
})
