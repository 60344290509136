import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import { Formik, Form, Field } from 'formik'
import { useIntl } from 'react-intl'

import classes from './EndStudy.module.scss'

import { hasInvalidRedirect } from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateEndstudy'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import ModuleAdvancedOptionsToggle from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/ModuleAdvancedOptionsToggle'

import Icon from 'components/_scaffolding/Icon'
import Tooltip from 'components/_scaffolding/Tooltip'

import PanelRedirects from './_components/PanelRedirects'

const isHttps = url => url.includes('https://')

const getInitialIsExpanded = initialValues =>
	initialValues.enableRedirectButton === true || initialValues.overrideRedirect === true

const EndStudy = props => {
	const intl = useIntl()

	const [isExpanded, setIsExpanded] = useState(getInitialIsExpanded(props.initialValues))

	const handleIsExpandedToggleClick = () =>
		setIsExpanded(currentIsExpanded => currentIsExpanded === false)

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setFieldValue }) => (
				<Fragment>
					<Header
						closeModuleDetail={props.closeModuleDetail}
						generalDefinition={props.generalDefinition}
						disabled={props.disabled}
						moduleDefinition={values}
						isFlowChanged={props.isFlowChanged}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						showCopyToLibrary={false}
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={isSubmitting => (
								<Form>
									<DatasetShortName
										disabled={props.disabled}
										errorInvalidSimpleName={props.errorInvalidSimpleName}
										errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
										values={values}
									/>
									<Label
										label={intl.formatMessage({
											id: 'panel_redirects',
										})}
									/>
									<PanelRedirects
										isInternalEmployee={props.isInternalEmployee}
										respondentSources={props.respondentSources}
										subtype={props.initialValues.subtype}
									/>
									<ModuleAdvancedOptionsToggle
										id="end-study-advanced-options-toggle"
										onClick={handleIsExpandedToggleClick}
										isExpanded={isExpanded}
									/>
									{isExpanded === true && (
										<div data-testid="advanced-options">
											<Field
												component={Checkbox}
												componentProps={{
													label: intl.formatMessage({
														id: 'endstudy.redirect_button.enable',
													}),
												}}
												disabled={props.disabled}
												name="enableRedirectButton"
											/>
											{values.enableRedirectButton === true && (
												<div data-testid="redirect-button">
													<Label
														label={intl.formatMessage({
															id: 'endstudy.redirect_button.label',
														})}
													/>
													<Field component={Input} disabled={props.disabled} name="redirectLabel" />
													{values.redirectLabel.length === 0 && (
														<span className="title-error">
															{intl.formatMessage({
																id: 'endstudy.redirect_button_label.required',
															})}
														</span>
													)}
													{values.redirectLabel.length > 50 && (
														<span className="title-error">
															{intl.formatMessage({
																id: 'endstudy.redirect_button_label.too_long',
															})}
														</span>
													)}
												</div>
											)}
											{props.shouldShowAdvancedFeatures === true && (
												<Fragment>
													<Field
														component={Checkbox}
														componentProps={{
															label: intl.formatMessage({ id: 'endstudy.redirect.override' }),
														}}
														disabled={props.disabled}
														name="overrideRedirect"
													/>
													{values.overrideRedirect === true && (
														<div data-testid="override-redirect">
															<Label
																label={intl.formatMessage({ id: 'endstudy.redirect_url' })}
																secondaryLabel={
																	<Icon
																		name={Icon.NAMES.QUESTION}
																		data-tip={intl.formatMessage({ id: 'url_validation_skip' })}
																		data-for="url-validation-tooltip"
																	/>
																}
															/>
															<div
																className={classnames(classes['url__holder'], {
																	[classes['url__holder--https']]:
																		isHttps(values.redirectUrl) === true,
																	[classes['url__holder--http']]:
																		isHttps(values.redirectUrl) === false,
																})}
															>
																<Field
																	name="redirectUrl"
																	component={Input}
																	className={classes.url__input}
																	disabled={props.disabled}
																	placeholder={intl.formatMessage({ id: 'endstudy.type_url' })}
																	validate={{ validUrl: true }}
																/>
															</div>
															{hasInvalidRedirect({ definition: values }) === true && (
																<span className="title-error">
																	{intl.formatMessage({ id: 'endstudy.invalidUrl' })}
																</span>
															)}
															<Tooltip
																className={classes.url__tooltip}
																id="url-validation-tooltip"
															/>
														</div>
													)}
												</Fragment>
											)}
										</div>
									)}
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

EndStudy.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isInternalEmployee: PropTypes.bool.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	respondentSources: PropTypes.array.isRequired,
	saveModule: PropTypes.func.isRequired,
	shouldShowAdvancedFeatures: PropTypes.bool.isRequired,
}

export default EndStudy
