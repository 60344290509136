const isBrowserSupported = () => {
	try {
		const cookies = navigator.cookieEnabled
		const cssFlexbox = CSS.supports('display', 'flex')
		const localStorage = window.localStorage
		const cssTransition =
			'transition' in document.documentElement.style ||
			'WebkitTransition' in document.documentElement.style
		const svg =
			document.createElementNS &&
			document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect

		return Boolean(cookies && cssFlexbox && localStorage && cssTransition && svg)
	} catch (error) {
		console.error('BROWSER NOT SUPPORTED:', error)
		return false
	}
}

export default isBrowserSupported
