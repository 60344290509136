import { CHOICE_SUBTYPE, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

export const MESSAGE_DESCRIPTION = {
	[VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT]: 'freetext.question.description',
	[VISUAL_FLOW_MODULE_TYPES.A_OEQ]: 'oeq.description',
	[VISUAL_FLOW_MODULE_TYPES.ALLOCATION]: 'allocation.description',
	[VISUAL_FLOW_MODULE_TYPES.RANKING]: 'ranking.description',
	[VISUAL_FLOW_MODULE_TYPES.A_CHOICE]: {
		[CHOICE_SUBTYPE.CHECKBOX]: 'choice.question.description',
		[CHOICE_SUBTYPE.SELECT]: 'choice.question.description',
		[CHOICE_SUBTYPE.IMAGE]: 'imageChoice.question.description',
	},
	[VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE]: {
		[CHOICE_SUBTYPE.CHECKBOX]: 'matrixChoice.question.description',
		[CHOICE_SUBTYPE.IMAGE]: 'matrixImageChoice.question.description',
	},
	[VISUAL_FLOW_MODULE_TYPES.MAXDIFF]: 'maxDiff.description',
	[VISUAL_FLOW_MODULE_TYPES.HEATMAP]: 'heatmap.description',
}
