import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

export const flattenOrder = order => {
	const flatOrder = []

	order.forEach(orderModule => {
		flatOrder.push(orderModule)

		if (
			orderModule.type === VISUAL_FLOW_MODULE_TYPES.UI_COMMAND &&
			orderModule.then !== undefined
		) {
			flatOrder.push(...flattenOrder(orderModule.then))
		}
	})

	return flatOrder
}
