import {
	CONDITION_SELECTION_TYPES,
	CONDITION_CASE_TYPES,
	CONDITION_TYPES,
} from 'constants/conditionBuilder'

export const getEmptyFlowCondition = includeNoData => ({
	type: CONDITION_TYPES.CONDITION, // CONDITION_TYPES.TRACK,
	case: CONDITION_CASE_TYPES.AND, // 'and' | 'or'
	studyObject: {
		id: null,
	},
	is: true,
	includeNoData: includeNoData,
	selection: {
		type: CONDITION_SELECTION_TYPES.EQUAL_TO, // 'equal_to' | 'anyOf' | 'everyOf' | 'after' | 'before' | 'between' | 'date_equals
		minAnyOfValidSelection: 1,
		maxAnyOfValidSelection: 1,
	},
	left: [],
})
