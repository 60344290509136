import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'
import validateDatasetMessages from './_validateDatasetMessages'
import validateRelatedMessage from './_validateRelatedMessage'

export const hasInvalidPreseededStatement = module =>
	module.definition.preseededStatements.some(statement => statement.length === 0)

export const hasInvalidElaboreSettings = module =>
	module.definition.elaborate.minEvaluations < 0 ||
	module.definition.elaborate.maxEvaluations < 0 ||
	module.definition.elaborate.minEvaluations > module.definition.elaborate.maxEvaluations

const validateOeq = (module, modules, studyTags) => {
	const validationResult = []

	validationResult.push(...validateDatasetMessages(module, studyTags))
	validationResult.push(...validateRelatedMessage(module, modules, studyTags))

	if (hasInvalidPreseededStatement(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.oeq_preseeded_statement_too_short,
		})
	}

	if (hasInvalidElaboreSettings(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.oeq_invalid_elaborate_settings,
		})
	}

	return validationResult
}

export default validateOeq
