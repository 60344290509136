import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { useIntl } from 'react-intl'

import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import classes from './RelatedModuleToggle.module.scss'

const getRelated = (definition, property) => _.get(definition, property, null)

const getIdRelatedModule = (definition, moduleType) => {
	return moduleType === MODULE_DEFINITIONS.A_MESSAGE.type
		? getRelated(definition, 'idRelatedAnswer')
		: getRelated(definition, 'idRelatedMessage')
}

const getQuestionTabLabelTranslation = (isMatrixChoice, generalDefinition) => {
	if (isMatrixChoice === true) {
		return 'rows'
	}

	return generalDefinition.type === MODULE_DEFINITIONS.MAXDIFF.type ? 'questions' : 'question'
}

const RelatedModuleToggle = ({
	generalDefinition,
	hideRelatedMessage,
	isMatrixChoice,
	isRelatedModuleInvalid,
	isRelatedMessageShown,
	moduleDefinition,
	showRelatedMessage,
}) => {
	const intl = useIntl()
	const { type } = generalDefinition
	const idRelatedModule = getIdRelatedModule(moduleDefinition, type)

	if (
		isMatrixChoice === false &&
		generalDefinition.type !== MODULE_DEFINITIONS.MAXDIFF.type &&
		idRelatedModule === null &&
		_.get(moduleDefinition, 'messages[0]') === undefined
	) {
		return null
	}

	const isMessageTabActive = isRelatedMessageShown

	const handleDatasetTabClick = () => {
		if (isMessageTabActive === true) {
			hideRelatedMessage()
		}
	}

	const handleQuestionTabClick = () => {
		if (isMessageTabActive === false) {
			showRelatedMessage()
		}
	}

	return (
		<div className={classes.holder}>
			<div
				className={classnames(classes.tab, {
					[classes['tab--message']]: isMessageTabActive === false,
					[classes['tab--invalid']]:
						isMessageTabActive === false && isRelatedModuleInvalid === true,
				})}
				data-isactive={isMessageTabActive === true}
				onClick={handleQuestionTabClick}
			>
				{intl.formatMessage({
					id: getQuestionTabLabelTranslation(isMatrixChoice, generalDefinition),
				})}
			</div>
			<div
				className={classnames(classes.tab, {
					[classes['tab--dataset']]: isMessageTabActive === true,
					[classes['tab--invalid']]: isMessageTabActive === true && isRelatedModuleInvalid === true,
				})}
				data-isactive={isMessageTabActive === false}
				onClick={handleDatasetTabClick}
			>
				{intl.formatMessage({ id: isMatrixChoice === true ? 'columns' : 'options' })}
			</div>
		</div>
	)
}

RelatedModuleToggle.defaultProps = {
	isRelatedDatasetInvalid: true,
	isRelatedMessageInvalid: true,
}

RelatedModuleToggle.propTypes = {
	generalDefinition: PropTypes.shape({
		description: PropTypes.func.isRequired,
		style: PropTypes.string.isRequired,
		title: PropTypes.func.isRequired,
		type: PropTypes.string.isRequired,
	}).isRequired,
	isMatrixChoice: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	isRelatedMessageShown: PropTypes.bool.isRequired,
	moduleDefinition: PropTypes.object.isRequired,
	showRelatedMessage: PropTypes.func.isRequired,
	hideRelatedMessage: PropTypes.func.isRequired,
}

export default RelatedModuleToggle
