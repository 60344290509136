import { v4 as uuidv4 } from 'uuid'
import MEDIA_OBJECT from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'

export const MATRIX_QUESTION = (shortName, text) => ({
	id: uuidv4(),
	idRelatedMessage: null,
	shortName,
	text,
	media: MEDIA_OBJECT(),
	timeout: 1000,
	isExpanded: true,
	isUnsaved: true,
	isHidden: false,
})
