import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import { fieldShape } from 'components/_formik/_constants'
import { getMediaThumbnailUrl } from 'helpers/getMediaThumbnailUrl'

import Icon from 'components/_scaffolding/Icon'
import SmallButton from 'components/_scaffolding/Buttons/SmallButton'

import classes from './MediaOpener.module.scss'

const MediaOpener = ({
	canSubmitVideo,
	className,
	disabled,
	field,
	fieldIndex,
	form,
	openMediaManager,
	isShowImageText,
	placeholderClassName,
	placeholderSize,
}) => {
	const intl = useIntl()
	const thumbnail = getMediaThumbnailUrl(field.value.url)
	const isImageUploaded = field.value.url !== ''
	const isImageWithText = isShowImageText === true && isImageUploaded === true

	const handleClick = () => {
		if (disabled === false) {
			openMediaManager(field, form, canSubmitVideo)
		}
	}

	const handleLabelClick = () => {
		if (isShowImageText === true && isImageUploaded === true) {
			return
		}

		handleClick()
	}

	const imageClassName =
		isShowImageText === true
			? classes['thumbnail-wrapper__default-image']
			: classes['thumbnail-wrapper__image-choice-option']

	const changeLabel = canSubmitVideo === true ? 'change_media' : 'change_image'

	return (
		<div
			className={classnames(classes.wrapper, {
				[className]: className !== null,
				[classes['wrapper--disabled']]: disabled === true,
			})}
		>
			<label
				className={classnames(classes.label, {
					[classes['label--disabled']]: disabled === true,
				})}
				onClick={handleLabelClick}
			>
				<div
					className={classnames(classes.holder, {
						[classes['holder--with-text']]: isImageWithText === true,
						[classes['holder--without-text']]: isImageWithText === false,
					})}
					data-testid={
						fieldIndex === null ? 'media-opener-thumbnail' : `media-opener-thumbnail-${fieldIndex}`
					}
				>
					{thumbnail !== null ? (
						<div className={classes['thumbnail-wrapper']}>
							<div className={imageClassName}>
								<img src={thumbnail} alt="media" className={classes.image} />
							</div>
							{isShowImageText === true && (
								<SmallButton
									title={intl.formatMessage({ id: changeLabel })}
									onClick={handleClick}
								/>
							)}
						</div>
					) : (
						<div
							className={classnames(classes.placeholder, {
								[classes['placeholder--default']]: isShowImageText === true,
								[classes['placeholder--image-choice-option']]: isShowImageText === false,
								[placeholderClassName]: placeholderClassName !== null,
								[classes['placeholder--disabled']]: disabled === true,
							})}
						>
							<Icon name={Icon.NAMES.IMAGE_ADD_PHOTO} size={placeholderSize} />
						</div>
					)}
				</div>
			</label>
		</div>
	)
}

MediaOpener.propTypes = {
	canSubmitVideo: PropTypes.bool.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	field: fieldShape.isRequired,
	fieldIndex: PropTypes.number,
	form: PropTypes.object.isRequired,
	openMediaManager: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	isShowImageText: PropTypes.bool.isRequired,
	placeholderClassName: PropTypes.string,
	placeholderSize: PropTypes.number.isRequired,
}

MediaOpener.defaultProps = {
	canSubmitVideo: false,
	className: null,
	disabled: false,
	fieldIndex: null,
	isShowImageText: true,
	placeholderClassName: null,
}

export default MediaOpener
