import _ from 'lodash'
import tlds from 'tlds'

import curry from 'lodash/curry'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'

import { URL_VALIDATION_SKIP_PREFIX } from 'constants/regex'

/**
 * Validator function used for required fields
 *
 * @param {mixed}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 *
 * Number: null || undefined,
 * String: null || undefined || ''
 * Array: null || undefined || []
 * Object: null || undefined || {}
 */
const required = fieldValue => {
	if (fieldValue === undefined || fieldValue === null) return true

	if (isString(fieldValue) && fieldValue.trim() === '') return true

	if (isArray(fieldValue) === true) return fieldValue.length === 0

	if (isObject(fieldValue) === true) return isEmpty(fieldValue)

	return false
}

/**
 * Validator function used for fields that require
 * their content to have a maximum amount of characters
 *
 * @param {Integer} maxLength 	Maximum lenght of value
 * @param {mixed}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const atMost = (maxLength, fieldValue) => fieldValue.length > maxLength

/**
 * Validator function used for fields that require
 * their content to have a minimum amount of characters
 *
 * @param {Integer} minLength 	Minimum lenght of value
 * @param {mixed}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const atLeast = (minLength, fieldValue) => fieldValue.length < minLength

/**
 * Validator function that validates
 * top level domain of email address
 *
 * @param {String} 	email 	email
 *
 * @return {Bool} 	true in case of valid top level domain, false otherwise
 */
const getIsValidEmailTopLevelDomain = email => {
	const topLevelDomain = _.last(email.split('.'))

	return tlds.includes(topLevelDomain.toLowerCase())
}

/**
 * Validator function that validates
 * that the provided password matches all required rules
 *
 * @param {String} 	password 	password
 *
 * @return {Bool} 	true in case of valid password, false otherwise
 */
const getIsValidPassword = password => {
	const trimmedPassword = password.trim()

	if (trimmedPassword.length < 8) {
		return false
	}

	if (/\d/.test(trimmedPassword) === false) {
		return false
	}

	if (/[A-Z]/.test(trimmedPassword) === false) {
		return false
	}

	if (/[a-z]/.test(trimmedPassword) === false) {
		return false
	}

	return true
}

/**
 * Validator function used for fields that require
 * their value to match a regular expression
 *
 * @param {RegEx} 	regex 	Regular expression to check the value against
 * @param {mixed}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const matches = curry((regex, fieldValue) => regex.test(fieldValue) === false)

const matchNumeric = matches(/^[0-9 +-]*$/)
const matchEmail = matches(/^\S+@\S+\.\S+$/)

/**
 * Validator function used for urls
 * URL_VALIDATION_SKIP_PREFIX skips validation
 *
 * @param {String}	value 	url to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const matchUrl = value => {
	if (value.startsWith(URL_VALIDATION_SKIP_PREFIX) === true) {
		return false
	}

	const regex = /^(https?:\/\/)?(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.{1}[a-z0-9]{2,}(\/.*)?(\?.*)?$/

	return regex.test(value) === false
}

/**
 * Validator function used for fields that require
 * their value to match to another provided value
 *
 * @param {String}	valueToMatch  Value to compare the fieldValue to
 * @param {String}	fieldValue  Value of the field
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const matchValue = (valueToMatch, fieldValue) => valueToMatch !== fieldValue

/**
 * Validator function used for number fields that require
 * their content to have a maximum value
 *
 * @param {Integer} maxValue 	Maximum lenght of value
 * @param {Integer}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const numAtMost = (maxValue, fieldValue) => fieldValue > maxValue

/**
 * Validator function used for number fields that require
 * their content to have a minimum value
 *
 * @param {Integer} minValue 	Minimum lenght of value
 * @param {Integer}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const numAtLeast = (minValue, fieldValue) => fieldValue < minValue

/**
 * Validator function used for date fields
 * check if the moment object has valid date
 *
 * @param {Object}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const validMomentDate = fieldValue => fieldValue.isValid() === false

/**
 * Validator function used for proflow and pipe in variable names
 * variable name cannot contain spaces
 *
 * @param {String}	fieldValue 	Value of the field to check
 *
 * @return {Bool} 	true in case of error, false otherwise
 */
const validVariableName = fieldValue => fieldValue.includes(' ') === true

export {
	atLeast,
	atMost,
	getIsValidEmailTopLevelDomain,
	getIsValidPassword,
	matchEmail,
	matchNumeric,
	matchUrl,
	matchValue,
	matches,
	numAtLeast,
	numAtMost,
	required,
	validMomentDate,
	validVariableName,
}
