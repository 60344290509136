import PropTypes from 'prop-types'
import React, { Fragment, useRef } from 'react'
import _ from 'lodash'
import { Field } from 'formik'
import { useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import { MEDIA_AGATHA_TYPE } from 'constants/mediaObject'
import { getFieldName } from 'helpers/formik/getFieldName'
import {
	handleActionButtonEnabledChange,
	handleMaxTimerEnabledChange,
	handleForceFullscreenChange,
} from './_hooks'

import Checkbox from 'components/_formik/_base/Checkbox'
import FormattedInput from 'components/_formik/_complex/FormattedInput'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Slider from 'components/_formik/_base/Slider'
import Tooltip from 'components/_scaffolding/Tooltip'

const getMinTimerLabel = (minTimer, intl) =>
	intl.formatMessage({ id: 'action_button.minTimer' }, { count: minTimer / 1000 })

const getMaxTimerLabel = (isEnabled, maxTimer, intl) =>
	isEnabled === true
		? intl.formatMessage({ id: 'action_button.maxTimer' }, { count: maxTimer / 1000 })
		: intl.formatMessage({ id: 'action_button.maxTimer.disabled' })

const MessageModuleOptions = props => {
	const intl = useIntl()
	const { current: idTooltip } = useRef(uuidv4())
	const { formProps, namePrefix } = props
	const { setFieldValue, values } = formProps

	if (_.isNil(namePrefix) === true) {
		throw new Error('namePrefix has to be defined')
	}

	const mediaValue = _.get(values, getFieldName(namePrefix, 'media'))
	const timeoutValue = _.get(values, getFieldName(namePrefix, 'timeout'))

	const maxTimerMaxValue =
		_.get(values, getFieldName(namePrefix, 'media.type')) === MEDIA_AGATHA_TYPE.A_VIDEO
			? 5 * 60 * 1000
			: 60 * 1000
	const minTimerMaxValue =
		_.get(values, getFieldName(namePrefix, 'media.type')) === MEDIA_AGATHA_TYPE.A_VIDEO
			? 5 * 60 * 1000
			: 30 * 1000

	const renderAltInput = () => {
		if (props.isAccessibilityEnabled === false) {
			return null
		}

		return (
			<Fragment>
				<Label label={intl.formatMessage({ id: 'message.detail.media_alt.label' })} />
				<Field
					component={Input}
					disabled={props.disabled}
					name={getFieldName(namePrefix, 'media.alt')}
				/>
				{mediaValue.alt === '' ? (
					<div className="title-error">
						{intl.formatMessage({ id: 'message.detail.media_alt.error' })}
						&nbsp;
						{intl.formatMessage({ id: 'message.detail.media_alt.info' })}
					</div>
				) : (
					<div className="title-info">
						{intl.formatMessage({ id: 'message.detail.media_alt.info' })}
					</div>
				)}
			</Fragment>
		)
	}

	return (
		<Fragment>
			{mediaValue.url.length > 0 && (
				<Fragment>
					{renderAltInput()}
					<div
						data-for={idTooltip}
						data-tip={intl.formatMessage({ id: 'message.tooltip_text' })}
						data-tip-disable={mediaValue.type !== MEDIA_AGATHA_TYPE.A_VIDEO}
						data-place="bottom"
					>
						<Field
							component={Checkbox}
							componentProps={{
								label: intl.formatMessage({ id: 'message.detail.stop_for_review' }),
							}}
							disabled={mediaValue.type === MEDIA_AGATHA_TYPE.A_VIDEO || props.disabled === true}
							name={getFieldName(namePrefix, 'media.actionButton.enabled')}
							onChange={value => {
								setFieldValue(
									getFieldName(namePrefix, 'media'),
									handleActionButtonEnabledChange(value, mediaValue),
								)
							}}
						/>
						{mediaValue.type === MEDIA_AGATHA_TYPE.A_VIDEO && <Tooltip id={idTooltip} />}
					</div>
				</Fragment>
			)}
			{mediaValue.actionButton.enabled === false && (
				<Fragment>
					<Label label={intl.formatMessage({ id: 'message.detail.seconds_to_read' })} />
					<Field
						component={FormattedInput}
						disabled={props.disabled}
						name={getFieldName(namePrefix, 'timeout')}
						type="number"
						format={value => value / 1000}
						normalize={value => value * 1000}
					/>
					{timeoutValue < 500 && (
						<span className="title-error">
							{intl.formatMessage({ id: 'message.min_timeout_error' })}
						</span>
					)}
				</Fragment>
			)}
			{mediaValue.actionButton.enabled === true && (
				<Fragment>
					<Label
						label={intl.formatMessage({
							id: 'message.detail.proceed_button_label',
						})}
					/>
					<Field
						component={Input}
						disabled={props.disabled}
						name={getFieldName(namePrefix, 'media.actionButton.label')}
						placeholder={intl.formatMessage({ id: 'proceed' })}
						type="text"
					/>
					<Label label={getMinTimerLabel(mediaValue.actionButton.minTimer, intl)} />
					<Field
						name={getFieldName(namePrefix, 'media.actionButton.minTimer')}
						component={Slider}
						disabled={props.disabled}
						min={0}
						max={minTimerMaxValue}
						step={1000}
						isSpacerNeeded
						{...Slider.STYLE_PRESETS.light}
					/>
					<Field
						name={getFieldName(namePrefix, 'media.actionButton.maxTimerEnabled')}
						component={Checkbox}
						componentProps={{
							label: getMaxTimerLabel(
								mediaValue.actionButton.maxTimerEnabled,
								mediaValue.actionButton.maxTimer,
								intl,
							),
						}}
						disabled={props.disabled}
						onChange={value => {
							setFieldValue(
								getFieldName(namePrefix, 'media'),
								handleMaxTimerEnabledChange(value, mediaValue),
							)
						}}
					/>
					{mediaValue.actionButton.maxTimerEnabled === true && (
						<Field
							component={Slider}
							disabled={props.disabled}
							max={maxTimerMaxValue}
							min={0}
							name={getFieldName(namePrefix, 'media.actionButton.maxTimer')}
							step={1000}
							isSpacerNeeded
							{...Slider.STYLE_PRESETS.light}
						/>
					)}
					<Field
						component={Checkbox}
						componentProps={{
							label: intl.formatMessage({
								id: 'message.detail.media.start_in_fullscreen',
							}),
						}}
						disabled={props.disabled}
						name={getFieldName(namePrefix, 'media.render.forceFullscreen')}
						onChange={value => {
							setFieldValue(
								getFieldName(namePrefix, 'media'),
								handleForceFullscreenChange(value, mediaValue),
							)
						}}
					/>
					{mediaValue.render.forceFullscreen === false && (
						<Field
							component={Checkbox}
							componentProps={{
								label: intl.formatMessage({ id: 'message.detail.media.blur' }),
							}}
							disabled={props.disabled}
							name={getFieldName(namePrefix, 'media.render.blurBefore')}
						/>
					)}
					{mediaValue.type === MEDIA_AGATHA_TYPE.A_VIDEO && (
						<Field
							component={Checkbox}
							componentProps={{
								label: intl.formatMessage({ id: 'message.detail.media.videoAutoPlay' }),
							}}
							disabled={props.disabled}
							name={getFieldName(namePrefix, 'media.render.videoAutoPlay')}
						/>
					)}
					<Field
						component={Checkbox}
						componentProps={{
							label: intl.formatMessage({ id: 'message.detail.media.disable' }),
						}}
						disabled={props.disabled}
						name={getFieldName(namePrefix, 'media.render.hideAfter')}
					/>
				</Fragment>
			)}
		</Fragment>
	)
}

MessageModuleOptions.propTypes = {
	disabled: PropTypes.bool.isRequired,
	isAccessibilityEnabled: PropTypes.bool.isRequired,
	formProps: PropTypes.shape({
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object.isRequired,
	}).isRequired,
	namePrefix: PropTypes.string.isRequired,
}

export default MessageModuleOptions
