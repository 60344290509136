import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './OverlayTitle.module.scss'

const OverlayTitle = ({ children, className }) => {
	return (
		<div className={classnames(classes.title, { [className]: className != null })}>{children}</div>
	)
}

OverlayTitle.defaultProps = {
	className: null,
}

OverlayTitle.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}

export default OverlayTitle
