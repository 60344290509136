const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/ACTION_BUTTON.json',
	properties: {
		id: {
			$id: '/properties/id',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: 'string',
		},
		label: {
			$id: '/properties/label',
			type: ['string', 'null'],
		},
		maxTimer: {
			minimum: 0,
			maximum: 3600000,
			$id: '/properties/maxTimer',
			type: ['integer', 'null'],
		},
		minTimer: {
			minimum: 0,
			maximum: 3600000,
			$id: '/properties/minTimer',
			type: ['integer', 'null'],
		},
	},
	required: ['minTimer', 'maxTimer', 'id', 'label'],
	type: 'object',
}

export default schema
