import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

import validateAllocation from './validateAllocation'
import validateChoice from './validateChoice'
import validateEndstudy from './validateEndstudy'
import validateFreetext from './validateFreetext'
import validateHeatmap from './validateHeatmap'
import validateList from './validateList'
import validateMatrixChoice, { validateMatrixQuestions } from './validateMatrixChoice'
import validateMaxDiff from './validateMaxDiff'
import validateMessage from './validateMessage'
import validateOeq from './validateOeq'
import validateRanking from './validateRanking'
import validateSetVariable from './validateSetVariable'
import validateSnippet from './validateSnippet'
import validateUICommand from './validateUICommand'

import validateShortName from './_validateShortName'

const validateModule = (
	module,
	modules,
	orderModule,
	flatOrder,
	communityModules,
	proFlow,
	respondentSources,
	studyTags,
	duplicateShortNamesObject,
) => {
	const shortNameValidationResult = validateShortName(module, duplicateShortNamesObject)

	if (module.isHidden === true) {
		const validationResult = shortNameValidationResult

		if (module.type === MODULE_DEFINITIONS.MATRIX_CHOICE.type) {
			validationResult.push(...validateMatrixQuestions(module, studyTags))
		}

		return validationResult
	}

	const allModules = {
		...modules,
	}

	communityModules.forEach(module => {
		allModules[module.id] = module
	})

	const proFlowLoopModuleIterations = proFlow.filter(
		proFlowModule => proFlowModule.listSettings.length > 0,
	)

	proFlowLoopModuleIterations.forEach(proFlowModule => {
		allModules[proFlowModule.id] = proFlowModule
	})

	respondentSources.forEach(respondentSource => {
		allModules[respondentSources.idRespondentSources] = respondentSource
	})

	let validationResult = []

	switch (module.type) {
		case MODULE_DEFINITIONS.ALLOCATION.type:
			validationResult = validateAllocation(module, allModules, orderModule, flatOrder, studyTags)
			break
		case MODULE_DEFINITIONS.A_CHOICE.type:
			validationResult = validateChoice(module, allModules, orderModule, flatOrder, studyTags)
			break
		case MODULE_DEFINITIONS.A_END_STUDY.type:
			validationResult = validateEndstudy(module)
			break
		case MODULE_DEFINITIONS.A_FREE_TEXT.type:
			validationResult = validateFreetext(module, allModules, studyTags)
			break
		case MODULE_DEFINITIONS.HEATMAP.type:
			validationResult = validateHeatmap(module, allModules, studyTags)
			break
		case MODULE_DEFINITIONS.A_IMAGE_CHOICE.type:
			validationResult = validateChoice(module, allModules, orderModule, flatOrder, studyTags)
			break
		case MODULE_DEFINITIONS.MAXDIFF.type:
			validationResult = validateMaxDiff(module, studyTags)
			break
		case MODULE_DEFINITIONS.A_MESSAGE.type:
			validationResult = validateMessage(module, studyTags)
			break
		case MODULE_DEFINITIONS.A_OEQ.type:
			validationResult = validateOeq(module, allModules, studyTags)
			break
		case MODULE_DEFINITIONS.A_SNIPPET.type:
			validationResult = validateSnippet(module, allModules)
			break
		case MODULE_DEFINITIONS.LIST.type:
			validationResult = validateList(module, allModules, orderModule, flatOrder, communityModules)
			break
		case MODULE_DEFINITIONS.MATRIX_CHOICE.type:
			validationResult = validateMatrixChoice(module, allModules, orderModule, flatOrder, studyTags)
			break
		case MODULE_DEFINITIONS.MATRIX_IMAGE_CHOICE.type:
			validationResult = validateMatrixChoice(module, allModules, orderModule, flatOrder, studyTags)
			break
		case MODULE_DEFINITIONS.RANKING.type:
			validationResult = validateRanking(module, allModules, orderModule, flatOrder, studyTags)
			break
		case MODULE_DEFINITIONS.SET_VARIABLE.type:
			validationResult = validateSetVariable(module)
			break
		case MODULE_DEFINITIONS.UI_COMMAND.type:
			validationResult = validateUICommand(
				module,
				allModules,
				orderModule,
				flatOrder,
				communityModules,
				proFlowLoopModuleIterations,
				respondentSources,
				studyTags,
				duplicateShortNamesObject,
			)
			break
		default:
			throw new Error('Unsupported module type in VisualFlow')
	}

	return [...shortNameValidationResult, ...validationResult]
}

export default validateModule
