/**
 * Create/Update Pages
 */
import AssignUser from './AssignUser'
import AccountList from './AccountList'
import AssignStudy from './AssignStudy'
import CreateAccount from './CreateAccount'
import UpdateAccount from './UpdateAccount'

export default () => ({
	path: 'account',
	children: [AssignUser(), AccountList(), AssignStudy(), CreateAccount(), UpdateAccount()],
})
