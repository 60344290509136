import fnList from './_fnList'

/**
 * evaluators that return true or throw error if solver should not continue
 */
const breakingEvaluators = [fnList.validateRandomizerModules]

const evaluators = [
	fnList.add,
	fnList.agCompletes,
	fnList.agQuota,
	fnList.and,
	fnList.arrayContainsAny,
	fnList.arrayContainsEvery,
	fnList.arrayEquals,
	fnList.arrayLength,
	fnList.asPrice,
	fnList.choose,
	fnList.dateAfter,
	fnList.dateBefore,
	fnList.dateBetween,
	fnList.dateEquals,
	fnList.divide,
	fnList.eq,
	fnList.eqNumber,
	fnList.filterIdsModulesByList,
	fnList.firstElement,
	fnList.ge,
	fnList.gt,
	fnList.isIdeationClosed,
	fnList.isIntQuotaTargetGroup,
	fnList.le,
	fnList.lt,
	fnList.multiply,
	fnList.not,
	fnList.or,
	fnList.randomElement,
	fnList.randomFromRange,
	fnList.round,
	fnList.stringAnyOf,
	fnList.stringContains,
	fnList.stringEndsWith,
	fnList.stringRegexp,
	fnList.stringStartsWith,
	fnList.split,
	fnList.subString,
	fnList.varOrDefault,
	fnList.var,
	...breakingEvaluators,
]

const modifiers = [
	fnList.append,
	fnList.arrayOperation,
	fnList.concatenate,
	fnList.removeElement,
	fnList.set,
	fnList.setPath,
	fnList.setPath,
	fnList.setPathLiteral,
]

const basicFunctions = [fnList.if, fnList.set, fnList.setLiteral]

export const fnMap = {
	[fnList.add]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.agCompletes]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.agName]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [value => (value.includes('.') === false ? null : 'value cannot include "."')],
	},
	[fnList.agQuota]: {
		allowed: [fnList.agName],
		required: [fnList.agName],
		forbidden: [],
		validators: [],
	},
	[fnList.and]: {
		allowed: evaluators,
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.append]: {
		allowed: [fnList.what, fnList.to],
		required: [fnList.what, fnList.to],
		forbidden: [],
		validators: [],
	},
	[fnList.arrayContainsAny]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.arrayContainsEvery]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.arrayEquals]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.arrayLength]: {
		allowed: [fnList.array, fnList.distinct],
		required: [fnList.array],
		forbidden: [],
		validators: [],
	},
	[fnList.arrayOperation]: {
		allowed: [fnList.array1, fnList.array2, fnList.operation],
		required: [fnList.array1, fnList.array2, fnList.operation],
		forbidden: [],
		validators: [],
	},
	[fnList.asPrice]: {
		allowed: evaluators,
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.choose]: {
		allowed: [fnList.expression, fnList.options],
		required: [fnList.expression, fnList.options],
		forbidden: [],
		validators: [],
	},
	[fnList.condition]: {
		allowed: evaluators,
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.concatenate]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.dateAfter]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.dateBefore]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.dateBetween]: {
		allowed: [fnList.datesObject, fnList.right],
		required: [fnList.datesObject, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.dateEquals]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.divide]: {
		allowed: [fnList.numerator, fnList.denominator],
		required: [fnList.numerator, fnList.denominator],
		forbidden: [],
		validators: [],
	},
	[fnList.else]: {
		allowed: [...basicFunctions, ...modifiers],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.eq]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.eqNumber]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.filterIdsModulesByList]: {
		allowed: [fnList.idInputList, fnList.idsArray],
		required: [fnList.idInputList, fnList.idsArray],
		forbidden: [],
		validators: [],
	},
	[fnList.firstElement]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.ge]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.gt]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.if]: {
		allowed: [fnList.condition, fnList.then, fnList.else],
		required: [fnList.condition, fnList.then],
		forbidden: [],
		validators: [],
	},
	[fnList.in]: {
		allowed: [fnList.left, fnList.elements],
		required: [fnList.left, fnList.elements],
		forbidden: [],
		validators: [],
	},
	[fnList.isIdeationClosed]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.isIntQuotaTargetGroup]: {
		allowed: [fnList.idStudyObject],
		required: [fnList.idStudyObject],
		forbidden: [],
		validators: [],
	},
	[fnList.le]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.left]: {
		allowed: [fnList.var, fnList.condition, ...evaluators],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.logic]: {
		allowed: [...basicFunctions, ...modifiers, ...breakingEvaluators],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.lt]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.multiply]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.not]: {
		allowed: evaluators,
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.or]: {
		allowed: evaluators,
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.randomElement]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.randomFromRange]: {
		allowed: [fnList.from, fnList.to, fnList.step],
		required: [fnList.from, fnList.to, fnList.step],
		forbidden: [],
		validators: [],
	},
	[fnList.removeElement]: {
		allowed: [fnList.elements, fnList.exclude],
		required: [fnList.elements, fnList.exclude],
		forbidden: [],
		validators: [],
	},
	[fnList.right]: {
		allowed: [fnList.var, fnList.condition, ...evaluators],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.round]: {
		allowed: [fnList.number, fnList.decimals],
		required: [fnList.number, fnList.decimals],
		forbidden: [],
		validators: [],
	},
	[fnList.set]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.setLiteral]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.setPath]: {
		allowed: [fnList.path, fnList.value],
		required: [fnList.path, fnList.value],
		forbidden: [],
		validators: [],
	},
	[fnList.setPathLiteral]: {
		allowed: [fnList.path, fnList.value],
		required: [fnList.path, fnList.value],
		forbidden: [],
		validators: [],
	},
	[fnList.stringAnyOf]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.stringContains]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.stringEndsWith]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.stringRegexp]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.stringStartsWith]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.split]: {
		allowed: [fnList.value, fnList.delimiter],
		required: [fnList.value, fnList.delimiter],
		forbidden: [],
		validators: [],
	},
	[fnList.subString]: {
		allowed: [fnList.string, fnList.first, fnList.last],
		required: [fnList.string, fnList.first, fnList.last],
		forbidden: [],
		validators: [],
	},
	[fnList.then]: {
		allowed: [...basicFunctions, ...modifiers],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.validateRandomizerModules]: {
		allowed: [fnList.left, fnList.right],
		required: [fnList.left, fnList.right],
		forbidden: [],
		validators: [],
	},
	[fnList.var]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [
			value =>
				value.includes('[') === true || value.includes(']') === true
					? 'var key cannot contain "[" or "]". use "." to access array elements'
					: null,
		],
	},
	[fnList.varOrDefault]: {
		allowed: [fnList.default, fnList.variable],
		required: [fnList.default, fnList.variable],
		forbidden: [],
		validators: [
			value => {
				if (typeof value.variable !== 'string') {
					return '"variable" is required and has to be string in varOrDefault'
				}

				return value.variable.includes('[') === true || value.variable.includes(']') === true
					? 'variable key cannot contain "[" or "]". use "." to access array elements'
					: null
			},
		],
	},
	// params
	[fnList.array]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.array1]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.array2]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.datesObject]: {
		allowed: [fnList.from, fnList.to],
		required: [fnList.from, fnList.to],
		forbidden: [],
		validators: [],
	},
	[fnList.decimals]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.default]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.delimiter]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.denominator]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.elements]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.exclude]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.expression]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.first]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.from]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.idStudyObject]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.last]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.number]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.numerator]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.operation]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.options]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.path]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.step]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.string]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.to]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.value]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.variable]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
	[fnList.what]: {
		allowed: [],
		required: [],
		forbidden: [],
		validators: [],
	},
}

export default fnMap
