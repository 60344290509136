import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'

import classes from './Input.module.scss'

class Input extends Component {
	static propTypes = {
		autoFocus: PropTypes.bool.isRequired,
		className: PropTypes.string,
		isValid: PropTypes.bool.isRequired,
		name: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		required: PropTypes.bool,
		type: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	}

	static defaultProps = {
		autoComplete: 'off',
		autoFocus: false,
		className: '',
		isValid: true,
		placeholder: 'Please type your text here',
		required: false,
		type: 'text',
		value: '',
	}

	isValid = (required, value) => required === false || value !== ''

	render() {
		const { value, isValid, ...restOfProps } = this.props
		const className = classnames(classes.input, this.props.className, {
			[classes['input--invalid']]: isValid === false,
			[classes.disabled]: this.props.disabled === true,
			[classes['wrapper__invalid']]: this.isValid(this.props.required, value) === false,
		})

		let valueToRender = undefined

		if (this.props.defaultValue === undefined) {
			valueToRender = _.isNil(value) === false ? value : ''
		}

		if (Array.isArray(value) === true) {
			valueToRender = ''
		}

		return <input {...restOfProps} className={className} value={valueToRender} />
	}
}

export default Input
