import React from 'react'
import PropTypes from 'prop-types'

import { fieldShape } from 'components/_formik/_constants'
import Input from 'components/_formik/_base/Input'

const FormattedInput = ({ field, format, normalize, ...props }) => {
	if (format === undefined || normalize === undefined) {
		throw new Error(
			`Format or normalize functions cannot be undefined. format: ${format}, normalize: ${normalize}`,
		)
	}

	const onChange = event => {
		if (props.disabled === true) return

		props.form.setFieldValue(field.name, normalize(event.currentTarget.value))
	}

	const fieldProps = {
		...field,
		value: format(field.value),
		onChange,
	}

	return <Input field={fieldProps} {...props} />
}

FormattedInput.propTypes = {
	disabled: PropTypes.bool.isRequired,
	field: fieldShape.isRequired,
	format: PropTypes.func.isRequired,
	normalize: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
}

export default FormattedInput
