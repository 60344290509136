import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { Field } from 'formik'
import _ from 'lodash'

import { catchJSONError, validateQuotaCondition } from 'helpers/visualFlowModules/snippet'

import QuotaDefinition from 'components/_formik/_custom/_studyDesign/QuotaDefinition'
import Textarea from 'components/_formik/_base/Textarea'

import CodeErrors from 'components/_scaffolding/CodeErrors'
import CodeLabel from 'components/_scaffolding/CodeLabel'

import classes from './Quota.module.scss'

const Quota = ({ disabled, idStudy, quota, setFieldValue, fieldNamePrefix }) => {
	const intl = useIntl()

	const getConditionValue = quota => {
		const condition = _.get(quota, 'condition', '')

		if (condition === '') {
			return condition
		}

		if (_.get(quota, 'errors', []).length > 0) {
			return condition
		}

		try {
			return JSON.stringify(JSON.parse(condition), null, 2)
		} catch (e) {
			const result = catchJSONError(e)
			setFieldValue(`${fieldNamePrefix}.errors`, result.codeErrors)
		}
	}

	const handleConditionBlur = event => {
		const codeString = event.currentTarget.value

		const validationResult = validateQuotaCondition(codeString)

		if (validationResult.isCodeValid === false) {
			setFieldValue(`${fieldNamePrefix}.errors`, validationResult.codeErrors)
		} else {
			setFieldValue(`${fieldNamePrefix}.errors`, [])
		}
	}

	return (
		<div className={classes.holder}>
			<div className={classes.name}>
				{intl.formatMessage({ id: 'quota.title' })}:&nbsp;{quota.agName}
			</div>
			<QuotaDefinition
				disabled={disabled}
				fieldNamePrefix={fieldNamePrefix}
				idStudy={idStudy}
				quota={quota}
			/>
			<CodeLabel
				label={intl.formatMessage({ id: 'snippet.quota.condition' })}
				isCodeValid={_.get(quota, 'errors', []).length === 0}
			/>
			<Field
				component={Textarea}
				disabled={disabled}
				className={classes.code}
				name={`${fieldNamePrefix}.condition`}
				type="text"
				value={getConditionValue(quota)}
				onBlur={handleConditionBlur}
			/>
			<CodeErrors root="Condition" errors={_.get(quota, 'errors', [])} />
		</div>
	)
}

Quota.propTypes = {
	disabled: PropTypes.bool.isRequired,
	idStudy: PropTypes.string.isRequired,
	quota: PropTypes.shape({
		agName: PropTypes.string.isRequired,
		number: PropTypes.number.isRequired,
		isPercentage: PropTypes.bool.isRequired,
		condition: PropTypes.string.isRequired,
		errors: PropTypes.array,
	}).isRequired,
	setFieldValue: PropTypes.func.isRequired,
}

export default Quota
