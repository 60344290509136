export const validate = (module, flow, config = null) => {
	if (
		module.definition.media &&
		module.definition.media.type === 'A_VIDEO' &&
		module.definition.media.actionButton === null
	) {
		return {
			infos: null,
			warnings: null,
			errors: ['Action button cannot be null for video.'],
		}
	}
	return null
}

export default validate
