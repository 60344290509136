import { LEGEND_COLUMN_TYPES } from 'constants/reports'
import { QUANT_QUAL_LEGEND_COLUMN_NAMES } from 'routes/_study/QuantQual/_constants/sortAndFilter'

const getModifier = (moddedValue, regex) => {
	const modifier = moddedValue.match(regex)

	return modifier === null ? null : modifier[0]
}

export const resolveFilterValue = moddedValue => {
	const regex = /^\^(.*?):|^([<>=])*/gm
	const resolvedValue = {
		modifier: null,
		value: null,
	}

	if (moddedValue === null) {
		return resolvedValue
	}

	resolvedValue.modifier = getModifier(moddedValue, regex)
	resolvedValue.value = moddedValue.replace(regex, '')

	return resolvedValue
}

export const getIsFilteredValueString = idFilter =>
	idFilter === LEGEND_COLUMN_TYPES.LETTER ||
	idFilter === LEGEND_COLUMN_TYPES.ANSWER ||
	idFilter === LEGEND_COLUMN_TYPES.MATRIX_OPTION ||
	idFilter === LEGEND_COLUMN_TYPES.MATRIX_QUESTION ||
	idFilter === LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME ||
	idFilter === QUANT_QUAL_LEGEND_COLUMN_NAMES.NAME
