import PropTypes from 'prop-types'
import { qualificationShape } from './qualificationShape'

import { PROVIDER_TYPE } from 'constants/panels/providerType'
import {
	CONDITION_CASE_TYPES,
	CONDITION_SELECTION_TYPES,
	CONDITION_TYPES,
	DATE_TIME_OBJECT_SHAPE,
	EVALUATION_POSITION,
} from 'constants/conditionBuilder'

export const panelShape = PropTypes.shape({
	isEnabled: PropTypes.bool.isRequired,
	forcedRedirects: PropTypes.shape({
		complete: PropTypes.string,
		overquota: PropTypes.string,
		terminate: PropTypes.string,
		qualityTerminate: PropTypes.string,
	}).isRequired,
	idRespondentSource: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	isStopped: PropTypes.bool.isRequired,
	loginMethod: PropTypes.string.isRequired,
	loginScreen: PropTypes.shape({
		publicLabel: PropTypes.string.isRequired,
		studyDescription: PropTypes.string.isRequired,
		logo: PropTypes.shape({
			id: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}).isRequired,
		visibleButtons: PropTypes.shape({
			anonymous: PropTypes.bool.isRequired,
			email: PropTypes.bool.isRequired,
		}).isRequired,
	}).isRequired,
	matchParams: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	sourceType: PropTypes.string.isRequired,
	provider: PropTypes.shape({
		demographics: PropTypes.arrayOf(qualificationShape),
		groupSolverPanelInfo: PropTypes.shape({
			collectsPii: PropTypes.bool.isRequired,
			expectedCompletes: PropTypes.number.isRequired,
			expectedCompletionLoi: PropTypes.number.isRequired,
			expectedDays: PropTypes.number.isRequired,
			expectedIncidenceRate: PropTypes.number.isRequired,
			industry: PropTypes.string.isRequired,
		}),
		isFinished: PropTypes.bool.isRequired,
		payment: PropTypes.shape({
			clientSecret: PropTypes.string,
			error: PropTypes.string,
			paymentStatus: PropTypes.string,
			priceEstimate: PropTypes.number,
			shouldCreatePayment: PropTypes.bool.isRequired,
		}),
		providerType: PropTypes.oneOf(Object.values(PROVIDER_TYPE)).isRequired,
		requestedCompletes: PropTypes.number,
		info: PropTypes.shape({
			surveyNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			surveyQuotaID: PropTypes.number,
			increment: PropTypes.number,
		}),
		community: PropTypes.shape({
			communityConditions: PropTypes.arrayOf(
				PropTypes.shape({
					type: PropTypes.oneOf(Object.values(CONDITION_TYPES)).isRequired,
					case: PropTypes.oneOf(Object.values(CONDITION_CASE_TYPES)).isRequired,
					studyObject: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
					is: PropTypes.bool.isRequired,
					includeNoData: PropTypes.bool.isRequired,
					selection: PropTypes.shape({
						type: PropTypes.oneOf(Object.values(CONDITION_SELECTION_TYPES)).isRequired,
						minAnyOfValidSelection: PropTypes.number.isRequired,
						maxAnyOfValidSelection: PropTypes.number.isRequired,
					}).isRequired,
					left: PropTypes.oneOfType([
						PropTypes.string.isRequired,
						PropTypes.arrayOf(PropTypes.string).isRequired,
						DATE_TIME_OBJECT_SHAPE.isRequired,
						PropTypes.shape({
							from: DATE_TIME_OBJECT_SHAPE.isRequired,
							to: DATE_TIME_OBJECT_SHAPE.isRequired,
						}).isRequired,
						PropTypes.shape({
							evaluationPosition: PropTypes.oneOf(Object.values(EVALUATION_POSITION)).isRequired,
							idsStatements: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
						}).isRequired,
					]).isRequired,
				}).isRequired,
			).isRequired,
			idCommunity: PropTypes.string.isRequired,
			invitationEmailSettings: PropTypes.shape({
				emailButtonLabel: PropTypes.string.isRequired,
				emailHeadline: PropTypes.string.isRequired,
				emailSubject: PropTypes.string.isRequired,
				emailTemplate: PropTypes.string.isRequired,
				idEmailModule: PropTypes.string.isRequired,
				idNameModule: PropTypes.string,
			}).isRequired,
			invitationSettings: PropTypes.shape({
				batchSize: PropTypes.number.isRequired,
			}).isRequired,
		}),
	}).isRequired,
})
