import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const PresentationEditorContainer = lazy(() => import('./PresentationEditorContainer'))
const WrappedPresentationEditorContainer = errorWrapper(PresentationEditorContainer)

export default () => ({
	path: 'presentation/:idPresentation/editor',
	element: <WrappedPresentationEditorContainer />,
})
