import fnMap from './_fnMap'
import forbiddenFnList from './_forbiddenFnList'
import validateFnArray from './_validateFnArray'
import _ from 'lodash'

export const validateFnObject = (path, fnName, code, errors) => {
	if (forbiddenFnList[fnName] !== undefined) {
		errors.push({
			message: `${path}: globally forbidden function "${fnName}"`,
		})
		return
	}

	const thisPath = `${path}.${fnName}`
	const functionDefinition = fnMap[fnName]

	if (code === null && functionDefinition !== undefined && functionDefinition.required.length > 0) {
		errors.push({ message: `${thisPath}: cannot be null` })
		return
	} else if (code === null) {
		return
	}

	// check required properties
	const missingParam =
		functionDefinition !== undefined
			? functionDefinition.required.find(r => code[r] === undefined)
			: undefined
	if (missingParam !== undefined) {
		errors.push({ message: `${thisPath}: missing required property: "${missingParam}"` })
		return
	}

	// get list of properties
	const properties = Object.keys(code)

	// check list for forbidden commands
	if (functionDefinition !== undefined && functionDefinition.forbidden.length > 0) {
		const forbiddenProperty = properties.find(property =>
			functionDefinition.forbidden.includes(property),
		)

		if (forbiddenProperty !== undefined) {
			errors.push({ message: `${thisPath}: forbidden property: "${forbiddenProperty}"` })
			return
		}
	}

	// check object for unsupported commands
	const additionalProperty =
		functionDefinition !== undefined && functionDefinition.allowed.length > 0
			? properties.find(property => functionDefinition.allowed.includes(property) === false)
			: undefined
	if (additionalProperty !== undefined) {
		errors.push({ message: `${thisPath}: unknown property: "${additionalProperty}"` })
		return
	}

	properties.forEach(property => {
		_.get(fnMap, `${property}.validators`, []).forEach(validator => {
			const value = code[property]
			const validationResult = validator(value)
			if (validationResult !== null) {
				errors.push({ message: `${thisPath}: ${validationResult}: "${value}"` })
			}
		})
	})

	// check code of every property
	properties.forEach(property => {
		if (Array.isArray(code[property]) === true) {
			validateFnArray(thisPath, property, code[property], errors)
			return
		}

		if (typeof code[property] === 'object') {
			validateFnObject(thisPath, property, code[property], errors)
		}
	})
}

export default validateFnObject
