import { LIST_INPUT_TYPE } from 'constants/studyDesign'

export const findSelectedList = (idInputList, upperLists) => {
	const foundList = upperLists.find(list => list.definition.id === idInputList)

	if (foundList === undefined) {
		return null
	}

	if (foundList.definition.inputType === LIST_INPUT_TYPE.FILE) {
		return foundList
	}

	return findSelectedList(foundList.definition.idInputList, upperLists)
}
