import { orderBy as naturalOrderBy } from 'natural-orderby'

import {
	resolveFilterValue,
	getIsFilteredValueString,
} from 'helpers/templateBuilder/chartEditorFilter'
import { CONDITION_MODIFIERS } from 'constants/templateBuilder/chartEditorFilter'

/**
 * SORT
 */

export const sortData = (data, sorted) => {
	const identifiers = sorted.map(sort => sort.id)
	const orders = sorted.map(sort => (sort.desc === true ? 'desc' : 'asc'))

	return naturalOrderBy(data, identifiers, orders)
}

/**
 * FILTER
 */

const getRowValue = (filter, row) => {
	const value = filter.resolver === undefined ? row[filter.id] : filter.resolver(row)

	return value
}

export const filterStringColumn = (filter, row) => {
	const resolvedFilter = resolveFilterValue(filter.value)
	const modifier = resolvedFilter.modifier

	const rowValueString = String(getRowValue(filter, row)).toLowerCase()
	const filterValueString = String(resolvedFilter.value).toLowerCase()

	if (modifier === CONDITION_MODIFIERS.IS) {
		return rowValueString === filterValueString
	}

	if (modifier === CONDITION_MODIFIERS.IS_NOT) {
		return rowValueString !== filterValueString
	}

	if (modifier === CONDITION_MODIFIERS.DOES_NOT_CONTAIN) {
		return rowValueString.includes(filterValueString) === false
	}

	if (modifier === CONDITION_MODIFIERS.IS_EMPTY) {
		return getRowValue(filter, row) === null || getRowValue(filter, row) === ''
	}

	if (modifier === CONDITION_MODIFIERS.IS_NOT_EMPTY) {
		return getRowValue(filter, row) !== null && getRowValue(filter, row) !== ''
	}

	if (modifier === CONDITION_MODIFIERS.UNTHEMED) {
		return row[filter.id] === null || row[filter.id] === ''
	}

	if (modifier === CONDITION_MODIFIERS.NOT_UNTHEMED) {
		return row[filter.id] !== null && row[filter.id] !== ''
	}

	return rowValueString.includes(filterValueString) === true
}

export const filterNumberColumn = (filter, row) => {
	const resolvedFilter = resolveFilterValue(filter.value)
	const modifier = resolvedFilter.modifier
	const filterValue = resolvedFilter.value

	if (filterValue === '') {
		return true
	}

	const rowValueNumber = Number(getRowValue(filter, row))

	const range = filterValue.split('-')
	const from = range[0] === '' ? 0 : Number(range[0])
	const to = range[1] === '' ? null : Number(range[1])

	if (modifier === CONDITION_MODIFIERS.IS_EQUAL) {
		return rowValueNumber === Number(filterValue)
	}

	if (modifier === CONDITION_MODIFIERS.GREATER) {
		return rowValueNumber > Number(filterValue)
	}

	if (modifier === CONDITION_MODIFIERS.LESSER) {
		return rowValueNumber < Number(filterValue)
	}

	if (modifier === CONDITION_MODIFIERS.LESSER_EQUAL) {
		return rowValueNumber <= Number(filterValue)
	}

	if (modifier === CONDITION_MODIFIERS.NOT_IN_RANGE) {
		if (to === null) {
			return rowValueNumber <= from
		}

		return rowValueNumber <= from || rowValueNumber >= to
	}

	if (filterValue.includes('-') === true) {
		if (to === null) {
			return rowValueNumber >= from
		}

		return rowValueNumber >= from && rowValueNumber <= to
	}

	return rowValueNumber >= Number(filterValue)
}

const filterRow = (filter, row) => {
	// skip empty filters
	if (filter.id === null || filter.value === null) {
		return true
	}

	if (getIsFilteredValueString(filter.id) === true) {
		return filterStringColumn(filter, row)
	}

	return filterNumberColumn(filter, row)
}

export const filterData = (data, filtered) => {
	const filteredData = filtered.reduce((acc, filter) => {
		return acc.filter(row => filterRow(filter, row))
	}, data)

	return filteredData
}
