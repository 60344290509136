import addModule from './addModule'
import filterSegmentsToBeAdded from './filterSegmentsToBeAdded'
import getInvalidModules from './getInvalidModules'
import getModuleNameByType from './getModuleNameByType'
import getOrderModuleById from './getOrderModuleById'
import getOrderPartByPath from './getOrderPartByPath'
import getUpperModuleIds from './getUpperModuleIds'
import normalizeOrderModule from './normalizeOrderModule'
import refreshPaths from './refreshPaths'
import setUniqueIdsInDefinition from './setUniqueIdsInDefinition'
import getDatasetShortName from './getDatasetShortName'

const helpers = {
	addModule,
	filterSegmentsToBeAdded,
	getInvalidModules,
	getOrderModuleById,
	getModuleNameByType,
	getOrderPartByPath,
	getUpperModuleIds,
	normalizeOrderModule,
	refreshPaths,
	setUniqueIdsInDefinition,
	getDatasetShortName,
}

export default helpers
