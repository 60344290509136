import React from 'react'

import Icon from 'components/_scaffolding/Icon'

import classes from './AdvancedFeatureLabel.module.scss'

const AdvancedFeatureLabel = () => (
	<div className={classes['advanced-label']}>
		<Icon
			name={Icon.NAMES.STAR_FILLED}
			size={12}
			customClassName={classes['advanced-label__icon']}
		/>
	</div>
)

export default AdvancedFeatureLabel
