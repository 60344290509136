import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import { KNOWLEDGE_BASE_LINKS } from 'constants/links'
import { VISUAL_FLOW_MODULE_TYPES, VISUAL_FLOW_WELCOME_SCREEN } from 'constants/studyDesign'

import isDatasetModule from 'helpers/visualFlowModules/isDatasetModule'

import SelectAction from 'components/_scaffolding/_input/SelectAction'
import SelectOptionWithTooltip from 'components/_scaffolding/_input/SelectOptionWithTooltip'

import RelatedModuleToggle from './_components/RelatedModuleToggle'

import classes from './Header.module.scss'

const showCopyModule = type =>
	type !== VISUAL_FLOW_MODULE_TYPES.A_END_STUDY &&
	type !== VISUAL_FLOW_MODULE_TYPES.A_SNIPPET &&
	type !== VISUAL_FLOW_MODULE_TYPES.UI_COMMAND &&
	type !== VISUAL_FLOW_MODULE_TYPES.SET_VARIABLE &&
	type !== VISUAL_FLOW_WELCOME_SCREEN &&
	type !== VISUAL_FLOW_MODULE_TYPES.LIST

const getIsQuestion = generalDefinition => {
	if (generalDefinition.type === VISUAL_FLOW_MODULE_TYPES.A_SNIPPET) {
		return false
	}

	if (generalDefinition.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE) {
		return true
	}

	return isDatasetModule(generalDefinition)
}

const getCopyToLibraryLabel = (intl, generalDefinition) => {
	const translatedSubject = intl
		.formatMessage({
			id: getIsQuestion(generalDefinition) === true ? 'question' : 'module',
		})
		.toLowerCase()

	return intl.formatMessage({ id: 'copy_to_library' }, { subject: translatedSubject })
}

const getDetailActions = ({
	intl,
	generalDefinition,
	copyModuleButtonParams,
	copyModule,
	additionalActions,
	hideCopyToLibrary,
	isFlowChanged,
	openCopyToLibraryForm,
	disabled,
}) => {
	const detailActionsArray = []
	if (showCopyModule(generalDefinition.type) === true && disabled === false) {
		detailActionsArray.push({
			isDisabled: copyModuleButtonParams.disabled === true,
			label: intl.formatMessage({ id: 'duplicate' }),
			tooltipText: copyModuleButtonParams.tooltipText,
			value: copyModule,
		})
	}

	detailActionsArray.push(...additionalActions)

	if (
		generalDefinition.type !== VISUAL_FLOW_MODULE_TYPES.SET_VARIABLE &&
		hideCopyToLibrary !== true
	) {
		detailActionsArray.push({
			isDisabled: isFlowChanged === true,
			label: getCopyToLibraryLabel(intl, generalDefinition),
			tooltipText: intl.formatMessage({ id: 'study_actions.save_as_template.save_flow' }),
			value: openCopyToLibraryForm,
		})
	}

	return detailActionsArray
}

const Header = ({
	actions,
	additionalActions = [],
	closeModuleDetail,
	copyModule,
	copyModuleButtonParams = {
		disabled: true,
		tooltipText: '',
	},
	disabled,
	generalDefinition,
	isFlowChanged,
	isMatrixChoice = false,
	isPreviewOnly = false,
	isRelatedMessageShown,
	isRelatedModuleInvalid = true,
	knowledgeBaseLinkTypeOverride = null,
	moduleDefinition,
	modules,
	openCopyToLibraryForm,
	showCopyToLibrary,
}) => {
	const intl = useIntl()
	const detailActions = getDetailActions({
		intl,
		generalDefinition,
		copyModuleButtonParams,
		copyModule,
		additionalActions,
		isFlowChanged,
		openCopyToLibraryForm,
		disabled,
		hideCopyToLibrary: showCopyToLibrary === false,
	})

	const knowledgeBaseLinkType =
		knowledgeBaseLinkTypeOverride === null ? generalDefinition.type : knowledgeBaseLinkTypeOverride
	const knowledgeBaseLink = _.get(KNOWLEDGE_BASE_LINKS, knowledgeBaseLinkType, null)
	const showKnowledgeBaseLink = knowledgeBaseLink !== null

	const handleSelectChange = option => {
		if (option.data.isDisabled === false) {
			option.value()
		}
	}

	return (
		<Fragment>
			<header
				className={classnames(classes['holder-header-detail'], classes[generalDefinition.style], {
					[classes.message]: isRelatedMessageShown === true,
				})}
			>
				<div className={classes.actions}>
					<button
						className={classnames('test-btn-return', 'btn', classes['btn-return'])}
						onClick={closeModuleDetail}
						data-intercom-target="close-module-detail"
					>
						{intl.formatMessage({ id: 'close' })}
					</button>
					{detailActions.length > 0 && (
						<SelectAction
							id="flow-detail-actions"
							components={{ Option: SelectOptionWithTooltip }}
							options={detailActions}
							onChange={handleSelectChange}
							controlClassName={classnames({
								[classes['actions__select--white']]:
									generalDefinition.type !== VISUAL_FLOW_MODULE_TYPES.A_MESSAGE &&
									generalDefinition.type !== VISUAL_FLOW_MODULE_TYPES.UI_COMMAND &&
									generalDefinition.type !== VISUAL_FLOW_MODULE_TYPES.A_SNIPPET &&
									isRelatedMessageShown === false,
							})}
						/>
					)}
				</div>
				<div className={classes['module-intro']}>
					<div className={classes['module-name']}>
						{intl.formatMessage({
							id: generalDefinition.title(moduleDefinition, modules, {
								isFlowDetail: true,
								isPreviewOnly,
							}),
						})}
						{showKnowledgeBaseLink === true && (
							<a rel="noopener noreferrer" target="_blank" href={knowledgeBaseLink}>
								<div
									className={classes['module-name__help']}
									title={intl.formatMessage({ id: 'knowledge_base' })}
								>
									?
								</div>
							</a>
						)}
					</div>
					<div className={classes['module-description']}>
						{intl.formatMessage({
							id: generalDefinition.description(moduleDefinition, modules, { isPreviewOnly }),
						})}
					</div>
				</div>
			</header>
			<RelatedModuleToggle
				generalDefinition={generalDefinition}
				moduleDefinition={moduleDefinition}
				showRelatedMessage={actions.showRelatedMessage}
				hideRelatedMessage={actions.hideRelatedMessage}
				isMatrixChoice={isMatrixChoice}
				isRelatedModuleInvalid={isRelatedModuleInvalid}
				isRelatedMessageShown={isRelatedMessageShown}
			/>
		</Fragment>
	)
}

Header.propTypes = {
	actions: PropTypes.shape({
		showRelatedMessage: PropTypes.func.isRequired,
		hideRelatedMessage: PropTypes.func.isRequired,
	}).isRequired,
	additionalActions: PropTypes.arrayOf(
		PropTypes.shape({
			isDisabled: PropTypes.bool.isRequired,
			label: PropTypes.string.isRequired,
			tooltipText: PropTypes.string.isRequired,
			value: PropTypes.func.isRequired,
		}),
	),
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func,
	copyModuleButtonParams: PropTypes.shape({
		disabled: PropTypes.bool.isRequired,
		tooltipText: PropTypes.string.isRequired,
	}),
	disabled: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.shape({
		description: PropTypes.func.isRequired,
		style: PropTypes.string.isRequired,
		title: PropTypes.func.isRequired,
		type: PropTypes.string.isRequired,
	}).isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isMatrixChoice: PropTypes.bool,
	isPreviewOnly: PropTypes.bool,
	isRelatedMessageShown: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool,
	knowledgeBaseLinkTypeOverride: PropTypes.string,
	moduleDefinition: PropTypes.object.isRequired,
	modules: PropTypes.object,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	showCopyToLibrary: PropTypes.bool.isRequired,
}

export default Header
