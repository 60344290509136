import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { enTranslator as intl } from 'intl.js'

import { DEFAULT_ICON_PROPS, DEFAULT_ICON_PROPS_SHAPE } from 'constants/icons'
import { COLORS } from 'constants/colors'

import Icon from 'components/_scaffolding/Icon'
import DotsLoader from 'components/_scaffolding/DotsLoader'

import classes from './Button.module.scss'

const SIZE_PRESET = {
	DEFAULT: 'DEFAULT',
	BIG: 'BIG',
	QHD: 'QHD',
}

const ICON_SIZE = {
	[SIZE_PRESET.DEFAULT]: 16,
	[SIZE_PRESET.BIG]: 18,
	[SIZE_PRESET.QHD]: 32,
}

const Button = ({ As = 'button', ...props }) => {
	const {
		primary,
		secondary,
		negative,
		outlined,
		title,
		className,
		iconProps,
		isLoading,
		dotsBackgroundColor,
		secondaryIconProps,
		sizePreset = SIZE_PRESET.DEFAULT,
		...buttonProps
	} = props

	const isIconComponent = iconProps !== null

	const mergedIconProps = { ...DEFAULT_ICON_PROPS, size: ICON_SIZE[sizePreset], ...iconProps }

	const buttonClassName = classnames(classes.button, {
		[classes.button__big]: sizePreset === SIZE_PRESET.BIG,
		[classes['button--qhd']]: sizePreset === SIZE_PRESET.QHD,
		[classes.disabled]: props.disabled === true,
		[classes.button__primary]: primary === true && isLoading === false,
		[classes.button__secondary]: secondary === true || isLoading === true,
		[classes.button__negative]: negative === true && isLoading === false,
		[classes.button__outlined]: outlined === true && isLoading === false,
		[classes['icon-component']]: isIconComponent === true || secondaryIconProps !== null,
		[classes['icon-component--big']]: isIconComponent === true && sizePreset === SIZE_PRESET.BIG,
		[classes['icon-component--qhd']]: isIconComponent === true && sizePreset === SIZE_PRESET.QHD,
		[className]: className !== null,
		[classes['with-secondary-icon-default']]:
			secondaryIconProps !== null && sizePreset !== SIZE_PRESET.BIG,
		[classes['with-secondary-icon-big']]:
			secondaryIconProps !== null && sizePreset === SIZE_PRESET.BIG,

		// should be at the end of list of classes to make sure that loader styles won’t be overridden by other classes
		[classes.loader]: isLoading === true,
	})

	if (isLoading === true) {
		return (
			<div className={buttonClassName}>
				<DotsLoader size={'small'} backgroundColor={dotsBackgroundColor} />
			</div>
		)
	}

	return (
		<As className={buttonClassName} {...buttonProps}>
			{isIconComponent === true && <Icon {...mergedIconProps} />}
			{title}
			{secondaryIconProps !== null && <Icon {...secondaryIconProps} />}
		</As>
	)
}

Button.SIZE_PRESET = SIZE_PRESET

Button.propTypes = {
	As: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	dotsBackgroundColor: PropTypes.string.isRequired,
	icon: PropTypes.string,
	iconProps: DEFAULT_ICON_PROPS_SHAPE,
	isLoading: PropTypes.bool.isRequired,
	negative: PropTypes.bool.isRequired,
	outlined: PropTypes.bool.isRequired,
	primary: PropTypes.bool.isRequired,
	secondary: PropTypes.bool.isRequired,
	secondaryIconProps: DEFAULT_ICON_PROPS_SHAPE,
	sizePreset: PropTypes.oneOf(Object.values(SIZE_PRESET)),
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
}

Button.defaultProps = {
	className: null,
	disabled: false,
	dotsBackgroundColor: COLORS.GREY_90,
	icon: null,
	iconProps: null,
	isLoading: false,
	negative: false,
	outlined: false,
	primary: true,
	secondary: false,
	secondaryIconProps: null,
	title: intl.formatMessage({ id: 'submit' }),
	type: 'submit',
}

export default Button
