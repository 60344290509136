import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'

const AllocationHintValueSettings = ({ disabled }) => {
	const intl = useIntl()

	const positionOptions = [
		{
			value: true,
			label: _.capitalize(intl.formatMessage({ id: 'before' })),
		},
		{
			value: false,
			label: _.capitalize(intl.formatMessage({ id: 'after' })),
		},
	]

	return (
		<div>
			<Label label={intl.formatMessage({ id: 'allocation.detail.hint.unit.label' })} />
			<Field component={Input} disabled={disabled} name="hintValueSettings.unitLabel" type="text" />
			<Field
				component={Checkbox}
				componentProps={{
					label: intl.formatMessage({ id: 'allocation.detail.hint.unit.add.space' }),
				}}
				disabled={disabled}
				name="hintValueSettings.addSpace"
			/>
			<Label label={intl.formatMessage({ id: 'allocation.detail.hint.unit.position' })} />
			<Field
				component={Select}
				disabled={disabled}
				name={'hintValueSettings.isLeft'}
				options={positionOptions}
			/>
		</div>
	)
}

AllocationHintValueSettings.propTypes = {
	disabled: PropTypes.bool.isRequired,
}

export default AllocationHintValueSettings
