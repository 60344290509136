import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'
import validateDatasetMessages from './_validateDatasetMessages'
import validateRelatedMessage from './_validateRelatedMessage'

const validateHeatmap = (module, modules, studyTags) => {
	const validationResult = []

	validationResult.push(...validateDatasetMessages(module, studyTags))
	validationResult.push(...validateRelatedMessage(module, modules, studyTags))

	if (module.definition.imageUrl === '') {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.heatmap_no_image,
		})
	}

	if (module.definition.minAnswers < 1) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.heatmap_min_answers_error,
		})
	}

	if (module.definition.maxAnswers < 1) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.heatmap_max_answers_error,
		})
	}

	if (module.definition.maxAnswers < module.definition.minAnswers) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.heatmap_min_max_answers_error,
		})
	}

	if (module.definition.maxTimer !== null && Number(module.definition.maxTimer) < 1) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.heatmap_max_timer_error,
		})
	}

	return validationResult
}

export default validateHeatmap
