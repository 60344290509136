import { v4 as uuidv4 } from 'uuid'
import MEDIA_OBJECT from './MEDIA_OBJECT'
import { CHOICE_SUBTYPE, ALLOCATION_SUBTYPE } from 'constants/studyDesign'

const getDefaultOption = (subtype, isNoneOfThese, code, label, showLabel) => ({
	id: uuidv4(),
	label,
	randomize: false,
	isNoneOfThese: isNoneOfThese,
	showLabel,
	media: subtype === CHOICE_SUBTYPE.IMAGE ? MEDIA_OBJECT(isNoneOfThese) : null,
	code,
	simpleName: '',
	isUnsaved: true,
	isHidden: false,
})

export const CHOICE_OPTION = (subtype, isNoneOfThese, code, label, showLabel) => {
	const option = getDefaultOption(subtype, isNoneOfThese, code, label, showLabel)

	// add allocation option fields
	if (subtype === ALLOCATION_SUBTYPE.INPUT) {
		option.defaultValue = 0
	}

	return option
}
