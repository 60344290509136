import { MATRIX_QUESTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MATRIX_QUESTION'
import { replaceItemInText } from 'helpers/visualFlowModules/replaceItemInText'
import { sanitizeMatrixAttributesShortName } from 'helpers/visualFlowModules/sanitizeMatrixAttributesShortName'

export const createMatrixAttributesFromList = (list, attributeShortNameColumn, text) =>
	list.definition.items.map(item => {
		const itemLabel = item[attributeShortNameColumn]

		return MATRIX_QUESTION(
			sanitizeMatrixAttributesShortName(itemLabel),
			replaceItemInText(text, itemLabel, item, list.definition.columns),
		)
	})
