import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classnames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { ALLOCATION_SUBTYPE, CHOICE_SUBTYPE, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import Tooltip from 'components/_scaffolding/Tooltip'

import {
	isAllocationOptionDefaultInRange,
	isAllocationOptionDefaultValid,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateAllocation'
import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'

import Icon from 'components/_scaffolding/Icon'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import MediaOpener from 'components/_scaffolding/_input/MediaOpener'
import { BUCKET_NAMES } from 'constants/mediaUpload'

import OptionActions from './_components/OptionActions'

import classes from './Option.module.scss'

const Option = props => {
	const intl = useIntl()

	const toggleOption = () => {
		props.toggleOption(props.option.id)
	}

	const handleOnPaste = event => {
		if (!event.clipboardData) {
			return
		}

		const unorderedListRegexPattern = /^(•|o||-)+\s+/
		const orderedListRegexPattern = /^(([0-9]{1,2}\.)+|([0-9a-zA-Z]{1,2}[.)])|\([0-9a-zA-Z]{1,2}\))\s+/

		const clipboardData = event.clipboardData.getData('text/plain')
		const splittedData = clipboardData.split(/\n+|\r+|\r\n+/g)
		const filteredData = splittedData.filter((item, index) => item.length > 0 && index > 0)

		const isOrderedList = filteredData.every(item =>
			new RegExp(orderedListRegexPattern).test(item.trim()),
		)

		const labels = splittedData.map(label => {
			if (isOrderedList === true) {
				return label.replace(orderedListRegexPattern, '').trim()
			}

			const isLabelPartOfUnorderedList = new RegExp(unorderedListRegexPattern).test(label.trim())

			if (isLabelPartOfUnorderedList === true) {
				return label.replace(unorderedListRegexPattern, '').trim()
			}

			return label
		})

		if (labels.length > 1 && props.option.isNoneOfThese === false) {
			event.preventDefault()
			props.handleMultiLinePaste(labels, props.index)
		}
	}

	const addOptionAbove = () => {
		props.arrayHelpers.insert(props.index, props.getChoiceOption())
	}

	const addOptionBelow = () => {
		props.arrayHelpers.insert(props.index + 1, props.getChoiceOption())
	}

	const removeOption = () => {
		// remove option and decrement maxSelection if needed
		const { maxSelection, minSelection, options, optionsShownLimit } = props.values

		const newOptions = [...options]

		if (getIsStudyEditable(props.studyState) === false && props.option.isUnsaved !== true) {
			newOptions[props.index] = {
				...options[props.index],
				isHidden: true,
			}
		} else {
			newOptions.splice(props.index, 1)
		}

		if (
			[VISUAL_FLOW_MODULE_TYPES.A_CHOICE, VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE].includes(
				props.moduleType,
			) === false
		) {
			props.setValues({
				...props.values,
				options: newOptions,
			})

			return
		}

		const normalOptionsCountAfterDelete = props.getNormalOptionsCount(newOptions)

		const newMaxSelection =
			maxSelection > normalOptionsCountAfterDelete ? maxSelection - 1 : maxSelection

		const newMinSelection =
			minSelection > normalOptionsCountAfterDelete - 1 ? minSelection - 1 : minSelection

		const newOptionsShownLimit =
			optionsShownLimit === null
				? optionsShownLimit
				: Math.min(optionsShownLimit, normalOptionsCountAfterDelete)

		props.setValues({
			...props.values,
			maxSelection: newMaxSelection,
			minSelection: newMinSelection,
			options: newOptions,
			optionsShownLimit: newOptionsShownLimit,
		})
	}

	const restoreOption = () => {
		props.setFieldValue(`options[${props.index}].isHidden`, false)
	}

	const toggleRandomize = () => {
		const newValue = !props.option.randomize
		props.setFieldValue(`options[${props.index}].randomize`, newValue)
	}

	const changeIdOption = idOption => {
		props.expandOption(idOption)
		props.setFieldValue(`options[${props.index}].id`, idOption)
	}

	const handleIdOptionChange = event => {
		const { value } = event.currentTarget
		changeIdOption(value)
	}

	const generateIdOption = () => {
		changeIdOption(uuidv4())
	}

	if (props.option.isNoneOfThese === true && props.showNoneOfThese === false) {
		return null
	}

	const { index } = props
	const isImageChoice = props.choiceSubtype === CHOICE_SUBTYPE.IMAGE

	const showImage = isImageChoice === true && props.showNoneOfThese === false

	const renderError = idMessage => (
		<div className={classes.row}>
			<div className={classes['row__single-item']}>
				<span className="title-error">{intl.formatMessage({ id: idMessage })}</span>
			</div>
		</div>
	)

	const handleCodeChange = event => {
		const value = event.currentTarget.value

		const valueToSet = value === '' || isNaN(value) === true ? '' : Number(value)

		props.setFieldValue(`options[${index}].code`, valueToSet)
	}

	const minOptions = props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF ? 3 : 2
	const canRemoveOption =
		props.getNormalOptionsCount(props.values.options) > minOptions && props.option.isHidden !== true

	return (
		<div
			className={classnames(classes.wrapper, {
				[classes['wrapper--expanded']]: props.isExpanded === true,
			})}
			id={`option-${index}`}
		>
			<div
				className={classnames(classes.row, {
					[classes['row--with-image']]: showImage === true,
					[classes['row--max-diff']]: props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF,
					[classes['row--max-diff-image']]:
						props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF &&
						props.values.subtype === CHOICE_SUBTYPE.IMAGE,
				})}
			>
				{props.option.isNoneOfThese === false ? (
					<div className={classes.drag}>
						<Icon name={Icon.NAMES.DRAG} size={14} customClassName={classes.drag__icon} />
					</div>
				) : (
					<div />
				)}
				{showImage === true && (
					<div
						className={classnames(classes['wrapper-image'], {
							[classes['input--hidden']]: props.option.isHidden === true,
						})}
					>
						<Field
							bucketName={BUCKET_NAMES.PROJECT}
							canSubmitVideo={false}
							component={MediaOpener}
							disabled={props.disabled}
							name={`options[${index}].media`}
							placeholder="Image"
							openMediaManager={props.openMediaManager}
							fieldIndex={index}
							isShowImageText={false}
							placeholderSize={22}
							placeholderClassName={classes['wrapper-image__placeholder']}
						/>
					</div>
				)}
				<div className={classes.input__wrapper}>
					<Field
						className={classnames(classes.input, {
							[classes['input--max-diff']]: props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF,
							[classes['input--hidden']]: props.option.isHidden === true,
						})}
						component={Input}
						disabled={props.disabled}
						name={`options[${index}].label`}
						onPaste={handleOnPaste}
						placeholder={props.placeholder}
						type="text"
						onFocus={() => {
							if (props.values.options[index].label === props.placeholder) {
								props.setFieldValue(`options[${index}].label`, '')
							}
						}}
					/>
					{props.showNoneOfThese === false && (
						<div className={classes.actions}>
							<OptionActions
								addOptionAbove={addOptionAbove}
								addOptionBelow={addOptionBelow}
								canRemove={canRemoveOption}
								canRestore={props.option.isHidden}
								disabled={props.disabled}
								idOption={props.option.id}
								isRandomized={props.option.randomize}
								moduleType={props.moduleType}
								removeOption={removeOption}
								restoreOption={restoreOption}
								toggleRandomize={toggleRandomize}
							/>
							{props.moduleType !== VISUAL_FLOW_MODULE_TYPES.MAXDIFF && (
								<Icon
									name={props.option.randomize === true ? Icon.NAMES.SHUFFLE : Icon.NAMES.PIN}
									size={18}
								/>
							)}
						</div>
					)}
				</div>
				{props.moduleType !== VISUAL_FLOW_MODULE_TYPES.MAXDIFF && (
					<Field
						className={classnames(classes['code-input'], {
							[classes['input--hidden']]: props.option.isHidden === true,
						})}
						component={Input}
						disabled={props.disabled}
						name={`options[${index}].code`}
						onChange={handleCodeChange}
						placeholder=""
						type="text"
					/>
				)}
				<div
					className={classes['toggle-expanded']}
					id={`option-${props.index}-toggle-expanded`}
					onClick={toggleOption}
				>
					<Icon
						name={props.isExpanded === true ? Icon.NAMES.CHEVRON_UP : Icon.NAMES.CHEVRON_DOWN}
					/>
				</div>
			</div>
			{props.hasDuplicateLabel === true &&
				renderError(
					props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF
						? 'maxDiff.detail.non_unique_options'
						: 'choice.detail.non_unique_options',
				)}
			{props.hasDuplicateSimpleName === true &&
				renderError(
					props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF
						? 'maxDiff.detail.non_unique_simple_names'
						: 'choice.detail.non_unique_simple_names',
				)}
			{isImageChoice === true &&
				props.option.media.url.length === 0 &&
				renderError('choice.detail.no_image')}
			{props.option.label.trim().length === 0 &&
				renderError(
					props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF
						? 'maxDiff.detail.empty_options'
						: 'choice.detail.empty_options',
				)}
			{(props.option.code === undefined || String(props.option.code).length === 0) &&
				renderError('choice.detail.missing_option_code')}
			{props.choiceSubtype === ALLOCATION_SUBTYPE.INPUT &&
				isAllocationOptionDefaultInRange(props.values.options[index], props.values.range) ===
					false &&
				renderError('allocation.detail.option.default.out.of.range')}
			{props.choiceSubtype === ALLOCATION_SUBTYPE.INPUT &&
				isAllocationOptionDefaultValid(props.values.options[index]) === false &&
				renderError('allocation.detail.option.default.empty')}
			{props.isExpanded === true && (
				<Fragment>
					{props.choiceSubtype === ALLOCATION_SUBTYPE.INPUT ? (
						<div className={classes.row}>
							<div
								className={classes['row__single-item']}
								data-tip-disable={props.option.isNoneOfThese === false}
								data-for={'none-of-these-default'}
								data-tip={intl.formatMessage({ id: 'allocation.detail.none_of_these' })}
							>
								<Label label={intl.formatMessage({ id: 'default_value' })} />
								<Field
									className={classnames({
										[classes['input--hidden']]: props.option.isHidden === true,
									})}
									component={Input}
									disabled={props.disabled === true || props.option.isNoneOfThese === true}
									name={`options[${index}].defaultValue`}
									placeholder=""
									type="number"
								/>
								<Tooltip id={'none-of-these-default'} />
							</div>
						</div>
					) : (
						<div className={classes.row}>
							<div className={classes['row__single-item']}>
								<Label label={intl.formatMessage({ id: 'choice.option.shortName' })} />
								<Field
									className={classnames({
										[classes['input--hidden']]: props.option.isHidden === true,
									})}
									component={Input}
									disabled={props.disabled}
									name={`options[${index}].simpleName`}
									placeholder=""
									type="text"
								/>
							</div>
						</div>
					)}
					{props.isInternalEmployee === true &&
						props.moduleType !== VISUAL_FLOW_MODULE_TYPES.MAXDIFF && (
							<div className={classes.row}>
								<div className={classes['row__single-item']}>
									<Label
										label={intl.formatMessage({ id: 'choice.option.id' })}
										secondaryLabel={
											<div
												className={classnames('title-secondary', classes['generate-id'])}
												onClick={generateIdOption}
											>
												{intl.formatMessage({ id: 'choice.option.generate_id' })}
											</div>
										}
									/>
									<Field
										className={classnames({
											[classes['input--hidden']]: props.option.isHidden === true,
										})}
										component={Input}
										disabled={props.disabled}
										onChange={handleIdOptionChange}
										name={`options[${index}].id`}
										placeholder=""
										type="text"
									/>
									{props.hasDuplicateId === true && (
										<span className="title-error">
											{intl.formatMessage({ id: 'choice.detail.non_unique_ids' })}
										</span>
									)}
									{props.hasInvalidId === true && (
										<span className="title-error">
											{intl.formatMessage({ id: 'choice.option.invalid_id' })}
										</span>
									)}
								</div>
							</div>
						)}
				</Fragment>
			)}
		</div>
	)
}

const optionShape = PropTypes.shape({
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	randomize: PropTypes.bool.isRequired,
	isNoneOfThese: PropTypes.bool.isRequired,
	showLabel: PropTypes.bool.isRequired,
	media: PropTypes.object,
})

Option.defaultProps = {
	isExpanded: null,
}

Option.propTypes = {
	disabled: PropTypes.bool.isRequired,
	expandOption: PropTypes.func.isRequired,
	choiceSubtype: PropTypes.string.isRequired,
	getChoiceOption: PropTypes.func.isRequired,
	getNormalOptionsCount: PropTypes.func.isRequired,
	handleMultiLinePaste: PropTypes.func.isRequired,
	hasDuplicateLabel: PropTypes.bool.isRequired,
	hasDuplicateSimpleName: PropTypes.bool.isRequired,
	hasDuplicateId: PropTypes.bool.isRequired,
	hasInvalidId: PropTypes.bool.isRequired,
	isExpanded: PropTypes.bool,
	index: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
	moduleType: PropTypes.string.isRequired,
	option: optionShape.isRequired,
	placeholder: PropTypes.string.isRequired,
	showNoneOfThese: PropTypes.bool.isRequired,
	toggleOption: PropTypes.func.isRequired,
	values: PropTypes.object.isRequired,
	isInternalEmployee: PropTypes.bool.isRequired,
	studyState: PropTypes.string.isRequired,
	// formik helpers
	arrayHelpers: PropTypes.object.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
	openMediaManager: PropTypes.func.isRequired,
}

export default Option
