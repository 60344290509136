/**
 * Imports
 */
import _ from 'lodash'
import { compare } from 'natural-orderby'

import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'
import { tools } from 'constants/tools'

import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getStatementThemeName } from 'helpers/statementThemes'
import { getThemeParams } from 'helpers/reportBuilder/getLetterAndThemeParams'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'

/**
 * Helpers
 */
const calcPos = (numberOfAxes, ...expectedSupport) =>
	numberOfAxes === 4 ? expectedSupport[0] - expectedSupport[1] : expectedSupport[0]

const getSegmentedBlobRow = (segmentsData, segmentsDataKey, totalSegmentData) => {
	if (totalSegmentData.isStatementTheme === true) {
		const segmentedBlobRow =
			segmentsData.theme[segmentsDataKey]?.themeSupport[totalSegmentData.idStatement]

		if (segmentedBlobRow === undefined) {
			return { expectedSupport: null }
		}

		return segmentedBlobRow
	}

	const segmentedBlobRow = segmentsData.statement[segmentsDataKey]?.statementSupports.find(
		segmentedBlobStatement => segmentedBlobStatement.idStatement === totalSegmentData.idStatement,
	)

	if (segmentedBlobRow === undefined) {
		return { expectedSupport: null }
	}

	return segmentedBlobRow
}

const calculateStatementData = (idea, segmentedData) => {
	const segmentY0Data = getSegmentedBlobRow(segmentedData, 'topSegment', idea)
	const segmentY1Data = getSegmentedBlobRow(segmentedData, 'bottomSegment', idea)
	const segmentX0Data = getSegmentedBlobRow(segmentedData, 'leftSegment', idea)
	const segmentX1Data = getSegmentedBlobRow(segmentedData, 'rightSegment', idea)

	const result = {
		...idea,
		support_total: {
			// NOTE: something is mutating support_total.supportStrength so we need to copy it
			supportStrength: { ...idea.supportStrength },
			expectedSupport: idea.expectedSupport,
			totalSeen: idea.totalSeen,
		},
		support_y0: { expectedSupport: segmentY0Data.expectedSupport },
		support_y1: { expectedSupport: segmentY1Data.expectedSupport },
		support_x0: { expectedSupport: segmentX0Data.expectedSupport },
		support_x1: { expectedSupport: segmentX1Data.expectedSupport },
	}

	return result
}

const roundStatement = statement => ({
	...statement,
	support_total: {
		...statement.support_total,
		decimalExpectedSupport: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(statement, 'support_total.expectedSupport', 0),
		),
		decimalSupportPercent: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(statement, 'support_total.supportStrength.lowerBound', 0),
		),
		expectedSupport: _.round(_.get(statement, 'support_total.expectedSupport', 0), 2) * 100,
		supportStrength: {
			...statement.support_total.supportStrength,
			lowerBound: _.round(_.get(statement, 'support_total.supportStrength.lowerBound', 0), 2) * 100,
		},
	},
	support_x0: {
		...statement.support_x0,
		decimalExpectedSupport: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(statement, 'support_x0.expectedSupport', 0),
		),
		expectedSupport: _.round(_.get(statement, 'support_x0.expectedSupport', 0), 2) * 100,
	},
	support_x1: {
		...statement.support_x1,
		decimalExpectedSupport: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(statement, 'support_x1.expectedSupport', 0),
		),
		expectedSupport: _.round(_.get(statement, 'support_x1.expectedSupport', 0), 2) * 100,
	},
	support_y0: {
		...statement.support_y0,
		decimalExpectedSupport: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(statement, 'support_y0.expectedSupport', 0),
		),
		expectedSupport: _.round(_.get(statement, 'support_y0.expectedSupport', 0), 2) * 100,
	},
	support_y1: {
		...statement.support_y1,
		decimalExpectedSupport: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(statement, 'support_y1.expectedSupport', 0),
		),
		expectedSupport: _.round(_.get(statement, 'support_y1.expectedSupport', 0), 2) * 100,
	},
})

const sortStatementsByTotal = (a, b) => {
	const res =
		a.support_total.supportStrength.lowerBound - b.support_total.supportStrength.lowerBound

	if (res < 0) return 1
	if (res > 0) return -1

	return compare(a.label, b.label)
}

const calculateStatement = (idea, axes) => ({
	...idea,
	x: calcPos(axes, idea.support_x1.expectedSupport, idea.support_x0.expectedSupport),
	y: calcPos(axes, idea.support_y0.expectedSupport, idea.support_y1.expectedSupport),
})

const formatStatement = (idea, axes, settings) => {
	const round = n => Math.round(Number(n))
	const shouldDisplayFourSegments = axes === 4

	const [idTopSegment, idRightSegment, idBottomSegment, idLeftSegment] = settings.idsSegments

	const customColor = _.get(settings, `customColors.${idea.idStatement}`, null)

	const formattedStatement = {
		answersCount: idea.answersCount,
		fill: customColor ?? idea.color,
		hasCustomColor: customColor !== null,
		id: idea.idStatement,
		idStatement: idea.idStatement,
		isStatementTheme: idea.isStatementTheme,
		letter: idea.letter,
		name: idea.label,
		themeName: idea.themeName,
		themeFill: idea.themeColor,
		themeStatements: idea.statements ?? [],
		unit: '%',
		x: idea.x,
		y: idea.y,
		isExcluded: idea.isExcluded,

		totalExpectedSupport: round(idea.support_total.expectedSupport),
		totalSupportCompletes: round(idea.support_total.totalSeen),
		totalSupportPercent: round(idea.support_total.supportStrength.lowerBound),

		topSupport: round(idea.support_y0.expectedSupport),
		rightSupport: round(idea.support_x1.expectedSupport),

		totalDecimalExpectedSupport: idea.support_total.decimalExpectedSupport,
		totalDecimalSupportPercent: idea.support_total.decimalSupportPercent,

		topDecimalSupport: idea.support_y0.decimalExpectedSupport,
		rightDecimalSupport: idea.support_x1.decimalExpectedSupport,
	}

	if (shouldDisplayFourSegments === true) {
		formattedStatement.bottomSupport = round(idea.support_y1.expectedSupport)
		formattedStatement.leftSupport = round(idea.support_x0.expectedSupport)

		formattedStatement.bottomDecimalSupport = idea.support_y1.decimalExpectedSupport
		formattedStatement.leftDecimalSupport = idea.support_x0.decimalExpectedSupport
	}

	formattedStatement.aiDescription = {
		label: formattedStatement.name,
		[idTopSegment]: formattedStatement.topDecimalSupport,
		[idRightSegment]: formattedStatement.rightDecimalSupport,
	}

	if (shouldDisplayFourSegments === true) {
		formattedStatement.aiDescription[idBottomSegment] = formattedStatement.bottomDecimalSupport
		formattedStatement.aiDescription[idLeftSegment] = formattedStatement.leftDecimalSupport
	}

	return formattedStatement
}

const getSegmentDataToCheck = (
	isThemeViewActive,
	showTopUnthemedStatements,
	axes,
	totalSegmentData,
	segmentedData,
) => {
	const shouldCheckThemes = isThemeViewActive === true
	const shouldCheckStatements =
		isThemeViewActive === false ||
		(isThemeViewActive === true && showTopUnthemedStatements === true)

	return {
		theme:
			shouldCheckThemes === false
				? []
				: [
						totalSegmentData.theme,
						segmentedData.theme.topSegment,
						segmentedData.theme.rightSegment,
						...(axes === 4
							? [segmentedData.theme.bottomSegment, segmentedData.theme.leftSegment]
							: []),
				  ],
		statement:
			shouldCheckStatements === false
				? []
				: [
						totalSegmentData.statement,
						segmentedData.statement.topSegment,
						segmentedData.statement.rightSegment,
						...(axes === 4
							? [segmentedData.statement.bottomSegment, segmentedData.statement.leftSegment]
							: []),
				  ],
	}
}

const getFormattedData = (dataToFormat, segmentedData, axes, filtered, sorted, settings) => {
	const formattedData = dataToFormat
		.map(statement => calculateStatementData(statement, segmentedData))
		.map(roundStatement)
		.sort(sortStatementsByTotal)
		.map(statement => calculateStatement(statement, axes))
		.map(statement => formatStatement(statement, axes, settings))

	return sortData(filterData(formattedData, filtered), sorted)
}

export const calculateDataset = (
	idStudy,
	calculatedSlide,
	blobData,
	chartColorSettings,
	isReportBuilder,
) => {
	const {
		settings,
		legendState: { sorted, filtered },
		slideType,
		themes: statementThemes,
	} = calculatedSlide

	if (slideType !== REPORT_TYPES.INTELLISEGMENT) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const {
		axes,
		idsSegments,
		hiddenStatements,
		idStudyObject,
		themeViewSettings,
		useThemes,
	} = settings

	const totalSegmentData = {
		statement:
			blobData[
				getBlobDataKey(
					idStudy,
					idStudyObject,
					REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
					tools.TOTAL_SEGMENT_UUID,
				)
			],
		theme:
			blobData[
				getBlobDataKey(
					idStudy,
					idStudyObject,
					REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
					tools.TOTAL_SEGMENT_UUID,
				)
			],
	}

	const segmentedData = {
		statement: {
			topSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
						idsSegments[0],
					)
				],
			rightSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
						idsSegments[1],
					)
				],
			bottomSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
						idsSegments[2],
					)
				],
			leftSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
						idsSegments[3],
					)
				],
		},
		theme: {
			topSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
						idsSegments[0],
					)
				],
			rightSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
						idsSegments[1],
					)
				],
			bottomSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
						idsSegments[2],
					)
				],
			leftSegment:
				blobData[
					getBlobDataKey(
						idStudy,
						idStudyObject,
						REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
						idsSegments[3],
					)
				],
		},
	}

	const segmentDataToCheck = getSegmentDataToCheck(
		themeViewSettings.isActive,
		themeViewSettings.showTopUnthemedStatements,
		axes,
		totalSegmentData,
		segmentedData,
	)

	const hasBlobDataArray =
		themeViewSettings.isActive === true
			? [
					hasBlobData(segmentDataToCheck.theme, SUPPORT_KEYS.THEME_SUPPORT),
					themeViewSettings.showTopUnthemedStatements === true
						? hasBlobData(segmentDataToCheck.statement, SUPPORT_KEYS.STATEMENT_SUPPORTS)
						: true,
			  ]
			: [hasBlobData(segmentDataToCheck.statement, SUPPORT_KEYS.STATEMENT_SUPPORTS)]

	if (hasBlobDataArray.includes(false)) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const preparedThemes =
		themeViewSettings.isActive === false
			? []
			: statementThemes
					.filter(
						theme =>
							theme.statements.filter(idStatement =>
								totalSegmentData.statement[SUPPORT_KEYS.STATEMENT_SUPPORTS].some(
									statement => statement.idStatement === idStatement,
								),
							).length > 0,
					)
					.map(theme => {
						const themeBlobData =
							totalSegmentData.theme[SUPPORT_KEYS.THEME_SUPPORT][theme.idStatementTheme]

						return {
							answersCount: theme.statements.length,
							color: theme.color,
							expectedSupport: themeBlobData.expectedSupport,
							idStatement: theme.idStatementTheme,
							isStatementTheme: true,
							label: getStatementThemeName(theme, true),
							letter: themeBlobData.letter,
							statements: theme.statements,
							supportStrength: themeBlobData.supportStrength,
							themeColor: theme.color,
							themeName: null,
							totalSeen: themeBlobData.absoluteSupport,
							isExcluded: settings.hiddenStatements.includes(theme.idStatementTheme),
						}
					})

	const defaultColors = chartColorSettings.openEnded.colors

	const preparedStatements =
		themeViewSettings.isActive === true && themeViewSettings.showTopUnthemedStatements === false
			? []
			: totalSegmentData.statement[SUPPORT_KEYS.STATEMENT_SUPPORTS]
					.map((statementWithoutColor, index, allStatements) => {
						const statement = {
							...statementWithoutColor,
							color: defaultColors[index % defaultColors.length],
						}

						const statementTheme = preparedThemes.find(preparedTheme =>
							preparedTheme.statements.includes(statement.idStatement),
						)

						if (themeViewSettings.isActive === true && statementTheme !== undefined) {
							return null
						}

						const statementThemeParams = getThemeParams(
							statement,
							statement,
							statementThemes,
							allStatements,
							useThemes,
							themeViewSettings.isActive,
						)

						const themeName = getStatementThemeName(statementThemeParams, true)

						return {
							answersCount: 1,
							color: statementThemeParams.color,
							expectedSupport: statement.expectedSupport,
							idStatement: statement.idStatement,
							isStatementTheme: false,
							label: statement.label,
							letter: statement.letter,
							supportStrength: statement.supportStrength,
							themeColor: statementTheme?.color ?? null,
							themeName: themeName,
							totalSeen: statement.totalSeen,
							isExcluded: hiddenStatements.includes(statement.idStatement),
						}
					})
					.filter(statement => statement !== null)

	/**
	 * NOTE: Merge both datasets into single format.
	 * This has unfortunate effect of having statementThemes treated as `statement`.
	 *
	 * In free time it might be good idea to instead refactor code around
	 * to treat both datasets as just `data`, instead of mislabeling `statementTheme` as `statement`.
	 */
	const preparedData =
		themeViewSettings.isActive === true
			? [...preparedThemes, ...preparedStatements.filter(statement => statement.themeName === null)]
			: preparedStatements

	const formattedData = getFormattedData(
		preparedData,
		segmentedData,
		axes,
		filtered,
		sorted,
		settings,
	)

	return { statementSupports: formattedData, allIdeasLength: preparedData.length }
}

export const calculateLegendStatements = (calculatedSlide, dataset) => {
	const { hiddenStatements, axes } = calculatedSlide.settings

	return dataset.statementSupports
		.filter(statement => hiddenStatements.includes(statement.idStatement) === false)
		.map(statement => {
			const shouldDisplayFourSegments = axes === 4

			return {
				...statement,
				toExport: () => [
					statement.letter,
					statement.name,
					statement.totalDecimalSupportPercent,
					statement.totalSupportCompletes,
					statement.totalDecimalExpectedSupport,
					statement.topDecimalSupport,
					shouldDisplayFourSegments === true ? statement.bottomDecimalSupport : undefined,
					shouldDisplayFourSegments === true ? statement.leftDecimalSupport : undefined,
					statement.rightDecimalSupport,
					statement.themeName,
				],
			}
		})
}
