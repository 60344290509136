export const validate = (module, flow, config = null) => {
	let knownIds = {}
	let warnings = []

	flow.map(module => {
		if (knownIds[module.id]) {
			// module id already exists
			warnings.push('Duplicate module id: ' + module.id)
		} else {
			// add module to knownIds
			knownIds[module.id] = true
		}
	})

	if (warnings.length > 0) {
		return {
			infos: null,
			warnings: warnings,
			errors: null,
		}
	} else {
		return null
	}
}

export default validate
