import { connect } from 'react-redux'
import { showNotification } from 'store/notification'

import ContactUsForm from './ContactUsForm'

const mapActionCreators = {
	showNotification,
}

export default connect(null, mapActionCreators)(ContactUsForm)
