import React from 'react'
import PropTypes from 'prop-types'

import classes from './FieldArrayAdd.module.scss'
import IconButton from 'components/_scaffolding/Buttons/IconButton'

const FieldArrayAdd = ({ label, add }) => (
	<IconButton onClick={add} iconClassName={classes.plus} label={label} />
)

FieldArrayAdd.propTypes = {
	label: PropTypes.string.isRequired,
	add: PropTypes.func.isRequired,
}

export default FieldArrayAdd
