import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import { fieldShape } from 'components/_formik/_constants'
import DefaultInput from 'components/_scaffolding/_input/Input'

const Input = ({ field, form, onBlur, ...props }) => {
	const onBlurWithTrim = event => {
		event.persist()

		const value = event.currentTarget.value

		const trimmedValue = value.trim()

		if (value !== trimmedValue) {
			form.setFieldValue(field.name, trimmedValue)
		}

		if (onBlur !== null) {
			onBlur(event)
		} else {
			field.onBlur(event)
		}
	}

	const isValid = _.get(form.errors, field.name, null) === null

	return <DefaultInput {...field} onBlur={onBlurWithTrim} {...props} isValid={isValid} />
}

Input.defaultProps = {
	onBlur: null,
}

Input.propTypes = {
	field: fieldShape.isRequired,
	form: PropTypes.object.isRequired,
	onBlur: PropTypes.func,
}

export default Input
