import _ from 'lodash'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { UnsavedChangesContext } from 'context/UnsavedChangesContext'
import { fromStudyDesign } from 'selectors'

import { PATHS } from 'constants/router'

export const useHasUnsavedChanges = () => {
	const { hasUnsavedChanges, setHasUnsavedChanges } = useContext(UnsavedChangesContext)
	const isFlowChanged = useSelector(fromStudyDesign.getFlowIsChanged)

	return window.location.pathname.includes(PATHS.STUDY_DESIGN) === true
		? {
				hasUnsavedChanges: isFlowChanged,
				setHasUnsavedChanges: _.noop,
		  }
		: {
				hasUnsavedChanges,
				setHasUnsavedChanges,
		  }
}
