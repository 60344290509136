import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const PlanPaymentStatusPage = lazy(() => import('./PlanPaymentStatusPage'))
const WrappedAccount = errorWrapper(PlanPaymentStatusPage)

export default () => ({
	path: 'payment-status',
	element: <WrappedAccount />,
})
