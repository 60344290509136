import { OPTION_LABEL_SOURCE } from 'constants/reports'

export const getOptionLabel = (option, chartSettings) => {
	if (chartSettings.optionLabelSource === OPTION_LABEL_SOURCE.LABEL) {
		return option.label
	}

	// ignore '' simpleName
	return option.simpleName || option.label
}
