import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Account = lazy(() => import('./Account'))
const WrappedAccount = errorWrapper(Account)

export default () => ({
	path: 'account',
	element: <WrappedAccount />,
})
