import React from 'react'
import PropTypes from 'prop-types'

import classes from './InteractiveContent.module.scss'
import classnames from 'classnames'

const InteractiveContent = ({ contentClassName, children }) => {
	return (
		<div
			className={classnames(classes.content, {
				[contentClassName]: contentClassName !== null,
			})}
		>
			{children}
		</div>
	)
}

InteractiveContent.defaultProps = {
	contentClassName: null,
}
InteractiveContent.propTypes = {
	contentClassName: PropTypes.string,
	children: PropTypes.array.isRequired,
}

export default InteractiveContent
