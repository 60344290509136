import SetVariable from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/SetVariable'
import {
	SET_FN_TYPES,
	SET_FN_CUSTOM_VARIABLES,
} from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/SET_VARIABLE_CONSTANTS'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { fnList } from 'libs/gs3-libs-flow-validation/src/validators/evaluator/_fnList'

const getTitle = definition => {
	switch (definition.setFn) {
		case SET_FN_TYPES.SET:
			return 'set_variable.title'
		case SET_FN_TYPES.SET_PATH:
			return 'set_path.title'
		case SET_FN_TYPES.SET_LITERAL:
			return 'set_literal.title'
		case SET_FN_TYPES.SET_PATH_LITERAL:
			return 'set_path_literal.title'
		default:
			throw new Error(`Unknown setFn in SET_VARIABLE. setFn: ${definition.setFn}`)
	}
}

const getDescription = definition => {
	switch (definition.setFn) {
		case SET_FN_TYPES.SET:
			return 'set_variable.description'
		case SET_FN_TYPES.SET_PATH:
			return 'set_path.description'
		case SET_FN_TYPES.SET_LITERAL:
			return 'set_literal.description'
		case SET_FN_TYPES.SET_PATH_LITERAL:
			return 'set_path_literal.description'
		default:
			throw new Error(`Unknown setFn in SET_VARIABLE. setFn: ${definition.setFn}`)
	}
}

export default {
	type: VISUAL_FLOW_MODULE_TYPES.SET_VARIABLE,
	style: 'snippet',
	title: getTitle,
	description: getDescription,
	component: SetVariable,
	generator: (modules, additionalProperties) => ({
		type: VISUAL_FLOW_MODULE_TYPES.SET_VARIABLE,
		setFn: additionalProperties.setFn,
		fnArguments: {
			[SET_FN_CUSTOM_VARIABLES.VARIABLE_VALUE]: '',
		},
		proFlowFn: fnList.set,
		variableName: '',
	}),
}
