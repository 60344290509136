const FILE_KEYS = {
	COMMUNITY: 'community',
	DATA_IMPORT: 'dataImport',
}

export const getUploadedFileKey = isCommunity => {
	if (isCommunity === true) {
		return FILE_KEYS.COMMUNITY
	}

	return FILE_KEYS.DATA_IMPORT
}
