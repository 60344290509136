import React, { lazy } from 'react'

import { links } from 'constants/links'

const LegalDocument = lazy(() => import('components/_scaffolding/LegalDocument'))

export default () => ({
	path: 'privacy-policy',
	element: <LegalDocument blobUrl={links.PRIVACY_POLICY} />,
})
