import moment from 'moment'
import { CONDITION_SELECTION_TYPES, CONDITION_TYPES } from 'constants/conditionBuilder'

export const hasInvalidDate = condition => {
	if (
		condition.type === CONDITION_TYPES.DATE &&
		condition.selection.type !== CONDITION_SELECTION_TYPES.BETWEEN &&
		moment(condition.left.dateTime).isValid() === false
	) {
		return true
	}

	if (
		condition.type === CONDITION_TYPES.DATE &&
		condition.selection.type === CONDITION_SELECTION_TYPES.BETWEEN &&
		(moment(condition.left.from.dateTime).isValid() === false ||
			moment(condition.left.to.dateTime).isValid() === false)
	) {
		return true
	}

	return false
}

export const hasInvalidDateSequence = condition => {
	if (
		condition.type === CONDITION_TYPES.DATE &&
		condition.selection.type === CONDITION_SELECTION_TYPES.BETWEEN &&
		moment(condition.left.to.dateTime).isSameOrBefore(condition.left.from.dateTime) === true
	) {
		return true
	}

	return false
}
