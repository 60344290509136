import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const DataImporter = lazy(() => import('routes/_study/DataImporter/DataImporterContainer'))
const WrappedDataImporter = errorWrapper(DataImporter)

export default () => ({
	path: 'community',
	element: <WrappedDataImporter isCommunity />,
})
