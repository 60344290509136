import PropTypes from 'prop-types'

export const MEDIA_AGATHA_TYPE = {
	A_IMAGE: 'A_IMAGE',
	A_VIDEO: 'A_VIDEO',
}

export const MEDIA_TYPE = {
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
}

export const THUMBNAIL_EXTENSION = '.480.thumbnail.jpeg'

export const FILE_EXTENSIONS = {
	GIF: 'gif',
}

export const ALLOWED_IMAGE_FORMATS = ['image/png', 'image/jpeg', 'image/gif']

export const ALLOWED_VIDEO_FORMATS = ['video/mp4']

export const ALLOWED_TEXT_FORMATS = 'text/csv'

export const ALLOWED_XLSX_FORMAT =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const mediaShape = PropTypes.shape({
	id: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
})
