import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'

import { getFreeTextStringsConditionSelect } from 'helpers/conditionBuilder/getFreeTextStringsConditionSelect'
import { getNumberConditionSelectionTypeOptions } from 'helpers/conditionBuilder/getNumberConditionSelectionTypeOptions'

import FieldArrayAdd from 'components/_formik/_base/FieldArrayAdd'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'

import Icon from 'components/_scaffolding/Icon'

import classes from './ItemAttributeRule.module.scss'

const ItemAttributeRule = ({ disabled, rule, ruleIndex, selectedList, setFieldValue }) => {
	const intl = useIntl()

	const listKeyOptions = selectedList.definition.columns.map(column => ({
		value: column.key,
		label: column.key,
	}))

	const handleListKeyChange = value => {
		setFieldValue(`filterRules[${ruleIndex}].listKey`, value)
		setFieldValue(`filterRules[${ruleIndex}].function`, null)
	}

	const negationSelectOptions = [
		{
			value: true,
			label: intl.formatMessage({ id: 'is' }),
		},
		{
			value: false,
			label: intl.formatMessage({ id: 'is_not' }),
		},
	]

	const selectedColumn = selectedList.definition.columns.find(column => column.key === rule.listKey)

	const selectedColumnValues = selectedList.definition.items.map(item => item[rule.listKey])

	const isNumberCondition = selectedColumnValues.every(value => isNaN(value) === false)

	const selectionOptions =
		isNumberCondition === true
			? getNumberConditionSelectionTypeOptions(intl)
			: getFreeTextStringsConditionSelect(intl)

	const handleSelectionChange = value => {
		setFieldValue(`filterRules[${ruleIndex}].function`, value)
	}

	const removeFreeTextAnyOf = indexToRemove => {
		const newValue = [...rule.valueArray]
		newValue.splice(indexToRemove, 1)

		setFieldValue(`filterRules[${ruleIndex}].valueArray`, newValue)
	}

	const renderValueInput = () => {
		if (rule.function !== CONDITION_SELECTION_TYPES.STRING_ANY_OF) {
			return (
				<Fragment>
					<Field component={Input} disabled={disabled} name={`filterRules[${ruleIndex}].value`} />
					{isNumberCondition === true && isNaN(rule.value) === true && (
						<div className="title-error">
							{intl.formatMessage({ id: 'list_filter.detail.rule.item_attribute.value.nan_error' })}
						</div>
					)}
				</Fragment>
			)
		}

		return (
			<Fragment>
				{rule.valueArray.map((_unused, valueIndex) => (
					<div key={valueIndex} className={classes['input-with-icon']}>
						<Field
							component={Input}
							className={classes['input-with-icon__input']}
							disabled={disabled}
							name={`filterRules[${ruleIndex}].valueArray[${valueIndex}]`}
						/>
						{rule.valueArray.length > 1 && (
							<div
								className={classes['input-with-icon__icon']}
								onClick={() => removeFreeTextAnyOf(valueIndex)}
							>
								<Icon name={Icon.NAMES.MINUS} />
							</div>
						)}
					</div>
				))}
				{disabled !== true && (
					<FieldArrayAdd
						label={intl.formatMessage({
							id: 'condition.string_any_of.add',
						})}
						add={() => {
							setFieldValue(`filterRules[${ruleIndex}].valueArray`, [...rule.valueArray, ''])
						}}
					/>
				)}
			</Fragment>
		)
	}

	return (
		<div>
			<Label
				label={intl.formatMessage({ id: 'list_filter.detail.rule.item_attribute.list_item_param' })}
			/>
			<Field
				component={Select}
				disabled={disabled}
				name={`filterRules[${ruleIndex}].listKey`}
				options={listKeyOptions}
				onChange={handleListKeyChange}
			/>
			{rule.listKey === '' && (
				<div className="title-error">
					{intl.formatMessage({
						id: 'list_filter.detail.rule.item_attribute.list_item_param.empty',
					})}
				</div>
			)}
			{selectedColumn === undefined && (
				<div className="title-error">
					{intl.formatMessage({
						id: 'list_filter.detail.rule.item_attribute.column_not_found',
					})}
				</div>
			)}
			<Label label={intl.formatMessage({ id: 'comparison_rule' })} />
			<div className={classes.split}>
				<Field
					component={Select}
					disabled={disabled}
					name={`filterRules[${ruleIndex}].is`}
					options={negationSelectOptions}
				/>
				<Field
					component={Select}
					disabled={disabled}
					name={`filterRules[${ruleIndex}].function`}
					options={selectionOptions}
					onChange={handleSelectionChange}
				/>
			</div>
			{rule.function === null && (
				<div className="title-error">
					{intl.formatMessage({ id: 'list_filter.detail.rule.item_attribute.function.empty' })}
				</div>
			)}
			<Label label={intl.formatMessage({ id: 'list_filter.detail.rule.item_attribute.value' })} />
			{renderValueInput()}
		</div>
	)
}

ItemAttributeRule.propTypes = {
	disabled: PropTypes.bool.isRequired,
	rule: PropTypes.object.isRequired,
	ruleIndex: PropTypes.number.isRequired,
	selectedList: PropTypes.object.isRequired,
	setFieldValue: PropTypes.func.isRequired,
}

export default ItemAttributeRule
