import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Report = lazy(() => import('./Report'))
const WrappedReport = errorWrapper(Report)

export default () => ({
	path: 'report',
	element: <WrappedReport />,
})
