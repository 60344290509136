import React, { lazy } from 'react'

import { links } from 'constants/links'

const LegalDocument = lazy(() => import('components/_scaffolding/LegalDocument'))

export default () => ({
	path: 'terms-and-conditions',
	element: <LegalDocument blobUrl={links.TERMS_AND_CONDITIONS} />,
})
