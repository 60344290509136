import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'
import { PATHS } from 'constants/router'

const SharePresentation = lazy(() => import('./SharePresentation'))
const WrappedSharePresentation = errorWrapper(SharePresentation)

export default () => ({
	path: `${PATHS.SHARE_PRESENTATION}/:idPresentation`,
	element: <WrappedSharePresentation />,
})
