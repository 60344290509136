import { prepareMutation } from 'store/tools/graphql'
import { gql } from '@apollo/client'

import { USER_PAGE } from 'gql/queries/user'
import { notificationSettings } from 'gql/fragments/user'
import { setToken } from 'localStorage/token'

import { NOTIFICATION_DASHBOARD_STATUS } from 'constants/user'

export const USER_UPDATE_INFO = (closeFunc = () => null) =>
	prepareMutation({
		mutation: gql`
			mutation USER_UPDATE_INFO($userInfoInput: UserInfoInput!) {
				user {
					updateInfo(userInfoInput: $userInfoInput) {
						idUser
						displayName
						fullName
					}
				}
			}
		`,
		options: { onCompleted: closeFunc },
		optimisticResponse: (values, props) => ({
			__typename: 'Mutation',
			user: {
				__typename: 'UserMutation',
				updateInfo: {
					__typename: 'User',
					idUser: props.idUser,
					displayName: values.name.display,
					fullName: values.name.full,
				},
			},
		}),
	})

export const USER_UPDATE_AVATAR = showNotification =>
	prepareMutation({
		mutation: gql`
			mutation USER_UPDATE_AVATAR($userImageInput: UserImageInput!) {
				user {
					updateImage(userImageInput: $userImageInput) {
						idUser
						image {
							id
							url
						}
					}
				}
			}
		`,
		options: {
			onError: () => {
				showNotification(
					{
						id: 'user-image-update-error',
						type: 'error',
						message: 'Image update failed. Please try again.',
						buttons: ['dismiss'],
					},
					true,
				)
			},
		},
		prepareData: (id, url) => ({
			userImageInput: { id, url },
		}),
	})

export const USER_RESET_IMAGE = showNotification =>
	prepareMutation({
		mutation: gql`
			mutation USER_RESET_IMAGE {
				user {
					resetImage {
						idUser
						image {
							id
							url
						}
					}
				}
			}
		`,
		options: {
			onError: () => {
				showNotification(
					{
						id: 'user-image-update-error',
						type: 'error',
						message: 'Please try again.',
						buttons: ['dismiss'],
					},
					true,
				)
			},
		},
	})

export const USER_UPDATE_PASSWORD = () =>
	prepareMutation({
		mutation: gql`
			mutation USER_UPDATE_PASSWORD($userPasswordInput: UserPasswordInput!) {
				user {
					updatePassword(userPasswordInput: $userPasswordInput) {
						idUser
						passwordLastChangeDate
						token
					}
				}
			}
		`,
		update: (cache, { data }) => {
			const { user, accounts } = cache.readQuery({ ...USER_PAGE() })

			const { token, passwordLastChangeDate } = data.user.updatePassword

			setToken(token)

			cache.writeQuery({
				...USER_PAGE(),
				data: {
					accounts,
					user: {
						...user,
						passwordLastChangeDate,
					},
				},
			})
		},
	})

export const UPDATE_EMAIL_NOTIFICATIONS_SETTINGS = () =>
	prepareMutation(
		{
			mutation: gql`
				mutation UPDATE_EMAIL_NOTIFICATIONS_SETTINGS(
					$emailNotificationSettingsInput: EmailNotificationSettingsInput!
				) {
					user {
						updateEmailNotificationSettings(
							emailNotificationSettingsInput: $emailNotificationSettingsInput
						) {
							idUser
							...notificationSettings
						}
					}
				}
			`,
		},
		[notificationSettings],
	)

export const UPDATE_DASHBOARD_NOTIFICATIONS_SETTINGS = () =>
	prepareMutation(
		{
			mutation: gql`
				mutation UPDATE_DASHBOARD_NOTIFICATIONS_SETTINGS(
					$dashboardNotificationSettingsInput: DashboardNotificationSettingsInput!
				) {
					user {
						updateDashboardNotificationSettings(
							dashboardNotificationSettingsInput: $dashboardNotificationSettingsInput
						) {
							idUser
							...notificationSettings
						}
					}
				}
			`,
		},
		[notificationSettings],
	)

export const UPDATE_NOTIFICATIONS_SETTINGS = () =>
	prepareMutation(
		{
			mutation: gql`
				mutation UPDATE_NOTIFICATIONS_SETTINGS(
					$notificationSettingsInput: NotificationSettingsInput!
				) {
					user {
						updateNotificationSettings(notificationSettingsInput: $notificationSettingsInput) {
							idUser
							...notificationSettings
						}
					}
				}
			`,
		},
		[notificationSettings],
	)

export const MARK_NOTIFICATIONS_AS_SEEN = () =>
	prepareMutation({
		mutation: gql`
			mutation MARK_NOTIFICATIONS_AS_SEEN($idsNotifications: [UUID!]!) {
				user {
					markNotificationsAsSeen(idsNotifications: $idsNotifications) {
						idNotification
						dashboardStatus
					}
				}
			}
		`,
		update: (cache, { data }) => {
			const idsChangedNotifications = data.user.markNotificationsAsSeen.map(
				notification => notification.idNotification,
			)

			const cachedData = cache.readQuery({
				query: USER_PAGE().query,
			})

			const notificationsToMove = cachedData.user.newNotifications.filter(
				notification => idsChangedNotifications.includes(notification.idNotification) === true,
			)

			const newNotifications = cachedData.user.newNotifications.filter(
				notification => idsChangedNotifications.includes(notification.idNotification) === false,
			)

			const seenNotifications = [
				...notificationsToMove.map(notification => ({
					...notification,
					dashboardStatus: NOTIFICATION_DASHBOARD_STATUS.SEEN,
				})),
				...cachedData.user.seenNotifications,
			]

			cache.writeQuery({
				query: USER_PAGE().query,
				data: {
					...cachedData,
					user: {
						...cachedData.user,
						newNotifications,
						seenNotifications,
					},
				},
			})
		},
	})

export const MARK_ALL_NOTIFICATIONS_AS_SEEN = () =>
	prepareMutation({
		mutation: gql`
			mutation MARK_ALL_NOTIFICATIONS_AS_SEEN {
				user {
					markAllNotificationsAsSeen
				}
			}
		`,
		update: cache => {
			const cachedData = cache.readQuery({
				query: USER_PAGE().query,
			})

			const pastNotifications = cachedData.user.newNotifications.map(notification => ({
				...notification,
				dashboardStatus: NOTIFICATION_DASHBOARD_STATUS.SEEN,
			}))

			const seenNotifications = [...pastNotifications, ...cachedData.user.seenNotifications]

			const newNotifications = []

			cache.writeQuery({
				query: USER_PAGE().query,
				data: {
					...cachedData,
					user: {
						...cachedData.user,
						seenNotifications,
						newNotifications,
					},
				},
			})
		},
	})

export const ACCEPT_PRIVACY_POLICY = () =>
	prepareMutation({
		mutation: gql`
			mutation ACCEPT_PRIVACY_POLICY {
				user {
					acceptPrivacyPolicy {
						idUser
						shouldAcceptNewPrivacyPolicy
					}
				}
			}
		`,
	})

export const ACCEPT_TERMS_AND_CONDITIONS = () =>
	prepareMutation({
		mutation: gql`
			mutation ACCEPT_TERMS_AND_CONDITIONS {
				user {
					acceptTermsAndConditions {
						idUser
						shouldAcceptNewTerms
					}
				}
			}
		`,
	})

export const CONTACT_US = () =>
	prepareMutation({
		mutation: gql`
			mutation CONTACT_US($contactUsInput: ContactUsInput!) {
				user {
					contactUs(contactUsInput: $contactUsInput)
				}
			}
		`,
	})
