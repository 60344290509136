export const replaceItemInText = (text, itemLabel, item, listColumns) => {
	const textWithReplacedItemLabel = text.replace(/\[\[item\]\]/g, itemLabel)

	let result = textWithReplacedItemLabel

	listColumns.forEach(({ key }) => {
		const matches = textWithReplacedItemLabel.match(new RegExp(`[[item.${key}]]`, 'g')) ?? []

		matches.forEach(match => {
			result = result.replace(`[[item.${key}]]`, item[key])
		})
	})

	return result
}
