const getIdElement = statementOrGroup =>
	statementOrGroup.idStatement || statementOrGroup.idStatementGroup

export const getStatementsWithoutThemes = (statements, statementGroups, statementThemes) => {
	const stringifiedThemes = JSON.stringify(statementThemes)

	return [...statements, ...statementGroups]
		.filter(
			statementOrGroup => stringifiedThemes.includes(getIdElement(statementOrGroup)) === false,
		)
		.map(statementOrGroup => ({
			idStatement: getIdElement(statementOrGroup),
			label: statementOrGroup.label,
			totalSegmentSupport: statementOrGroup.totalSegmentSupport,
		}))
}
