import _ from 'lodash'
import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

const refreshWithForEach = (order, modules, orderParentPath, moduleParentPath) => {
	order.forEach((orderModule, index) => {
		const orderPath = `${orderParentPath}_${index}`
		const modulePath = `${moduleParentPath}_${index}`

		const module = modules[orderModule.id]

		orderModule.path = orderPath
		module.path = modulePath

		const idRelatedMessage = _.get(module, 'definition.idRelatedMessage', null)
		const relatedMessageModule = modules[idRelatedMessage]

		if (relatedMessageModule !== undefined) {
			relatedMessageModule.path = modulePath
		}

		if (orderModule.type === MODULE_DEFINITIONS.UI_COMMAND.type) {
			const isLoop = module.definition.loopSettings.isActive === true

			const nextOrderParentPath = `${orderPath}_then`
			const nextModuleParentPath = isLoop === true ? `${modulePath}_loop` : `${modulePath}_then`

			refreshWithForEach(orderModule.then, modules, nextOrderParentPath, nextModuleParentPath)
		}
	})
}

const refreshPaths = (order, modules) => {
	const clonedOrder = _.cloneDeep(order)
	const clonedModules = _.cloneDeep(modules)

	refreshWithForEach(clonedOrder, clonedModules, '0', '0')

	return { order: clonedOrder, modules: clonedModules }
}

export default refreshPaths
