import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { SET_FN_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/SET_VARIABLE_CONSTANTS'
import _validateSetVariableArgumentLength from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/_validateSetVariableArgumentLength'
import _validateSetVariableFnArguments from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/_validateSetVariableFnArguments'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Textarea from 'components/_formik/_base/Textarea'

const formatJson = (json, isValidJson, setIsValidJson) => {
	let formattedJson = json
	try {
		formattedJson = JSON.stringify(JSON.parse(json), null, 2)

		if (isValidJson === false) {
			setIsValidJson(true)
		}
	} catch (e) {
		if (isValidJson === true) {
			setIsValidJson(false)
		}
	}
	return formattedJson
}

const validateJson = string => {
	try {
		JSON.parse(string)
		return true
	} catch (e) {
		return false
	}
}

const FunctionArgument = ({ argumentKey, argumentValue, disabled, setFn }) => {
	const intl = useIntl()

	const [isValidJson, setIsValidJson] = useState(validateJson(argumentValue))

	if (setFn === SET_FN_TYPES.SET || setFn === SET_FN_TYPES.SET_PATH) {
		return (
			<Fragment>
				<Label label={argumentKey} />
				<Field
					component={Input}
					disabled={disabled}
					name={`fnArguments.${argumentKey}`}
					type="text"
				/>
				{_validateSetVariableArgumentLength(argumentKey, argumentValue) === false && (
					<span className="title-error">
						{intl.formatMessage({ id: 'set_variable.invalid_argument' })}
					</span>
				)}
			</Fragment>
		)
	}

	return (
		<Fragment>
			<Label label={argumentKey} />
			<Field
				component={Textarea}
				disabled={disabled}
				name={`fnArguments.${argumentKey}`}
				type="text"
				value={formatJson(argumentValue, isValidJson, setIsValidJson)}
			/>
			{_validateSetVariableArgumentLength(argumentKey, argumentValue) === false && (
				<span className="title-error">
					{intl.formatMessage({ id: 'set_variable.invalid_argument' })}
				</span>
			)}
			{validateJson(argumentValue) === true &&
				_validateSetVariableFnArguments(JSON.parse(argumentValue)) === false && (
					<span className="title-error">
						{intl.formatMessage({ id: 'set_variable.argument_contains_dot' })}
					</span>
				)}
			{isValidJson === false && (
				<span className="title-error">{intl.formatMessage({ id: 'invalid_json' })}</span>
			)}
		</Fragment>
	)
}

FunctionArgument.propTypes = {
	argumentKey: PropTypes.string.isRequired,
	argumentValue: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	setFn: PropTypes.string.isRequired,
}

export default FunctionArgument
