import { enTranslator as intl } from 'intl.js'

import Heatmap from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Heatmap'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { GET_QUESTION_MESSAGE } from './MESSAGE'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.HEATMAP,
	style: 'heatmap',
	title: () => 'heatmap.title',
	description: () => 'heatmap.description',
	component: Heatmap,
	generator: (modules, additionalProperties) => ({
		idRelatedMessage: null,
		mandatory: true,
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'heatmap.title' }),
			VISUAL_FLOW_MODULE_TYPES.HEATMAP,
		),
		messages: [GET_QUESTION_MESSAGE()],
		blurBefore: false,
		blurAfter: false,
		imageUrl: '',
		thumbnailUrl: '',
		isRanking: false,
		maxAnswers: 1,
		maxTimer: null,
		minAnswers: 1,
	}),
}
