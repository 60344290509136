import _ from 'lodash'
import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'

const hasInvalidCode = module => module.definition.isCodeValid === false

const hasInvalidNextSteps = (module, modules) =>
	module.definition.nextSteps &&
	module.definition.nextSteps.some(step => modules[step.id] === undefined)

const hasInvalidQuotas = module =>
	module.definition.quotas.some(quota => _.get(quota, 'errors', []).length > 0)

const validateSnippet = (module, modules) => {
	const validationResult = []

	if (hasInvalidCode(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.snippet_invalid_code,
		})
	}

	if (hasInvalidNextSteps(module, modules) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.snippet_invalid_next_step,
		})
	}

	if (hasInvalidQuotas(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.snippet_invalid_quota_condition,
		})
	}

	return validationResult
}

export default validateSnippet
