import { gql } from '@apollo/client'

export const slide = gql`
	fragment slide on Slide {
		idSlide
		idStudy
		legendState
		meta {
			updated
		}
		order
		settings
		statementPositions
		slideType
	}
`

export const oldSlide = gql`
	fragment oldSlide on OldSlide {
		detailBubbles
		idSlide
		idStudy
		label
		legendState
		meta {
			updated
		}
		order
		settings
		slideType
	}
`

export const sunburstSlide = gql`
	fragment sunburstSlide on SunburstSlide {
		meta {
			updated
		}
		idSlide
		idStudy
		idSunburstSlide
		isValid
		slideType
		settings {
			answersLimit
			filtered
			hiddenStatements
			idStudyObject
			idsSegments
			isResponsesCountDisplayed
			sorted
			title
			useThemes
			customColors
		}
		errorMessage
	}
`

export const maxDiffSlide = gql`
	fragment maxDiffSlide on MaxDiffSlide {
		idMaxDiffSlide
		idSlide
		idStudy
		meta {
			updated
		}
		settings {
			answersLimit
			excludedAnswers
			filtered
			graphType
			horizontal
			idStudyObject
			idsSegments
			optionLabelSource
			roundType
			showLabels
			sorted
			title
			xAxisDataKey
			xAxisRotate
			customColors
		}
		slideType
	}
`

export const heatmapSlide = gql`
	fragment heatmapSlide on HeatmapSlide {
		idHeatmapSlide
		idSlide
		idStudy
		meta {
			updated
		}
		settings {
			blur
			gradientName
			idStudyObject
			idsSegments
			maxOpacity
			maxValue
			radius
			showLabels
			title
		}
		slideType
	}
`
