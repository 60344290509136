import React, { lazy } from 'react'

const EmailVerifyWasSend = lazy(() =>
	import('routes/_scaffolding/Auth/_components/EmailVerifyWasSend/EmailVerifyWasSend'),
)

export default () => ({
	path: 'created-user',
	element: <EmailVerifyWasSend />,
})
