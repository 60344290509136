import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Slider from 'components/_formik/_base/Slider'

const getMinTimerLabel = (minTimer, intl) =>
	intl.formatMessage({ id: 'action_button.minTimer' }, { count: minTimer / 1000 })

const Proceed = ({ disabled, values }) => {
	const intl = useIntl()

	return (
		<Fragment>
			<Label label="Proceed button label" />
			<Field
				component={Input}
				disabled={disabled}
				name="actionButton.label"
				placeholder="Proceed"
				type="text"
			/>
			<Label label={getMinTimerLabel(values.actionButton.minTimer, intl)} />
			<Field
				component={Slider}
				disabled={disabled}
				max={30 * 1000}
				min={0}
				name="actionButton.minTimer"
				step={1000}
				{...Slider.STYLE_PRESETS.light}
			/>
		</Fragment>
	)
}

Proceed.propTypes = {
	disabled: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
}

export default Proceed
