import { enTranslator as intl } from 'intl.js'

import Snippet from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Snippet'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_SNIPPET,
	style: 'snippet',
	title: () => 'snippet.title',
	description: () => 'snippet.description',
	component: Snippet,
	generator: (modules, additionalProperties) => ({
		type: 'A_EVALUATOR',
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'snippet.title' }),
			VISUAL_FLOW_MODULE_TYPES.A_SNIPPET,
		),
		description: 'description',
		code: '{"logic":[]}',
		parsedCode: { logic: [] },
		isCodeValid: true,
		nextSteps: [],
		quotas: [],
		codeErrors: [],
	}),
}
