const _validateSetVariableFnArguments = argumentObject => {
	const keys = Object.keys(argumentObject)
	const values = Object.values(argumentObject)
	const objectValues = values.filter(element => typeof element === 'object' && element !== null)

	if (keys.some(element => element.includes('.')) === true) {
		return false
	}

	if (objectValues.length > 0) {
		return objectValues.every(element => _validateSetVariableFnArguments(element))
	}

	return true
}

export default _validateSetVariableFnArguments
