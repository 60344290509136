import _ from 'lodash'

export const prepareStyles = (innerStyles, ownStyles) => {
	const preparedOwnStyles = _.mapValues(ownStyles, (f, k) => (base, state) => {
		const style = innerStyles[k] !== undefined ? innerStyles[k](base, state) : base
		return f(style, state)
	})

	return { ...innerStyles, ...preparedOwnStyles }
}
