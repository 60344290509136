import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { FieldArray } from 'formik'
import _ from 'lodash'

import { getEmptyFlowCondition } from 'helpers/conditionBuilder/getEmptyFlowCondition'
import { getStudyObjectDefinition } from 'helpers/conditionBuilder/getStudyObjectDefinition'
import { CONDITION_CASE_TYPES } from 'constants/conditionBuilder'

import FieldArrayAdd from 'components/_formik/_base/FieldArrayAdd'

import Select from 'components/_scaffolding/_input/Select'

import Condition from './_components/Condition'
import classes from './ConditionBuilder.module.scss'

export const getNewCondition = (
	conditionCase,
	defaultChoiceModule,
	createEmptyCondition,
	isQuotaCheckpoint,
) => {
	if (createEmptyCondition === true) {
		return {
			...getEmptyFlowCondition(isQuotaCheckpoint),
			case: conditionCase,
		}
	}

	const idStudyObject = _.get(defaultChoiceModule, 'definition.id', null)

	const studyObjectDefinition =
		idStudyObject === null ? null : getStudyObjectDefinition(defaultChoiceModule)

	const left =
		studyObjectDefinition === null
			? []
			: [_.get(studyObjectDefinition, 'options[0].id', null)].filter(
					element => _.isNil(element) === false,
			  )

	return {
		...getEmptyFlowCondition(isQuotaCheckpoint),
		case: conditionCase,
		studyObject: {
			id: idStudyObject,
		},
		left,
	}
}

export const getConditionCaseOptions = intl => [
	{
		label: intl.formatMessage({ id: 'condition_builder.all_apply' }),
		value: CONDITION_CASE_TYPES.AND,
	},
	{
		label: intl.formatMessage({ id: 'condition_builder.one_applies' }),
		value: CONDITION_CASE_TYPES.OR,
	},
]

export const getConditionsCaseValue = (conditions, options) => {
	const isConditionOr = conditions.some(condition => condition.case === CONDITION_CASE_TYPES.OR)
	const optionToGet = isConditionOr ? CONDITION_CASE_TYPES.OR : CONDITION_CASE_TYPES.AND

	return options.find(option => option.value === optionToGet)
}

const ConditionBuilder = ({
	createEmptyCondition = false,
	defaultChoiceModule = null,
	dirty = true,
	disabled = false,
	header = null,
	modules = [],
	isQuotaCheckpoint,
	isSegmentBuilder,
	noModulesMessage,
	setFieldValue,
	setTouched,
	study,
	touched,
	values,
}) => {
	const intl = useIntl()

	const changeConditionsCase = conditionCase => {
		setFieldValue(
			'conditions',
			values.conditions.map(condition => ({
				...condition,
				case: conditionCase.value,
			})),
		)
	}

	const renderHeader = () =>
		header === null ? null : <span className={classes['conditions__label']}>{header}</span>

	if (modules.length === 0) {
		return (
			<div data-testid="condition-builder">
				{renderHeader()}

				<span className="title-error">{noModulesMessage}</span>
			</div>
		)
	}

	const conditionsCaseOptions = getConditionCaseOptions(intl)

	return (
		<div data-testid="condition-builder">
			{renderHeader()}
			{values.conditions.length > 0 && (
				<Select
					disabled={disabled}
					id="conditionsCase"
					onChange={changeConditionsCase}
					options={conditionsCaseOptions}
					value={getConditionsCaseValue(values.conditions, conditionsCaseOptions)}
				/>
			)}
			<FieldArray name="conditions">
				{arrayHelpers => {
					const addCondition = () => {
						arrayHelpers.push(
							getNewCondition(
								getConditionsCaseValue(values.conditions, conditionsCaseOptions).value,
								defaultChoiceModule,
								createEmptyCondition,
								isQuotaCheckpoint,
							),
						)
					}

					return (
						<div data-testid="condition-builder-conditions">
							{values.conditions.map((condition, index) => (
								<Condition
									arrayHelpers={arrayHelpers}
									condition={condition}
									conditionsCount={values.conditions.length}
									dirty={dirty}
									disabled={disabled}
									index={index}
									isQuotaCheckpoint={isQuotaCheckpoint}
									isSegmentBuilder={isSegmentBuilder}
									key={index}
									modules={modules}
									setFieldValue={setFieldValue}
									setTouched={setTouched}
									study={study}
									touched={touched}
								/>
							))}
							{disabled === false && (
								<div className={classes['conditions__add-new']}>
									<FieldArrayAdd
										label={intl.formatMessage({
											id:
												values.conditions.length === 0
													? 'condition_builder.add_condition'
													: 'condition_builder.add_another_condition',
										})}
										add={addCondition}
									/>
								</div>
							)}
						</div>
					)
				}}
			</FieldArray>
		</div>
	)
}

ConditionBuilder.propTypes = {
	createEmptyCondition: PropTypes.bool,
	defaultChoiceModule: PropTypes.object,
	dirty: PropTypes.bool,
	disabled: PropTypes.bool,
	header: PropTypes.string,
	isQuotaCheckpoint: PropTypes.bool.isRequired,
	isSegmentBuilder: PropTypes.bool.isRequired,
	modules: PropTypes.array,
	noModulesMessage: PropTypes.string,
	setFieldValue: PropTypes.func.isRequired,
	setTouched: PropTypes.func.isRequired,
	study: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	values: PropTypes.shape({ conditions: PropTypes.array.isRequired }).isRequired,
}

export default ConditionBuilder
