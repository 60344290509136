import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'

import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'
import { getFieldName } from 'helpers/formik/getFieldName'

import DatePicker from 'components/_formik/_base/DatePicker'
import Field from 'components/_formik/_complex/Field'
import TimeZoneSelect from 'components/_formik/_complex/TimeZoneSelect'

import classes from './ConditionDatePicker.module.scss'

const ConditionDatePicker = ({
	fieldNamePrefix,
	index,
	maxDate,
	minDate,
	selectionType,
	isFreeTextDate,
}) => {
	const intl = useIntl()

	const isTimeSelectShown =
		selectionType !== CONDITION_SELECTION_TYPES.DATE_EQUALS && isFreeTextDate === false

	const isLabelShown = fieldNamePrefix !== ''

	return (
		<div className={classes.wrapper} data-testid="condition-date-picker">
			<Field
				name={`conditions[${index}].left.${getFieldName(fieldNamePrefix, 'dateTime')}`}
				component={DatePicker}
				dateFormat={isTimeSelectShown === true ? 'MM/dd/yyyy h:mm a' : 'MM/dd/yyyy'}
				label={{
					label:
						isLabelShown === true
							? intl.formatMessage({ id: `condition.${fieldNamePrefix}` })
							: undefined,
				}}
				maxDate={maxDate}
				minDate={minDate}
				showTimeSelect={isTimeSelectShown}
			/>
			{isFreeTextDate === false && (
				<TimeZoneSelect
					fieldName={`conditions[${index}].left.${getFieldName(fieldNamePrefix, 'timezone')}`}
				/>
			)}
		</div>
	)
}

ConditionDatePicker.propTypes = {
	fieldNamePrefix: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	maxDate: PropTypes.object,
	minDate: PropTypes.object,
	selectionType: PropTypes.string.isRequired,
	isFreeTextDate: PropTypes.bool.isRequired,
}

export default ConditionDatePicker
