import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

const isDatasetType = type =>
	type === MODULE_DEFINITIONS.ALLOCATION.type ||
	type === MODULE_DEFINITIONS.A_CHOICE.type ||
	type === MODULE_DEFINITIONS.A_FREE_TEXT.type ||
	type === MODULE_DEFINITIONS.A_OEQ.type ||
	type === MODULE_DEFINITIONS.HEATMAP.type ||
	type === MODULE_DEFINITIONS.MAXDIFF.type ||
	type === MODULE_DEFINITIONS.RANKING.type

const isSnippetDataset = module =>
	module.type === MODULE_DEFINITIONS.A_SNIPPET.type &&
	isDatasetType(module.definition.type) === true

const isDatasetModule = module =>
	isDatasetType(module.type) === true ||
	module.type === MODULE_DEFINITIONS.MATRIX_CHOICE.type ||
	isSnippetDataset(module) === true

export default isDatasetModule
