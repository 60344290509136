import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Panels = lazy(() => import('./PanelsContainer'))
const WrappedPanels = errorWrapper(Panels)

export default () => ({
	path: 'panels',
	element: <WrappedPanels />,
})
