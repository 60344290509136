import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const DivOrFragment = ({ children, divKey, fragmentKey, wrapWithDiv, ...props }) =>
	wrapWithDiv === true ? (
		<div key={divKey} {...props}>
			{children}
		</div>
	) : (
		<Fragment key={fragmentKey}>{children}</Fragment>
	)

DivOrFragment.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
	divKey: PropTypes.string.isRequired,
	fragmentKey: PropTypes.string.isRequired,
	wrapWithDiv: PropTypes.bool.isRequired,
}

DivOrFragment.defaultProps = {
	divKey: '',
	fragmentKey: '',
	wrapWithDiv: false,
}

export default DivOrFragment
