import _ from 'lodash'

import { tools } from 'constants/tools'
import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { getOptionLabel } from 'helpers/reports/getOptionLabel'
import { assignOptionColors } from 'helpers/reports/assignOptionColors'

import { getSegmentAverageValueKey, getSegmentOptionRespondentsCountKey } from 'store/reportBuilder'
import { REPORT_TYPES, REPORT_BLOB_TYPES } from 'constants/reports'

/**
 * Munge Functions
 */
const mungeIdeas = (options, idsSegments, legendState, slideSettings) => {
	const chartOptions = options.map(option => {
		const averageValues = idsSegments.map(idSegment => {
			return option[getSegmentAverageValueKey(idSegment)]
		})

		const customColor = _.get(slideSettings, `customColors.${option.idOption}`, null)

		const formattedOption = {
			..._.pick(
				option,
				idsSegments.map(idSegment => getSegmentAverageValueKey(idSegment)),
			),
			..._.pick(
				option,
				idsSegments.map(idSegment => getSegmentOptionRespondentsCountKey(idSegment)),
			),
			letter: option.letter,
			name: option.label,
			code: option.code,
			themeName: null,
			fill: customColor ?? option.color,
			hasCustomColor: customColor !== null,
			id: option.idOption,
			shown: true,
			unit: '',
			toExport: () => [option.letter, option.label, ...averageValues, option.code],
			aiDescription: {
				label: option.label,
			},
		}

		idsSegments.forEach(idSegment => {
			formattedOption.aiDescription[idSegment] = option[getSegmentAverageValueKey(idSegment)]
		})

		return formattedOption
	})

	return sortData(filterData(chartOptions, legendState.filtered), legendState.sorted)
}

/**
 * Calculators
 */
const getSegmentOption = (idOption, segmentData) =>
	segmentData.average.options.find(option => option.idOption === idOption)

export const calculateLegendIdeas = (
	idStudy,
	reportType,
	datasetOptions,
	slideSettings,
	legendState,
	blobData,
	chartColorSettings,
) => {
	if (reportType !== REPORT_TYPES.ALLOCATION_ANSWERS) {
		return []
	}

	if (slideSettings === undefined) return []

	const { idStudyObject, idsSegments, options: hiddenOptions } = slideSettings

	const dataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.ALLOCATION_STATISTICS, idSegment)
			],
	)
	const totalSegmentDataToCheck =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.ALLOCATION_STATISTICS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (hasBlobData([...dataArray, totalSegmentDataToCheck], 'average') === false) {
		return []
	}

	const totalSegmentData = assignOptionColors(
		totalSegmentDataToCheck,
		chartColorSettings,
		'average.options',
	)

	const dataset = datasetOptions.find(selector => selector.value === idStudyObject)

	const newOptions = dataset.options
		.map((option, index) => {
			const totalSegmentOption = getSegmentOption(option.id, totalSegmentData)

			const chartOption = {
				idOption: option.id,
				color: totalSegmentOption.color,
				letter: totalSegmentOption.letter,
				label: getOptionLabel(option, slideSettings),
				code: option.code,
			}

			idsSegments.forEach(idSegment => {
				const segmentData =
					blobData[
						getBlobDataKey(
							idStudy,
							idStudyObject,
							REPORT_BLOB_TYPES.ALLOCATION_STATISTICS,
							idSegment,
						)
					]
				const segmentOption = getSegmentOption(option.id, segmentData)

				chartOption[getSegmentAverageValueKey(idSegment)] = Number(segmentOption.value)
				chartOption[getSegmentOptionRespondentsCountKey(idSegment)] = segmentOption.respondentsCount
			})

			return chartOption
		})
		.filter(option => option !== null && hiddenOptions.includes(option.idOption) === false)

	return mungeIdeas(newOptions, idsSegments, legendState, slideSettings)
}
