import _ from 'lodash'
import {
	CONDITION_SELECTION_TYPES,
	CONDITION_TYPES,
	EVALUATION_POSITION,
} from 'constants/conditionBuilder'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

export const getEvaluationPositionOptions = (intl, customLabel) => [
	{
		value: EVALUATION_POSITION.AGREE,
		label: customLabel.agree === '' ? intl.formatMessage({ id: 'agree' }) : customLabel.agree,
	},
	{
		value: EVALUATION_POSITION.DISAGREE,
		label:
			customLabel.disagree === '' ? intl.formatMessage({ id: 'disagree' }) : customLabel.disagree,
	},
	{
		value: EVALUATION_POSITION.INDIFFERENT,
		label: customLabel.neutral === '' ? intl.formatMessage({ id: 'neutral' }) : customLabel.neutral,
	},
]

export const getDateConditionOptions = intl => [
	{ value: CONDITION_SELECTION_TYPES.AFTER, label: intl.formatMessage({ id: 'after' }) },
	{ value: CONDITION_SELECTION_TYPES.BEFORE, label: intl.formatMessage({ id: 'before' }) },
	{
		value: CONDITION_SELECTION_TYPES.BETWEEN,
		label: intl.formatMessage({ id: 'between' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.DATE_EQUALS,
		label: intl.formatMessage({ id: 'date_equals' }),
	},
]

export const getSelectionTypeAllocationRanking = intl => [
	{
		value: CONDITION_SELECTION_TYPES.GREATER_THAN,
		label: intl.formatMessage({ id: 'template_builder.chart_editor.greater' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.LESS_THAN,
		label: intl.formatMessage({ id: 'template_builder.chart_editor.lesser' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.NUMBER_EQUAL_TO,
		label: intl.formatMessage({ id: 'template_builder.chart_editor.is_equal' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.IN_RANGE,
		label: intl.formatMessage({ id: 'template_builder.chart_editor.in_range' }).toLowerCase(),
	},
]

export const getOEQEvaluateSelectionOptions = intl => [
	{
		value: CONDITION_SELECTION_TYPES.ANY_OF,
		label: intl.formatMessage({ id: 'condition.any_of' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.EVERY_OF,
		label: intl.formatMessage({ id: 'condition.every_of' }),
	},
]

export const getOEQFollowUpSelectionOptions = intl => [
	{
		value: CONDITION_SELECTION_TYPES.CONTAINS,
		label: intl.formatMessage({ id: 'contains' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.REGEXP,
		label: intl.formatMessage({ id: 'regexp' }),
	},
]

export const getConditionIsOptions = (intl, selectedStudyObject, conditionType) => {
	if (_.get(selectedStudyObject, 'definition.enableQuota', false) === true) {
		return [
			{ value: true, label: intl.formatMessage({ id: 'condition.respondent_is' }) },
			{ value: false, label: intl.formatMessage({ id: 'condition.respondent_is_not' }) },
		]
	}

	if (_.get(selectedStudyObject, 'definition.isTrack', false) === true) {
		return [
			{ value: true, label: intl.formatMessage({ id: 'was' }) },
			{ value: false, label: intl.formatMessage({ id: 'was_not' }) },
		]
	}

	if (conditionType === CONDITION_TYPES.OEQ_FOLLOW_UP) {
		return [
			{
				value: true,
				label: intl.formatMessage({ id: 'condition.follow_up_is' }),
			},
			{
				value: false,
				label: intl.formatMessage({ id: 'condition.follow_up_is_not' }),
			},
		]
	}

	if (conditionType === CONDITION_TYPES.OEQ_EVALUATION) {
		return [
			{
				value: true,
				label: intl.formatMessage({ id: 'condition.evaluation_is' }),
			},
			{
				value: false,
				label: intl.formatMessage({ id: 'condition.evaluation_is_not' }),
			},
		]
	}

	if (_.get(selectedStudyObject, 'type', '') === VISUAL_FLOW_MODULE_TYPES.ALLOCATION) {
		return [
			{ value: true, label: intl.formatMessage({ id: 'condition.value_is' }) },
			{ value: false, label: intl.formatMessage({ id: 'condition.value_is_not' }) },
		]
	}

	if (_.get(selectedStudyObject, 'type', '') === VISUAL_FLOW_MODULE_TYPES.RANKING) {
		return [
			{ value: true, label: intl.formatMessage({ id: 'condition.position_is' }) },
			{ value: false, label: intl.formatMessage({ id: 'condition.position_is_not' }) },
		]
	}

	if (conditionType === CONDITION_TYPES.LIST_LENGTH) {
		return [
			{ value: true, label: intl.formatMessage({ id: 'condition.list_length.is' }) },
			{ value: false, label: intl.formatMessage({ id: 'condition.list_length.is_not' }) },
		]
	}

	if (conditionType === CONDITION_TYPES.RESPONDENT_SOURCE) {
		return [
			{ value: true, label: intl.formatMessage({ id: 'condition.respondent_is' }) },
			{ value: false, label: intl.formatMessage({ id: 'condition.respondent_is_not' }) },
		]
	}

	return [
		{ value: true, label: intl.formatMessage({ id: 'is' }) },
		{ value: false, label: intl.formatMessage({ id: 'is_not' }) },
	]
}
