import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { fieldShape } from 'components/_formik/_constants'
import DefaultTextarea from 'components/_scaffolding/_input/Textarea'

const Textarea = ({ field, onChange, ...props }) => {
	const className = classnames({
		[props.className]: props.className !== undefined,
	})

	const handleChangeFn = onChange !== undefined ? onChange : field.onChange

	return <DefaultTextarea {...field} {...props} onChange={handleChangeFn} className={className} />
}

Textarea.propTypes = {
	className: PropTypes.string,
	field: fieldShape.isRequired,
	onChange: PropTypes.func,
}

export default Textarea
