import PropTypes from 'prop-types'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import _ from 'lodash'
import classnames from 'classnames'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'

import Input from 'components/_scaffolding/_input/Input'

import classes from './DatePicker.module.scss'

import { fieldShape } from 'components/_formik/_constants'

const DatePicker = ({ field, inputClassName, calendarClassName, ...props }) => {
	const getNewDate = () =>
		moment
			.utc()
			.hours(0)
			.minutes(0)
			.seconds(0)

	const onChange = value => {
		const newValue =
			props.showTimeSelect === true
				? moment(value)
				: moment(value)
						.utc()
						.hours(0)
						.minutes(0)
						.seconds(0)
						.milliseconds(0)

		props.form.setFieldValue(field.name, newValue)
	}

	const className = classnames('input-form', inputClassName)

	// react-datepicker v4 expects Date
	// our code works with moment instances
	const value = new Date(
		moment(field.value).isValid() ? moment(field.value).toISOString() : getNewDate().toISOString(),
	)

	return (
		<div className={classes['calendar-wrapper']} data-testid={field.name}>
			<ReactDatePicker
				className={className}
				customInput={<Input name={field.name} onChange={_.noop} value={value} />}
				name={field.name}
				openToDate={value}
				selected={value}
				onChange={onChange}
				calendarClassName={calendarClassName}
				timeClassName={() => classes.time}
				{...props}
			/>
		</div>
	)
}

DatePicker.defaultProps = {
	showTimeSelect: false,
}

DatePicker.propTypes = {
	// custom props
	calendarClassName: PropTypes.string,
	field: fieldShape.isRequired,
	inputClassName: PropTypes.string,
	// date-picker props
	dateFormat: PropTypes.string,
	maxDate: PropTypes.object,
	minDate: PropTypes.object,
	showTimeSelect: PropTypes.bool.isRequired,
	// formik bag
	form: PropTypes.object.isRequired,
}

export default DatePicker
