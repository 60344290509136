import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import _ from 'lodash'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Label from 'components/_formik/_base/Label'
import Input from 'components/_formik/_base/Input'

const DatasetShortName = props => {
	const intl = useIntl()

	const renderError = idTranslation => (
		<span className="title-error">{intl.formatMessage({ id: idTranslation })}</span>
	)

	const label =
		_.isNil(props.label) === false
			? props.label
			: intl.formatMessage({ id: 'dataset_shortname_label' })

	return (
		<Fragment>
			<Label label={label} />
			<Field
				component={Input}
				disabled={props.disabled}
				name="shortName"
				placeholder={intl.formatMessage({ id: 'unnamed_module' })}
				type="text"
			/>
			{props.errorInvalidSimpleName === true && renderError('dataset_invalid')}
			{props.errorNonUniqueSimpleName === true && renderError('dataset_nonUniqueSimpleName')}
			{props.values.shortName.trim().length === 0 && renderError('dataset_requiredSimpleName')}
			{props.values.shortName.length > 50 && renderError('dataset_simpleNameTooLong')}
			{props.values.shortName.includes('.') && renderError('dataset_shortNameContainsDot')}
		</Fragment>
	)
}

DatasetShortName.defaultProps = {
	label: null,
}

DatasetShortName.propTypes = {
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	label: PropTypes.string,
	values: PropTypes.shape({
		shortName: PropTypes.string.isRequired,
	}).isRequired,
}

export default DatasetShortName
