const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/UI_COMMAND.json',
	properties: {
		actionButton: {
			default: 'null',
			$id: '/properties/definition/properties/actionButton',
			oneOf: [
				{$ref: 'http://groupsolver.com/v1/ACTION_BUTTON.json'},
				{type: 'null'},
			],
		},
		history: {
			default: 'null',
			$id: '/properties/definition/properties/history',
			pattern: '^BLUR$|^UNBLUR$|^CLEAR$',
			type: ['string', 'null'],
		},
		layout: {
			default: 'null',
			$id: '/properties/definition/properties/layout',
			pattern: '^CINEMA_START$|^CINEMA_END$',
			type: ['string', 'null'],
		},
	},
	required: ['actionButton', 'history', 'layout'],
	type: 'object',
}

export default schema
