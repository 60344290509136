import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'

import { PREPARATION_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/PREPARATION_TYPES'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'

import Conditional from './_components/Conditional'
import History from './_components/History'
import LoopSettings from './_components/LoopSettings'
import Proceed from './_components/Proceed'

import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'

import classes from './If.module.scss'

const getSettingsLabel = (values, intl) => {
	if (values.enableConditions === false && values.actionButton.enabled === false) {
		return intl.formatMessage({ id: 'historyFilter.settings_label' })
	}

	if (values.enableConditions === false) {
		return intl.formatMessage({ id: 'uicommand.proceed_button.settings' })
	}

	if (values.enableQuota === true) {
		return intl.formatMessage({ id: 'quota.definition' })
	}

	if (values.isTrack === true) {
		return intl.formatMessage({ id: 'track.settings' })
	}

	if (values.isBlock === true) {
		return intl.formatMessage({ id: 'block.settings' })
	}

	return intl.formatMessage({ id: 'branch_labels' })
}

const getCommandForm = (disabled, values, setFieldValue) => {
	if (values.actionButton.enabled === false) {
		return <History disabled={disabled} />
	}

	return <Proceed disabled={disabled} values={values} setFieldValue={setFieldValue} />
}

const getUICommandSubType = definition => {
	if (definition.actionButton.enabled === true) {
		return PREPARATION_TYPES.PROCEED
	}

	if (definition.history !== null) {
		return PREPARATION_TYPES.HISTORY
	}

	if (definition.enableQuota === true) {
		return PREPARATION_TYPES.QUOTA
	}

	if (definition.isTrackRandomizer === true) {
		return PREPARATION_TYPES.TRACK_RANDOMIZER
	}

	if (definition.isRandomizer === true) {
		return PREPARATION_TYPES.RANDOMIZER
	}

	if (definition.isBlock === true) {
		return PREPARATION_TYPES.BLOCK
	}

	if (definition.loopSettings.isActive === true) {
		return PREPARATION_TYPES.LOOP
	}

	return PREPARATION_TYPES.CONDITION
}

const If = props => {
	const intl = useIntl()

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}} enableReinitialize>
			{({ values, setFieldValue, setTouched, setValues, touched }) => (
				<Fragment>
					<Header
						closeModuleDetail={props.closeModuleDetail}
						generalDefinition={props.generalDefinition}
						disabled={props.disabled}
						moduleDefinition={values}
						isFlowChanged={props.isFlowChanged}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						showCopyToLibrary={values.enableConditions}
						knowledgeBaseLinkTypeOverride={getUICommandSubType(values)}
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => (
								<Form>
									<span className={classes['section-name-label']}>
										{getSettingsLabel(values, intl)}
									</span>
									{values.enableConditions === false &&
										getCommandForm(props.disabled, values, setFieldValue)}
									{values.loopSettings?.isActive === true && (
										<LoopSettings
											disabled={props.disabled}
											errorInvalidSimpleName={props.errorInvalidSimpleName}
											errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
											setFieldValue={setFieldValue}
											upperLists={props.upperLists}
											values={values}
											hasZeroNestedModules={props.hasZeroNestedModules}
											{...props}
										/>
									)}
									{values.loopSettings?.isActive !== true && values.enableConditions === true && (
										<Conditional
											idStudy={props.idStudy}
											touched={touched}
											values={values}
											setFieldValue={setFieldValue}
											setTouched={setTouched}
											setValues={setValues}
											{...props}
										/>
									)}
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

If.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	idStudy: PropTypes.string.isRequired,
	initialValues: PropTypes.object.isRequired,
	saveModule: PropTypes.func.isRequired,
	upperModules: PropTypes.array.isRequired,
	upperLists: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	hasZeroNestedModules: PropTypes.bool.isRequired,
}

export default If
