import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import { COLORS } from 'constants/colors'
import { reactSelectStyles } from 'helpers/reactSelect/reactSelectStyles'

import Select from 'components/_scaffolding/_input/Select'

const getOverrides = (fn, ...args) => {
	if (_.isFunction(fn) === false) {
		return {}
	}

	return fn(...args)
}

const getStyles = styles => ({
	control: (base, state) => ({
		...base,
		...reactSelectStyles.control(base, state),
		height: 'auto',
		minHeight: 30,
		...getOverrides(styles.control, base),
	}),
	dropdownIndicator: base => ({
		...base,
		padding: 4,
		...getOverrides(styles.dropdownIndicator, base),
	}),
	indicatorsContainer: base => ({
		...base,
		...getOverrides(styles.indicatorsContainer, base),
	}),
	multiValue: (base, state) => ({
		...base,
		backgroundColor: COLORS.GREY_95,
		...getOverrides(styles.multiValue, base),
		border: `1px solid ${COLORS.GREY_90}`,
		borderColor: state.data.isValid === false ? COLORS.RED_40 : COLORS.GREY_90,
	}),
	multiValueLabel: (base, state) => ({
		...base,
		background: state.data.isValid === false ? COLORS.RED_40 : COLORS.GREY_95,
		color: state.data.isValid === false ? COLORS.WHITE : COLORS.GREY_10,
		...getOverrides(styles.multiValueLabel, base, state),
	}),
	valueContainer: base => ({
		...reactSelectStyles.valueContainer(base),
		flexWrap: 'wrap',
	}),
	option: (base, state) => ({
		...base,
		...reactSelectStyles.option(base, state),
		...getOverrides(styles.option, base),
	}),
	clearIndicator: base => ({
		...base,

		...getOverrides(styles.clearIndicator, base),
	}),
	multiValueRemove: base => ({
		...base,
		backgroundColor: COLORS.GREY_95,
		color: COLORS.GREY_10,
		...getOverrides(styles.multiValueRemove, base),
	}),
})

const MultiSelect = ({ field, ...props }) => {
	const processSelectOption = selectOption => selectOption.value

	const onChange = options => {
		const newValue = options.map(processSelectOption)

		if (props.disabled === true) {
			return
		}

		if (props.onChange !== undefined) {
			props.onChange(newValue)
		} else {
			props.form.setFieldValue(field.name, newValue)
		}
	}

	return (
		<Select
			shouldValidate={false}
			{...props}
			isDisabled={props.disabled}
			isMulti
			onChange={onChange}
			styles={getStyles(props.styles)}
		/>
	)
}

MultiSelect.defaultProps = {
	disabled: false,
	styles: {},
}

MultiSelect.propTypes = {
	disabled: PropTypes.bool.isRequired,
	field: PropTypes.shape({
		onChange: PropTypes.func.isRequired,
		onBlur: PropTypes.func.isRequired,
		value: PropTypes.array.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	onChange: PropTypes.func,
	styles: PropTypes.object.isRequired,
	// formik bag
	form: PropTypes.object.isRequired,
}

export default MultiSelect
