import PropTypes from 'prop-types'

export const qualificationShape = PropTypes.shape({
	name: PropTypes.string,
	isActive: PropTypes.bool,
	quotas: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			completesRatio: PropTypes.number.isRequired,
		}).isRequired,
	),
})
