import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from 'components/_scaffolding/_input/Button'

import classes from './SmallButton.module.scss'

const SmallButton = ({ preset, className, ...restOfProps }) => (
	<Button
		className={classnames(classes.button, classes[`button--${preset}`], {
			[className]: className !== null,
		})}
		{...restOfProps}
		primary={false}
	/>
)

SmallButton.PRESETS = {
	confirmation: 'confirmation',
	alert: 'alert',
}

SmallButton.propTypes = {
	preset: PropTypes.string.isRequired,
	className: PropTypes.string,
}

SmallButton.defaultProps = {
	preset: SmallButton.PRESETS.confirmation,
	className: null,
}

export default SmallButton
