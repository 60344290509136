export const validate = (module, flow, config = null) => {

  try {
    const stringFlow = JSON.stringify(flow, null, 2)
    const warnings = []
    const startsTest = new RegExp(/(\}{2}[^\{]+\{)(?!\{)/, 'gmu')
    const endTest = new RegExp(/(\{{2}[^\}]+\})(?!\})/, 'gmu')

    // match {{ } through lookahaed
    const endsMatches = stringFlow.match(endTest) || []

    // match { }} through lookahaed since lookbehind are not supported in Firefox and Edge
    // lookbehind test version is (?<!\{)\{[^\{]+\}\})
    let startsMatches = stringFlow.split('').reverse().join('').match(startsTest) || []
    startsMatches = startsMatches.map((match) => match.split('').reverse().join(''))

    const matches = [...startsMatches, ...endsMatches]

    matches.forEach((match) => {
      warnings.push('Possible invalid piping: ' + match)
    })

    return {
      infos: null,
      warnings: (warnings.length > 0) ? warnings : null,
      errors: null,
    }
  }
  catch(e) {
    return {
      infos: null,
      warnings: ["Unexpected error while checking invalid pipings in the flow"],
      errors: null,
    }
  }
}

export default validate
