const getOrderPartByPath = (order, path) => {
	let orderPart = order
	let parsedPath = path.split('_')
	parsedPath.shift()
	while (parsedPath.length) {
		orderPart = orderPart[parsedPath.shift()]
	}

	return orderPart || null
}

export default getOrderPartByPath
