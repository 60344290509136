import { enTranslator as intl } from 'intl.js'

import Icon from 'components/_scaffolding/Icon'
import { STATEMENTS_VIEW } from 'constants/reports'

export const QUANT_QUAL_LEGEND_COLUMN_NAMES = {
	SCORE: 'score',
	AGREE_SCORE: 'agreeScore',
	NEUTRAL_SCORE: 'neutralScore',
	DISAGREE_SCORE: 'disagreeScore',
	RESPONDENT_COUNT: 'respondentCount',
	NAME: 'name',
	P_VALUE: 'pValue',
}

export const QUANT_QUAL_DATA_ATTRIBUTES = {
	[QUANT_QUAL_LEGEND_COLUMN_NAMES.SCORE]: {
		label: intl.formatMessage({ id: 'quant_qual.estimated_score' }),
		value: QUANT_QUAL_LEGEND_COLUMN_NAMES.SCORE,
		resolver: isDesc => row => {
			const agreeScore = row.dummyData.agree?.coefficient ?? 0
			const neutralScore = row.dummyData.neutral?.coefficient ?? 0
			const disagreeScore = row.dummyData.disagree?.coefficient ?? 0

			return isDesc === true
				? Math.max(agreeScore, neutralScore, disagreeScore)
				: Math.min(agreeScore, neutralScore, disagreeScore)
		},
	},
	[QUANT_QUAL_LEGEND_COLUMN_NAMES.AGREE_SCORE]: {
		label: intl.formatMessage({ id: 'quant_qual.estimate_for_agrees' }),
		value: QUANT_QUAL_LEGEND_COLUMN_NAMES.AGREE_SCORE,
		resolver: () => row => row.dummyData.agree?.coefficient ?? 0,
	},
	[QUANT_QUAL_LEGEND_COLUMN_NAMES.NEUTRAL_SCORE]: {
		label: intl.formatMessage({ id: 'quant_qual.estimate_for_neutrals' }),
		value: QUANT_QUAL_LEGEND_COLUMN_NAMES.NEUTRAL_SCORE,
		resolver: () => row => row.dummyData.neutral?.coefficient ?? 0,
	},
	[QUANT_QUAL_LEGEND_COLUMN_NAMES.DISAGREE_SCORE]: {
		label: intl.formatMessage({ id: 'quant_qual.estimate_for_disagrees' }),
		value: QUANT_QUAL_LEGEND_COLUMN_NAMES.DISAGREE_SCORE,
		resolver: () => row => row.dummyData.disagree?.coefficient ?? 0,
	},
	[QUANT_QUAL_LEGEND_COLUMN_NAMES.RESPONDENT_COUNT]: {
		label: intl.formatMessage({ id: 'quant_qual.number_of_respondents_long' }),
		value: QUANT_QUAL_LEGEND_COLUMN_NAMES.RESPONDENT_COUNT,
		resolver: () => row => row.dummyData.summaryStatistics.respondentCount,
	},
	[QUANT_QUAL_LEGEND_COLUMN_NAMES.NAME]: {
		label: intl.formatMessage({ id: 'name' }),
		value: QUANT_QUAL_LEGEND_COLUMN_NAMES.NAME,
		resolver: () => row => row.labelFull,
	},
	[QUANT_QUAL_LEGEND_COLUMN_NAMES.P_VALUE]: {
		label: intl.formatMessage({ id: 'quant_qual.p_value' }),
		value: QUANT_QUAL_LEGEND_COLUMN_NAMES.P_VALUE,
		resolver: () => row => row.pValue,
	},
}

export const SORT_FIELD_OPTIONS = [
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.SCORE],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.AGREE_SCORE],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.NEUTRAL_SCORE],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.DISAGREE_SCORE],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.RESPONDENT_COUNT],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.NAME],
]

export const QUANT_QUAL_FILTER_OPTIONS = [
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.AGREE_SCORE],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.NEUTRAL_SCORE],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.DISAGREE_SCORE],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.RESPONDENT_COUNT],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.NAME],
	QUANT_QUAL_DATA_ATTRIBUTES[QUANT_QUAL_LEGEND_COLUMN_NAMES.P_VALUE],
]

// TODO
// delete SORT_ORDER_OPTIONS and DEFAULT_SORT_AND_FILTER_VALUES
export const SORT_ORDER_OPTIONS = [
	{
		label: intl.formatMessage({ id: 'ascending' }),
		value: 'asc',
		iconProps: { name: Icon.NAMES.SORT_ASCENDING },
	},
	{
		label: intl.formatMessage({ id: 'descending' }),
		value: 'desc',
		iconProps: { name: Icon.NAMES.SORT_DESCENDING },
	},
]

export const DEFAULT_SORT_AND_FILTER_VALUES = {
	answersLimit: 10,
	respondentCount: null,
	sorted: [
		{
			id: SORT_FIELD_OPTIONS[0].id,
			desc: true,
		},
	],
	statementsView: STATEMENTS_VIEW.THEMES,
}
