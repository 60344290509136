import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

const getIsRankingModule = module => {
	return (
		module.type === MODULE_DEFINITIONS.RANKING.type ||
		(module.type === MODULE_DEFINITIONS.A_SNIPPET.type &&
			module.definition.type === MODULE_DEFINITIONS.RANKING.type)
	)
}

export default getIsRankingModule
