import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import BaseSlider from 'components/_scaffolding/_input/Slider'
import { fieldShape } from 'components/_formik/_constants'

const Slider = ({ field, ...props }) => {
	const handleOnChange = (_, value) => {
		if (props.onChange !== undefined) {
			props.onChange(value)
		} else {
			props.form.setFieldValue(field.name, value)
		}
	}

	const value = _.isNil(field.value) === true ? 0 : field.value

	return <BaseSlider {...field} value={value} {...props} onChange={handleOnChange} />
}

Slider.STYLE_PRESETS = BaseSlider.STYLE_PRESETS

Slider.propTypes = {
	field: fieldShape.isRequired,
	form: PropTypes.object.isRequired,
}

export default Slider
