import refreshPaths from 'routes/_study/StudyDesign/_store/helpers/refreshPaths.js'

import addToOrder from './_toOrder'
import addToModules from './_toModules'

const addModule = (order, modules, newModuleProps) => {
	let newModules = { ...modules }
	let newOrder = [...order]
	const { path, moduleType, newModuleId, definition, additionalProperties } = newModuleProps

	newModules = addToModules(newModules, {
		moduleType,
		newModuleId,
		definition,
		additionalProperties,
	})

	newOrder = addToOrder(newOrder, {
		path,
		moduleType,
		newModuleId,
	})

	const refreshPathsResult = refreshPaths(newOrder, newModules)

	return { newModules: refreshPathsResult.modules, newOrder: refreshPathsResult.order }
}

export default addModule
