import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import classes from './DotsLoader.module.scss'

const SIZES = {
	BIG: 'big',
	SMALL: 'small',
}

const DotsLoader = ({ backgroundColor, size = null }) => {
	const styles = { backgroundColor }
	const wrapperClassName = classnames(classes.wrapper, {
		[classes[`wrapper--${size}`]]: size !== null,
	})

	return (
		<ul className={wrapperClassName}>
			<li className={classes.dot} style={styles} />
			<li className={classes.dot} style={styles} />
			<li className={classes.dot} style={styles} />
		</ul>
	)
}

DotsLoader.SIZES = SIZES

DotsLoader.propTypes = {
	size: PropTypes.oneOf(Object.values(SIZES)),
	backgroundColor: PropTypes.string,
}

export default DotsLoader
