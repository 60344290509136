import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './RadioList.module.scss'

const LIST_THEMES = {
	DARK: 'dark',
	LIGHT: 'light',
	BLUE: 'blue',
}

const RadioList = props => {
	const {
		disabled = false,
		isInlineList = false,
		listTheme = LIST_THEMES.DARK,
		onChange,
		options,
		value,
	} = props

	const handleOnChange = value => {
		if (disabled === true) {
			return
		}

		onChange(value)
	}

	const isOptionShown = option => disabled === false || option.value === value

	return (
		<div
			className={classnames(classes.wrapper, {
				[classes['wrapper--inline']]: isInlineList === true,
			})}
		>
			{options.map((option, i) => {
				return (
					isOptionShown(option) && (
						<div data-testid={option.title} key={i}>
							<div
								className={classnames(classes['option__wrapper'], {
									[classes.disabled]: disabled === true,
								})}
								onClick={() => handleOnChange(option.value)}
							>
								{disabled === false && (
									<div
										className={classnames(classes[`toggle-${listTheme}`], {
											[classes[`toggle-${listTheme}--selected`]]: option.value === value,
										})}
									>
										<div
											className={classnames({
												[classes[`selected-${listTheme}`]]: option.value === value,
											})}
										/>
									</div>
								)}
								<div className={classes[`label-${listTheme}`]}>{option.title}</div>
							</div>
							{option.description !== undefined && option.description.length > 0 && (
								<div
									className={classnames(classes.description, {
										[classes['description__disabled']]: disabled === true,
									})}
									key={`${option.value}-description`}
								>
									{option.description}
								</div>
							)}
						</div>
					)
				)
			})}
		</div>
	)
}

RadioList.LIST_THEMES = LIST_THEMES

RadioList.propTypes = {
	disabled: PropTypes.bool,
	isInlineList: PropTypes.bool,
	listTheme: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string,
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
		}).isRequired,
	).isRequired,
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
}

export default RadioList
