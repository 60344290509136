import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classnames from 'classnames'

import AdvancedFeatureLabel from 'components/_scaffolding/AdvancedFeatureLabel'
import Badge from 'components/_scaffolding/Badge'
import Link from 'components/_scaffolding/_links/Link'
import Tooltip from 'components/_scaffolding/Tooltip'

import classes from './Tab.module.scss'

const Tab = ({
	handleTabClick,
	hasError,
	idProductTour,
	idTab,
	isActive,
	isBeta,
	isDisabled,
	isInternal,
	label,
	linkTo,
	secondaryLabel,
	tooltipText,
}) => {
	const handleClick = () => {
		if (isDisabled === true) {
			return
		}

		handleTabClick(idTab)
	}

	const WrapperComponent = linkTo === null ? Fragment : Link

	const wrapperProps =
		linkTo === null ? {} : { to: linkTo, className: classes.wrapper, isDisabled, tooltipText }

	return (
		<WrapperComponent {...wrapperProps}>
			<div
				className={classnames(classes.tab, {
					[classes['tab--active']]: isActive === true,
					[classes['tab--disabled']]: isDisabled === true,
				})}
				data-testid={idTab}
				onClick={handleClick}
				data-for={idTab}
				data-tip={tooltipText}
				data-tip-disable={isDisabled !== true}
				data-place="bottom"
				data-intercom-target={idProductTour}
			>
				{label}
				{secondaryLabel ?? null}
				{hasError === true && <div className={classes.tab__error} />}
				{isBeta === true && <Badge preset={Badge.PRESETS.beta} />}
				{isInternal === true && <AdvancedFeatureLabel />}
			</div>
			<Tooltip id={idTab} />
		</WrapperComponent>
	)
}

Tab.defaultProps = {
	hasError: false,
	isBeta: false,
	idProductTour: null,
	isDisabled: false,
	isInternal: false,
	linkTo: null,
	secondaryLabel: null,
	tooltipText: null,
}

Tab.propTypes = {
	handleTabClick: PropTypes.func.isRequired,
	hasError: PropTypes.bool,
	idProductTour: PropTypes.string,
	idTab: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	isBeta: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isInternal: PropTypes.bool,
	label: PropTypes.string.isRequired,
	linkTo: PropTypes.string,
	secondaryLabel: PropTypes.element,
	tooltipText: PropTypes.string,
}

export default Tab
