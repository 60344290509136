import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

export const setActionButtonIds = actionButton => ({ ...actionButton, id: uuidv4() })

export const setMediaIds = media => ({
	...media,
	id: uuidv4(),
	actionButton: setActionButtonIds(media.actionButton),
	render: { ...media.render },
})

export const setChoiceOptionIds = (option, moduleDefinition) => ({
	...option,
	// keep ids if we're copying question with filtered options
	id:
		_.isNil(moduleDefinition.filter) === false && moduleDefinition.filter !== ''
			? option.id
			: uuidv4(),
	media: option.media === null ? null : setMediaIds(option.media),
})

export const setQuestionIds = question => ({
	...question,
	id: uuidv4(),
	idRelatedMessage: uuidv4(),
	media: setMediaIds(question.media),
})

const setUniqueIdsInDefinition = definition => {
	const duplicateDefinition = { ...definition }

	if (_.isNil(definition.options) === false) {
		duplicateDefinition.options = definition.options.map(option =>
			setChoiceOptionIds(option, definition),
		)
	}

	if (_.isNil(definition.media) === false) {
		duplicateDefinition.media = setMediaIds(definition.media)
	}

	if (_.isNil(definition.sharedMessage) === false) {
		duplicateDefinition.sharedMessage = setQuestionIds(definition.sharedMessage)
	}

	if (_.isNil(definition.questions) === false) {
		duplicateDefinition.questions = definition.questions.map(setQuestionIds)
	}

	// TODO
	// unit tests
	if (_.isNil(definition.messages) === false) {
		duplicateDefinition.messages = definition.messages.map(message => ({
			...message,
			definition: {
				...message.definition,
				media: setMediaIds(message.definition.media),
				id: uuidv4(),
			},
		}))
	}

	return duplicateDefinition
}

export default setUniqueIdsInDefinition
