import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './OverlayMessage.module.scss'

const OverlayMessage = ({ children, className }) => {
	return (
		<div className={classnames(classes.message, { [className]: className != null })}>
			{children}
		</div>
	)
}

OverlayMessage.defaultProps = {
	className: null,
}

OverlayMessage.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	className: PropTypes.string,
}

export default OverlayMessage
