import { enTranslator as intl } from 'intl.js'

import Ranking from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Ranking'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_OPTION } from './helpers/CHOICE_OPTION'
import { VISUAL_FLOW_MODULE_TYPES, RANKING_SUBTYPE } from 'constants/studyDesign'
import { GET_QUESTION_MESSAGE } from './MESSAGE'
import { getDefaultDynamicOptionsSettings } from './CHOICE'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.RANKING,
	style: 'ranking',
	title: () => 'ranking.title',
	description: () => 'ranking.description',
	component: Ranking,
	generator: (modules, additionalProperties) => ({
		messages: [GET_QUESTION_MESSAGE()],
		filter: '',
		idRelatedMessage: null,
		requireUniqueValues: true,
		mandatory: true,
		subtype: RANKING_SUBTYPE.RANKING_DRAG_AND_DROP,
		dynamicOptionsSettings: getDefaultDynamicOptionsSettings(),
		options: [
			CHOICE_OPTION(
				RANKING_SUBTYPE.RANKING_DRAG_AND_DROP,
				false,
				1,
				intl.formatMessage({ id: 'ranking.option.default_label' }, { index: 1 }),
				true,
			),
			CHOICE_OPTION(
				RANKING_SUBTYPE.RANKING_DRAG_AND_DROP,
				false,
				2,
				intl.formatMessage({ id: 'ranking.option.default_label' }, { index: 2 }),
				true,
			),
		],
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'ranking.title' }),
			VISUAL_FLOW_MODULE_TYPES.RANKING,
		),
	}),
}
