import _ from 'lodash'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { STUDY_DETAIL } from 'gql/queries/study'
import { PATHS } from 'constants/router'

import LoadingStatus from 'components/_scaffolding/LoadingStatus'

import { setActiveAccount } from 'sessionStorage/idActiveAccount'

export const useActiveStudyQuery = () => {
	const location = useLocation()
	const { studyName: idStudy } = useParams()

	const isStudyRoute =
		location.pathname.startsWith(PATHS.STUDY) || location.pathname.startsWith(PATHS.REPORT_EXPORT)

	const preparedQuery = STUDY_DETAIL(idStudy)

	const result = useQuery(preparedQuery.query, {
		...preparedQuery,
		skip: isStudyRoute === false || _.isNil(idStudy),
	})

	const { data, loading, error } = result

	const LoaderElement = loading === true ? <LoadingStatus loading /> : null
	const ErrorElement = error !== undefined ? <LoadingStatus error /> : null
	const study = _.get(data, 'study', null)

	const idAccount = study?.idAccount ?? null

	if (idAccount !== null) {
		setActiveAccount(idAccount)
	}

	return {
		ErrorElement,
		LoaderElement,
		result,
		study,
	}
}
