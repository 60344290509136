const localStoragePolyfill = () => {
	// Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
	// throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
	// to avoid the entire page breaking, without having to do a check at each usage of Storage.
	if (typeof localStorage === 'object') {
		try {
			localStorage.setItem('localStorageSupport', 1)
			localStorage.removeItem('localStorageSupport')
			// eslint-disable-next-line no-console
			console.info('Local storage supported')
		} catch (e) {
			Storage.prototype._setItem = Storage.prototype.setItem
			Storage.prototype.setItem = () => {}
			Storage.polyfill = true
			// eslint-disable-next-line no-console
			console.warn(
				'LocalStorage not supported',
				`
				Your web browser does not support storing settings locally.
				In Safari, the most common cause of this is using "Private Browsing Mode".
				Some settings may not save or some features may not work properly for you.
			`,
			)
		}
	}
}

export default localStoragePolyfill()
