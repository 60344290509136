import _ from 'lodash'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

const isOptionsFilterValid = module => {
	const filter =
		module.type === VISUAL_FLOW_MODULE_TYPES.A_CHOICE ||
		module.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE
			? _.get(module, 'definition.filter.variable', '')
			: module.definition.filter

	return filter.includes(' ') === false
}

export default isOptionsFilterValid
