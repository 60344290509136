import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const RestoreStudy = lazy(() => import('./RestoreStudy'))
const WrappedRestoreStudy = errorWrapper(RestoreStudy)

export default () => ({
	path: 'restore',
	element: <WrappedRestoreStudy />,
})
