import { enTranslator as intl } from 'intl.js'

import MaxDiff from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/MaxDiff'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { CHOICE_SUBTYPE, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import { CHOICE_OPTION } from './helpers/CHOICE_OPTION'
import { GET_QUESTION_MESSAGE } from './MESSAGE'

const defaultOptionsIndices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const NUMBER_OF_ITEMS_PER_ROUND = 3

export default {
	type: VISUAL_FLOW_MODULE_TYPES.MAXDIFF,
	style: 'max-diff',
	title: definition =>
		definition.subtype === CHOICE_SUBTYPE.CHECKBOX ? 'maxDiff.title' : 'maxDiff.image.title',
	description: () => 'maxDiff.description',
	component: MaxDiff,
	generator: (modules, additionalProperties) => ({
		idRelatedMessage: null,
		mandatory: true,
		options: defaultOptionsIndices.map(index =>
			CHOICE_OPTION(
				CHOICE_SUBTYPE.CHECKBOX,
				false,
				index,
				intl.formatMessage({ id: 'maxDiff.option.default_label' }, { index: index + 1 }),
				true,
			),
		),
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'maxDiff.title' }),
			VISUAL_FLOW_MODULE_TYPES.MAXDIFF,
		),
		numberOfItemsPerRound: NUMBER_OF_ITEMS_PER_ROUND,
		messages: [],
		bestQuestion: GET_QUESTION_MESSAGE('maxDiff.detail.best_question.placeholder'),
		worstQuestion: GET_QUESTION_MESSAGE('maxDiff.detail.worst_question.placeholder'),
		subtype: CHOICE_SUBTYPE.CHECKBOX,
		blurBetweenRounds: true,
	}),
}
