import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'
import validateDatasetMessages from './_validateDatasetMessages'
import validateRelatedMessage from './_validateRelatedMessage'
import {
	validateChoiceOptions,
	hasInvalidListSettings,
	hasInvalidListSelected,
	hasInvalidListColumnSelected,
} from './validateChoice'

const validateRanking = (module, modules, orderModule, flatOrder, studyTags) => {
	const validationResult = []

	validationResult.push(...validateDatasetMessages(module, studyTags))
	validationResult.push(...validateRelatedMessage(module, modules, studyTags))

	if (hasInvalidListSettings(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_incomplete_dynamic_options_settings,
		})

		return validationResult
	}

	if (hasInvalidListSelected(module, modules, orderModule, flatOrder) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalid_list_selected,
		})

		return validationResult
	}

	if (hasInvalidListColumnSelected(module, modules) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalid_list_column_selected,
		})

		return validationResult
	}

	validationResult.push(...validateChoiceOptions(module))

	return validationResult
}

export default validateRanking
