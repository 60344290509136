const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	$id: 'http://groupsolver.com/v1/MAXDIFF.json',
	type: 'object',
	additionalProperties: false,
	definitions: {},
	required: [
		'idRelatedMessage',
		'mandatory',
		'options',
		'subtype',
		'messages',
		'bestQuestion',
		'worstQuestion',
		'numberOfItemsPerRound',
		'blurBetweenRounds',
	],
	properties: {
		idRelatedMessage: {
			$id: '/properties/idRelatedMessage',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: ['string', 'null'],
		},
		mandatory: {
			default: true,
			$id: '/properties/mandatory',
			type: 'boolean',
		},
		options: {
			additionalItems: false,
			$id: '/properties/options',
			items: {
				additionalProperties: false,
				$id: '/properties/options/items',
				properties: {
					id: {
						$id: '/properties/options/items/properties/id',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
						type: 'string',
					},
					code: {
						$id: '/properties/options/items/properties/code',
						type: 'integer',
					},
					showLabel: {
						$id: '/properties/options/items/properties/showLabel',
						type: 'boolean',
						default: true,
					},
					label: {
						$id: '/properties/options/items/properties/label',
						type: 'string',
					},
					media: {
						$id: '/properties/options/items/properties/media',
						oneOf: [{ $ref: 'http://groupsolver.com/v1/MEDIA_OBJECT.json' }, { type: 'null' }],
					},
					randomize: {
						$id: '/properties/options/items/properties/randomize',
						type: 'boolean',
						default: false,
					},
					isNoneOfThese: {
						default: false,
						$id: '/properties/options/items/properties/isNoneOfThese',
						type: 'boolean',
					},
					simpleName: {
						$id: '/properties/options/items/properties/simpleName',
						type: ['string', 'null'],
					},
					isHidden: {
						default: false,
						$id: '/properties/options/items/properties/isHidden',
						type: 'boolean',
					},
				},
				required: [
					'code',
					'id',
					'isNoneOfThese',
					'label',
					'media',
					'randomize',
					'showLabel',
					'simpleName',
					'isHidden',
				],
				type: 'object',
			},
			minItems: 1,
			type: 'array',
			uniqueItems: true,
		},
		subtype: {
			$id: '/properties/subtype',
			enum: ['CHECKBOX', 'SELECT', 'IMAGE', 'BLOCK'],
			type: 'string',
		},
		messages: {
			$id: '/properties/messages',
			type: 'array',
			additionalItems: false,
			uniqueItems: true,
			items: {
				$id: '/properties/messages/items',
				type: 'object',
				additionalProperties: false,
				required: ['id', 'definition', 'type'],
				properties: {
					id: {
						$id: '/properties/messages/items/id',
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					type: {
						$id: '/properties/messages/items/type',
						type: 'string',
						enum: ['A_MESSAGE'],
					},
					definition: {
						$id: '/properties/messages/items/definition',
						$ref: 'http://groupsolver.com/v1/MESSAGE.json',
					},
				},
			},
		},
		bestQuestion: {
			$id: '/properties/bestQuestion',
			type: 'object',
			additionalProperties: false,
			required: ['id', 'definition', 'type'],
			properties: {
				id: {
					$id: '/properties/bestQuestion/id',
					type: 'string',
					pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
				},
				type: {
					$id: '/properties/bestQuestion/type',
					type: 'string',
					enum: ['A_MESSAGE'],
				},
				definition: {
					$id: '/properties/bestQuestion/definition',
					$ref: 'http://groupsolver.com/v1/MESSAGE.json',
				},
			},
		},
		worstQuestion: {
			$id: '/properties/worstQuestion',
			type: 'object',
			additionalProperties: false,
			required: ['id', 'definition', 'type'],
			properties: {
				id: {
					$id: '/properties/worstQuestion/id',
					type: 'string',
					pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
				},
				type: {
					$id: '/properties/worstQuestion/type',
					type: 'string',
					enum: ['A_MESSAGE'],
				},
				definition: {
					$id: '/properties/worstQuestion/definition',
					$ref: 'http://groupsolver.com/v1/MESSAGE.json',
				},
			},
		},
		numberOfItemsPerRound: {
			$id: '/properties/numberOfItemsPerRound',
			type: 'integer',
		},
		blurBetweenRounds: {
			$id: '/properties/blurBetweenRounds',
			type: 'boolean',
		},
	},
}

export default schema
