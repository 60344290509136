import config from 'config'

export const BUCKET_NAMES = {
	PROJECT: 'projectData',
	DASHBOARD: 'dashboardData',
}

export const BUCKET_URLS = {
	[BUCKET_NAMES.PROJECT]: config.publicProjectDataBucketUrl,
	[BUCKET_NAMES.DASHBOARD]: config.publicDashboardDataBucketUrl,
}

export const PROJECT_BUCKET_DIRECTORIES = {
	STATIC: '/static',
	STUDY: '/study',
	PRESENTATION: '/presentation',
}
