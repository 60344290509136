import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { logError } from 'helpers/logError'

import Button from 'components/_scaffolding/_input/Button'

import classes from './ErrorWrapper.module.scss'

export default function withErrorWrapper(WrappedComponent) {
	const ErrorWrapperHOC = class ErrorWrapper extends Component {
		state = { error: null }

		static getDerivedStateFromError(error) {
			return { error }
		}

		componentDidCatch(error, errorInfo) {
			// eslint-disable-next-line
			console.error('Error from ErrorWrapper component:', error)

			logError(error, {
				component: WrappedComponent,
				componentProps: this.props,
				reactErrorInfo: errorInfo,
			})
		}

		handleTryAgainClick = () => {
			window.location.reload()
		}

		render() {
			const { intl, ...props } = this.props

			if (this.state.error !== null) {
				return (
					<div className={classes.wrapper}>
						<div className={classes['error-icon']} />
						<div className={classes.message__holder}>
							<div className={classes.message__title}>
								{intl.formatMessage({ id: 'request_error_notification_title' })}
							</div>
							<div className={classes.message__text}>
								{intl.formatMessage({ id: 'request_error_notification_short' })}
								<br />
								{intl.formatMessage({ id: 'study_loading_error' })}
							</div>
						</div>
						<Button
							title={intl.formatMessage({ id: 'try_again' })}
							onClick={this.handleTryAgainClick}
						/>
					</div>
				)
			}

			return <WrappedComponent {...props} />
		}
	}

	ErrorWrapperHOC.displayName = `withErrorWrapper(${WrappedComponent.displayName ||
		WrappedComponent.name})`

	return injectIntl(ErrorWrapperHOC)
}
