import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'

export const getLeftObjectForAllocationAndRanking = (selectionType, options) => {
	const idOption = options[0].value

	if (selectionType === CONDITION_SELECTION_TYPES.IN_RANGE) {
		return {
			idOption,
			expectedValue: {
				from: '',
				to: '',
			},
		}
	}

	return {
		idOption,
		expectedValue: '',
	}
}
