import {
	VISUAL_FLOW_INVALID_MODULE_TYPES,
	VISUAL_FLOW_MODULE_TYPES,
	CHOICE_SUBTYPE,
} from 'constants/studyDesign'
import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'
import { uuidRegex } from 'constants/regex'
import { normalizeInput } from 'helpers/string/normalizeInput'
import { getVisibleOptions } from 'helpers/visualFlowModules/getVisibleOptions'

import getUpperModuleIds from 'routes/_study/StudyDesign/_store/helpers/getUpperModuleIds'

import validateRelatedMessage from './_validateRelatedMessage'
import validateDatasetMessages from './_validateDatasetMessages'
import isOptionsFilterValid from './_isOptionsFilterValid'

/***
 * single option helpers
 */
export const hasDuplicateLabel = (option, allOptions) =>
	allOptions.filter(opt => normalizeInput(opt.label) === normalizeInput(option.label)).length > 1

export const hasDuplicateSimpleName = (option, allOptions) =>
	allOptions.filter(
		opt =>
			normalizeInput(opt.simpleName) === normalizeInput(option.simpleName) &&
			normalizeInput(option.simpleName) !== '',
	).length > 1

export const hasDuplicateId = (option, allOptions) =>
	allOptions.filter(opt => opt.id === option.id).length > 1

export const hasInvalidId = option => uuidRegex.test(option.id) === false

/***
 * validation functions
 */
export const hasInvalidImageOption = module =>
	module.definition.subtype === CHOICE_SUBTYPE.IMAGE &&
	module.definition.options.some(option => option.media.url.trim() === '')

export const hasEmptyChoiceOption = module =>
	module.definition.options.some(option => option.label.trim() === '')

export const hasNonUniqueOptionLabels = module =>
	module.definition.options.find(option => hasDuplicateLabel(option, module.definition.options)) !==
	undefined

export const hasNonUniqueOptionSimpleNames = module =>
	module.definition.options.find(option =>
		hasDuplicateSimpleName(option, module.definition.options),
	) !== undefined

const hasNonUniqueOptionIds = module =>
	module.definition.options.find(option => hasDuplicateId(option, module.definition.options)) !==
	undefined

const hasOptionWithMissingCode = module =>
	module.definition.options.some(
		option => option.code === undefined || String(option.code).length === 0,
	)

const hasInvalidOptionId = module =>
	module.definition.options.some(option => hasInvalidId(option) === true)

export const hasInvalidOptionsFilter = module =>
	module.definition.filter !== null && isOptionsFilterValid(module) === false

const hasInvalidTrapQuestionSettings = module => {
	const { trapQuestionSettings, maxSelection, minSelection } = module.definition

	if (trapQuestionSettings.isTrapQuestion === false) {
		return false
	}

	if (trapQuestionSettings.correctAnswers.length === 0) {
		return true
	}

	if (
		trapQuestionSettings.selection === CONDITION_SELECTION_TYPES.EVERY_OF &&
		trapQuestionSettings.correctAnswers.length > maxSelection
	) {
		return true
	}

	if (
		trapQuestionSettings.selection === CONDITION_SELECTION_TYPES.EQUAL_TO &&
		(trapQuestionSettings.correctAnswers.length > maxSelection ||
			trapQuestionSettings.correctAnswers.length < minSelection)
	) {
		return true
	}

	const options = getVisibleOptions(module.definition.options)

	return trapQuestionSettings.correctAnswers.some(
		idOption => options.find(option => option.id === idOption) === undefined,
	)
}

export const hasInvalidListSettings = module => {
	if (module.definition.dynamicOptionsSettings.isActive === false) {
		return false
	}

	return (
		module.definition.dynamicOptionsSettings.idInputList === null ||
		module.definition.dynamicOptionsSettings.optionLabelColumn === null
	)
}

export const hasInvalidListSelected = (module, modules, orderModule, flatOrder) => {
	if (module.definition.dynamicOptionsSettings.isActive === false) {
		return false
	}

	const idsUpperModules = getUpperModuleIds(orderModule.path, flatOrder, modules)

	if (
		idsUpperModules.some(id => id === module.definition.dynamicOptionsSettings.idInputList) ===
		false
	) {
		return true
	}

	const selectedModule = modules[module.definition.dynamicOptionsSettings.idInputList]

	if (selectedModule === undefined) {
		return true
	}

	if (selectedModule.type !== VISUAL_FLOW_MODULE_TYPES.LIST) {
		return true
	}

	if (selectedModule.isHidden === true) {
		return true
	}

	return false
}

export const hasInvalidListColumnSelected = (module, modules) => {
	if (module.definition.dynamicOptionsSettings.isActive === false) {
		return false
	}

	const selectedList = modules[module.definition.dynamicOptionsSettings.idMainList]

	if (selectedList === undefined) {
		return true
	}

	const selectedColumn = selectedList.definition.columns.find(
		column => column.key === module.definition.dynamicOptionsSettings.optionLabelColumn,
	)

	return selectedColumn === undefined || selectedColumn.isUnique === false
}

export const validateChoiceOptions = module => {
	const validationResult = []

	if (hasInvalidImageOption(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_noImage,
		})
	}

	if (hasEmptyChoiceOption(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_emptyOptions,
		})
	}

	if (hasNonUniqueOptionLabels(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_nonUniqueOptions,
		})
	}

	if (hasNonUniqueOptionSimpleNames(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_nonUniqueSimpleNames,
		})
	}

	if (hasOptionWithMissingCode(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_missingOptionCode,
		})
	}

	if (hasNonUniqueOptionIds(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_nonUniqueOptionIds,
		})
	}

	if (hasInvalidOptionId(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalidOptionId,
		})
	}

	return validationResult
}

const validateChoice = (module, modules, orderModule, flatOrder, studyTags) => {
	const validationResult = []

	validationResult.push(...validateDatasetMessages(module, studyTags))
	validationResult.push(...validateRelatedMessage(module, modules, studyTags))

	if (hasInvalidOptionsFilter(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalidFilter,
		})
	}

	if (hasInvalidTrapQuestionSettings(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalidTrapQuestionSettings,
		})
	}

	if (hasInvalidListSettings(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_incomplete_dynamic_options_settings,
		})

		return validationResult
	}

	if (hasInvalidListSelected(module, modules, orderModule, flatOrder) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalid_list_selected,
		})

		return validationResult
	}

	if (hasInvalidListColumnSelected(module, modules) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.choice_invalid_list_column_selected,
		})

		return validationResult
	}

	validationResult.push(...validateChoiceOptions(module))

	return validationResult
}

export default validateChoice
