import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const RespondentManager = lazy(() => import('./RespondentManager'))
const WrappedRespondentManager = errorWrapper(RespondentManager)

export default () => ({
	path: 'respondent-manager',
	element: <WrappedRespondentManager />,
})
