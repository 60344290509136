const copyToClipboard = valueToCopy => {
	if (valueToCopy === null) return

	const textareaForCopy = document.createElement('textarea')
	document.body.appendChild(textareaForCopy)

	textareaForCopy.value = valueToCopy
	textareaForCopy.select()

	document.execCommand('Copy')

	textareaForCopy.remove()
}

export default copyToClipboard
