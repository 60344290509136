import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'

export const freeTextStringsConditionRules = [
	{
		value: CONDITION_SELECTION_TYPES.STRING_EQUAL_TO,
		idTranslation: 'condition.equal_to',
	},
	{
		value: CONDITION_SELECTION_TYPES.CONTAINS,
		idTranslation: 'condition.contains',
	},
	{
		value: CONDITION_SELECTION_TYPES.STARTS_WITH,
		idTranslation: 'condition.starts_with',
	},
	{
		value: CONDITION_SELECTION_TYPES.ENDS_WITH,
		idTranslation: 'condition.ends_with',
	},
	{
		value: CONDITION_SELECTION_TYPES.REGEXP,
		idTranslation: 'condition.regexp',
	},
	{
		value: CONDITION_SELECTION_TYPES.STRING_ANY_OF,
		idTranslation: 'condition.any_of',
	},
]

export const getFreeTextStringsConditionSelect = intl =>
	freeTextStringsConditionRules.map(rule => ({
		value: rule.value,
		label: intl.formatMessage({ id: rule.idTranslation }),
	}))
