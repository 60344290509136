// TODO: Recheck and possibly refactor this mess.
const prepareLines = (words, max) => {
	const longestWord = words.reduce((a, b) => (a.length > b.length ? a : b))
	const lineMaxLength = longestWord.length > max ? longestWord.length + 1 : max
	// minimum line length is 4 characters
	const lineMinLength = max - 10 > 4 ? max - 10 : 4

	const lines = []

	let lineIndex = 0
	let lineLength = 0
	let wordIndex = 0

	words.forEach(word => {
		if (typeof lines[lineIndex] === 'undefined') {
			lines[lineIndex] = []
		}

		lines[lineIndex][wordIndex] = word
		lineLength += word.length
		wordIndex += 1

		// word does not fit
		if (lineLength > lineMaxLength) {
			if (typeof lines[lineIndex + 1] === 'undefined') {
				lines[lineIndex + 1] = []
			}

			// do not create new line for 1 short word
			if ((words.indexOf(word) === words.length - 1 && word.length < lineMinLength) === false) {
				lines[lineIndex].pop()
				lines[lineIndex + 1].push(word)
				lineLength = word.length + 1
				lineIndex += 1
				wordIndex = 1
			}
		} else {
			// word fits in line, add spacebar to line length
			lineLength += 1
		}
	})

	return lines.map(line => line.join(' '))
}

const getOptimalCombination = (
	words,
	lineCombinations,
	lengthRangeFrom,
	lengthRangeTo,
	lengthLimit,
) => {
	for (let i = lengthRangeFrom; i < lengthRangeTo; i++) {
		lineCombinations.push(prepareLines(words, i))
	}

	const lengthDifferences = lineCombinations.map(lines => {
		const lineLengths = lines.map(line => line.length)
		const longest = Math.max(...lineLengths)
		const shortest = Math.min(...lineLengths)

		return longest - shortest
	})

	const optimalIndex = lengthDifferences.indexOf(Math.min(...lengthDifferences))
	const optimalCombination = lineCombinations[optimalIndex]

	// get lengths of lines in the best line combination
	const lineLengths = optimalCombination.map(line => line.length)
	const longestLine = Math.max(...lineLengths)
	const shortestLine = Math.min(...lineLengths)

	const hasTooMuchWhitespace = shortestLine < (longestLine / 4) * 3

	// try again with longer lines
	if (hasTooMuchWhitespace && lengthRangeTo < lengthLimit) {
		return getOptimalCombination(
			words,
			lineCombinations,
			lengthRangeTo,
			lengthRangeTo + 5,
			lengthLimit,
		)
	}

	return optimalCombination
}

export const createLines = (text, lengthRangeTo = 51, lengthLimit = 71) => {
	const lengthRangeFrom = 15
	const words = text.split(/\s+/)

	return getOptimalCombination(words, [], lengthRangeFrom, lengthRangeTo, lengthLimit)
}
