import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'

export const getConditionSelectionTypeSelectOptions = intl => [
	{
		value: CONDITION_SELECTION_TYPES.EQUAL_TO,
		label: intl.formatMessage({ id: 'condition.exactly' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.ANY_OF,
		label: intl.formatMessage({ id: 'condition.any_of' }),
	},
	{
		value: CONDITION_SELECTION_TYPES.EVERY_OF,
		label: intl.formatMessage({ id: 'condition.every_of' }),
	},
]
