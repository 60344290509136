export const validate = (module, flow, config = null) => {
	if (
		module.definition.media &&
		module.definition.media.type === 'A_IMAGE' &&
		module.definition.media.render &&
		(module.definition.media.render.forceFullscreen === true ||
			module.definition.media.render.blurBefore === true) &&
		module.definition.media.actionButton === null
	) {
		return {
			infos: null,
			warnings: null,
			errors: [
				'Action button cannot be null if forceFullscreen or blurBefore are true.',
			],
		}
	}

	return null
}

export default validate
