import _ from 'lodash'

import { tools } from 'constants/tools'
import { COLORS } from 'constants/colors'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getStatementThemeName } from 'helpers/statementThemes'
import { getStatementsWithoutThemes } from 'helpers/reports/getStatementsWithoutThemes'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { makeStatementsObject } from 'helpers/makeStatementsObject'

import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'

const getAnswerWithResponses = (statementObject, statementSupportBlob, sunburstBlob) => {
	const { idStatement, groupedStatements, label } = statementObject

	const responses = []

	if (_.isNil(sunburstBlob) === false) {
		const answers = groupedStatements === null ? [{ idStatement }] : groupedStatements

		for (const answer of answers) {
			const answerFromBlob = sunburstBlob.answers[answer.idStatement]
			if (_.isNil(answerFromBlob) === true) {
				throw new Error(
					"Sunburst Blob data don't match with study object data. Try triggering (base) recalc on study.",
				)
			}
			responses.push(...answerFromBlob.responses)
		}
	}
	const statementSupportObject = statementSupportBlob.statementSupports.find(
		statementSupport => statementSupport.idStatement === idStatement,
	)

	const support = statementSupportObject?.expectedSupport ?? '-'

	return {
		idStatement,
		label,
		responses: responses,
		support,
		isGroupedAnswer: groupedStatements !== null,
	}
}

const calculateSunburstIdeas = (
	statementSupportBlob,
	themeSupportBlob,
	sunburstBlob,
	studyObjectData,
	slideSettings,
) => {
	if (studyObjectData === undefined) {
		return []
	}

	const validStatements = studyObjectData.statements.filter(
		statement => statement.isInvalid === false,
	)

	const { customColors } = slideSettings

	const statementsObject = makeStatementsObject(validStatements, studyObjectData.statementGroups)

	const themedAnswers = studyObjectData.statementThemes.map(theme => {
		const { color, idStatementTheme, statements: themeStatements } = theme
		const themeName = getStatementThemeName(theme, true)

		const customColor = _.get(customColors, idStatementTheme, null)

		return {
			answers: themeStatements
				.filter(idStatement => statementsObject[idStatement] !== undefined)
				.map(idStatement =>
					getAnswerWithResponses(statementsObject[idStatement], statementSupportBlob, sunburstBlob),
				),
			color: customColor ?? color,
			hasCustomColor: customColor !== null,
			idStatementTheme,
			support: themeSupportBlob.themeSupport[idStatementTheme]?.expectedSupport ?? '-',
			themeName,
		}
	})

	// add unthemed answers
	const statementsWithoutThemes = getStatementsWithoutThemes(
		validStatements,
		studyObjectData.statementGroups,
		studyObjectData.statementThemes,
	)

	const ID_UNTHEMED_ANSWERS = 'ID_UNTHEMED_ANSWERS'

	const customUnthemedColor = _.get(customColors, ID_UNTHEMED_ANSWERS, null)

	const allIdeas = [
		...themedAnswers,
		{
			answers: statementsWithoutThemes.map(statement =>
				getAnswerWithResponses(
					statementsObject[statement.idStatement],
					statementSupportBlob,
					sunburstBlob,
				),
			),
			color: customUnthemedColor ?? COLORS.GREY_90,
			hasCustomColor: customUnthemedColor !== null,
			idStatementTheme: ID_UNTHEMED_ANSWERS,
			support: null,
			themeName: null,
		},
	]

	return allIdeas
}

/**
 * Derivators
 */
export const calculateLegendIdeas = (
	idStudy,
	reportType,
	studyObjectData,
	slideSettings,
	_legendState,
	blobData,
) => {
	if (reportType !== REPORT_TYPES.SUNBURST) {
		return []
	}

	if (slideSettings === undefined) {
		return []
	}

	const { idStudyObject } = slideSettings

	const totalSegmentStatementsData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const totalSegmentThemesData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (
		hasBlobData([totalSegmentStatementsData], SUPPORT_KEYS.STATEMENT_SUPPORTS) === false ||
		hasBlobData([totalSegmentThemesData], SUPPORT_KEYS.THEME_SUPPORT) === false
	) {
		return []
	}

	const sunburstBlobData =
		blobData[
			getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.SUNBURST, tools.TOTAL_SEGMENT_UUID)
		]

	const sunburstData =
		hasBlobData([sunburstBlobData], 'answers') === false ? null : sunburstBlobData

	const ideas = calculateSunburstIdeas(
		totalSegmentStatementsData,
		totalSegmentThemesData,
		sunburstData,
		studyObjectData,
		slideSettings,
	)

	return ideas
}
