const IGNORED_CSV_ERRORS = ['UndetectableDelimiter']

export const hasCsvError = (errors, isSingleColumnFile) => {
	if (isSingleColumnFile === false) {
		return errors.length > 0
	}

	if (errors.length === 0) {
		return false
	}

	/**
	 * NOTE: Single column files will always have at least one error
	 * because papaparse can not determine the delimiter (since it doesn't exist).
	 * That's why, when we encounter "UndetectableDelimiter" error code in single column
	 * file we actually don't mind it and continue on with the upload.
	 */
	if (errors.length > 1) {
		return true
	}

	if (IGNORED_CSV_ERRORS.includes(errors[0].code) === false) {
		return true
	}

	return false
}
