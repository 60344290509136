import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import Label from 'components/_formik/_base/Label'

import classes from './CodeLabel.module.scss'

const CodeLabel = ({ label, isCodeValid }) => (
	<Label
		label={label}
		className={classnames(classes['code-label-valid'], {
			[classes['code-label-invalid']]: isCodeValid === false,
		})}
	/>
)

CodeLabel.propTypes = {
	label: PropTypes.string.isRequired,
	isCodeValid: PropTypes.bool.isRequired,
}

export default CodeLabel
