import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import Icon from 'components/_scaffolding/Icon'

import classes from './CheckmarkCheckbox.module.scss'

const CheckmarkCheckbox = ({
	checkboxWrapperClassName,
	checked,
	className,
	disabled,
	label,
	labelClassName,
	markOnLabelClick,
	name,
	onChange,
	tooltip,
	big,
}) => {
	const handleOnChange = e => {
		if (disabled === true) return

		return onChange(name, checked === false)
	}

	const wrapperClassName = classnames(classes.wrapper, {
		[classes.big]: big === true,
		[checkboxWrapperClassName]: checkboxWrapperClassName !== null,
	})

	const checkboxClassName = classnames(classes.checkbox, {
		[classes.big]: big === true,
		[classes['checkbox--checked']]: checked === true,
		[classes['checkbox--disabled']]: disabled === true,
		[className]: className !== null,
	})

	const labelClassNames = classnames(classes.label, {
		[labelClassName]: labelClassName !== null,
		[classes['label--disabled']]: disabled === true,
		[classes['label--unmarkable']]: markOnLabelClick === false,
	})

	return (
		<div className={wrapperClassName}>
			<div className={checkboxClassName} onClick={handleOnChange} data-testid={name} id={name}>
				{checked === true && <Icon name={Icon.NAMES.DONE} size={big === true ? 24 : 20} />}
			</div>

			{_.isNil(label) === false && (
				<div
					className={labelClassNames}
					onClick={markOnLabelClick === true ? handleOnChange : _.noop}
					title={tooltip}
				>
					{label}
				</div>
			)}
		</div>
	)
}

CheckmarkCheckbox.defaultProps = {
	checkboxWrapperClassName: null,
	className: null,
	disabled: false,
	label: null,
	labelClassName: null,
	markOnLabelClick: true,
	tooltip: null,
	big: false,
}

CheckmarkCheckbox.propTypes = {
	checkboxWrapperClassName: PropTypes.string,
	checked: PropTypes.bool.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	labelClassName: PropTypes.string,
	markOnLabelClick: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	tooltip: PropTypes.string,
	big: PropTypes.bool.isRequired,
}

export default CheckmarkCheckbox
