import { validateModuleDefinition, findNextStepsInEvaluator } from 'libs/gs3-libs-flow-validation'
import { validateFnObject } from 'libs/gs3-libs-flow-validation/src/validators/evaluator/_validateFnObject'
import { flatten } from 'lodash'

const validateDefinition = (type, codeObject) => {
	const moduleToValidate = {
		type: type,
		definition: codeObject,
	}

	const validationResult = validateModuleDefinition(moduleToValidate, moduleToValidate)
	const errors = flatten(
		validationResult.map(result => (result === null ? null : result.errors)),
	).filter(e => e !== null)
	const isValid = errors.length === 0
	return {
		valid: isValid,
		codeErrors: isValid ? [] : errors.slice(),
	}
}

const updateNextSteps = (previousNextSteps, codeObject) => {
	if (!codeObject.logic) {
		return []
	}

	const foundNextStepIds = findNextStepsInEvaluator(codeObject.logic)
	if (foundNextStepIds.length === 0) {
		return []
	}

	const nextSteps = []

	foundNextStepIds.forEach((nextStepId, i) => {
		let toPush = { id: nextStepId, name: 'Branch ' + (i + 1) }
		const existingNextStep = previousNextSteps.find(step => step.id === nextStepId)

		if (existingNextStep) {
			toPush = existingNextStep
		}

		nextSteps.push(toPush)
	})

	return nextSteps
}

const updateQuotas = (code, quotas) => {
	const codeString = JSON.stringify(code)

	const quotaNamePattern = /"agName":"[^,]+"/g

	const quotasFromCode = codeString.match(quotaNamePattern)

	if (quotasFromCode === null) {
		return { quotas: [], errors: [] }
	}

	const quotaNames = quotasFromCode.map(quotaString => {
		const splitString = quotaString.split(':')
		const quotaName = splitString[1]

		return quotaName.replace(/"/g, '').replace(',', '')
	})

	const newQuotas = quotaNames.map(name => {
		const existingQuota = quotas.find(quota => quota.agName === name)

		if (existingQuota !== undefined) {
			return {
				// make sure that every object has condition and errors defined
				condition: '',
				errors: [],
				...existingQuota,
			}
		}

		return {
			agName: name,
			isPercentage: false,
			number: 20,
			condition: '',
			errors: [],
		}
	})

	// validate new quotas
	const errors = []

	if (new Set(quotaNames).size < newQuotas.length) {
		errors.push({
			dataPath: 'Code',
			message: 'duplicate quota names',
		})
	}

	newQuotas.forEach(quota => {
		if (quota.number === undefined || quota.number === null || quota.number <= 0) {
			errors.push({
				dataPath: 'Code',
				message: `invalid number in quota: ${quota.agName}`,
			})
		}
	})

	return { quotas: newQuotas, errors }
}

export const catchJSONError = e => {
	return {
		isCodeValid: false,
		codeErrors:
			e instanceof SyntaxError
				? [{ dataPath: 'Syntax error', message: 'Invalid JSON' }]
				: [{ dataPath: 'Error', message: 'Something went wrong' }],
		nextSteps: [],
	}
}

export const validate = (type, code, nextSteps, quotas) => {
	try {
		const codeObject = JSON.parse(code)
		const codeValidationResult = validateDefinition(type, codeObject)
		if (codeValidationResult.valid === false) {
			return {
				isCodeValid: codeValidationResult.valid,
				codeErrors: codeValidationResult.codeErrors,
				nextSteps: [],
			}
		}

		const nextStepsValidationResult = updateNextSteps(nextSteps, codeObject)
		const { quotas: updatedQuotas, errors: quotasErrors } = updateQuotas(codeObject, quotas)

		return {
			isCodeValid: codeValidationResult.valid,
			codeErrors: [...codeValidationResult.codeErrors, ...quotasErrors],
			nextSteps: nextStepsValidationResult,
			quotas: updatedQuotas,
			parsedCode: codeObject,
		}
	} catch (e) {
		return catchJSONError(e)
	}
}

export const validateQuotaCondition = condition => {
	if (condition.includes('agQuota') === true) {
		return {
			isCodeValid: false,
			codeErrors: [
				{
					dataPath: 'Condition',
					message: 'nested quotas are not supported',
				},
			],
		}
	}

	try {
		const errors = []

		const conditionObject = JSON.parse(condition)
		validateFnObject('', 'condition', conditionObject, errors)

		return {
			isCodeValid: errors.length === 0,
			codeErrors: errors,
		}
	} catch (e) {
		return catchJSONError(e)
	}
}
