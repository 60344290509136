import PropTypes from 'prop-types'
import React from 'react'

import { ErrorMessage as FormikErrorMessage } from 'formik'

const ErrorMessage = props => <FormikErrorMessage {...props} />

ErrorMessage.defaultProps = {
	render: msg => <span className="title-error">{msg}</span>,
}

ErrorMessage.propTypes = {
	name: PropTypes.string.isRequired,
	render: PropTypes.func.isRequired,
}

export default ErrorMessage
