import React from 'react'
import PropTypes from 'prop-types'

import classes from './InteractiveBackground.module.scss'

const InteractiveBackground = ({ children }) => {
	return <div className={classes.holder}>{children}</div>
}

InteractiveBackground.propTypes = {
	children: PropTypes.object.isRequired,
}
export default InteractiveBackground
