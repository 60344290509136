export const validate = module => {
	if (module.definition.minAnswers > module.definition.maxAnswers) {
		return {
			infos: null,
			warnings: null,
			errors: ['Heatmap minAnswers cannot be bigger than maxAnswers'],
		}
	}

	return null
}

export default validate
