import ReactDOM from 'react-dom'
import { ID_OVERLAY_ELEMENT } from 'constants/overlay'

const StaticPortalOverlay = ({ children }) => {
	const renderOverlay = () => children

	const overlayElement = document.getElementById(ID_OVERLAY_ELEMENT)

	return ReactDOM.createPortal(renderOverlay(), overlayElement)
}

export default StaticPortalOverlay
