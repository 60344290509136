export const KNOWN_GRAPHQL_ERRORS = {
	ACCOUNT_ACCESS_ERROR: 'You do not have permission in this account',
	INCORRECT_PASSWORD: 'Incorrect Password!',
	INVALID_CREDENTIALS: 'backend_translate.invalid_credentials',
	INVALID_PASSWORD: 'Invalid password!',
	INVARIANT_VIOLATION_8:
		'Invariant Violation: 8 (see https://github.com/apollographql/invariant-packages)',
	SLIDE_NOT_FOUND_ERROR: 'This slide does not exist.',
	STUDY_ACCESS_ERROR: 'You do not have permission for this study',
	WRONG_PASSWORD: 'Wrong password!',
	INVITATION_EXPIRED: 'Invitation expired',
	INVITATION_EMAIL_MISSMATCH: 'Email does not match invitation',
	INVITATION_NO_ACCESS: 'You do not have access to this invitation!',
	INVITATION_NOT_FOUND: 'Invitation not found',
	PASSWORD_CHANGE_REQUIRED: 'backend_translate.password_change_required',
	AUTH_TOO_MANY_REQUESTS: 'backend_translate.too_many_request',
	AUTH_MULTI_FACTOR_ACTIVE: 'backend_translate.multi_factor_auth_is_active',
}

export const IGNORED_GRAPHQL_ERRORS = [
	KNOWN_GRAPHQL_ERRORS.ACCOUNT_ACCESS_ERROR,
	KNOWN_GRAPHQL_ERRORS.INCORRECT_PASSWORD,
	KNOWN_GRAPHQL_ERRORS.INVALID_CREDENTIALS,
	KNOWN_GRAPHQL_ERRORS.INVALID_PASSWORD,
	KNOWN_GRAPHQL_ERRORS.INVARIANT_VIOLATION_8,
	KNOWN_GRAPHQL_ERRORS.SLIDE_NOT_FOUND_ERROR,
	KNOWN_GRAPHQL_ERRORS.STUDY_ACCESS_ERROR,
	KNOWN_GRAPHQL_ERRORS.WRONG_PASSWORD,
	KNOWN_GRAPHQL_ERRORS.INVITATION_EXPIRED,
	KNOWN_GRAPHQL_ERRORS.INVITATION_EMAIL_MISSMATCH,
	KNOWN_GRAPHQL_ERRORS.INVITATION_NO_ACCESS,
	KNOWN_GRAPHQL_ERRORS.INVITATION_NOT_FOUND,
	KNOWN_GRAPHQL_ERRORS.PASSWORD_CHANGE_REQUIRED,
	KNOWN_GRAPHQL_ERRORS.AUTH_TOO_MANY_REQUESTS,
	KNOWN_GRAPHQL_ERRORS.AUTH_MULTI_FACTOR_ACTIVE,
]
