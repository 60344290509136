import { COLORS } from 'constants/colors'

export const sliderStyles = (
	disabled,
	trackStyles,
	railStyles,
	handleStyles,
	dotStyle,
	activeDotStyle,
) => ({
	trackStyle: {
		backgroundColor: disabled === true ? 'transparent' : COLORS.PRIMARY_45,
		...trackStyles,
	},
	railStyle: {
		backgroundColor: COLORS.GREY_90,
		...railStyles,
	},
	handleStyle: {
		borderColor: disabled === true ? COLORS.GREY_60 : COLORS.PRIMARY_45,
		backgroundColor: disabled === true ? COLORS.GREY_60 : COLORS.PRIMARY_45,
		display: disabled === true ? 'none' : 'block',
		cursor: disabled === true ? 'not-allowed' : 'pointer',
		textAlign: 'center',
		color: 'white',
		...handleStyles,
	},
	dotStyle,
	activeDotStyle,
})
