import _ from 'lodash'
import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import { VISUAL_FLOW_INVALID_MODULE_TYPES, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { CONDITION_SELECTION_TYPES, CONDITION_TYPES } from 'constants/conditionBuilder'

import getUpperModuleIds from 'routes/_study/StudyDesign/_store/helpers/getUpperModuleIds'
import validateModule from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule'
import hasInvalidModuleFilter from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/_hasInvalidModuleFilter'
import { getSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/FREE_TEXT'

import isChoiceModule from 'helpers/visualFlowModules/isChoiceModule'
import { filterModulesForCondition } from 'helpers/visualFlowModules/filterModulesForCondition'
import { getStudyObjectDefinition } from 'helpers/conditionBuilder/getStudyObjectDefinition'
import {
	isFreetextDateType,
	isFreetextModule,
	isFreetextNumberType,
	isFreetextSelectionTypeStringAnyOf,
} from 'helpers/visualFlowModules/isFreetextModule'
import { hasInvalidDate, hasInvalidDateSequence } from 'helpers/segments/validateDateSegment'
import { getVisibleOptions } from 'helpers/visualFlowModules/getVisibleOptions'
import getIsAllocationModule from 'helpers/visualFlowModules/getIsAllocationModule'
import getIsRankingModule from 'helpers/visualFlowModules/getIsRankingModule'
import isOpenAnswerModule from 'helpers/visualFlowModules/isOpenAnswerModule'

const CHOICE_SELECTION_TYPES = [
	CONDITION_SELECTION_TYPES.EQUAL_TO,
	CONDITION_SELECTION_TYPES.ANY_OF,
	CONDITION_SELECTION_TYPES.EVERY_OF,
]

const FREE_TEXT_NUMBER_SELECTION_TYPES = [
	CONDITION_SELECTION_TYPES.NUMBER_EQUAL_TO,
	CONDITION_SELECTION_TYPES.GREATER_THAN,
	CONDITION_SELECTION_TYPES.GREATER_THAN_OR_EQUAL_TO,
	CONDITION_SELECTION_TYPES.LESS_THAN_OR_EQUAL_TO,
	CONDITION_SELECTION_TYPES.LESS_THAN,
]

const FREE_TEXT_STRING_SELECTION_TYPES = [
	CONDITION_SELECTION_TYPES.STRING_EQUAL_TO,
	CONDITION_SELECTION_TYPES.CONTAINS,
	CONDITION_SELECTION_TYPES.STARTS_WITH,
	CONDITION_SELECTION_TYPES.ENDS_WITH,
	CONDITION_SELECTION_TYPES.REGEXP,
	CONDITION_SELECTION_TYPES.STRING_ANY_OF,
]

const FREE_TEXT_DATE_SELECTION_TYPES = [
	CONDITION_SELECTION_TYPES.AFTER,
	CONDITION_SELECTION_TYPES.BEFORE,
	CONDITION_SELECTION_TYPES.BETWEEN,
	CONDITION_SELECTION_TYPES.DATE_EQUALS,
]

const getFreetextSelectionTypeByStudyObject = studyObject => {
	if (isFreetextNumberType(studyObject)) {
		return FREE_TEXT_NUMBER_SELECTION_TYPES
	}

	if (isFreetextDateType(studyObject)) {
		return FREE_TEXT_DATE_SELECTION_TYPES
	}

	return FREE_TEXT_STRING_SELECTION_TYPES
}

export const hasInvalidStudyObject = (condition, upperModulesIds, modules, respondentSources) => {
	if (_.isNil(condition.studyObject) === true) {
		return true
	}

	if (condition.type === CONDITION_TYPES.RESPONDENT_SOURCE) {
		return (
			respondentSources.find(
				({ idRespondentSource }) => idRespondentSource === condition.studyObject.id,
			) === undefined
		)
	}

	if (upperModulesIds.includes(condition.studyObject.id) === false) {
		return true
	}

	if (filterModulesForCondition([modules[condition.studyObject.id]]).length === 0) {
		return true
	}

	if (_.get(modules[condition.studyObject.id], 'isHidden', false) === true) {
		return true
	}

	return false
}

export const hasInvalidOptionSelected = (condition, studyObject) => {
	if (isChoiceModule(studyObject) === true) {
		if (Array.isArray(condition.left) === false) {
			return true
		}

		const options = getVisibleOptions(getStudyObjectDefinition(studyObject).options)

		if (
			condition.left.some(idOption => options.find(option => option.id === idOption) === undefined)
		) {
			return true
		}
	}

	if (getIsAllocationModule(studyObject) === true || getIsRankingModule(studyObject) === true) {
		const options = getVisibleOptions(getStudyObjectDefinition(studyObject).options)

		if (options.find(option => option.id === condition.left.idOption) === undefined) {
			return true
		}
	}

	return false
}

export const hasInvalidSelection = (condition, studyObject) => {
	if (studyObject.type === VISUAL_FLOW_MODULE_TYPES.A_CHOICE) {
		return CHOICE_SELECTION_TYPES.includes(condition.selection.type) === false
	}

	if (studyObject.type === VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT) {
		const allowedTypes = getFreetextSelectionTypeByStudyObject(studyObject)

		return allowedTypes.includes(condition.selection.type) === false
	}

	return false
}

export const hasNoOptionsSelected = (condition, studyObject) => {
	if (isChoiceModule(studyObject) === true && condition.left.length === 0) {
		return true
	}

	if (
		(getIsAllocationModule(studyObject) === true || getIsRankingModule(studyObject) === true) &&
		condition.left.idOption === ''
	) {
		return true
	}

	return false
}

export const hasTooManyOptionsSelected = (condition, studyObject) => {
	if (
		isChoiceModule(studyObject) === true &&
		condition.selection.type !== CONDITION_SELECTION_TYPES.ANY_OF &&
		condition.left.length > getStudyObjectDefinition(studyObject).maxSelection
	) {
		return true
	}

	return false
}

export const hasTooFewOptionsSelected = (condition, studyObject) => {
	if (isChoiceModule(studyObject) === false) {
		return false
	}

	if (condition.selection.type !== CONDITION_SELECTION_TYPES.EQUAL_TO) {
		return false
	}

	if (condition.left.length >= getStudyObjectDefinition(studyObject).minSelection) {
		return false
	}

	if (condition.left.length === 1) {
		const selectedOption = studyObject.definition.options.find(
			option => option.id === condition.left[0] && option.isHidden !== true,
		)

		return _.get(selectedOption, 'isNoneOfThese', null) === false
	}

	return true
}

export const hasInvalidMinAnyOfValidSelection = (condition, studyObject) => {
	if (isChoiceModule(studyObject) === false) {
		return false
	}

	return (
		condition.selection.minAnyOfValidSelection > getStudyObjectDefinition(studyObject).maxSelection
	)
}

const getFreetextStudyObjectDefinition = studyObject => {
	if (studyObject.type === MODULE_DEFINITIONS.A_SNIPPET.type) {
		return studyObject.definition.parsedCode
	}

	return studyObject.definition
}

export const hasInvalidFreetextInput = (condition, studyObject) => {
	const left = condition.left

	if (isFreetextModule(studyObject) === false) {
		return false
	}

	if (_.isString(left) === false) {
		return false
	}

	if (condition.type === CONDITION_TYPES.DATE) {
		return false
	}

	if (
		condition.selection.type === CONDITION_SELECTION_TYPES.STARTS_WITH ||
		condition.selection.type === CONDITION_SELECTION_TYPES.ENDS_WITH ||
		condition.selection.type === CONDITION_SELECTION_TYPES.CONTAINS ||
		condition.selection.type === CONDITION_SELECTION_TYPES.REGEXP
	) {
		return false
	}

	const freeTextDefinition = getFreetextStudyObjectDefinition(studyObject)

	const regex =
		freeTextDefinition.validationPattern ??
		getSettings(freeTextDefinition.freetextType).validationPattern

	return left.match(regex) === null
}

export const hasInvalidOEQFollowUpInput = (condition, inputValue, studyObject) => {
	if (studyObject === undefined) {
		return false
	}

	if (isOpenAnswerModule(studyObject) === false) {
		return false
	}

	if (condition.type !== CONDITION_TYPES.OEQ_FOLLOW_UP) {
		return false
	}

	if (_.isString(inputValue) === false) {
		return true
	}

	return inputValue.trim() === ''
}

export const getIsFollowUpConditionInvalid = (condition, studyObject) => {
	if (studyObject === undefined) {
		return false
	}

	if (isOpenAnswerModule(studyObject) === false) {
		return false
	}

	if (condition.type !== CONDITION_TYPES.OEQ_FOLLOW_UP) {
		return false
	}

	if (_.isArray(condition.left) === false) {
		return true
	}

	return condition.left.some(inputValue =>
		hasInvalidOEQFollowUpInput(condition, inputValue, studyObject),
	)
}

//allocation and ranking
export const hasAllocationRankingInvalidRange = ({ selection, left }) => {
	if (selection.type !== CONDITION_SELECTION_TYPES.IN_RANGE) {
		return false
	}

	const {
		expectedValue: { from, to },
	} = left

	const trimmedFrom = from.trim()
	const trimmedTo = to.trim()

	if (trimmedFrom === '' || trimmedTo === '') {
		return true
	}

	const fromAsNumber = Number(trimmedFrom)
	const toAsNumber = Number(trimmedTo)

	if (Number.isInteger(fromAsNumber) === false || Number.isInteger(toAsNumber) === false) {
		return true
	}

	return fromAsNumber >= toAsNumber
}

export const hasInvalidAllocationRankingValues = ({ selection, left }) => {
	if (selection.type === CONDITION_SELECTION_TYPES.IN_RANGE) {
		return false
	}

	const trimmedExpectedValue = left.expectedValue.trim()
	if (trimmedExpectedValue === '') {
		return true
	}

	const expectedValueAsNumber = Number(trimmedExpectedValue)

	return Number.isInteger(expectedValueAsNumber) === false
}

const getHasInvalidListSelected = (module, modules, orderModule, flatOrder) => {
	const idsUpperModules = getUpperModuleIds(orderModule.path, flatOrder, modules)

	const upperModules = idsUpperModules.map(id => modules[id])

	const upperLists = upperModules.filter(module => VISUAL_FLOW_MODULE_TYPES.LIST === module.type)

	const selectedList = upperLists.find(
		list => list.definition.id === module.definition.loopSettings.idInputList,
	)

	return selectedList === undefined
}

//visual flow commands
const validateUICommand = (
	module,
	flowModules,
	orderModule,
	flatOrder,
	communityModules,
	proFlowLoopModuleIterations,
	respondentSources,
	studyTags,
	duplicateShortNamesObject,
) => {
	const modules = {
		...flowModules,
	}

	// nothing to validate in HISTORY_FILTER and ACTION_BUTTON
	if (module.definition.enableConditions === false) return []

	const validationResult = []

	const thenBranchValidationResult = []
	const branchNonUniqueNamesErrors = []

	orderModule.then
		.filter(thenModule => thenModule.type !== MODULE_DEFINITIONS.PLACEHOLDER.type)
		.forEach(childOrderModule => {
			const childModule = modules[childOrderModule.id]

			thenBranchValidationResult.push(
				...validateModule(
					childModule,
					modules,
					childOrderModule,
					flatOrder,
					communityModules,
					proFlowLoopModuleIterations,
					respondentSources,
					studyTags,
					duplicateShortNamesObject,
				),
			)
		})

	if (module.definition.isBlockExpanded === true) {
		validationResult.push(...thenBranchValidationResult)
	}

	if (
		module.definition.isBlock === true &&
		module.definition.isBlockExpanded === false &&
		(thenBranchValidationResult.length > 0 || branchNonUniqueNamesErrors.length > 0)
	) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.block_invalid_children,
		})
	}

	if (module.definition.isRandomizer === true && orderModule.then.length === 0) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.randomizer_empty,
		})
	}

	if (hasInvalidModuleFilter(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.invalid_module_filter,
		})
	}

	// end validation of Block and Randomizer modules
	if (module.definition.isBlock === true || module.definition.isRandomizer === true) {
		return validationResult
	}

	// validate loop module and end execution
	if (module.definition.loopSettings?.isActive === true) {
		const hasInvalidListSelected = getHasInvalidListSelected(
			module,
			modules,
			orderModule,
			flatOrder,
		)

		if (orderModule.then.length === 0) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.loop_empty,
			})

			return validationResult
		}

		if (hasInvalidListSelected === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.list_filter_invalid_selected,
			})
		}

		return validationResult
	}

	const upperModuleIds = getUpperModuleIds(orderModule.path, flatOrder, modules)

	const currentLoopPath =
		module.path !== undefined && module.path.includes('loop') ? module.path.split('loop')[0] : null

	const upperLoopIterations = []

	proFlowLoopModuleIterations.forEach(proFlowModule => {
		const { idOriginalModule } = proFlowModule.listSettings[0]
		if (upperModuleIds.includes(idOriginalModule)) {
			upperLoopIterations.push(proFlowModule)
		}
	})

	// check conditions
	module.definition.conditions.forEach(condition => {
		if (
			hasInvalidStudyObject(
				condition,
				[
					...upperModuleIds.filter(id => {
						if (modules[id] === undefined) {
							return false
						}

						return (
							modules[id].path === undefined ||
							modules[id].path.includes('loop') === false ||
							modules[id].path.startsWith(currentLoopPath)
						)
					}),
					...communityModules.map(module => module.id),
					...upperLoopIterations.map(proFlowModule => proFlowModule.id),
				],
				modules,
				respondentSources,
			) === true
		) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_studyObjectMissed,
			})

			// break validation if the study object isn't valid
			return
		}

		// nothing to validate in RESPONDENT_SOURCE condition
		if (condition.type === CONDITION_TYPES.RESPONDENT_SOURCE) {
			return
		}

		const studyObject = modules[condition.studyObject.id]

		if (hasInvalidSelection(condition, studyObject) === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_hasInvalidSelection,
			})

			return
		}

		if (hasInvalidOptionSelected(condition, studyObject) === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_oneOfOptionsMissed,
			})
		}

		if (hasNoOptionsSelected(condition, studyObject) === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_noOptionsSelected,
			})
		}

		if (hasTooManyOptionsSelected(condition, studyObject) === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_tooManyOptions,
			})
		}

		if (hasTooFewOptionsSelected(condition, studyObject) === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_tooFewOptions,
			})
		}

		if (hasInvalidMinAnyOfValidSelection(condition, studyObject) === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_invalidAnyOfValidSelection,
			})
		}

		if (
			condition.type !== CONDITION_TYPES.DATE &&
			isFreetextSelectionTypeStringAnyOf(condition) === false &&
			hasInvalidFreetextInput(condition, studyObject) === true
		) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_invalidFreetextInput,
			})
		}

		if (
			condition.type !== CONDITION_TYPES.DATE &&
			isFreetextSelectionTypeStringAnyOf(condition) === true
		)
			condition.left.forEach(conditionLeft => {
				if (hasInvalidFreetextInput({ ...condition, left: conditionLeft }, studyObject) === true) {
					validationResult.push({
						id: module.definition.id,
						type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_invalidFreetextInput,
					})
				}
			})

		if (
			condition.type === CONDITION_TYPES.DATE &&
			(hasInvalidDateSequence(condition) === true || hasInvalidDate(condition) === true)
		) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_invalidDateInput,
			})
		}

		if (
			(condition.type === CONDITION_TYPES.ALLOCATION ||
				condition.type === CONDITION_TYPES.RANKING) &&
			hasInvalidAllocationRankingValues(condition)
		) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_invalidAllocationOrRankingInput,
			})
		}

		if (
			(condition.type === CONDITION_TYPES.ALLOCATION ||
				condition.type === CONDITION_TYPES.RANKING) &&
			hasAllocationRankingInvalidRange(condition)
		) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_invalidAllocationOrRankingRange,
			})
		}

		if (getIsFollowUpConditionInvalid(condition, studyObject) === true) {
			validationResult.push({
				id: module.definition.id,
				type: VISUAL_FLOW_INVALID_MODULE_TYPES.condition_invalid_follow_up,
			})
		}
	})

	return validationResult
}

export default validateUICommand
