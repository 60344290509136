export const CONDITION_MODIFIERS = {
	// numbers
	IS_EQUAL: '=',
	GREATER: '>',
	LESSER: '<',
	GREATER_EQUAL: '>=',
	LESSER_EQUAL: '<=',
	IN_RANGE: '^in-range:',
	NOT_IN_RANGE: '^not-in-range:',
	// text
	IS: '^is:',
	IS_NOT: '^is-not:',
	IS_EMPTY: '^is-empty:',
	IS_NOT_EMPTY: '^is-not-empty:',
	CONTAINS: '^contains:',
	DOES_NOT_CONTAIN: '^not-contains:',
	NOT_UNTHEMED: '^is-not-unthemed:',
	UNTHEMED: '^is-unthemed:',
}

export const NUMBER_CONDITION_OPTIONS = {
	IS_EQUAL: 'template_builder.chart_editor.is_equal',
	GREATER: 'template_builder.chart_editor.greater',
	LESSER: 'template_builder.chart_editor.lesser',
	GREATER_EQUAL: 'template_builder.chart_editor.greater_equal',
	LESSER_EQUAL: 'template_builder.chart_editor.lesser_equal',
	IN_RANGE: 'template_builder.chart_editor.in_range',
	NOT_IN_RANGE: 'template_builder.chart_editor.not_in_range',
}

export const TEXT_CONDITION_OPTIONS = {
	IS: 'template_builder.chart_editor.is',
	IS_NOT: 'template_builder.chart_editor.is_not',
	CONTAINS: 'template_builder.chart_editor.contains',
	DOES_NOT_CONTAIN: 'template_builder.chart_editor.does_not_contain',
	IS_EMPTY: 'template_builder.chart_editor.is_empty',
	IS_NOT_EMPTY: 'template_builder.chart_editor.is_not_empty',
	NOT_UNTHEMED: 'template_builder.chart_editor.is_not_unthemed',
	UNTHEMED: 'template_builder.chart_editor.is_unthemed',
}

export const TEXT_CONDITION_ALLOWED_OPTIONS = {
	THEME_NAME: ['IS', 'IS_NOT', 'CONTAINS', 'DOES_NOT_CONTAIN', 'IS_EMPTY', 'IS_NOT_EMPTY'],
	SUNBURST_THEME_NAME: ['NOT_UNTHEMED', 'UNTHEMED', 'CONTAINS', 'DOES_NOT_CONTAIN', 'IS', 'IS_NOT'],
	LETTER: ['IS', 'IS_NOT'],
	DEFAULT: ['IS', 'IS_NOT', 'CONTAINS', 'DOES_NOT_CONTAIN'],
}
