import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const LiveSlides = lazy(() => import('./LiveSlides'))
const WrappedLiveSlides = errorWrapper(LiveSlides)

export default () => ({
	path: 'live-slides',
	element: <WrappedLiveSlides />,
})
