import React from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import Icon from 'components/_scaffolding/Icon'
import Tooltip from 'components/_scaffolding/Tooltip'

import classes from './LoopItemReferencingTooltip.module.scss'

const LoopItemReferencingTooltip = ({ isMatrix = false }) => {
	const intl = useIntl()

	const idHeadlineTranslation =
		isMatrix === true
			? 'loop.referencing_items.headline.matrix'
			: 'loop.referencing_items.headline.list'

	const idTipTranslation =
		isMatrix === true ? 'loop.referencing_items.tip.matrix' : 'loop.referencing_items.tip.list'

	return (
		<div>
			<div
				className={classnames(classes.hint, {
					[classes['hint--matrix']]: isMatrix === true,
				})}
				data-for="referencing-list-items"
				data-tip={intl.formatMessage({ id: idTipTranslation })}
			>
				<Icon name={Icon.NAMES.QUESTION} />
				{intl.formatMessage({ id: idHeadlineTranslation })}
			</div>
			<Tooltip id="referencing-list-items" className={classes.tip} />
		</div>
	)
}

export default LoopItemReferencingTooltip
