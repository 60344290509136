import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const SegmentationTypingToolRoute = lazy(() => import('./SegmentationTypingToolRoute'))
const WrappedSegmentationTypingToolRoute = errorWrapper(SegmentationTypingToolRoute)

export default () => ({
	path: 'segmentation-typing-tool',
	element: <WrappedSegmentationTypingToolRoute />,
})
