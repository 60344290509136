import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

export default function getUpperModuleIds(pathToModule, flatOrder, modules) {
	const activeIndex = flatOrder.findIndex(m => m.path === pathToModule)

	const aboveModules = flatOrder.slice(0, activeIndex)

	const result = []

	aboveModules.forEach(orderModule => {
		result.push(orderModule.id)

		const module = modules[orderModule.id]

		if (module.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE) {
			module.definition.questions.forEach(question => result.push(question.id))
		}
	})

	return result
}
