import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

import Tooltip from 'components/_scaffolding/Tooltip'

import classes from './DisabledLink.module.scss'

const DisabledLink = ({
	children,
	className,
	hasUnsavedChanges,
	to,
	tooltipText = null,
	unsavedChangesTooltipText = null,
}) => {
	const intl = useIntl()
	const id = `unsaved-changes-${to}`

	const getTooltipMessage = () => {
		if (hasUnsavedChanges === false) {
			return tooltipText
		}

		if (unsavedChangesTooltipText !== null) {
			return unsavedChangesTooltipText
		}

		return intl.formatMessage({ id: 'navigation.unsaved_changes' })
	}

	const tooltipMessage = getTooltipMessage()

	const canDisplayTooltip = tooltipMessage !== null

	return (
		<div
			data-place="bottom"
			data-for={id}
			data-tip={tooltipMessage}
			className={classnames(className, classes.disabled)}
		>
			{children}
			{canDisplayTooltip === true && <Tooltip id={id} />}
			<div className={classes.disabled__blocker} />
		</div>
	)
}

DisabledLink.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	hasUnsavedChanges: PropTypes.bool.isRequired,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	tooltipText: PropTypes.string,
	unsavedChangesTooltipText: PropTypes.string,
}

export default DisabledLink
