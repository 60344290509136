import PropTypes from 'prop-types'

import { getPublicIconPath } from 'helpers/templateBuilder/getPublicIconPath'

export const ICONS = {
	ADD: 'add',
	ADDRESS: 'address',
	ADD_CARD: 'add-card',
	ADMIN_DASHBOARD: 'admin-dashboard',
	ALIGN_CENTER: 'align-center',
	ALIGN_JUSTIFY: 'align-justify',
	ALIGN_LEFT: 'align-left',
	ALIGN_RIGHT: 'align-right',
	ANALYSIS: 'analysis',
	ANSWERS: 'answers',
	APPROVED_PAYMENT: 'approved-payment',
	ARCHIVED: 'archived',
	ARROWS_SORT: 'arrows-sort',
	ARROW_DOWN: 'arrow-down',
	ARROW_EXTERNAL_LINK: 'arrow-external-link',
	ARROW_LEFT: 'arrow-left',
	ARROW_REDO: 'arrow-redo',
	ARROW_RIGHT: 'arrow-right',
	ARROW_SORT: 'arrows-sort',
	ARROW_UNDO: 'arrow-undo',
	ARROW_UP: 'arrow-up',
	AWAITING_DELETE: 'awaiting-delete',
	AWAITING_DUPLICATE: 'awaiting-duplicate',
	AWAITING_PAUSE: 'awaiting-pause',
	AWAITING_SOLVE: 'awaiting-solve',
	BELL: 'bell',
	BOLD_FONT: 'bold-font',
	BOTS: 'bots',
	BRAND_POSITIONING: 'brand-positioning',
	BRUSH: 'brush',
	BUBBLE_COMMENT: 'bubble-comment',
	CARD_TO_EXPIRE: 'card-to-expire',
	CHANGE_PLAN: 'change-plan',
	CHART: 'chart',
	CHART_ADD: 'chart-add',
	CHART_CONSENSUS: 'chart-consensus',
	CHART_DONUT: 'chart-donut',
	CHART_HORIZONTAL: 'chart-horizontal',
	CHART_IDEACLOUD: 'chart-idea-cloud',
	CHART_IDEACLUSTER: 'chart-idea-cluster',
	CHART_INTELLISEGMENT: 'chart-intelli-segment',
	CHART_OPEN_ANSWERS: 'chart-AI-open-end',
	CHART_STACKED: 'chart-stacked',
	CHART_SUNBURST: 'chart-sunburst',
	CHART_VERTICAL: 'chart-vertical',
	CHART_WORD_CLOUD: 'chart-word-cloud',
	CHECK_DOUBLE: 'check-double',
	CHEVRON_COLLAPSE: 'chevron-collapse',
	CHEVRON_DOWN: 'chevron-down',
	CHEVRON_EXPAND: 'chevron-expand',
	CHEVRON_LEFT: 'chevron-left',
	CHEVRON_RIGHT: 'chevron-right',
	CHEVRON_UP: 'chevron-up',
	CLEAR_FORMATING_TEXT: 'clear-formating-text',
	COLLECTING: 'collecting',
	COLOR_PALETTE: 'color-palette',
	COMBINE: 'combine',
	CONCEPT_TESTING: 'concept-testing',
	COPY: 'copy',
	COPY_TO_LIVE_SLIDES: 'copy-to-live-slides',
	CREDIT_CARD_CHECKMARK: 'credit-card-checkmark',
	CUSTOMER_JOURNEY: 'customer-journey',
	DATASET: 'dataset',
	DELETE: 'delete',
	DELETED: 'deleted',
	DEMO_STUDIES: 'demo-studies',
	DESELECT: 'deselect',
	DISPLAY_BREAK_TEXT: 'display-break-text',
	DISPLAY_INLINE: 'display-inline',
	DOCUMENT: 'document',
	DONE: 'done',
	DOWNLOAD: 'download',
	DRAFT: 'draft',
	DRAG: 'drag',
	EARTH: 'earth',
	EDIT: 'edit',
	EDITED: 'edited',
	EMAIL: 'email',
	EMPLOYEE_SATISFACTION: 'employee-satisfaction',
	ERROR: 'error',
	EXPIRE: 'expire',
	EYE: 'eye',
	EYE_CROSSED: 'eye-crossed',
	EYE_REMOVE: 'eye-remove',
	FACEBOOK: 'facebook',
	FILE: 'file',
	FILES: 'files',
	FILTER: 'filter',
	FLOW: 'flow',
	FOLDER_OPENED: 'folder-opened',
	GET_HELP: 'get-help',
	GROUP: 'group',
	GROUPSOLVER_LOGO: 'GroupSolver-logo',
	HELP_CENTER: 'help-center',
	HIGHLIGHT: 'highlight',
	HOME: 'home',
	IDEATION: 'ideation',
	ID_BADGE: 'id-badge',
	IMAGE_ADD_PHOTO: 'image-add-photo',
	IMAGE_PHOTO: 'image-photo',
	IMPACT_BRIEF: 'impact-brief',
	INFO: 'info',
	INSERT_PASTE: 'insert-paste',
	INSTAGRAM: 'instagram',
	INTERSECTION: 'intersection',
	INTERVIEW: 'interview',
	ITALIC_FONT: 'italic-font',
	KEY: 'key',
	KNOWLEDGE_BASE: 'knowledge-base',
	LAYOUT: 'layout',
	LIBRARY: 'library',
	LINE_HEIGHT: 'line-height',
	LINK: 'link',
	LINKEDIN: 'linkedin',
	LINK_GROUP: 'link-group',
	LIST_BULLET: 'list-bullet',
	LIST_ORDERED: 'list-ordered',
	LIVESLIDES: 'liveslides',
	LOCK: 'lock',
	MAGIC: 'magic',
	MAIL: 'mail',
	MAIL_UPDATE: 'mail-update',
	MENU_HAMBURGER: 'menu-hamburger',
	MINUS: 'minus',
	MOBILE_OOPS: 'mobile-oops',
	MOBILE_ROTATE: 'mobile-rotate',
	MORE_HORIZONTAL_DOTS: 'more-horizontal-dots',
	MORE_VERTICAL_DOTS: 'more-vertical-dots',
	NET_PROMOTER_SCORE: 'net-promoter-score',
	NEW_STUDY: 'new-study',
	NOTE: 'note',
	NO_BRAND_CARD: 'no-brand-card',
	ORGANIZATION: 'organization',
	PAPER_PLANE: 'paper-plane',
	PARAGRAPH_STYLE: 'paragraph-style',
	PASSWORD_SUCCESSFULLY_RESET: 'password-successfully-reset',
	PAUSE: 'pause',
	PAUSED: 'paused',
	PAYMENTS_WARNING: 'payments-warning',
	PHONE: 'phone',
	PIN: 'pin',
	PLAY: 'play',
	POST_ANALYTICS: 'post-analytics',
	PRIVATE: 'private',
	PRODUCT_NAMING: 'product-naming',
	QUESTION: 'question',
	QUESTION_BUBBLE: 'question-bubble',
	QUESTION_MARK: 'question-mark',
	RECEIPT: 'receipt',
	RECENT_UPDATES: 'recent-updates',
	REFRESH: 'refresh',
	REFUND: 'refund',
	RENEW_PAYMENT: 'renew-payment',
	RESIZE: 'resize',
	RESTORE: 'restore',
	ROCKET: 'rocket',
	SAD_EMOJI: 'sad-emoji',
	SEARCH: 'search',
	SECURITY: 'security',
	SEGMENTS: 'segments',
	SELECT_ALL: 'select-all',
	SETTINGS: 'settings',
	SETTINGS_COG: 'settings-cog',
	SHUFFLE: 'shuffle',
	SIGN_OUT: 'sign-out',
	SOCIAL: 'social',
	SOLVED: 'solved',
	SORT_ASCENDING: 'sort-ascending',
	SORT_DESCENDING: 'sort-descending',
	SPACER: 'spacer',
	SPACER_ADD: 'spacer-add',
	SPARKLE2: 'sparkle2',
	SPARKLE: 'sparkle',
	SPARKLE_ADD: 'sparkle-add',
	STAR: 'star',
	STAR_FILLED: 'star-filled',
	STUDY: 'study',
	STYLE: 'style',
	SUCCESS: 'success',
	TABLE: 'table',
	TABLE_ADD: 'table-add',
	TABLE_OF_CONTENTS: 'table-of-contents',
	TABLE_OF_CONTENTS_ADD: 'table-of-contents-add',
	TEMPLATES: 'templates',
	TERMINATE: 'terminate',
	TEXT: 'text',
	TEXT_ADD: 'text-add',
	TIME: 'time',
	TWITTER: 'twitter',
	UNDERLINE_TEXT: 'underline-text',
	UNION: 'union',
	UPDATE_CARD: 'update-card',
	UPGRADE: 'upgrade',
	UPLOAD: 'upload',
	USER: 'user',
	USERS: 'users',
	USER_ADD: 'user-add',
	USER_ADMIN: 'user-admin',
	USER_EDIT: 'user-edit',
	USER_MESSAGE: 'user-message',
	USER_REMOVE: 'user-remove',
	VERIFY: 'verify',
	VERTICAL_ALIGN_CENTER: 'vertical-align-center',
	VERTICAL_ALIGN_FLEX_END: 'vertical-align-flex-end',
	VERTICAL_ALIGN_FLEX_START: 'vertical-align-flex-start',
	VIDEO: 'video',
	WARNING: 'warning',
	WEB: 'web',
	WELCOME: 'welcome',
	WILLINGNESS_TO_PAY: 'willingness-to-pay',
	X_CLOSE: 'x-close',
}

export const DEFAULT_ICON_PROPS = {
	name: null,
	size: 16,
	customClassName: null,
}

export const DEFAULT_ICON_PROPS_SHAPE = PropTypes.shape({
	name: PropTypes.string.isRequired,
	size: PropTypes.number,
	customClassName: PropTypes.string,
})

export const SOCIAL_ICONS_PUBLIC_LINKS = {
	EMAIL: getPublicIconPath(ICONS.EMAIL),
	FACEBOOK: getPublicIconPath(ICONS.FACEBOOK),
	INSTAGRAM: getPublicIconPath(ICONS.INSTAGRAM),
	LINKEDIN: getPublicIconPath(ICONS.LINKEDIN),
	TWITTER: getPublicIconPath(ICONS.TWITTER),
	WEB: getPublicIconPath(ICONS.WEB),
}
