import _ from 'lodash'
import { enTranslator as intl } from 'intl.js'

import EndStudy from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/EndStudy'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { ENDSTUDY_TYPES } from './helpers/END_STUDY'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

const getTranslations = definition => {
	switch (definition.subtype) {
		case ENDSTUDY_TYPES.COMPLETE:
			return { description: 'endstudy.complete.description', title: 'endstudy.complete' }
		case ENDSTUDY_TYPES.TERMINATE:
			return { description: 'endstudy.terminated.description', title: 'endstudy.terminated' }
		case ENDSTUDY_TYPES.OVERQUOTA:
			return { description: 'endstudy.overquota.description', title: 'endstudy.overquota' }
		case ENDSTUDY_TYPES.QUALITY_TERMINATE:
			return {
				description: 'endstudy.quality_terminate.description',
				title: 'endstudy.quality_terminate',
			}
		default:
			throw new Error('Invalid END_STUDY type')
	}
}

const getDescription = definition => {
	return getTranslations(definition).description
}

const getTitle = (definition, modules, options) => {
	const isFlowDetail = _.get(options, 'isFlowDetail', false)

	return isFlowDetail === true ? getTranslations(definition).title : 'endstudy.title'
}

const getShortNameBySubtype = subtype => {
	switch (subtype) {
		case ENDSTUDY_TYPES.COMPLETE:
			return intl.formatMessage({ id: 'endstudy.add_module.complete' })
		case ENDSTUDY_TYPES.TERMINATE:
			return intl.formatMessage({ id: 'endstudy.add_module.terminate' })
		case ENDSTUDY_TYPES.OVERQUOTA:
			return intl.formatMessage({ id: 'endstudy.add_module.overquota' })
		case ENDSTUDY_TYPES.QUALITY_TERMINATE:
			return intl.formatMessage({ id: 'endstudy.add_module.quality_terminate' })
		default:
			throw new Error('Invalid END_STUDY type')
	}
}

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_END_STUDY,
	style: 'end-study',
	title: getTitle,
	description: definition => getDescription(definition),
	component: EndStudy,
	generator: (modules, additionalProperties) => ({
		subtype: additionalProperties.endstudyType,
		enableRedirectButton: false,
		overrideRedirect: false,
		redirectLabel: intl.formatMessage({ id: 'submit' }),
		redirectUrl: 'http://',
		shortName: getDatasetShortName(
			modules,
			getShortNameBySubtype(additionalProperties.endstudyType),
			VISUAL_FLOW_MODULE_TYPES.A_END_STUDY,
			additionalProperties.endstudyType,
		),
	}),
}
