import './tools/localStoragePolyfill'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { userClient, adminClient } from 'store/tools/graphql'
import { showNotification } from 'store/notification'
import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'

import { IS_EMAIL_REGISTERED } from 'gql/queries/admin/user'
import {
	ADD_STUDY_MEDIA_OBJECT,
	VISUAL_FLOW_UPDATE,
	LIVE_VISUAL_FLOW_UPDATE,
} from 'gql/mutations/study'
import {
	ADMIN_ADD_ACCOUNT_MEDIA_OBJECT,
	ADMIN_ADD_USER_MEDIA_OBJECT,
} from 'gql/mutations/admin/dashboard'
import { ADD_ACCOUNT_MEDIA_OBJECT, ADD_USER_MEDIA_OBJECT } from '../gql/mutations/dashboard'
import { ADD_PRESENTATION_MEDIA_OBJECT } from 'gql/mutations/presentation'

import { BUCKET_NAMES } from 'constants/mediaUpload'

const requestActions = DEFINITION => ({
	...DEFINITION,
	STARTED: `${DEFINITION.ACTION}.started`,
	CANCELLED: `${DEFINITION.ACTION}.cancelled`,
	SUCCESS: `${DEFINITION.ACTION}.success`,
	ERROR: `${DEFINITION.ACTION}.error`,
})

// ------------------------------------
// Constants
// ------------------------------------
export const API = {
	// ACCOUNT ENDPOINTS
	VISUAL_FLOW_UPDATE: requestActions({
		// GraphQL
		ACTION: 'api.flow.update',
	}),
	LOAD_STUDY_DETAIL: requestActions({
		// GraphQL
		ACTION: 'api.load.study.detail',
	}),
	FLOW_LIBRARY_GET_DETAIL: requestActions({
		// GraphQL
		ACTION: 'api.flow.library.get.detail',
	}),
	UPLOAD_MEDIA: requestActions({
		// fetch url using graphql
		// upload directly to google storage
		ACTION: 'api.upload.image',
	}),
}

const ADD_CANCELABLE_REQUEST = 'api.add.cancelable.request'
const FINISH_CANCELABLE_REQUEST = 'api.finish.cancelable.request'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	authToken: null,
	cancelableRequests: {},
}

// ------------------------------------
// Helpers
// ------------------------------------

const createGraphqlRequest = (
	isQuery,
	query,
	action,
	transform = data => data,
	isRequestCancelable = false,
	isAdminDashboard = false,
) => (dispatch, getState) => {
	const progressToken = action.STARTED
	const cancelToken = action.CANCELLED
	const successToken = action.SUCCESS
	const errorToken = action.ERROR

	const idRequest = uuidv4()

	dispatch({ type: progressToken })

	if (isRequestCancelable === true) {
		dispatch({ type: ADD_CANCELABLE_REQUEST, payload: { action: action.ACTION, idRequest } })
	}

	const client = isAdminDashboard === true ? adminClient : userClient
	const clientFunction = isQuery === true ? client.query : client.mutate

	return clientFunction(query)
		.then(apiResponse => {
			// fix uppercase uuids to lowerCase uuids
			let response = null
			try {
				const stringResponse = JSON.stringify(apiResponse)
				const lowerCaseStringResponse = stringResponse.replace(
					/"[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}"/gi,
					id => id.toLowerCase(),
				)
				response = JSON.parse(lowerCaseStringResponse)
			} catch (e) {
				response = apiResponse
			}

			const data = transform(response.data)

			if (isRequestCancelable === true) {
				const state = getState()
				const actionRequest = _.get(state, `api.cancelableRequests['${action.ACTION}']`, null)
				const isSameRequest = actionRequest === idRequest

				if (isSameRequest === false) {
					// We're in old request here. Cancel execution!
					dispatch({ type: cancelToken, payload: { action: action.ACTION, idRequest } })
					return { type: cancelToken, payload: { action: action.ACTION, idRequest } }
				}

				if (isSameRequest === true) {
					// This is the latest request!
					// Mark cancelable request as finished and continue execution!
					dispatch({
						type: FINISH_CANCELABLE_REQUEST,
						payload: { action: action.ACTION, idRequest },
					})
				}
			}

			if (isQuery === true) {
				if (response.networkStatus === 7) {
					dispatch({ type: successToken, response: data })
					return { type: successToken, response: data }
				} else {
					dispatch({ type: errorToken, response: data })
					return { type: errorToken, response: data }
				}
			} else {
				if (response.errors === undefined) {
					dispatch({ type: successToken, response: data })
					return { type: successToken, response: data }
				} else {
					dispatch({ type: errorToken, response: data })
					return { type: errorToken, response: data }
				}
			}
		})
		.catch(error => {
			dispatch(showNotification({ id: errorToken }, true))
			dispatch({
				type: errorToken,
				response: {
					status: 500,
					message: 'There was an unexpected error, please try again later.',
				},
			})

			console.log('Unknown error in api comunication', error) // eslint-disable-line no-console
		})
}

// ------------------------------------
// Actions
// ------------------------------------
export const isEmailRegistered = email => {
	return adminClient.query(IS_EMAIL_REGISTERED(email))
}

export const saveVisualFlow = (studyState, idStudy, data, dispatch, getState) => {
	const prepareMutation =
		getIsStudyEditable(studyState) === true ? VISUAL_FLOW_UPDATE : LIVE_VISUAL_FLOW_UPDATE

	return createGraphqlRequest(
		false,
		prepareMutation(idStudy, data),
		API.VISUAL_FLOW_UPDATE,
		response => response,
	)(dispatch, getState)
}

export const postImage = (
	bucketName,
	mutationVariables,
	file,
	isAdminDashboard,
	isAccountMediaObject,
) => (dispatch, getState) => {
	const getMutation = () => {
		if (bucketName === BUCKET_NAMES.PROJECT) {
			return _.isNil(mutationVariables.idStudy) === false
				? ADD_STUDY_MEDIA_OBJECT(mutationVariables)
				: ADD_PRESENTATION_MEDIA_OBJECT(mutationVariables)
		}

		if (isAccountMediaObject === true) {
			return isAdminDashboard === true
				? ADMIN_ADD_ACCOUNT_MEDIA_OBJECT(mutationVariables)
				: ADD_ACCOUNT_MEDIA_OBJECT(mutationVariables)
		}

		return isAdminDashboard === true
			? ADMIN_ADD_USER_MEDIA_OBJECT(mutationVariables)
			: ADD_USER_MEDIA_OBJECT(mutationVariables)
	}

	return createGraphqlRequest(
		false,
		getMutation(),
		API.UPLOAD_MEDIA,
		data => data,
		false,
		isAdminDashboard,
		isAccountMediaObject,
	)(dispatch, getState).then(res => {
		if (res === undefined) {
			throw new Error('Get signedUploadUrl failed')
		}

		const resolveMediaObject = responseObject => {
			if (bucketName === BUCKET_NAMES.PROJECT) {
				return _.isNil(responseObject.response.study) === false
					? responseObject.response.study.addStudyMediaObject
					: responseObject.response.presentation.addPresentationMediaObject
			}

			return isAccountMediaObject === true
				? responseObject.response.account.addAccountMediaObject
				: responseObject.response.user.addUserMediaObject
		}

		const mediaObject = resolveMediaObject(res)

		const url = mediaObject.mediaUploadUrl

		const cacheControlHeaders =
			bucketName === BUCKET_NAMES.PROJECT
				? {
						'cache-control': 'private, max-age=300',
				  }
				: {
						'cache-control': 'private, max-age=3600',
				  }

		const uploadImageRequestData = {
			method: 'PUT',
			mode: 'cors',
			redirect: 'error',
			headers: {
				'x-goog-acl': 'public-read',
				...cacheControlHeaders,
			},
			body: file,
		}
		return fetch(url, uploadImageRequestData).then(storageResponse => {
			const fileUrl = mediaObject.url
			return {
				storageResponse,
				fileUrl,
			}
		})
	})
}

export const actions = {
	postImage,
	saveVisualFlow,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_CANCELABLE_REQUEST]: (state, action) => ({
		...state,
		cancelableRequests: {
			...state.cancelableRequests,
			[action.payload.action]: action.payload.idRequest,
		},
	}),
	[FINISH_CANCELABLE_REQUEST]: (state, action) => {
		const newCancelableRequests = { ...state.cancelableRequests }
		delete newCancelableRequests[action.payload.action]

		return { ...state, cancelableRequests: newCancelableRequests }
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}
