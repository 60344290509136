import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field as FormikField } from 'formik'
import _ from 'lodash'

import ErrorMessage from 'components/_formik/_base/ErrorMessage'
import Label from 'components/_formik/_base/Label'

import DivOrFragment from 'components/_scaffolding/DivOrFragment'

import { getValidate } from './validate'

class Field extends Component {
	static propTypes = {
		label: PropTypes.shape({
			errorLabel: PropTypes.string, // provide errorLabel if label.label isn't primitive
			label: PropTypes.string,
			showLabel: PropTypes.bool,
			className: PropTypes.string,
		}),
		secondaryLabel: PropTypes.shape({
			label: PropTypes.node,
			className: PropTypes.string,
		}),
		validate: PropTypes.shape({
			required: PropTypes.bool,
			validEmail: PropTypes.bool,
			validFileName: PropTypes.bool,
			validMomentDate: PropTypes.bool,
			validNumber: PropTypes.bool,
			validString: PropTypes.bool,
			validVariableName: PropTypes.bool,
			maxLength: PropTypes.number,
			minLength: PropTypes.number,
			max: PropTypes.number,
			min: PropTypes.number,
			matchValue: PropTypes.string,
		}),
		showErrorMessage: PropTypes.bool.isRequired,
		wrapWithDiv: PropTypes.bool.isRequired,
		wrapperClassName: PropTypes.string,
		disabled: PropTypes.bool,
	}

	static defaultProps = {
		showErrorMessage: true,
		wrapWithDiv: false,
	}

	validate =
		this.props.validate !== undefined
			? getValidate(this.props.validate, this.props.label.errorLabel || this.props.label.label)
			: () => {}

	render() {
		const {
			validate,
			label,
			secondaryLabel,
			showErrorMessage,
			wrapWithDiv,
			wrapperClassName,
			...props
		} = this.props

		return (
			<DivOrFragment wrapWithDiv={wrapWithDiv} className={wrapperClassName}>
				{label !== undefined && label.label !== undefined && label.showLabel !== false && (
					<Label
						className={label.className}
						secondaryClassName={_.isNil(secondaryLabel) ? undefined : secondaryLabel.className}
						disabled={this.props.disabled}
						label={label.label}
						secondaryLabel={_.isNil(secondaryLabel) ? undefined : secondaryLabel.label}
						required={validate && validate.required}
					/>
				)}
				<FormikField {...props} validate={this.validate} />
				{showErrorMessage === true && <ErrorMessage name={props.name} />}
			</DivOrFragment>
		)
	}
}

export default Field
