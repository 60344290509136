import PropTypes from 'prop-types'

export const TASKS_SUMMARY = 'TASKS_SUMMARY'

export const STUDY_NOTE_TYPES = {
	VISUAL_FLOW: 'visualFlow',
	REPORT: 'report',
}

export const DELETED_NOTE_TEXT = 'backend_translate.note_deleted'

export const getNoteShape = isIdParentNoteRequired =>
	PropTypes.exact({
		idNote: PropTypes.string.isRequired,
		idStudy: PropTypes.string.isRequired,
		idItem: PropTypes.string.isRequired,
		idParentNote: isIdParentNoteRequired === false ? PropTypes.string : PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
		task: PropTypes.exact({
			isTask: PropTypes.bool.isRequired,
			isDone: PropTypes.bool.isRequired,
			__typename: PropTypes.string.isRequired,
		}).isRequired,
		meta: PropTypes.exact({
			created: PropTypes.string.isRequired,
			updated: PropTypes.string.isRequired,
			__typename: PropTypes.string.isRequired,
		}).isRequired,
		userInfo: PropTypes.exact({
			idUserInfo: PropTypes.string.isRequired,
			idUser: PropTypes.string.isRequired,
			displayName: PropTypes.string.isRequired,
			image: PropTypes.exact({
				id: PropTypes.string.isRequired,
				url: PropTypes.string.isRequired,
				__typename: PropTypes.string.isRequired,
			}).isRequired,
			__typename: PropTypes.string.isRequired,
		}),
		__typename: PropTypes.string.isRequired,
	})
