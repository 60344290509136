import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux'
import { makeRootReducer } from 'store/tools/reducers'
import { logError } from 'helpers/logError'

import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import config from 'config'

export default (initialState = {}) => {
	// ======================================================
	// Middleware Configuration
	// ======================================================
	const crashReporter = store => next => action => {
		try {
			return next(action)
		} catch (err) {
			logError(err, store.getState, { action })

			throw err
		}
	}

	const middleware = [crashReporter, createDebounce(), thunk]

	// ======================================================
	// Store Enhancers
	// ======================================================
	const enhancers = []
	if (config.isDev === true) {
		const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
		if (typeof devToolsExtension === 'function') {
			enhancers.push(devToolsExtension())
		}
	}

	// ======================================================
	// Store Instantiation and HMR Setup
	// ======================================================
	const store = createStore(
		makeRootReducer(),
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers),
	)
	store.asyncReducers = {}

	if (module.hot) {
		module.hot.accept('./reducers', () => {
			const reducers = require('./reducers').default
			store.replaceReducer(reducers(store.asyncReducers))
		})
	}

	return store
}
