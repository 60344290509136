import validateFnArray from './evaluator/_validateFnArray'
import fnList from './evaluator/_fnList'

export const validate = (module, flow, config = null) => {
	if (
		module.definition.logic === undefined ||
		module.definition.logic === null ||
		Array.isArray(module.definition.logic) === false
	) {
		return {
			infos: null,
			warnings: null,
			errors: [{ message: 'logic has to be an array' }],
		}
	}

	const errors = []
	validateFnArray('', fnList.logic, module.definition.logic, errors)

	return {
		infos: null,
		warnings: null,
		errors,
	}
}

export default validate
