import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Tab from './_components/Tab'

import classes from './Tabs.module.scss'

const Tabs = ({ handleTabClick, idActiveTab, tabs, customClassName, hasSpacer }) => {
	return (
		<div
			className={classnames(classes.wrapper, {
				[customClassName]: customClassName !== null,
			})}
		>
			{tabs.map(tab => (
				<Fragment key={tab.idTab}>
					<Tab
						{...tab}
						handleTabClick={handleTabClick}
						isActive={tab.idTab === idActiveTab}
						isDisabled={tab.isDisabled === true}
					/>
					{tab.hasDivider === true && <div className={classes.divider} />}
				</Fragment>
			))}
			{hasSpacer === true && <div className={classes.spacer} />}
		</div>
	)
}

Tabs.defaultProps = {
	customClassName: null,
	hasSpacer: false,
}

Tabs.propTypes = {
	customClassName: PropTypes.string,
	handleTabClick: PropTypes.func.isRequired,
	hasSpacer: PropTypes.bool.isRequired,
	idActiveTab: PropTypes.string.isRequired,
	tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Tabs
