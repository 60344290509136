const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/CHOICE.json',
	properties: {
		idRelatedMessage: {
			$id: '/properties/idRelatedMessage',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: ['string', 'null'],
		},
		mandatory: {
			default: true,
			$id: '/properties/mandatory',
			type: 'boolean',
		},
		maxSelection: {
			default: 1,
			$id: '/properties/maxSelection',
			minimum: 1,
			type: 'integer',
		},
		minSelection: {
			default: 1,
			$id: '/properties/minSelection',
			minimum: 1,
			type: 'integer',
		},
		multiple: {
			default: false,
			$id: '/properties/multiple',
			type: 'boolean',
		},
		filter: {
			$id: '/properties/multiple',
			type: ['object', 'null'],
		},
		hasCenteredOptions: {
			$id: '/properties/hasCenteredOptions',
			type: 'boolean',
		},
		optionsShownLimit: {
			$id: '/properties/optionsShownLimit',
			type: ['integer', 'null'],
		},
		options: {
			additionalItems: false,
			$id: '/properties/options',
			items: {
				additionalProperties: false,
				$id: '/properties/options/items',
				properties: {
					id: {
						$id: '/properties/options/items/properties/id',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
						type: 'string',
					},
					code: {
						$id: '/properties/options/items/properties/code',
						type: 'integer',
					},
					showLabel: {
						$id: '/properties/options/items/properties/showLabel',
						type: 'boolean',
						default: true,
					},
					label: {
						$id: '/properties/options/items/properties/label',
						type: 'string',
					},
					media: {
						$id: '/properties/options/items/properties/media',
						oneOf: [{ $ref: 'http://groupsolver.com/v1/MEDIA_OBJECT.json' }, { type: 'null' }],
					},
					randomize: {
						$id: '/properties/options/items/properties/randomize',
						type: 'boolean',
						default: false,
					},
					isNoneOfThese: {
						default: false,
						$id: '/properties/options/items/properties/isNoneOfThese',
						type: 'boolean',
					},
					simpleName: {
						$id: '/properties/options/items/properties/simpleName',
						type: ['string', 'null'],
					},
					isHidden: {
						default: false,
						$id: '/properties/options/items/properties/isHidden',
						type: 'boolean',
					},
				},
				required: [
					'code',
					'id',
					'isNoneOfThese',
					'label',
					'media',
					'randomize',
					'showLabel',
					'simpleName',
					'isHidden',
				],
				type: 'object',
			},
			minItems: 1,
			type: 'array',
			uniqueItems: true,
		},
		subtype: {
			$id: '/properties/subtype',
			enum: ['CHECKBOX', 'SELECT', 'IMAGE', 'BLOCK'],
			type: 'string',
		},
		trapQuestionSettings: {
			$id: '/properties/trapQuestionSettings',
			additionalProperties: false,
			allRequired: true,
			properties: {
				isTrapQuestion: {
					$id: '/properties/trapQuestionSettings/isTrapQuestion',
					type: 'boolean',
				},
				selection: {
					$id: '/properties/trapQuestionSettings/selection',
					type: 'string',
				},
				correctAnswers: {
					$id: '/properties/trapQuestionSettings/correctAnswers',
					type: 'array',
					items: {
						type: 'string',
					},
				},
			},
		},
		messages: {
			$id: '/properties/messages',
			type: 'array',
			additionalItems: false,
			uniqueItems: true,
			items: {
				$id: '/properties/messages/items',
				type: 'object',
				additionalProperties: false,
				required: ['id', 'definition', 'type'],
				properties: {
					id: {
						$id: '/properties/messages/items/id',
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					type: {
						$id: '/properties/messages/items/type',
						type: 'string',
						enum: ['A_MESSAGE'],
					},
					definition: {
						$id: '/properties/messages/items/definition',
						$ref: 'http://groupsolver.com/v1/MESSAGE.json',
					},
				},
			},
		},
		dynamicOptionsSettings: {
			$id: '/properties/dynamicOptionsSettings',
			type: 'object',
			required: ['isActive', 'idMainList', 'idInputList', 'optionLabelColumn', 'optionsOrderMode'],
			additionalProperties: false,
			properties: {
				isActive: {
					$id: '/properties/dynamicOptionsSettings/isActive',
					type: 'boolean',
				},
				idMainList: {
					$id: '/properties/dynamicOptionsSettings/idMainList',
					type: ['string', 'null'],
				},
				idInputList: {
					$id: '/properties/dynamicOptionsSettings/idInputList',
					type: ['string', 'null'],
				},
				optionLabelColumn: {
					$id: '/properties/dynamicOptionsSettings/optionLabelColumn',
					type: ['string', 'null'],
				},
				optionsOrderMode: {
					$id: '/properties/dynamicOptionsSettings/optionsOrderMode',
					type: 'string',
					enum: ['QUESTION_ORDER', 'INPUT_LIST_ORDER'],
				},
			},
		},
	},
	required: [
		'mandatory',
		'minSelection',
		'idRelatedMessage',
		'subtype',
		'multiple',
		'options',
		'filter',
		'maxSelection',
		'trapQuestionSettings',
		'messages',
		'dynamicOptionsSettings',
		'hasCenteredOptions',
		'optionsShownLimit',
	],
	type: 'object',
}

export default schema
