import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Field from 'components/_formik/_complex/Field'
import MultiSelect from 'components/_formik/_base/MultiSelect'

import StatementOption from './_components/StatementOption'

const IncludedStatements = props => {
	const [inputValue, setInputValue] = useState('')

	const multiValueMaxHeight = props.maxHeight

	const styles = {
		clearIndicator: () => ({ display: 'none' }),
		multiValueLabel: base => ({ ...base, fontSize: '100%' }),
		control: base => ({
			...base,
			height: 'auto',
			maxHeight: multiValueMaxHeight,
			overflow: 'auto',
		}),
		indicatorsContainer: base => ({
			...base,
			// select height - border
			maxHeight: multiValueMaxHeight - 2,
			position: 'sticky',
			top: 0,
		}),
	}

	const handleInputChange = (value, { action }) => {
		if (action !== 'set-value') {
			setInputValue(value)
		}
	}

	const handleMenuClose = () => {
		setInputValue('')
	}

	return (
		<Field
			closeMenuOnSelect={false}
			component={MultiSelect}
			components={{ Option: StatementOption }}
			disabled={props.disabled}
			hideSelectedOptions={false}
			id="included-statements"
			isLoading={props.isLoading}
			label={props.label}
			name={props.fieldName}
			onChange={props.onChange}
			options={props.options}
			styles={styles}
			validate={{ required: true }}
			value={props.value}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			onMenuClose={handleMenuClose}
		/>
	)
}

const optionShape = PropTypes.shape({
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
})

IncludedStatements.defaultProps = {
	isLoading: false,
	maxHeight: 200,
}

IncludedStatements.propTypes = {
	disabled: PropTypes.bool.isRequired,
	fieldName: PropTypes.string.isRequired,
	isLoading: PropTypes.bool,
	label: PropTypes.object.isRequired,
	maxHeight: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(optionShape).isRequired,
	value: PropTypes.arrayOf(optionShape).isRequired,
}

export default IncludedStatements
