export const FREE_TEXT_TYPES = {
	TEXT: 'TEXT',
	DATE: 'DATE',
	EMAIL: 'EMAIL',
	ZIP: 'ZIP',
	INTEGER: 'INTEGER',
	NUMBER: 'NUMBER',
	AMOUNT: 'AMOUNT',
	PHONE: 'PHONE',
}
