import PropTypes from 'prop-types'
import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import DisabledLink from 'components/_scaffolding/_links/DisabledLink'

import { useHasUnsavedChanges } from 'hooks/useHasUnsavedChanges'

const Link = ({ children, isDisabled, tooltipText, unsavedChangesTooltipText, ...props }) => {
	const { hasUnsavedChanges } = useHasUnsavedChanges()

	const isLinkDisabled = isDisabled === true || hasUnsavedChanges === true

	if (isLinkDisabled === true) {
		return (
			<DisabledLink
				className={props.className}
				hasUnsavedChanges={hasUnsavedChanges}
				to={props.to}
				tooltipText={tooltipText}
				unsavedChangesTooltipText={unsavedChangesTooltipText}
			>
				{children}
			</DisabledLink>
		)
	}

	return <ReactRouterLink {...props}>{children}</ReactRouterLink>
}

Link.defaultProps = {
	className: null,
	isDisabled: false,
	tooltipText: null,
	unsavedChangesTooltipText: null,
}

Link.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	isDisabled: PropTypes.bool.isRequired,
	to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	tooltipText: PropTypes.string,
	unsavedChangesTooltipText: PropTypes.string,
}

export default Link
