export const validate = (module, flow, config = null) => {
	let entryModules = flow.filter(module => module.entryPoint === true)
	if (entryModules.length === 0) {
		return {
			infos: null,
			warnings: null,
			errors: ['No module is set as study entry point'],
		}
	} else if (entryModules.length > 1) {
		const entryIds = entryModules.map(module => module.id)
		return {
			infos: null,
			warnings: null,
			errors: [
				'More then one module is set as study entry point: ' +
					entryIds.join(', '),
			],
		}
	}

	return null
}

export default validate
