import React from 'react'
import PropTypes from 'prop-types'
import classes from './Checkbox.module.scss'

import AdvancedFeatureLabel from 'components/_scaffolding/AdvancedFeatureLabel'

import classnames from 'classnames'

const Checkbox = ({
	checked,
	className = '',
	description = null,
	disabled = false,
	label = null,
	labelClassName = '',
	name,
	onChange,
	value = null,
	isAdvancedFeature = false,
	isBig = false,
	isDarkTheme = false,
	isLabelBefore = false,
	...restOfProps
}) => {
	const handleOnChange = () => {
		if (disabled === true) return

		return onChange(name, checked === false, value)
	}

	const getLabelPositionClassNames = () => {
		if (label === null) {
			return null
		}

		return isLabelBefore === true ? classes['right-to-left'] : classes['left-to-right']
	}

	const wrapperClassNames = classnames(classes.wrapper, className, getLabelPositionClassNames())

	const checkBoxClassNames = classnames(classes.checkbox, {
		[classes['checkbox--big']]: isBig === true,
		[classes['checkbox--checked']]: checked === true,
		[classes['checkbox--dark-theme']]: isDarkTheme === true,
		[classes['checkbox--disabled']]: disabled === true,
		[classes['checkbox--disabled-dark-theme']]: isDarkTheme === true && disabled === true,
	})

	return (
		<div {...restOfProps} className={wrapperClassNames}>
			<div className={checkBoxClassNames} data-testid={name} onClick={handleOnChange} />
			<div className={classes.titles}>
				{label !== null && (
					<div onClick={handleOnChange} className={classnames(classes.label, labelClassName)}>
						{label}
						{isAdvancedFeature === true && <AdvancedFeatureLabel />}
					</div>
				)}
				{description !== null && <div className={classes.description}>{description}</div>}
			</div>
		</div>
	)
}

Checkbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	className: PropTypes.string,
	description: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	labelClassName: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any,
	isAdvancedFeature: PropTypes.bool,
	isBig: PropTypes.bool,
	isDarkTheme: PropTypes.bool,
	isLabelBefore: PropTypes.bool,
}

export default Checkbox
