import { VISUAL_FLOW_INVALID_MODULE_TYPES, VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { desanitizeMatrixAttributesShortName } from 'helpers/visualFlowModules/sanitizeMatrixAttributesShortName'

const validateShortName = (module, duplicateShortNamesObject) => {
	const validationResult = []

	if (
		[VISUAL_FLOW_MODULE_TYPES.A_MESSAGE, VISUAL_FLOW_MODULE_TYPES.SET_VARIABLE].includes(
			module.type,
		) === true
	) {
		return validationResult
	}

	if (
		module.type === VISUAL_FLOW_MODULE_TYPES.UI_COMMAND &&
		module.definition.enableConditions === false
	) {
		return validationResult
	}

	if (
		module.definition.shortName === null ||
		module.definition.shortName === undefined ||
		module.definition.shortName.trim().length === 0
	) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_requiredSimpleName,
		})

		return validationResult
	}

	if (duplicateShortNamesObject[module.definition.id] !== undefined) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_nonUniqueSimpleName,
		})

		return validationResult
	}

	if (desanitizeMatrixAttributesShortName(module.definition.shortName).length > 50) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_simpleNametooLong,
		})

		return validationResult
	}

	if (new RegExp(/^\S\.*/).test(module.definition.shortName) === false) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_invalid,
		})
	}

	if (module.definition.shortName.includes('.') === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.dataset_shortNameContainsDot,
		})
	}

	return validationResult
}

export default validateShortName
