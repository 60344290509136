import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'

import {
	isAllocationRangeMinValid,
	isAllocationRangeValid,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateAllocation'

const AllocationRange = ({ disabled, setValues, values }) => {
	const intl = useIntl()

	const handleRangeMinChange = event => {
		const minValue = event.currentTarget.value
		const normalizedMinValue = minValue === '' ? '' : Number(minValue)

		setValues({
			...values,
			options: values.options.map(option => ({
				...option,
				defaultValue:
					option.isNoneOfThese === true
						? option.defaultValue
						: Math.max(option.defaultValue, Number(minValue)),
			})),
			range: {
				...values.range,
				min: normalizedMinValue,
			},
		})
	}

	return (
		<div>
			<Label label={intl.formatMessage({ id: 'allocation.detail.range.min.label' })} />
			<Field
				component={Input}
				disabled={disabled}
				name="range.min"
				onChange={handleRangeMinChange}
				placeholder={intl.formatMessage({ id: 'enter_a_number' })}
				type="number"
			/>
			{isAllocationRangeMinValid({ definition: values }) === false && (
				<span className="title-error">
					{intl.formatMessage({ id: 'allocation.detail.range.min.required' })}
				</span>
			)}
			<Label label={intl.formatMessage({ id: 'allocation.detail.range.max.label' })} />
			<Field
				component={Input}
				disabled={disabled}
				name="range.max"
				placeholder={intl.formatMessage({ id: 'enter_a_number' })}
				type="number"
			/>
			{isAllocationRangeValid({ definition: values }) === false && (
				<span className="title-error">
					{intl.formatMessage({ id: 'allocation.detail.range.invalid' })}
				</span>
			)}
		</div>
	)
}

AllocationRange.propTypes = {
	disabled: PropTypes.bool.isRequired,
	setValues: PropTypes.func.isRequired,
	values: PropTypes.shape({
		range: PropTypes.shape({
			max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		}).isRequired,
	}).isRequired,
}

export default AllocationRange
