export const validate = (module, flow, config = null) => {
	if (
		module.definition.history === null &&
		module.definition.layout === null &&
		module.definition.actionButton === null
	) {
		return {
			infos: null,
			warnings: null,
			errors: [
				'Empty UI Command: history, layout and actionButton cannot be null at the same time.',
			],
		}
	} else {
		return null
	}
}

export default validate
