import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { Field } from 'formik'

import { findSelectedList } from 'helpers/visualFlowModules/findSelectedList'
import { getAvailableInputListOptions } from 'helpers/visualFlowModules/getAvailableInputListOptions'
import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'

import Checkbox from 'components/_formik/_base/Checkbox'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'
import Slider from 'components/_formik/_base/Slider'

import LoopItemReferencingTooltip from 'components/_scaffolding/LoopItemReferencingTooltip'

const LoopSettings = props => {
	const intl = useIntl()

	const selectedList = findSelectedList(props.values.loopSettings.idInputList, props.upperLists)

	const inputListOptions = getAvailableInputListOptions(
		getIsStudyEditable(props.studyState),
		props.isUnsaved,
		selectedList,
		props.upperLists,
	)

	const hasEmptyListName = props.values.loopSettings.idInputList === ''
	const hasInvalidListSelected =
		props.values.loopSettings.idInputList !== '' && selectedList === null

	const renderError = idTranslation => (
		<div className="title-error">{intl.formatMessage({ id: idTranslation })}</div>
	)

	return (
		<div>
			<DatasetShortName
				disabled={props.disabled}
				errorInvalidSimpleName={props.errorInvalidSimpleName}
				errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
				label={intl.formatMessage({ id: 'loop.detail.short_name.label' })}
				values={props.values}
			/>
			{props.hasZeroNestedModules === true && (
				<span className="title-error">
					{intl.formatMessage({
						id: 'loop.detail.empty.error',
					})}
				</span>
			)}
			<Label label={intl.formatMessage({ id: 'list_filter.detail.input' })} />
			<Field
				component={Select}
				disabled={props.disabled}
				name={'loopSettings.idInputList'}
				options={inputListOptions}
				placeholder={intl.formatMessage({ id: 'select_list_placeholder' })}
			/>
			{hasEmptyListName === true && renderError('list_filter.detail.empty_name')}
			{hasInvalidListSelected === true && renderError('list_filter.detail.list_does_not_exist')}
			<LoopItemReferencingTooltip />
			{selectedList !== null && (
				<Fragment>
					<Label label={intl.formatMessage({ id: 'loop_settings' })} />
					<Field
						component={Checkbox}
						componentProps={{
							label: intl.formatMessage({ id: 'randomize' }),
						}}
						disabled={props.disabled}
						name={'randomize'}
					/>
					<Label
						label={intl.formatMessage(
							{ id: 'loop.detail.max_iterations' },
							{
								count:
									props.values.loopSettings.maxIterations ?? selectedList.definition.items.length,
							},
						)}
					/>
					<Field
						component={Slider}
						disabled={props.disabled}
						max={selectedList.definition.items.length}
						min={1}
						name="loopSettings.maxIterations"
						{...Slider.STYLE_PRESETS.light}
						step={1}
						onChange={value => {
							props.setFieldValue(
								'loopSettings.maxIterations',
								value === selectedList.definition.items.length ? null : value,
							)
						}}
						value={props.values.loopSettings.maxIterations ?? selectedList.definition.items.length}
					/>
					<Field
						component={Checkbox}
						componentProps={{
							label: intl.formatMessage({ id: 'randomizer.allow_skip' }),
						}}
						disabled={props.disabled}
						name={'randomizerAllowSkip'}
					/>
					<div className="title-info">
						{intl.formatMessage({
							id:
								props.values.randomizerAllowSkip === true
									? 'randomizer.allow_skip.enabled'
									: 'randomizer.allow_skip.disabled',
						})}
					</div>
				</Fragment>
			)}
		</div>
	)
}

LoopSettings.propTypes = {
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	hasZeroNestedModules: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	setFieldValue: PropTypes.func.isRequired,
	studyState: PropTypes.string.isRequired,
	upperLists: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	values: PropTypes.object.isRequired,
}

export default LoopSettings
