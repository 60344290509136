import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const QuantQualRoute = lazy(() => import('./QuantQualRoute'))
const WrappedQuantQualRoute = errorWrapper(QuantQualRoute)

export default () => ({
	path: 'quant-qual',
	element: <WrappedQuantQualRoute />,
})
