import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'
import _ from 'lodash'

import { COLORS } from 'constants/colors'
import { prepareStyles } from 'helpers/reactSelect/prepareStyles'
import { reactSelectStyles } from 'helpers/reactSelect/reactSelectStyles'

import isFunction from 'lodash/isFunction'

const createCustomSelectComponent = C => props => {
	if (isFunction(props.data.renderWrapWith)) {
		return props.data.renderWrapWith(props, C)
	}

	return <C {...props} />
}

const Group = createCustomSelectComponent(components.Group)
const Option = createCustomSelectComponent(components.Option)

export default class Select extends Component {
	static propTypes = {
		className: PropTypes.string,
		classNamePrefix: PropTypes.string,
		components: PropTypes.objectOf(PropTypes.func),
		onChange: PropTypes.func.isRequired,
		options: PropTypes.arrayOf(PropTypes.object).isRequired,
		shouldValidate: PropTypes.bool.isRequired,
		styles: PropTypes.objectOf(PropTypes.func),
		filterByValue: PropTypes.bool,
		useKeyboard: PropTypes.bool,
		value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
		noOptionsMessage: PropTypes.func.isRequired,
	}

	static defaultProps = {
		className: 'select-dropdown__wrapper',
		classNamePrefix: 'select-dropdown',
		components: {},
		filterByValue: false,
		shouldValidate: false,
		styles: {},
		useKeyboard: false,
		value: undefined,
		noOptionsMessage: _.noop,
	}

	components = { Group, Option, ...this.props.components }

	componentDidMount() {
		this.props.useKeyboard === true && window.addEventListener('keydown', this.handleOnKeyDown)
	}

	componentWillUnmount() {
		this.props.useKeyboard === true && window.removeEventListener('keydown', this.handleOnKeyDown)
	}

	getStyles = () => ({
		container: base => ({ ...base, minWidth: 0 }),
		control: (base, state) => ({
			...base,
			...reactSelectStyles.control(
				base,
				state,
				this.props.isValid !== false &&
					this.isValid(this.props.options, this.props.value) !== false,
			),
		}),
		dropdownIndicator: base => ({ ...base, padding: 4 }),
		indicatorSeparator: _ => ({ display: 'none' }),
		indicatorsContainer: base => ({ ...base, height: 28 }),
		valueContainer: reactSelectStyles.valueContainer,
		singleValue: reactSelectStyles.singleValue,
		menu: reactSelectStyles.menu,
		menuList: reactSelectStyles.menuList,
		option: reactSelectStyles.option,
		placeholder: base => ({ ...base, fontSize: 12 }),
		noOptionsMessage: base => ({ ...base, fontSize: 12 }),
	})

	getDefaultTheme = theme => ({
		...theme,
		borderRadius: 3,
		colors: {
			...theme.colors,
			text: COLORS.GREY_10,
			primary: COLORS.PRIMARY_45,
			primary25: COLORS.GREY_90,
		},
		spacing: { ...theme.spacing, baseUnit: 3 },
	})

	isValid = (options, currentOption) => {
		if (this.props.shouldValidate === false) return true
		return currentOption !== undefined && options.find(o => o === currentOption) !== undefined
	}

	filterOption = (option, str) => {
		const { isVisible, label } = option.data
		const isOptionVisible = isVisible === undefined || isVisible === true

		return (
			isOptionVisible === true &&
			(String(label)
				.toLowerCase()
				.includes(String(str).toLowerCase()) ||
				(this.props.filterByValue === true &&
					String(option.value)
						.toLowerCase()
						.includes(String(str).toLowerCase())))
		)
	}

	handleOnChange = (option, action) => {
		if (this.props.value === option) {
			return
		}

		this.props.onChange(option, action)
	}

	handleOnKeyDown = e => {
		const useControlKey = e.ctrlKey === true || e.metaKey === true

		if (this.props.useKeyboard === false || useControlKey === false) return

		const isArrowUp = e.keyCode === 38
		const isArrowDown = e.keyCode === 40

		if (isArrowUp === false && isArrowDown === false) return

		const { options, value } = this.props
		const valueIndex = options.findIndex(o => o === value)
		const optionsLength = options.length

		if (valueIndex < 0) return
		if (isArrowUp === true && valueIndex === 0) return
		if (isArrowDown === true && valueIndex === optionsLength - 1) return

		const step = isArrowUp === true ? -1 : 1
		this.handleOnChange(options[valueIndex + step])
	}

	render() {
		const { styles, ...props } = this.props
		const preparedStyles = prepareStyles(this.getStyles(), styles)

		return (
			<ReactSelect
				{...props}
				components={this.components}
				filterOption={this.filterOption}
				onChange={this.handleOnChange}
				onKeyDown={this.handleOnKeyDown}
				styles={preparedStyles}
				theme={this.getDefaultTheme}
			/>
		)
	}
}
