import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './BlockingContent.module.scss'

const BlockingContent = ({ children, className }) => {
	return (
		<div
			className={classnames(classes.content, {
				[className]: className !== null,
			})}
		>
			{children}
		</div>
	)
}

BlockingContent.defaultProps = {
	className: null,
}

BlockingContent.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}

export default BlockingContent
