import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'

const hasInvalidTimeout = module => module.definition.timeout < 500

const hasTooLongText = module => module.definition.text.length > 1000

export const hasInvalidMediaAlt = (media, studyTags) => {
	if (media === null) {
		return false
	}

	if (media.url === null || media.url === '') {
		return false
	}

	if (studyTags.isAccessibilityEnabled === true && media.alt === '') {
		return true
	}

	return false
}

const validateMessage = (module, studyTags) => {
	const validationResult = []

	if (hasInvalidTimeout(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.message_timeoutMinTimeout,
		})
	}

	if (hasTooLongText(module) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.message_text_too_long,
		})
	}

	if (hasInvalidMediaAlt(module.definition.media, studyTags) === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.message_media_missing_alt,
		})
	}

	return validationResult
}

export default validateMessage
