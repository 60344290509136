const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/FREE_TEXT.json',
	properties: {
		idRelatedMessage: {
			$id: '/properties/idRelatedMessage',
			pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
			type: ['string', 'null'],
		},
		inputHint: {
			$id: '/properties/inputHint',
			type: ['string', 'null'],
		},
		mandatory: {
			default: true,
			$id: '/properties/mandatory',
			type: 'boolean',
		},
		maxInputLength: {
			minimum: 0,
			maximum: 1000,
			$id: '/properties/maxInputLength',
			type: ['integer', 'null'],
		},
		placeholder: {
			$id: '/properties/placeholder',
			type: 'string',
		},
		subtype: {
			$id: '/properties/subtype',
			type: 'string',
			enum: ['INPUT', 'TEXTAREA'],
		},
		validationPattern: {
			default: '.*',
			$id: '/properties/validationPattern',
			type: 'string',
		},
		messages: {
			$id: '/properties/messages',
			type: 'array',
			additionalItems: false,
			uniqueItems: true,
			items: {
				$id: '/properties/messages/items',
				type: 'object',
				additionalProperties: false,
				required: ['id', 'definition', 'type'],
				properties: {
					id: {
						$id: '/properties/messages/items/id',
						type: 'string',
						pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
					},
					type: {
						$id: '/properties/messages/items/type',
						type: 'string',
						enum: ['A_MESSAGE'],
					},
					definition: {
						$id: '/properties/messages/items/definition',
						$ref: 'http://groupsolver.com/v1/MESSAGE.json',
					},
				},
			},
		},
	},
	required: [
		'mandatory',
		'maxInputLength',
		'idRelatedMessage',
		'subtype',
		'inputHint',
		'validationPattern',
		'placeholder',
		'messages',
	],
	type: 'object',
}

export default schema
