import _ from 'lodash'
import { LIST_INPUT_TYPE } from 'constants/studyDesign'

import { findSelectedList } from 'helpers/visualFlowModules/findSelectedList'

export const getAvailableInputListOptions = (
	isStudyEditable,
	isModuleUnsaved,
	selectedMainList,
	upperLists,
) => {
	const availableUpperLists =
		isStudyEditable === true || isModuleUnsaved === true || _.isNil(selectedMainList) === true
			? upperLists
			: upperLists.filter(module => {
					if (module.definition.inputType === LIST_INPUT_TYPE.FILE) {
						return module.definition.id === selectedMainList.definition.id
					}

					const mainList = findSelectedList(module.definition.id, upperLists)

					return mainList.definition.id === selectedMainList.definition.id
			  })

	return availableUpperLists.map(listModule => ({
		value: listModule.definition.id,
		label: listModule.definition.shortName,
	}))
}
