import { v4 as uuidv4 } from 'uuid'
import { enTranslator as intl } from 'intl.js'
import _ from 'lodash'

import MEDIA_OBJECT from './helpers/MEDIA_OBJECT'
import Message from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Message'
import { VISUAL_FLOW_MODULE_TYPES, CHOICE_SUBTYPE } from 'constants/studyDesign'
import { MESSAGE_DESCRIPTION } from './constants/MESSAGE'
import { getSettings as getFreetextSettings } from './FREE_TEXT'

const getIdRelatedAnswer = definition => _.get(definition, 'idRelatedAnswer', null)

const getTranslations = (definition, modules = null, options) => {
	const isPreviewOnly = _.get(options, 'isPreviewOnly', false)

	if (isPreviewOnly === true) {
		return { title: 'message.title.preview', description: 'message.description.preview' }
	}

	const messageTranslations = { title: 'message.title', description: 'message.description' }

	if (modules === null) {
		return messageTranslations
	}

	const idRelatedAnswer = getIdRelatedAnswer(definition)
	const relatedAnswerModule = modules[idRelatedAnswer]

	if (idRelatedAnswer === null || relatedAnswerModule === undefined) {
		return messageTranslations
	}

	const { type } = relatedAnswerModule

	if (type === VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT) {
		return {
			title: getFreetextSettings(relatedAnswerModule.definition.freetextType).title,
			description: MESSAGE_DESCRIPTION[type],
		}
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.A_OEQ) {
		return { title: 'oeq.title', description: MESSAGE_DESCRIPTION[type] }
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.ALLOCATION) {
		return { title: 'allocation.title', description: MESSAGE_DESCRIPTION[type] }
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.RANKING) {
		return { title: 'ranking.title', description: MESSAGE_DESCRIPTION[type] }
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.A_CHOICE) {
		return {
			title:
				relatedAnswerModule.definition.subtype === CHOICE_SUBTYPE.IMAGE
					? 'imageChoice.title'
					: 'choice.title',
			description: MESSAGE_DESCRIPTION[type][relatedAnswerModule.definition.subtype],
		}
	}

	if (type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE) {
		return {
			title:
				relatedAnswerModule.definition.subtype === CHOICE_SUBTYPE.IMAGE
					? 'matrixImageChoice.title'
					: 'matrixChoice.title',
			description: MESSAGE_DESCRIPTION[type][relatedAnswerModule.definition.subtype],
		}
	}

	return messageTranslations
}

const getText = additionalProperties =>
	_.get(additionalProperties, 'text', intl.formatMessage({ id: 'message.default_text' }))

const getTitle = (definition, modules, options) => {
	const { title } = getTranslations(definition, modules, options)

	return title
}

const getDescription = (definition, modules, options) => {
	const { description } = getTranslations(definition, modules, options)

	return description
}

const generator = (modules, additionalProperties) => ({
	idRelatedAnswer: getIdRelatedAnswer(additionalProperties),
	text: getText(additionalProperties),
	media: MEDIA_OBJECT(),
	timeout: 1000,
})

export const GET_QUESTION_MESSAGE = (idTextTranslation = 'message.question.default') => ({
	type: VISUAL_FLOW_MODULE_TYPES.A_MESSAGE,
	definition: {
		id: uuidv4(),
		...generator(
			{},
			{
				text: intl.formatMessage({ id: idTextTranslation }),
			},
		),
	},
})

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_MESSAGE,
	style: 'message',
	title: getTitle,
	description: getDescription,
	component: Message,
	generator,
}
