export const validate = (module, flow, config = null) => {
	let knownSimplenames = {}
	let errors = []

	flow.map(module => {
		if (knownSimplenames[module.simpleName]) {
			// module simpleName already exists
			errors.push('Duplicate module simpleName: ' + module.simpleName)
		} else if (module.simpleName === null) {
			// do nothing, dont add to known modules
		} else {
			// add module to knownSimplenames
			knownSimplenames[module.simpleName] = true
		}
	})

	if (errors.length > 0) {
		return {
			infos: null,
			warnings: null,
			errors,
		}
	} else {
		return null
	}
}

export default validate
