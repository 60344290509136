import { gql } from '@apollo/client'

export const mediaObject = gql`
	fragment mediaObject on MediaObject {
		idMediaObject
		url
		filename
		type
		errors
		isUploadFinished
		isPostProcessingFinished
	}
`
