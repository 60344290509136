import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const StudyDesign = lazy(() => import('./StudyDesign'))
const WrappedStudyDesign = errorWrapper(StudyDesign)

export default () => ({
	path: 'study-design',
	element: <WrappedStudyDesign />,
})
