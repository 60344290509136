import { combineReducers } from 'redux'

import api from 'store/api'
import notification from 'store/notification'
import studyDesign from 'routes/_study/StudyDesign/_store/studyDesign'

export const makeRootReducer = asyncReducers =>
	combineReducers({
		// Add sync reducers here
		api,
		notification,
		studyDesign,
		...asyncReducers,
	})

export const injectReducer = (store, { key, reducer }) => {
	if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

	store.asyncReducers[key] = reducer
	store.replaceReducer(makeRootReducer(store.asyncReducers))
}
