import { REPORT_TYPES, REPORT_BLOB_TYPES } from 'constants/reports'

import { tools } from 'constants/tools'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import {
	getSegmentTotalSupportPercentageKey,
	getSegmentTotalSupportCompletesKey,
} from 'store/reportBuilder'

export const calculateLegendIdeas = (
	idStudy,
	reportType,
	segmentationTypingTools,
	slideSettings,
	blobData,
	chartColorSettings,
) => {
	if (reportType !== REPORT_TYPES.SEGMENTATION_TYPING_TOOL) {
		return []
	}

	const { idStudyObject } = slideSettings

	const totalSegmentDataToCheck =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.SEGMENTATION_TYPING_TOOL_STATISTICS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (hasBlobData([totalSegmentDataToCheck], 'segments') === false) {
		return []
	}

	const defaultColors = chartColorSettings.closeEnded.colors

	const totalSegmentData = {
		...totalSegmentDataToCheck,
		segments: totalSegmentDataToCheck.segments.map((s, index) => ({
			...s,
			color: defaultColors[index % defaultColors.length],
		})),
	}

	const dataset = segmentationTypingTools.find(
		typingTool => typingTool.idSegmentationTypingTool === idStudyObject,
	)

	if (dataset === undefined) {
		return []
	}

	const datapoints = dataset.segments.map((segment, index) => {
		const segmentData = totalSegmentData.segments.find(
			d => d.idSegmentationTypingToolSegment === segment.idSegmentationTypingToolSegment,
		)

		const letter = segmentData?.letter ?? '-'
		const fill = segmentData?.color ?? 'grey'
		const respondentsCount = segmentData?.respondentsCount ?? 0
		const percentage =
			totalSegmentData.respondentsCount === 0
				? 0
				: Math.round(
						Number(
							Math.round((respondentsCount / totalSegmentData.respondentsCount) * 10000) / 100,
						),
				  )

		return {
			letter,
			name: segment.name,
			code: index,
			themeName: null,
			fill,
			// TODO:
			// hasCustomColor
			hasCustomColor: false,
			id: segment.idSegmentationTypingToolSegment,
			shown: true,
			unit: '%',
			[getSegmentTotalSupportPercentageKey(tools.TOTAL_SEGMENT_UUID)]: percentage,
			[getSegmentTotalSupportCompletesKey(tools.TOTAL_SEGMENT_UUID)]: respondentsCount,
			toExport: () => [letter, segment.name, percentage, respondentsCount],
		}
	})

	return datapoints
}
