import { enTranslator as intl } from 'intl.js'

import OpenQuestion from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/OpenQuestion'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { GET_QUESTION_MESSAGE } from './MESSAGE'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.A_OEQ,
	style: 'open-question',
	title: () => 'oeq.title',
	description: () => 'oeq.description',
	component: OpenQuestion,
	generator: (modules, _additionalProperties) => ({
		messages: [GET_QUESTION_MESSAGE()],
		idRelatedMessage: null,
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'oeq.default_dataset_name' }),
			VISUAL_FLOW_MODULE_TYPES.A_OEQ,
		),
		enableGibberishFilter: true,
		enableProfanityFilter: true,
		enableAutoTranslate: false,
		preseededStatements: [],
		ideate: {
			show: true,
			minIdeateUpperLimit: null,
			minIdeateLowerLimit: null,
			ideaPoolLimitShare: null,
			ideaPoolLimitNumber: null,
			idPriorMessage: null,
			disableSentenceSplitter: false,
		},
		eliminate: {
			show: true,
			idPriorMessage: null,
		},
		elaborate: {
			show: true,
			idPriorMessage: null,
			minEvaluations: 8,
			maxEvaluations: 12,
		},
		translations: {
			ideate_placeholder: '',
			elaborate_intro: '',
			elaborate_agree_label: '',
			elaborate_disagree_label: '',
			elaborate_indifferent_label: '',
			elaborate_unclear_statement_label: '',
		},
	}),
}
