export const SHARE_PRESENTATION = '/share'

export const ADMIN_PATHS = {
	ADMIN: '/admin',
	ADMIN_HOME: '/admin/home',
	ADMIN_STUDY_LIST: '/admin/study/list',
	ADMIN_STUDY_DETAIL: '/admin/study/detail',
	ADMIN_USER_LIST: '/admin/user/list',
	ADMIN_USER_ASSIGN_ACCOUNT: '/admin/user/assign-account',
	ADMIN_CREATE_USER: '/admin/user/create',
	ADMIN_CREATE_TEST_USER: '/admin/user/test/create',
	ADMIN_UPDATE_USER: '/admin/user/update',
	ADMIN_ACCOUNT_LIST: '/admin/account/list',
	ADMIN_ACCOUNT_ASSIGN_STUDY: '/admin/account/assign-study',
	ADMIN_ACCOUNT_ASSIGN_USER: '/admin/account/assign-user',
	ADMIN_CREATE_ACCOUNT: '/admin/account/create',
	ADMIN_UPDATE_ACCOUNT: '/admin/account/update',
	ADMIN_MANAGE_DOCUMENTS: '/admin/legal',
}

export const ADMIN_NAVIGATION_PATHS = [
	ADMIN_PATHS.ADMIN_STUDY_LIST,
	ADMIN_PATHS.ADMIN_USER_LIST,
	ADMIN_PATHS.ADMIN_CREATE_USER,
	ADMIN_PATHS.ADMIN_CREATE_TEST_USER,
	ADMIN_PATHS.ADMIN_ACCOUNT_LIST,
	ADMIN_PATHS.ADMIN_CREATE_ACCOUNT,
	ADMIN_PATHS.ADMIN_MANAGE_DOCUMENTS,
]

const STUDY_PATHS = {
	ALLOCATION_ANSWERS: '/allocation-answers',
	ANSWER_MANAGER: '/answermanager',
	CHOICE_ANSWERS: '/choice-answers',
	COMMUNITY: '/community',
	CONSENSUS: '/consensus',
	DATA_DOWNLOADER: '/datadownloader',
	DATA_IMPORTER: '/data-importer',
	FREE_TEXT: '/freetext',
	IDEACLOUD: '/ideacloud',
	IDEACLUSTER: '/idea-cluster',
	INTELLISEGMENT: '/intellisegment',
	LIVESLIDES: '/live-slides',
	MATRIX: '/matrix',
	NEW_IDEACLOUD: '/new-ideacloud',
	OPEN_ANSWERS: '/open-answers',
	PANELS: '/panels',
	QUANT_QUAL: '/quant-qual',
	RANKING_ANSWERS: '/ranking-answers',
	REPORT: '/report',
	RESPONDENT_MANAGER: '/respondent-manager',
	SEGMENTATION_TYPING_TOOL: '/segmentation-typing-tool',
	SEGMENTS: '/segments',
	STUDY_DESIGN: '/study-design',
}

const ACCOUNT_PATHS = {
	ACCOUNT: '/account',
	DASHBOARD: '/dashboard',
	LIBRARIES: '/libraries',
	PRESENTATION_LIST: '/presentations',
	STUDIES: '/studies',
	UPGRADE: '/upgrade',
	SUMMARY: '/summary',
	PAYMENT_STATUS: '/payment-status',
}

const LIVE_SLIDES_PATHS = {
	PRESENTATION: '/presentation',
	SHARE_PRESENTATION: '/share',
}

const AUTH_PATHS = {
	AUTH: '/auth',
	AUTH_CREATE_USER: '/auth/create-user',
	AUTH_LOGIN: '/auth/login',
	AUTH_PASSWORD_CHANGED: '/auth/password-changed',
	AUTH_EMAIL_VERIFIED: '/auth/email-verified',
	AUTH_VERIFY_PHONE_CODE: '/auth/verify-phone-code',
	AUTH_VERIFY_PHONE_FAILED: '/auth/verify-phone-failed',
	AUTH_EMAIL_VERIFY_SEND: '/auth/email-verify-was-send',
	AUTH_EMAIL_NOT_VERIFY: '/auth/email-not-verify',
	AUTH_NEW_PASSWORD: '/auth/new-password',
	AUTH_FORGOTTEN_PASSWORD: '/auth/forgotten-password',
	AUTH_ACCOUNT_DISABLED: '/auth/account-disabled',
	AUTH_CREATED_USER: '/auth/created-user',
}
export const PATHS = {
	...AUTH_PATHS,
	HOME: '/home',
	INBOX: '/inbox',
	STUDY: '/study',
	REPORT_EXPORT: '/reportExport',
	...ACCOUNT_PATHS,
	...ADMIN_PATHS,
	...LIVE_SLIDES_PATHS,
	...STUDY_PATHS,
}

export const LIVE_SLIDES_SUBPATHS = {
	EDITOR: '/editor',
	VIEW: '/view',
}

export const PUBLIC_PATHS = [
	'/logout',
	'/privacy-policy',
	'/terms-and-conditions',
	PATHS.AUTH,
	PATHS.SHARE_PRESENTATION,
]
