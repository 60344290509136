import noop from 'lodash/noop'

const handleFormikSubmit = (
	mutate,
	variables,
	values,
	actions,
	onError = noop,
	onCompleted = noop,
) => {
	mutate({ variables })
		.then(data => {
			actions.setSubmitting(false)
			actions.resetForm({ values })
			onCompleted(data)
		})
		.catch(error => {
			actions.setSubmitting(false)
			onError(error, actions)
		})
}

export default handleFormikSubmit
