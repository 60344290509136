import React from 'react'
import PropTypes from 'prop-types'

import { fieldShape } from 'components/_formik/_constants'

import RadioListComponent from 'components/_scaffolding/_input/RadioList'

const RadioList = ({ disabled = false, field, ...props }) => {
	const onChange = value => {
		if (disabled === true) {
			return
		}

		if (props.onChange !== undefined) {
			props.onChange(value)
		} else {
			props.form.setFieldValue(field.name, value)
		}
	}

	return (
		<RadioListComponent
			disabled={disabled}
			listTheme={props.listTheme}
			onChange={onChange}
			options={props.options}
			value={field.value}
		/>
	)
}

RadioList.LIST_THEMES = RadioListComponent.LIST_THEMES

RadioList.propTypes = {
	disabled: PropTypes.bool,
	field: fieldShape.isRequired,
	listTheme: PropTypes.string,
	options: PropTypes.array.isRequired,
	// formik bag
	form: PropTypes.object.isRequired,
}

export default RadioList
