import _ from 'lodash'

import { VISUAL_FLOW_REMOVE_TYPES } from 'constants/studyDesign'
import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import { getIsSoftModuleRemove } from 'helpers/visualFlowModules/getIsSoftModuleRemove'

const getNestedModuleIds = branch => {
	const ids = []

	branch.forEach(orderModule => {
		ids.push(orderModule.id)

		if (orderModule.type === MODULE_DEFINITIONS.UI_COMMAND.type) {
			ids.push(...getNestedModuleIds(orderModule.then))
			ids.push(...getNestedModuleIds(orderModule.else))
		}
	})

	return ids
}

const removeFromModules = (idToRemove, pathTo, removeType, modules, order) => {
	const isSoftRemove = getIsSoftModuleRemove(removeType)

	let currentBranch = [...order]

	pathTo.split('_').forEach((path, i) => {
		currentBranch =
			i !== 0 && i !== pathTo.split('_').length - 1 ? currentBranch[path] : currentBranch
	})

	const moduleToRemove = currentBranch.find(({ id }) => id === idToRemove)

	const updatedModules = { ...modules }

	const isRemoveAllAction = [
		VISUAL_FLOW_REMOVE_TYPES.REMOVE_ALL,
		VISUAL_FLOW_REMOVE_TYPES.SOFT_REMOVE_ALL,
	].includes(removeType)

	if (
		modules[idToRemove].type === MODULE_DEFINITIONS.UI_COMMAND.type &&
		isRemoveAllAction === true
	) {
		const idsModulesToRemove = [
			...getNestedModuleIds(moduleToRemove.then),
			...getNestedModuleIds(moduleToRemove.else),
		]

		idsModulesToRemove.forEach(idNestedModule => {
			if (isSoftRemove === true) {
				updatedModules[idNestedModule] = {
					...updatedModules[idNestedModule],
					isHidden: true,
				}
			} else {
				delete updatedModules[idNestedModule]
			}
		})
	} else if (moduleToRemove.type === MODULE_DEFINITIONS.UI_COMMAND.type) {
		const deleteEndpointFromBranchFn = branchType => {
			const branch = moduleToRemove[branchType]
			const lastModuleInBranch = branch[branch.length - 1]

			if (lastModuleInBranch?.type === MODULE_DEFINITIONS.A_END_STUDY.type) {
				if (isSoftRemove === true) {
					updatedModules[lastModuleInBranch.id] = {
						...updatedModules[lastModuleInBranch.id],
						isHidden: true,
					}
				} else {
					delete updatedModules[lastModuleInBranch.id]
				}
			}
		}

		deleteEndpointFromBranchFn('then')
		deleteEndpointFromBranchFn('else')
	}

	const idRelatedMessage = _.get(updatedModules[idToRemove], 'definition.idRelatedMessage', null)

	if (idRelatedMessage !== null) {
		if (isSoftRemove === true) {
			updatedModules[idRelatedMessage] = {
				...updatedModules[idRelatedMessage],
				isHidden: true,
			}
		} else {
			delete updatedModules[idRelatedMessage]
		}
	}

	if (
		modules[idToRemove].type === MODULE_DEFINITIONS.MATRIX_CHOICE.type ||
		modules[idToRemove].type === MODULE_DEFINITIONS.MATRIX_IMAGE_CHOICE.type
	) {
		const idsQuestionsToRemove = modules[idToRemove].definition.questions.map(
			question => question.id,
		)

		idsQuestionsToRemove.forEach(idQuestion => {
			if (isSoftRemove === true) {
				updatedModules[idQuestion] = {
					...updatedModules[idQuestion],
					isHidden: true,
				}
			} else {
				delete updatedModules[idQuestion]
			}
		})
	}

	if (isSoftRemove === true) {
		updatedModules[idToRemove] = {
			...updatedModules[idToRemove],
			isHidden: true,
		}
	} else {
		delete updatedModules[idToRemove]
	}

	return updatedModules
}

export default removeFromModules
