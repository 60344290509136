import { enTranslator as intl } from 'intl.js'

import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import List from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/List'

const title = intl.formatMessage({ id: 'list.module.title' })

export default {
	type: VISUAL_FLOW_MODULE_TYPES.LIST,
	style: 'list',
	title: () => 'list.module.title',
	description: () => 'list.module.description',
	component: List,
	generator: (modules, additionalProperties) => ({
		shortName: getDatasetShortName(modules, title, VISUAL_FLOW_MODULE_TYPES.LIST),
		inputType: null, // 'FILE' | 'LIST'
		idInputList: '',
		items: [],
		columns: [],
		identifier: '',
		filterRules: [],
		filename: '',
		isFilterEveryOf: true,
	}),
}
