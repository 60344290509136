const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	definitions: {},
	$id: 'http://groupsolver.com/v1/EVALUATOR_IF.json',
	properties: {
		if: {
			$id: '/properties/if',
			properties: {
				condition: {
					$id: '/properties/if/properties/condition',
					additionalProperties: true,
					type: 'object',
				},
				else: {
					$id: '/properties/if/properties/else',
					items: {
						anyOf: [
							{$ref: 'http://groupsolver.com/v1/EVALUATOR_IF.json'},
							{$ref: 'http://groupsolver.com/v1/EVALUATOR_SET.json'},
							{$ref: 'http://groupsolver.com/v1/EVALUATOR_SET_LITERAL.json'},
						],
					},
					type: 'array',
				},
				then: {
					$id: '/properties/if/properties/then',
					items: {
						anyOf: [
							{$ref: 'http://groupsolver.com/v1/EVALUATOR_IF.json'},
							{$ref: 'http://groupsolver.com/v1/EVALUATOR_SET.json'},
							{$ref: 'http://groupsolver.com/v1/EVALUATOR_SET_LITERAL.json'},
						],
					},
					type: 'array',
				},
			},
			required: ['then', 'condition'],
			type: 'object',
		},
	},
	required: ['if'],
	type: 'object',
}

export default schema
