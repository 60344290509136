import { AUTH_SEARCH_PARAMS } from 'constants/auth/authSearchParams'
import { PATHS } from 'constants/router'

export const redirectToAuth = () => {
	const location = window.location

	const pathnameParam = `${AUTH_SEARCH_PARAMS.PREVIOUS_PATHNAME}=${location.pathname}`
	const searchParam = `${AUTH_SEARCH_PARAMS.PREVIOUS_SEARCH}=${encodeURIComponent(
		location.search.replace('?', ''),
	)}`
	location.replace(`${PATHS.AUTH}?${pathnameParam}&${searchParam}`)
}
