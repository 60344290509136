import _ from 'lodash'

import { RESPONDENT_DATES, CONDITION_RESPONDENT_SOURCE } from 'constants/conditionBuilder'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

import { getSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/FREE_TEXT'

import { filterModulesForCondition } from 'helpers/visualFlowModules/filterModulesForCondition'

const getModulesFromFlow = (visualFlow, flatOrder) =>
	filterModulesForCondition(flatOrder.map(({ id }) => visualFlow.modules[id]))

export const getImportedModules = importedModules =>
	importedModules.map(module => ({
		isCommunityModule: module.isCommunity,
		isImportedModule: module.isCommunity === false,
		type: module.type,
		definition: {
			...(module.type === VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT
				? getSettings(module.definition.freetextType)
				: {}),
			id: module.id,
			shortName: module.simpleName,
			options: module.definition.options,
		},
	}))

const getDateModules = intl => [
	{
		isImportedModule: false,
		type: RESPONDENT_DATES.START_DATE,
		definition: {
			id: RESPONDENT_DATES.START_DATE,
			shortName: intl.formatMessage({
				id: 'start_date',
			}),
		},
	},
]

const getModulesFromProFlow = proFlow => {
	const loopedModulesIterations = []
	const idsLoopedModulesObject = {}

	filterModulesForCondition(
		proFlow.filter(proFlowModule => proFlowModule.listSettings.length > 0),
	).forEach(proFlowModule => {
		idsLoopedModulesObject[proFlowModule.listSettings[0].idOriginalModule] = null

		loopedModulesIterations.push({
			isLoopedModuleIteration: true,
			isCommunityModule: false,
			isImportedModule: false,
			type: proFlowModule.type,
			definition: {
				id: proFlowModule.id,
				shortName: proFlowModule.simpleName,
				...proFlowModule.definition,
			},
		})
	})

	return {
		loopedModulesIterations,
		idsLoopedModulesObject,
	}
}

const processSemgnetationTypingTools = segmentationTypingTools =>
	segmentationTypingTools.map(typingTool => ({
		isSegmentationTypingTool: true,
		type: VISUAL_FLOW_MODULE_TYPES.A_CHOICE,
		definition: {
			id: typingTool.idSegmentationTypingTool,
			shortName: typingTool.name,
			minSelection: 1,
			maxSelection: 1,
			options: typingTool.segments.map(segment => ({
				id: segment.idSegmentationTypingToolSegment,
				label: segment.name,
			})),
		},
	}))

const processRespondentSources = respondentSources =>
	respondentSources.map(respondentSource => ({
		isRespondentSource: true,
		type: CONDITION_RESPONDENT_SOURCE,
		definition: {
			id: respondentSource.idRespondentSource,
			shortName: respondentSource.label,
		},
	}))

export const getConditionBuilderModules = ({
	intl,
	importedModules,
	visualFlow,
	flatOrder,
	proFlow,
	segmentationTypingTools,
	respondentSources,
	includeDateModules,
}) => {
	const dateModules = includeDateModules === false ? [] : getDateModules(intl)

	if (_.get(visualFlow, 'order', []).length === 0) {
		return [
			...getImportedModules(importedModules),
			...dateModules,
			...getModulesFromProFlow(proFlow).loopedModulesIterations,
			...processSemgnetationTypingTools(segmentationTypingTools),
			...processRespondentSources(respondentSources),
		]
	}

	const { loopedModulesIterations, idsLoopedModulesObject } = getModulesFromProFlow(proFlow)

	return [
		...getModulesFromFlow(visualFlow, flatOrder).filter(
			module => idsLoopedModulesObject[module.definition.id] === undefined,
		),
		...getImportedModules(importedModules),
		...dateModules,
		...loopedModulesIterations,
		...processSemgnetationTypingTools(segmentationTypingTools),
		...processRespondentSources(respondentSources),
	]
}
