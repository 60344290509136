import { fnList } from 'libs/gs3-libs-flow-validation/src/validators/evaluator/_fnList'
import { SET_FN_CUSTOM_VARIABLES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/SET_VARIABLE_CONSTANTS'

const _validateSetVariableArgumentLength = (argumentKey, argumentValue) => {
	if (argumentKey === fnList.default || argumentKey === SET_FN_CUSTOM_VARIABLES.VARIABLE_VALUE) {
		return true
	}

	return argumentValue.length > 0
}

export default _validateSetVariableArgumentLength
