import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import { PREPARATION_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/PREPARATION_TYPES'

const getModulesWithSameType = (modulesArray, type, subtype) => {
	if (type === MODULE_DEFINITIONS.A_CHOICE.type || type === MODULE_DEFINITIONS.MATRIX_CHOICE.type) {
		return modulesArray.filter(
			module => module.type === type && module.definition.subtype === subtype,
		)
	}

	if (type === MODULE_DEFINITIONS.A_FREE_TEXT.type) {
		return modulesArray.filter(
			module => module.type === type && module.definition.freetextType === subtype,
		)
	}

	if (type === MODULE_DEFINITIONS.UI_COMMAND.type) {
		if (subtype === PREPARATION_TYPES.BLOCK) {
			return modulesArray.filter(
				module => module.type === type && module.definition.isBlock === true,
			)
		}

		if (subtype === PREPARATION_TYPES.TRACK_RANDOMIZER) {
			return modulesArray.filter(
				module => module.type === type && module.definition.isTrackRandomizer === true,
			)
		}

		if (subtype === PREPARATION_TYPES.RANDOMIZER) {
			return modulesArray.filter(
				module =>
					module.type === type &&
					module.definition.isRandomizer === true &&
					module.definition.isTrackRandomizer === false,
			)
		}

		if (subtype === PREPARATION_TYPES.QUOTA) {
			return modulesArray.filter(
				module => module.type === type && module.definition.enableQuota === true,
			)
		}

		if (subtype === PREPARATION_TYPES.CONDITION) {
			return modulesArray.filter(
				module =>
					module.type === type &&
					module.definition.enableQuota === false &&
					module.definition.enableConditions === true,
			)
		}
	}

	if (type === MODULE_DEFINITIONS.A_END_STUDY.type) {
		return modulesArray.filter(
			module => module.type === type && module.definition.subtype === subtype,
		)
	}

	return modulesArray.filter(module => module.type === type)
}

const getDatasetShortName = (modules, title, moduleType, subtype) => {
	const modulesArray = Object.values(modules)
	const modulesWithSameType = getModulesWithSameType(modulesArray, moduleType, subtype)

	const shortNames = modulesWithSameType.map(module => module.definition.shortName)
	const shortNameIndexes = shortNames
		.map(shortName => shortName.split(' ').pop())
		.filter(number => isNaN(number) === false)

	const modulesCount = modulesWithSameType.length
	const maxIndex = shortNameIndexes.length > 0 ? Math.max(...shortNameIndexes) : 0

	const newIndex = Math.max(modulesCount, maxIndex) + 1

	if (isNaN(title.split(' ').pop()) === false) {
		const splitTitle = title.split(' ')
		splitTitle.splice(-1, 1, newIndex)
		return splitTitle.join(' ')
	}

	return newIndex === 1 ? title : `${title} ${newIndex}`
}

export default getDatasetShortName
