import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './Label.module.scss'

const Label = props => (
	<div className={classes.wrapper}>
		<div
			className={classnames(classes.label, {
				[classes['label--is-secondary']]: props.isSecondary === true,
				[props.className]: props.className !== undefined,
			})}
		>
			{props.label}
			{props.required === true && props.disabled === false && '*'}
		</div>
		{props.secondaryLabel !== undefined && (
			<div
				className={classnames(classes.label, {
					[props.className]: props.className !== undefined,
					[classes['label--is-secondary']]: props.isSecondary === true,
					[props.secondaryClassName]: props.secondaryClassName !== undefined,
				})}
			>
				{props.secondaryLabel}
			</div>
		)}
	</div>
)

Label.defaultProps = {
	disabled: false,
	isSecondary: false,
}

Label.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	isSecondary: PropTypes.bool.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	required: PropTypes.bool,
	secondaryClassName: PropTypes.string,
	secondaryLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default Label
