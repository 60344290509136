import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

const getOrderModuleById = (id, order) => {
	let index = 0
	let module = null
	while (!module && order[index]) {
		const potentialModule = order[index]
		if (potentialModule.id === id) {
			module = potentialModule
		} else if (potentialModule.type === MODULE_DEFINITIONS.UI_COMMAND.type) {
			// try to find pathTo module in branches
			module = getOrderModuleById(id, potentialModule.then)
		}
		index++
	}
	return module
}

export default getOrderModuleById
