import { getStatementsWithoutThemes } from 'helpers/reports/getStatementsWithoutThemes'

export const getStatementsToRender = (
	statementsTotalSegmentData,
	studyObjectData,
	slideSettings,
	chartColorSettings,
) => {
	const { themeViewSettings } = slideSettings

	const defaultColors = chartColorSettings.openEnded.colors

	const totalSegmentStatementsSuppots = statementsTotalSegmentData.statementSupports.map(
		(statement, index) => ({
			...statement,
			color: defaultColors[index % defaultColors.length],
		}),
	)

	if (themeViewSettings.isActive === false) {
		return totalSegmentStatementsSuppots
	}

	if (themeViewSettings.showTopUnthemedStatements === false) {
		return []
	}

	const statementsWithoutThemes =
		themeViewSettings.showUnthemedAnswers === false
			? []
			: getStatementsWithoutThemes(
					studyObjectData.statements.filter(statement => statement.isInvalid === false),
					studyObjectData.statementGroups,
					studyObjectData.statementThemes,
			  )

	return totalSegmentStatementsSuppots.filter(statement =>
		statementsWithoutThemes.some(
			unthemedStatement => statement.idStatement === unthemedStatement.idStatement,
		),
	)
}
