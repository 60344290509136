import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

import AnswerManager from './AnswerManager'
import Community from './Community'
import DataDownloader from './DataDownloader'
import DataImporter from './DataImporter'
import LiveSlides from './LiveSlides'
import Panels from './Panels'
import QuantQual from './QuantQual'
import Report from './Report'
import RespondentManager from './RespondentManager'
import RestoreStudy from './RestoreStudy'
import StudyDesign from './StudyDesign'
import SegmentationTypingToolRoute from './SegmentationTypingToolRoute'

const Study = lazy(() => import('./Study'))
const WrappedStudy = errorWrapper(Study)

export default store => ({
	path: 'study/:studyName',
	element: <WrappedStudy />,
	children: [
		AnswerManager(store),
		Community(),
		DataDownloader(store),
		DataImporter(),
		LiveSlides(store),
		Panels(store),
		QuantQual(),
		Report(store),
		RestoreStudy(store),
		StudyDesign(store),
		SegmentationTypingToolRoute(),
		// Check for internal employee
		RespondentManager(store),
	],
})
