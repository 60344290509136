import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Field } from 'formik'
import { orderBy as naturalOrderBy } from 'natural-orderby'

import Select from 'components/_formik/_base/Select'
import { getTimezoneOffset, formatTimezoneName } from 'helpers/timeZone'

const timezoneOptions = naturalOrderBy(
	moment.tz
		.names()
		.filter(
			timezoneName =>
				timezoneName.includes('/') === true && timezoneName.startsWith('Etc') === false,
		)
		.map(timezoneName => {
			const offset = getTimezoneOffset(timezoneName)

			return {
				label: formatTimezoneName(timezoneName, offset),
				value: timezoneName,
				offset,
			}
		}),
	[option => option.offset, option => option.label],
	['asc', 'asc'],
)

const TimeZoneSelect = ({ fieldName, ...props }) => {
	return (
		<Field
			{...props}
			isClearable={false}
			component={Select}
			name={fieldName}
			options={timezoneOptions}
		/>
	)
}

TimeZoneSelect.propTypes = {
	fieldName: PropTypes.string.isRequired,
}

export default TimeZoneSelect
