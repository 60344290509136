import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { Field } from 'formik'
import { useIntl } from 'react-intl'
import moment from 'moment-timezone'

import { getConditionSelectionTypeSelectOptions } from 'helpers/conditionBuilder/getConditionSelectionTypeSelectOptions'
import {
	getEvaluationPositionOptions,
	getDateConditionOptions,
	getSelectionTypeAllocationRanking,
	getOEQEvaluateSelectionOptions,
	getOEQFollowUpSelectionOptions,
	getConditionIsOptions,
} from 'helpers/conditionBuilder/getSelectOptions'
import { getLeftObjectForAllocationAndRanking } from 'helpers/conditionBuilder/getLeftObjectShape'
import { getStudyObjectDefinition } from 'helpers/conditionBuilder/getStudyObjectDefinition'
import getIsAllocationModule from 'helpers/visualFlowModules/getIsAllocationModule'
import getIsRankingModule from 'helpers/visualFlowModules/getIsRankingModule'
import isChoiceModule from 'helpers/visualFlowModules/isChoiceModule'
import isOpenAnswerModule from 'helpers/visualFlowModules/isOpenAnswerModule'
import {
	hasAllocationRankingInvalidRange,
	hasInvalidAllocationRankingValues,
	hasInvalidFreetextInput,
	hasInvalidMinAnyOfValidSelection,
	hasInvalidOEQFollowUpInput,
	hasInvalidOptionSelected,
	hasInvalidSelection,
	hasNoOptionsSelected,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateUICommand'
import { hasInvalidDate, hasInvalidDateSequence } from 'helpers/segments/validateDateSegment'
import { getNumberConditionSelectionTypeOptions } from 'helpers/conditionBuilder/getNumberConditionSelectionTypeOptions'
import { getFreeTextStringsConditionSelect } from 'helpers/conditionBuilder/getFreeTextStringsConditionSelect'
import {
	isFreetextModule,
	isFreetextDateType,
	isFreetextNumberType,
	isFreetextStringType,
	isFreetextSelectionTypeStringAnyOf,
} from 'helpers/visualFlowModules/isFreetextModule'
import {
	getQuestionSelectedOptionsValues,
	getQuestionSelectOptions,
} from 'helpers/conditionBuilder/getQuestionOptionsValues'
import isQuotaModule from 'helpers/visualFlowModules/isQuotaModule'
import { desanitizeMatrixAttributesShortName } from 'helpers/visualFlowModules/sanitizeMatrixAttributesShortName'

import { COLORS } from 'constants/colors'
import {
	CONDITION_SELECTION_TYPES,
	CONDITION_TYPES,
	RESPONDENT_DATES,
	CONDITION_RESPONDENT_SOURCE,
	DATE_TIME_OBJECT_SHAPE,
	EVALUATION_POSITION,
} from 'constants/conditionBuilder'
import { STUDY_STATE } from 'constants/study'
import { PROFLOW_TYPES } from 'constants/studyDesign'

import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

import ChoiceAnswerMultiselect from 'components/_formik/_complex/ChoiceAnswerMultiselect'
import Select from 'components/_formik/_base/Select'
import Slider from 'components/_formik/_base/Slider'
import Label from 'components/_formik/_base/Label'
import Input from 'components/_formik/_base/Input'
import ModuleAdvancedOptionsToggle from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/ModuleAdvancedOptionsToggle'
import ConditionDatePicker from './_components/ConditionDatePicker/ConditionDatePicker'
import CheckmarkCheckbox from 'components/_formik/_base/CheckmarkCheckbox'
import FieldArrayAdd from 'components/_formik/_base/FieldArrayAdd'
import StatementsMultiselect from './_components/StatementsMultiselect'

import Icon from 'components/_scaffolding/Icon'

import classes from './Condition.module.scss'

const findStudyObject = (modules, idStudyObject) =>
	modules.find(module => module.definition.id === idStudyObject)

const createSelectOptionFromModule = module => ({
	label: desanitizeMatrixAttributesShortName(module.definition.shortName),
	value: module.definition.id,
})

const sortModules = modules => {
	const flowModules = []
	const importedModules = []
	const dateModules = []
	const communityModules = []
	const loopedModules = []
	const segmentationTypingToolModules = []
	const respondentSourceModules = []

	modules.forEach(module => {
		if (module.type === RESPONDENT_DATES.START_DATE) {
			dateModules.push(module)
		} else if (module.isImportedModule === true) {
			importedModules.push(module)
		} else if (module.isCommunityModule === true) {
			communityModules.push(module)
		} else if (module.isLoopedModuleIteration === true) {
			loopedModules.push(module)
		} else if (module.isSegmentationTypingTool === true) {
			segmentationTypingToolModules.push(module)
		} else if (module.isRespondentSource === true) {
			respondentSourceModules.push(module)
		} else {
			flowModules.push(module)
		}
	})

	return {
		flowModules,
		importedModules,
		dateModules,
		communityModules,
		loopedModules,
		segmentationTypingToolModules,
		respondentSourceModules,
	}
}

const getInitialIsExpanded = condition =>
	condition.selection.type === CONDITION_SELECTION_TYPES.ANY_OF &&
	condition.left.length > 1 &&
	(condition.selection.minAnyOfValidSelection !== 1 ||
		condition.selection.maxAnyOfValidSelection !== condition.left.length)

const getDatePickerMaxDate = (study, timezone) => {
	const { lastComplete } = study.statistics

	if (lastComplete === null) {
		return null
	}

	const lastCompleteDate = moment.tz(lastComplete, timezone)
	const roundedLastCompleteDate = lastCompleteDate
		.clone()
		.add(30 - (lastCompleteDate.minutes() % 30), 'minutes')
		.format('MM/DD/YYYY hh:mm')

	// we have to create new moment object with users timezone
	return moment(roundedLastCompleteDate, 'MM/DD/YYYY hh:mm')
}

const getDatePickerMinDate = (study, timezone) => {
	const { firstComplete } = study.statistics

	if (firstComplete === null) {
		return null
	}

	const firstCompleteDate = moment.tz(firstComplete, timezone)
	const roundedFirstCompleteDate = firstCompleteDate
		.clone()
		.subtract(firstCompleteDate.minutes() % 30, 'minutes')
		.format('MM/DD/YYYY hh:mm')

	// we have to create new moment object with users timezone
	return moment(roundedFirstCompleteDate, 'MM/DD/YYYY hh:mm')
}

const getUICommandConditionType = studyObject => {
	if (isQuotaModule(studyObject) === true) {
		return CONDITION_TYPES.QUOTA_RESULT
	}

	if (studyObject.definition.isTrack === true) {
		return CONDITION_TYPES.TRACK
	}

	return CONDITION_TYPES.CONDITION
}

const getConditionTypeOptions = (selectedStudyObject, isSegmentBuilder, intl) => {
	if (_.isNil(selectedStudyObject) === true) {
		return []
	}

	if (selectedStudyObject.type !== MODULE_DEFINITIONS.A_OEQ.type) {
		return []
	}

	const followUpOption = {
		label: intl.formatMessage({ id: 'condition.type.follow_up' }),
		value: CONDITION_TYPES.OEQ_FOLLOW_UP,
	}

	const evaluationPositionOption = {
		label: intl.formatMessage({ id: 'condition.type.evaluation_position' }),
		value: CONDITION_TYPES.OEQ_EVALUATION,
	}

	return isSegmentBuilder === true ? [followUpOption, evaluationPositionOption] : [followUpOption]
}

const Condition = props => {
	const intl = useIntl()

	const { index, condition, study } = props

	const [isExpanded, setIsExpanded] = useState(getInitialIsExpanded(condition))

	const handleAdvancedOptionsClick = () => {
		setIsExpanded(currentIsExpanded => currentIsExpanded === false)
	}

	const getLeftObjectShapeForDateType = selectionType => {
		const defaultLeftObject = {
			dateTime: getDatePickerMinDate(study, moment.tz.guess()) || moment(),
			timezone: moment.tz.guess(),
		}

		if (selectionType === CONDITION_SELECTION_TYPES.BETWEEN) {
			return {
				from: defaultLeftObject,
				to: {
					dateTime: getDatePickerMaxDate(study, moment.tz.guess()) || moment(),
					timezone: moment.tz.guess(),
				},
			}
		}

		return defaultLeftObject
	}

	const changeIdStudyObject = idStudyObject => {
		const newStudyObject = findStudyObject(props.modules, idStudyObject)
		setIsExpanded(false)

		if (newStudyObject.type === CONDITION_RESPONDENT_SOURCE) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.RESPONDENT_SOURCE,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.RESPONDENT_SOURCE_MATCH,
				},
				left: '',
			})
		}

		if (newStudyObject.type === MODULE_DEFINITIONS.A_CHOICE.type) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.CONDITION,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.EQUAL_TO,
				},
				left: [newStudyObject.definition.options[0].id],
			})
		}

		if (isFreetextModule(newStudyObject) === true && isFreetextDateType(newStudyObject) === false) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type:
					isFreetextNumberType(newStudyObject) === true
						? CONDITION_TYPES.NUMBER
						: CONDITION_TYPES.STRING,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type:
						isFreetextNumberType(newStudyObject) === true
							? CONDITION_SELECTION_TYPES.NUMBER_EQUAL_TO
							: CONDITION_SELECTION_TYPES.STRING_EQUAL_TO,
				},
				left: '',
			})
		}

		if (
			newStudyObject.type === RESPONDENT_DATES.START_DATE ||
			isFreetextDateType(newStudyObject) === true
		) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.DATE,
				studyObject: {
					id:
						isFreetextModule(newStudyObject) === true
							? newStudyObject.definition.id
							: RESPONDENT_DATES.START_DATE,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.AFTER,
				},
				left: getLeftObjectShapeForDateType(CONDITION_SELECTION_TYPES.AFTER),
			})
		}

		if (
			isChoiceModule(newStudyObject) &&
			newStudyObject.type === MODULE_DEFINITIONS.A_SNIPPET.type
		) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.CONDITION,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.EQUAL_TO,
				},
				left: [getStudyObjectDefinition(newStudyObject).options[0].id],
			})
		}

		if (
			newStudyObject.type === MODULE_DEFINITIONS.UI_COMMAND.type ||
			newStudyObject.type === PROFLOW_TYPES.A_EVALUATOR
		) {
			const conditionType = getUICommandConditionType(newStudyObject)

			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: conditionType,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.EQUAL,
				},
				left: 'true',
			})
		}

		if (newStudyObject.type === MODULE_DEFINITIONS.A_OEQ.type && props.isSegmentBuilder === true) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.OEQ_EVALUATION,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.ANY_OF,
				},
				left: {
					evaluationPosition: EVALUATION_POSITION.AGREE,
					idsStatements: [],
				},
			})
		}

		if (newStudyObject.type === MODULE_DEFINITIONS.A_OEQ.type && props.isSegmentBuilder === false) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.OEQ_FOLLOW_UP,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.CONTAINS,
				},
				left: [''],
			})
		}

		if (getIsAllocationModule(newStudyObject) === true) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.ALLOCATION,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.GREATER_THAN,
				},
				left: getLeftObjectForAllocationAndRanking(
					CONDITION_SELECTION_TYPES.GREATER_THAN,
					getQuestionSelectOptions(newStudyObject),
				),
			})
		}

		if (getIsRankingModule(newStudyObject) === true) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.RANKING,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.GREATER_THAN,
				},
				left: getLeftObjectForAllocationAndRanking(
					CONDITION_SELECTION_TYPES.GREATER_THAN,
					getQuestionSelectOptions(newStudyObject),
				),
			})
		}

		if (newStudyObject.type === MODULE_DEFINITIONS.LIST.type) {
			props.setFieldValue(`conditions[${index}]`, {
				...condition,
				type: CONDITION_TYPES.LIST_LENGTH,
				studyObject: {
					id: newStudyObject.definition.id,
				},
				selection: {
					...condition.selection,
					type: CONDITION_SELECTION_TYPES.LIST_EMPTY,
				},
				left: '0',
			})
		}
	}

	const removeCondition = () => {
		props.arrayHelpers.remove(index)
	}
	const {
		flowModules,
		importedModules,
		dateModules,
		communityModules,
		loopedModules,
		segmentationTypingToolModules,
		respondentSourceModules,
	} = sortModules(props.modules)

	const flowModulesOptions = flowModules.map(createSelectOptionFromModule)
	const importedModulesOptions = importedModules.map(createSelectOptionFromModule)
	const dateModulesOptions = dateModules.map(createSelectOptionFromModule)
	const communityModulesOptions = communityModules.map(createSelectOptionFromModule)
	const loopedModulesOptions = loopedModules.map(createSelectOptionFromModule)
	const segmentationTypingToolModulesOptions = segmentationTypingToolModules.map(
		createSelectOptionFromModule,
	)
	const respondentSourceModulesOptions = respondentSourceModules.map(createSelectOptionFromModule)

	const getStudyObjectIdOptions = () => {
		return [
			{
				label: intl.formatMessage({ id: 'flow_modules' }),
				options: flowModulesOptions,
			},
			{
				label: intl.formatMessage({ id: 'date_modules' }),
				options: dateModulesOptions,
			},
			{
				label: intl.formatMessage({ id: 'imported_modules' }),
				options: importedModulesOptions,
			},
			{
				label: intl.formatMessage({ id: 'community_modules' }),
				options: communityModulesOptions,
			},
			{
				label: intl.formatMessage({ id: 'looped_modules' }),
				options: loopedModulesOptions,
			},
			{
				label: intl.formatMessage({ id: 'segmentation_typing_tool' }),
				options: segmentationTypingToolModulesOptions,
			},
			{
				label: intl.formatMessage({ id: 'panels' }),
				options: respondentSourceModulesOptions,
			},
		].filter(section => section.options.length > 0)
	}

	const selectedStudyObject = findStudyObject(props.modules, condition.studyObject.id)

	const oeqCustomLabels = {
		agree: _.get(selectedStudyObject, 'definition.translations.elaborate_agree_label', ''),
		disagree: _.get(selectedStudyObject, 'definition.translations.elaborate_disagree_label', ''),
		neutral: _.get(selectedStudyObject, 'definition.translations.elaborate_indifferent_label', ''),
	}

	const isSelectedStudyObjectChoice =
		_.isNil(selectedStudyObject) === false && isChoiceModule(selectedStudyObject)

	const isSelectedStudyObjectAllocation =
		_.isNil(selectedStudyObject) === false && getIsAllocationModule(selectedStudyObject)

	const isSelectedStudyObjectRanking =
		_.isNil(selectedStudyObject) === false && getIsRankingModule(selectedStudyObject)

	const isSelectedStudyObjectOpenAnswer =
		_.isNil(selectedStudyObject) === false && isOpenAnswerModule(selectedStudyObject)

	const isFreeTextNumberSelectOption =
		_.isNil(selectedStudyObject) === false && isFreetextNumberType(selectedStudyObject)

	const isFreeTextStringsSelectOption =
		_.isNil(selectedStudyObject) === false && isFreetextStringType(selectedStudyObject)

	const isFreeTextDate =
		_.isNil(selectedStudyObject) === false && isFreetextDateType(selectedStudyObject)

	const isFreetextStringsAnyOf = isFreetextSelectionTypeStringAnyOf(condition)

	const renderError = (idTranslation, useInfo = false, questionType = null) => {
		const errorClassName = props.dirty === false && useInfo === true ? 'title-info' : 'title-error'

		return (
			<span className={classnames(errorClassName, classes.message)}>
				{intl.formatMessage({ id: idTranslation }, { type: questionType })}
			</span>
		)
	}

	const handleChoiceOptionsMultiSelectChange = value => {
		if (value.length < 2) {
			setIsExpanded(false)
		}

		const normalizedValueLength = Math.max(1, value.length)

		props.setFieldValue(`conditions[${index}]`, {
			...condition,
			left: value,
			selection: {
				...condition.selection,
				maxAnyOfValidSelection: normalizedValueLength,
				minAnyOfValidSelection: Math.min(
					normalizedValueLength,
					condition.selection.minAnyOfValidSelection,
				),
			},
		})
	}

	const handleStatementsMultiSelectChange = value => {
		if (value.length < 2) {
			setIsExpanded(false)
		}

		const normalizedValueLength = Math.max(1, value.length)

		props.setFieldValue(`conditions[${index}]`, {
			...condition,
			left: {
				...condition.left,
				idsStatements: value,
			},
			selection: {
				...condition.selection,
				maxAnyOfValidSelection: normalizedValueLength,
				minAnyOfValidSelection: Math.min(
					normalizedValueLength,
					condition.selection.minAnyOfValidSelection,
				),
			},
		})
	}

	const handleSelectionTypeChange = value => {
		if (value !== CONDITION_SELECTION_TYPES.ANY_OF) {
			setIsExpanded(false)
		}

		props.setFieldValue(`conditions[${index}].selection.type`, value)
	}

	const handleAllocationRankingSelectionTypeChange = value => {
		props.setFieldValue(`conditions[${index}]`, {
			...condition,
			left: {
				...condition.left,
				expectedValue: value === CONDITION_SELECTION_TYPES.IN_RANGE ? { from: '', to: '' } : '',
			},
			selection: {
				...condition.selection,
				type: value,
			},
		})
	}

	const handleFreeTextSelect = value => {
		props.setFieldValue(`conditions[${index}]`, {
			...condition,
			left: value === CONDITION_SELECTION_TYPES.STRING_ANY_OF ? [''] : '',
			selection: {
				...condition.selection,
				type: value,
			},
		})

		props.setTouched(_.omit(props.touched, [`conditions[${index}].left`]))
	}

	const isAnyOfSelectionValid = () => {
		if (condition.selection.type !== CONDITION_SELECTION_TYPES.ANY_OF) {
			return true
		}

		if (_.isNil(selectedStudyObject) === true) {
			return true
		}

		if (isSelectedStudyObjectChoice === false) {
			return true
		}

		return hasInvalidMinAnyOfValidSelection(condition, selectedStudyObject) === false
	}

	const isBetweenSelectionInvalid = () => hasInvalidDateSequence(condition)
	const isInvalidDateFormat = () => hasInvalidDate(condition)

	const isInvalidFieldFreetextInput = () => {
		if (_.isNil(selectedStudyObject) === true) {
			return false
		}

		if (isFreetextModule(selectedStudyObject) === false) {
			return false
		}

		return (
			(isFreeTextNumberSelectOption === true || isFreeTextStringsSelectOption === true) &&
			isFreetextStringsAnyOf === false &&
			hasInvalidFreetextInput(condition, selectedStudyObject) === true
		)
	}

	const isConditionSelectionTypeBetween =
		condition.selection.type === CONDITION_SELECTION_TYPES.BETWEEN

	const isConditionSelectionTypeInRange =
		condition.selection.type === CONDITION_SELECTION_TYPES.IN_RANGE

	const canUseOEQEvaluation =
		isSelectedStudyObjectOpenAnswer === true &&
		condition.type === CONDITION_TYPES.OEQ_EVALUATION &&
		[STUDY_STATE.SOLVED.status, STUDY_STATE.POST_ANALYTICS.status].includes(study.state)

	const handleFreeTextAnyOfPaste = event => {
		if (!event.clipboardData) {
			return
		}

		const inputIndex = event.currentTarget.getAttribute('data-inputindex')

		const clipboardData = event.clipboardData.getData('text/plain')

		const labels = clipboardData
			.split(/,|\n+|\t+|\r+|\r\n+/g)
			.map(label => label.trim())
			.filter(label => label.length > 0)

		if (labels.length > 1) {
			event.preventDefault()

			const newLeft = condition.left.slice()
			newLeft.splice(inputIndex, 0, ...labels)

			const valueToSet = newLeft.filter(label => label.length > 0)

			props.setFieldValue(`conditions[${index}].left`, valueToSet)
		}
	}

	const renderSelection = () => {
		if (isFreeTextStringsSelectOption === true) {
			return (
				<Field
					component={Select}
					disabled={props.disabled}
					name={`conditions[${index}].selection.type`}
					options={getFreeTextStringsConditionSelect(intl)}
					onChange={handleFreeTextSelect}
				/>
			)
		}

		if (isFreeTextNumberSelectOption === true) {
			return (
				<Field
					component={Select}
					disabled={props.disabled}
					name={`conditions[${index}].selection.type`}
					options={getNumberConditionSelectionTypeOptions(intl)}
					onChange={handleFreeTextSelect}
				/>
			)
		}

		if (isSelectedStudyObjectChoice === true) {
			return (
				<Field
					component={Select}
					disabled={props.disabled}
					name={`conditions[${index}].selection.type`}
					options={getConditionSelectionTypeSelectOptions(intl)}
					onChange={handleSelectionTypeChange}
				/>
			)
		}

		if (isSelectedStudyObjectAllocation === true || isSelectedStudyObjectRanking === true) {
			return (
				<Field
					component={Select}
					disabled={props.disabled}
					name={`conditions[${index}].selection.type`}
					options={getSelectionTypeAllocationRanking(intl)}
					onChange={handleAllocationRankingSelectionTypeChange}
				/>
			)
		}

		if (condition.type === CONDITION_TYPES.DATE) {
			return (
				<Fragment>
					<Field
						component={Select}
						disabled={props.disabled}
						name={`conditions[${index}].selection.type`}
						options={getDateConditionOptions(intl)}
						onChange={value => {
							props.setFieldValue(`conditions[${index}]`, {
								...condition,
								left: getLeftObjectShapeForDateType(value),
								selection: {
									...condition.selection,
									type: value,
								},
							})
						}}
					/>
					<ConditionDatePicker
						selectionType={condition.selection.type}
						isFreeTextDate={isFreeTextDate}
						index={index}
						fieldNamePrefix={isConditionSelectionTypeBetween === true ? 'from' : ''}
						maxDate={getDatePickerMaxDate(
							study,
							isConditionSelectionTypeBetween === true
								? condition.left.from.timezone
								: condition.left.timezone,
						)}
						minDate={getDatePickerMinDate(
							study,
							isConditionSelectionTypeBetween === true
								? condition.left.from.timezone
								: condition.left.timezone,
						)}
					/>
					{isConditionSelectionTypeBetween === true && (
						<ConditionDatePicker
							selectionType={condition.selection.type}
							isFreeTextDate={isFreeTextDate}
							index={index}
							fieldNamePrefix={'to'}
							maxDate={getDatePickerMaxDate(study, condition.left.to.timezone)}
							minDate={getDatePickerMinDate(study, condition.left.to.timezone)}
						/>
					)}
				</Fragment>
			)
		}

		if (_.get(selectedStudyObject, 'definition.isTrack', false) === true) {
			return (
				<div className={classes['met-label']}>
					{intl.formatMessage({ id: 'condition.seen_by_respondent' })}
				</div>
			)
		}

		if (_.isNil(selectedStudyObject) === false && isQuotaModule(selectedStudyObject) === true) {
			return (
				<div className={classes['met-label']}>
					{intl.formatMessage({ id: 'condition.in_target_group' })}
				</div>
			)
		}

		if (condition.type === CONDITION_TYPES.RESPONDENT_SOURCE) {
			return (
				<div className={classes['met-label']}>
					{intl.formatMessage({ id: 'condition.respondent_source.in_panel' })}
				</div>
			)
		}

		if (
			_.isNil(selectedStudyObject) === false &&
			selectedStudyObject.type === MODULE_DEFINITIONS.LIST.type
		) {
			const options = [
				{
					value: CONDITION_SELECTION_TYPES.LIST_EMPTY,
					label: '0',
				},
				...getNumberConditionSelectionTypeOptions(intl),
			]

			return (
				<Field
					component={Select}
					disabled={props.disabled}
					name={`conditions[${index}].selection.type`}
					options={options}
				/>
			)
		}

		if (
			condition.type === CONDITION_TYPES.OEQ_EVALUATION &&
			[STUDY_STATE.SOLVED.status, STUDY_STATE.POST_ANALYTICS.status].includes(study.state) === false
		) {
			return (
				<div className={classes['oeq-info-holder']}>
					<div className="title-info">
						{intl.formatMessage({ id: 'condition.oeq.evaluation_before_solve' })}
					</div>
				</div>
			)
		}

		if (condition.type === CONDITION_TYPES.OEQ_EVALUATION) {
			return (
				<div className={classes['definition__oeq']}>
					<Field
						component={Select}
						disabled={props.disabled}
						name={`conditions[${index}].selection.type`}
						options={getOEQEvaluateSelectionOptions(intl)}
						onChange={handleSelectionTypeChange}
					/>
				</div>
			)
		}

		if (condition.type === CONDITION_TYPES.OEQ_FOLLOW_UP) {
			return (
				<Field
					component={Select}
					disabled={props.disabled}
					name={`conditions[${index}].selection.type`}
					options={getOEQFollowUpSelectionOptions(intl)}
					onChange={handleSelectionTypeChange}
				/>
			)
		}

		return <div className={classes['met-label']}>{intl.formatMessage({ id: 'met' })}</div>
	}

	const renderEvaluationPosition = () => {
		if (canUseOEQEvaluation === false) {
			return null
		}

		return (
			<Field
				component={Select}
				disabled={props.disabled}
				name={`conditions[${index}].left.evaluationPosition`}
				options={getEvaluationPositionOptions(intl, oeqCustomLabels)}
				onChange={value =>
					props.setFieldValue(`conditions[${index}].left.evaluationPosition`, value)
				}
			/>
		)
	}

	const renderAllocationAndRankingLeftFields = () => {
		const placeholder = intl.formatMessage({ id: 'number_type_placeholder' })

		return (
			<Fragment>
				<div
					className={classnames({
						[classes['allocation-ranking-values']]: isConditionSelectionTypeInRange === true,
					})}
				>
					{isConditionSelectionTypeInRange === false && (
						<Field
							component={Input}
							disabled={props.disabled}
							name={`conditions[${index}].left.expectedValue`}
							placeholder={placeholder}
						/>
					)}

					{isConditionSelectionTypeInRange === true && (
						<Fragment>
							<Field
								component={Input}
								disabled={props.disabled}
								name={`conditions[${index}].left.expectedValue.from`}
								placeholder={placeholder}
							/>
							<Field
								component={Input}
								disabled={props.disabled}
								name={`conditions[${index}].left.expectedValue.to`}
								placeholder={placeholder}
							/>
						</Fragment>
					)}
				</div>

				{hasAllocationRankingInvalidRange(condition) === true &&
					renderError('condition.invalidPointsSequenceError')}

				{hasInvalidAllocationRankingValues(condition) === true &&
					renderError(
						'condition.invalidExpectedValue',
						false,
						selectedStudyObject.definition.shortName.toLowerCase(),
					)}
			</Fragment>
		)
	}

	const renderAllocationOrRankingOptions = () => (
		<div className={classes['options']}>
			<Field
				component={Select}
				disabled={props.disabled}
				name={`conditions[${index}].left.idOption`}
				options={getQuestionSelectOptions(selectedStudyObject)}
				value={getQuestionSelectedOptionsValues(intl, condition.left.idOption, selectedStudyObject)}
			/>

			{hasNoOptionsSelected(condition, selectedStudyObject) === true &&
				renderError('choice_options_multiselect.no_options_selected')}
		</div>
	)

	const conditionTypeOptions = getConditionTypeOptions(
		selectedStudyObject,
		props.isSegmentBuilder,
		intl,
	)

	const handleConditionTypeChange = newValue => {
		const newCondition = {
			...condition,
			type: newValue,
		}

		if (newValue === CONDITION_TYPES.OEQ_FOLLOW_UP) {
			newCondition.left = ['']
			newCondition.selection = {
				...condition.selection,
				type: CONDITION_SELECTION_TYPES.CONTAINS,
			}
		}

		if (newValue === CONDITION_TYPES.OEQ_EVALUATION) {
			newCondition.left = {
				evaluationPosition: EVALUATION_POSITION.AGREE,
				idsStatements: [],
			}
			newCondition.selection = {
				...condition.selection,
				type: CONDITION_SELECTION_TYPES.ANY_OF,
			}
		}

		props.setFieldValue(`conditions[${index}]`, newCondition)
	}

	const shouldRenderStringAnyOf =
		selectedStudyObject !== undefined &&
		((isFreetextModule(selectedStudyObject) === true && isFreetextStringsAnyOf === true) ||
			condition.type === CONDITION_TYPES.OEQ_FOLLOW_UP)

	return (
		<div data-id={`condition-${index}`}>
			<div className={classes.header}>
				{index > 0 ? (
					<div className={classes.operator} name={`conditions[${index}].case`}>
						{condition.case}
					</div>
				) : (
					<div />
				)}
				{props.disabled === false &&
					(props.isQuotaCheckpoint === false || index > 0) &&
					(props.conditionsCount > 1 || props.allowRemoveCondition === true) && (
						<div className={classes['btn-remove']}>
							<Icon name={Icon.NAMES.X_CLOSE} onClick={removeCondition} />
						</div>
					)}
			</div>

			<Field
				component={Select}
				disabled={props.disabled}
				name={`conditions[${index}].studyObject.id`}
				options={getStudyObjectIdOptions()}
				onChange={changeIdStudyObject}
				styles={{
					groupHeading: base => ({
						...base,
						color: COLORS.PRIMARY_45,
						borderBottom: '1px solid',
						borderTop: '1px solid',
						borderColor: COLORS.GREY_95,
						fontSize: 11,
						fontWeight: 500,
						margin: '0px 5px',
						padding: '5px',
						textAlign: 'left',
						textTransform: 'none',
					}),
				}}
			/>

			{(isSelectedStudyObjectAllocation === true || isSelectedStudyObjectRanking === true) &&
				renderAllocationOrRankingOptions()}

			{selectedStudyObject === undefined &&
				condition.studyObject.id === null &&
				renderError('condition.no_study_object_selected', true)}

			{conditionTypeOptions.length > 0 && (
				<div className={classes.options}>
					<Field
						component={Select}
						disabled={props.disabled || conditionTypeOptions.length === 1}
						name={`conditions[${index}].type`}
						onChange={handleConditionTypeChange}
						options={conditionTypeOptions}
					/>
				</div>
			)}

			<div className={classes.definition}>
				{(isSelectedStudyObjectOpenAnswer === false ||
					condition.type === CONDITION_TYPES.OEQ_FOLLOW_UP ||
					canUseOEQEvaluation === true) && (
					<Field
						component={Select}
						disabled={props.disabled}
						name={`conditions[${index}].is`}
						options={getConditionIsOptions(intl, selectedStudyObject, condition.type)}
					/>
				)}

				{renderEvaluationPosition()}

				{renderSelection()}

				{// update formatConditionIs if we enable this in segment conditions
				props.isQuotaCheckpoint === true && condition.is === false && (
					<div className={classes['definition__no-answer-checkbox']}>
						<Field
							component={CheckmarkCheckbox}
							disabled={props.disabled}
							name={`conditions[${index}].includeNoData`}
							componentProps={{ label: intl.formatMessage({ id: 'condition.include_no_data' }) }}
						/>
					</div>
				)}
			</div>

			{selectedStudyObject !== undefined &&
				hasInvalidSelection(condition, selectedStudyObject) === true &&
				renderError('condition.has_invalid_selection')}

			{selectedStudyObject === undefined &&
				condition.studyObject.id !== null &&
				renderError('condition.study_object_missed')}

			{isInvalidDateFormat() === true && renderError('condition.invalidDateFormatError')}

			{isBetweenSelectionInvalid() === true && renderError('condition.invalidDateSequenceError')}

			{isSelectedStudyObjectChoice === true && (
				<Fragment>
					<ChoiceAnswerMultiselect
						disabled={props.disabled}
						name={`conditions[${index}].left`}
						onChange={handleChoiceOptionsMultiSelectChange}
						selectionType={condition.selection.type}
						studyObject={selectedStudyObject}
						value={condition.left}
					/>
					{hasInvalidOptionSelected(condition, selectedStudyObject) &&
						renderError('condition.invalid_option_selected', true)}
				</Fragment>
			)}

			{canUseOEQEvaluation === true && (
				<StatementsMultiselect
					condition={condition}
					disabled={props.disabled}
					idStudy={study.idStudy}
					index={index}
					onChange={handleStatementsMultiSelectChange}
				/>
			)}

			{(isFreeTextNumberSelectOption === true || isFreeTextStringsSelectOption === true) &&
				isFreetextStringsAnyOf === false && (
					<Field
						component={Input}
						disabled={props.disabled}
						name={`conditions[${index}].left`}
						value={condition.left}
					/>
				)}

			{_.isNil(selectedStudyObject) === false &&
				selectedStudyObject.type === MODULE_DEFINITIONS.LIST.type &&
				condition.selection.type !== CONDITION_SELECTION_TYPES.LIST_EMPTY && (
					<Field
						component={Input}
						disabled={props.disabled}
						name={`conditions[${index}].left`}
						value={condition.left}
						type="number"
					/>
				)}

			{isInvalidFieldFreetextInput() === true &&
				renderError(
					'condition.invalid_freetext_input',
					false,
					selectedStudyObject.definition.shortName,
				)}

			{(isSelectedStudyObjectAllocation === true || isSelectedStudyObjectRanking === true) &&
				renderAllocationAndRankingLeftFields()}

			{_.isNil(selectedStudyObject) === false && shouldRenderStringAnyOf === true && (
				<div data-testid="conditions-string-any-of">
					{condition.left.map((unused, i) => (
						<div data-testid={`conditions.left-${i}`} key={i}>
							<div className={classes['condition-any-of']}>
								<Field
									className={classes['condition-any-of__input']}
									component={Input}
									data-inputindex={i}
									disabled={props.disabled}
									name={`conditions[${index}].left[${i}]`}
									onPaste={handleFreeTextAnyOfPaste}
									type="text"
								/>

								{condition.left.length > 1 && props.disabled !== true && (
									<div
										className={classes['condition-any-of__remove']}
										onClick={() => {
											props.setFieldValue(
												`conditions[${index}].left`,
												condition.left.filter((_, index) => index !== i),
											)
										}}
										title={intl.formatMessage({ id: 'remove' })}
									/>
								)}
							</div>

							{isFreetextModule(selectedStudyObject) === true &&
								hasInvalidFreetextInput(
									{ ...condition, left: condition.left[i] },
									selectedStudyObject,
								) === true &&
								renderError(
									'condition.invalid_freetext_input',
									false,
									selectedStudyObject.definition.shortName,
								)}

							{isOpenAnswerModule(selectedStudyObject) === true &&
								hasInvalidOEQFollowUpInput(condition, condition.left[i], selectedStudyObject) ===
									true &&
								renderError('condition.invalid_follow_up')}
						</div>
					))}

					{props.disabled !== true && (
						<FieldArrayAdd
							label={intl.formatMessage({
								id: 'condition.string_any_of.add',
							})}
							add={() => {
								props.setFieldValue(`conditions[${index}].left`, [...condition.left, ''])
							}}
						/>
					)}
				</div>
			)}

			{isSelectedStudyObjectChoice === true &&
				condition.selection.type === CONDITION_SELECTION_TYPES.ANY_OF &&
				condition.left.length > 1 && (
					<ModuleAdvancedOptionsToggle
						// TODO:
						// settings__label does not exist
						className={classes.settings__label}
						id={`conditions[${index}]-settings-toggle`}
						onClick={handleAdvancedOptionsClick}
						isExpanded={isExpanded}
					/>
				)}

			{canUseOEQEvaluation === true &&
				condition.selection.type === CONDITION_SELECTION_TYPES.ANY_OF &&
				condition.left.idsStatements.length > 1 && (
					<ModuleAdvancedOptionsToggle
						// TODO:
						// settings__label does not exist
						className={classes.settings__label}
						id={`conditions[${index}]-settings-toggle`}
						onClick={handleAdvancedOptionsClick}
						isExpanded={isExpanded}
					/>
				)}

			{isExpanded === true && (
				<div data-testid="any-of-settings" className={classes.settings__holder}>
					<Label
						label={intl.formatMessage(
							{ id: 'condition.anyOf.min' },
							{
								count: condition.selection.minAnyOfValidSelection,
								type: intl
									.formatMessage({
										id: isSelectedStudyObjectOpenAnswer === true ? 'statements' : 'options',
									})
									.toLowerCase(),
							},
						)}
					/>
					<Field
						component={Slider}
						disabled={props.disabled}
						max={
							isSelectedStudyObjectOpenAnswer === true
								? condition.left.idsStatements.length
								: condition.left.length
						}
						min={1}
						name={`conditions[${index}].selection.minAnyOfValidSelection`}
						onChange={value => {
							props.setFieldValue(`conditions[${index}]`, {
								...condition,
								selection: {
									...condition.selection,
									minAnyOfValidSelection: value,
									maxAnyOfValidSelection: Math.max(
										value,
										condition.selection.maxAnyOfValidSelection,
									),
								},
							})
						}}
						step={1}
						isSpacerNeeded
						{...Slider.STYLE_PRESETS.light}
					/>

					{isAnyOfSelectionValid() === false && renderError('condition.minAnyOfError')}

					<Label
						label={intl.formatMessage(
							{ id: 'condition.anyOf.max' },
							{
								count: condition.selection.maxAnyOfValidSelection,
								type: intl
									.formatMessage({
										id: isSelectedStudyObjectOpenAnswer === true ? 'statements' : 'options',
									})
									.toLowerCase(),
							},
						)}
					/>
					<Field
						component={Slider}
						disabled={props.disabled}
						max={
							isSelectedStudyObjectOpenAnswer
								? condition.left.idsStatements.length
								: condition.left.length
						}
						min={Math.max(1, condition.selection.minAnyOfValidSelection)}
						name={`conditions[${index}].selection.maxAnyOfValidSelection`}
						step={1}
						isSpacerNeeded
						{...Slider.STYLE_PRESETS.light}
					/>
				</div>
			)}
		</div>
	)
}

Condition.propTypes = {
	condition: PropTypes.shape({
		case: PropTypes.oneOf(['and', 'or']).isRequired,
		studyObject: PropTypes.shape({
			id: PropTypes.string,
		}).isRequired,
		is: PropTypes.bool.isRequired,
		selection: PropTypes.shape({
			type: PropTypes.oneOf(Object.values(CONDITION_SELECTION_TYPES)),
			minAnyOfValidSelection: PropTypes.number.isRequired,
			maxAnyOfValidSelection: PropTypes.number.isRequired,
		}).isRequired,
		left: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.arrayOf(PropTypes.string),
			DATE_TIME_OBJECT_SHAPE,
			PropTypes.shape({
				from: DATE_TIME_OBJECT_SHAPE.isRequired,
				to: DATE_TIME_OBJECT_SHAPE.isRequired,
			}),
			PropTypes.shape({
				evaluationPosition: PropTypes.oneOf(Object.values(EVALUATION_POSITION)),
				idsStatements: PropTypes.arrayOf(PropTypes.string),
			}),
		]).isRequired,
	}).isRequired,
	conditionsCount: PropTypes.number.isRequired,
	allowRemoveCondition: PropTypes.bool,
	disabled: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	isQuotaCheckpoint: PropTypes.bool.isRequired,
	isSegmentBuilder: PropTypes.bool.isRequired,
	modules: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			definition: PropTypes.shape({
				id: PropTypes.string.isRequired,
				shortName: PropTypes.string.isRequired,
			}).isRequired,
		}),
	).isRequired,

	// formik helpers
	setFieldValue: PropTypes.func.isRequired,
	setTouched: PropTypes.func.isRequired,
	touched: PropTypes.object.isRequired,
	arrayHelpers: PropTypes.object.isRequired,
}

export default Condition
