import { connect } from 'react-redux'
import Ranking from './Ranking'
import { fromStudyDesign } from 'selectors'
import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'

const mapStateToProps = state => {
	const upperModulesIds = fromStudyDesign.getUpperModulesIds(state)
	const modules = fromStudyDesign.getModules(state)

	const upperLists = upperModulesIds
		.filter(
			id => VISUAL_FLOW_MODULE_TYPES.LIST === modules[id].type && modules[id].isHidden !== true,
		)
		.map(id => modules[id])

	return {
		upperLists,
	}
}

export default connect(mapStateToProps)(Ranking)
