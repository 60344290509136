import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

import { normalizeInput } from 'helpers/string/normalizeInput'

const getNonUniqueShortNames = modules => {
	const duplicateShortNamesObject = {}
	const existingShortNames = {}

	Object.keys(modules).forEach(idModule => {
		const module = modules[idModule]

		if (module.definition.isMatrixChoice === true) {
			return
		}

		if (module.type === MODULE_DEFINITIONS.PLACEHOLDER.type) {
			return
		}

		if (module.definition.shortName === undefined || module.definition.enableConditions === false) {
			return
		}

		const moduleShortName = normalizeInput(module.definition.shortName)

		const snippetQuotaNames = []
		if (module.type === MODULE_DEFINITIONS.A_SNIPPET.type) {
			module.definition.quotas.forEach(quota => {
				snippetQuotaNames.push(normalizeInput(quota.agName))
			})
		}

		const shortNamesFromModule = [moduleShortName, ...snippetQuotaNames]

		if (module.definition.questions !== undefined) {
			module.definition.questions.forEach(question => {
				shortNamesFromModule.push(`${moduleShortName} - ${normalizeInput(question.shortName)}`)
			})
		}

		shortNamesFromModule.forEach(shortName => {
			if (existingShortNames[shortName] !== undefined) {
				duplicateShortNamesObject[idModule] = shortName
				duplicateShortNamesObject[existingShortNames[shortName]] = shortName

				return
			}

			existingShortNames[shortName] = idModule
		})
	})

	return duplicateShortNamesObject
}

export default getNonUniqueShortNames
