import { v4 as uuidv4 } from 'uuid'
import ACTION_BUTTON, { proptypes as ACTION_BUTTON_PROP_TYPES } from './ACTION_BUTTON'
import PropTypes from 'prop-types'
import { MEDIA_AGATHA_TYPE } from 'constants/mediaObject'

export default (isNoneOfThese = false) => ({
	id: uuidv4(),
	type: MEDIA_AGATHA_TYPE.A_IMAGE, // MEDIA_AGATHA_TYPE.A_VIDEO
	alt: '',
	url: isNoneOfThese ? '/assets/nonOfThese/{{$$study.language}}.jpg' : '',
	thumbnailUrl: isNoneOfThese ? '/assets/nonOfThese/{{$$study.language}}.jpg' : '',
	render: {
		forceFullscreen: false,
		blurBefore: false,
		hideAfter: false,
		videoShowControls: true,
		videoAutoPlay: false,
		imageFullscreenZoom: null,
	},
	actionButton: ACTION_BUTTON(),
})

export const proptypes = {
	id: PropTypes.string.isRequired,
	type: PropTypes.oneOf([MEDIA_AGATHA_TYPE.A_IMAGE, MEDIA_AGATHA_TYPE.A_VIDEO]).isRequired,
	alt: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	thumbnailUrl: PropTypes.string.isRequired,
	render: PropTypes.shape({
		forceFullscreen: PropTypes.bool.isRequired,
		blurBefore: PropTypes.bool.isRequired,
		hideAfter: PropTypes.bool.isRequired,
		videoShowControls: PropTypes.bool.isRequired,
		videoAutoPlay: PropTypes.bool.isRequired,
	}).isRequired,
	actionButton: PropTypes.shape(ACTION_BUTTON_PROP_TYPES),
}
