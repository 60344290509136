import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'

import { BUCKET_NAMES } from 'constants/mediaUpload'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Label from 'components/_formik/_base/Label'
import ImageTextArea from 'components/_formik/_complex/ImageTextArea'
import MessageModuleOptions from 'components/_formik/_complex/StudyDesign/MessageModuleOptions'

import LoopItemReferencingTooltip from 'components/_scaffolding/LoopItemReferencingTooltip'

import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import { getFieldName } from 'helpers/formik/getFieldName'

const getTextLabelTranslation = definition =>
	definition.idRelatedAnswer === null ? 'message.title' : 'question'

const getPlaceholderTranslation = definition =>
	definition.idRelatedAnswer === null ? 'message.placeholder' : 'message.question.default'

const Message = props => {
	const intl = useIntl()

	const namePrefix = props.namePrefix ?? ''

	const module = props.modules[props.initialValues.id]
	const isInLoop = module.path.includes('loop')

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setFieldValue }) => (
				<Fragment>
					<Header
						closeModuleDetail={props.closeModuleDetail}
						copyModule={props.copyModule}
						copyModuleButtonParams={props.copyModuleButtonParams}
						disabled={props.disabled === true}
						generalDefinition={props.generalDefinition}
						isFlowChanged={props.isFlowChanged}
						isRelatedModuleInvalid={props.isRelatedModuleInvalid}
						knowledgeBaseLinkTypeOverride={props.knowledgeBaseLinkTypeOverride}
						moduleDefinition={values}
						modules={props.modules}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						showCopyToLibrary
						isPreviewOnly={props.isPreviewOnly}
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => (
								<Form>
									<Label label={intl.formatMessage({ id: getTextLabelTranslation(values) })} />
									<ImageTextArea
										bucketName={BUCKET_NAMES.PROJECT}
										disabled={props.disabled}
										formProps={{
											setFieldValue,
											values,
										}}
										imageUploaderName={getFieldName(namePrefix, 'media')}
										placeholder={intl.formatMessage({ id: getPlaceholderTranslation(values) })}
										textareaName={getFieldName(namePrefix, 'text')}
										openMediaManager={props.openMediaManager}
									/>
									{isInLoop === true && <LoopItemReferencingTooltip />}
									<MessageModuleOptions
										disabled={props.disabled}
										isAccessibilityEnabled={props.study.tags.isAccessibilityEnabled}
										namePrefix={namePrefix}
										formProps={{
											setFieldValue,
											values,
										}}
									/>
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

Message.defaultProps = {
	knowledgeBaseLinkTypeOverride: null,
}

Message.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isPreviewOnly: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	knowledgeBaseLinkTypeOverride: PropTypes.string,
	modules: PropTypes.object.isRequired,
	namePrefix: PropTypes.string,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	openMediaManager: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
	study: PropTypes.object.isRequired,
}

export default Message
