import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'
import ChoiceAnswerMultiselect from 'components/_formik/_complex/ChoiceAnswerMultiselect'
import ListFilterIncludeNoData from 'components/_formik/_complex/StudyDesign/ListFilterIncludeNoData'

import { getConditionSelectionTypeSelectOptions } from 'helpers/conditionBuilder/getConditionSelectionTypeSelectOptions'

import classes from './MatrixListRule.module.scss'

const MatrixListRule = props => {
	const intl = useIntl()

	const { rule, ruleIndex } = props

	const selectedMatrixModule = props.upperMatrixModules.find(
		module => module.definition.id === rule.idMatrix,
	)

	const matrixQuestionOptions = props.upperMatrixModules.map(module => ({
		label: module.definition.shortName,
		value: module.definition.id,
	}))

	const handleSelectedOptionsChange = value => {
		props.setFieldValue(`filterRules[${ruleIndex}].options`, value)
	}

	const negationSelectOptions = [
		{
			value: true,
			label: intl.formatMessage({ id: 'is' }),
		},
		{
			value: false,
			label: intl.formatMessage({ id: 'is_not' }),
		},
	]

	return (
		<div id={`rule-${ruleIndex}`}>
			<div>
				<Label label={intl.formatMessage({ id: 'list_filter.detail.rule.matrix.question' })} />
				<Field
					component={Select}
					disabled={props.disabled}
					name={`filterRules[${ruleIndex}].idMatrix`}
					options={matrixQuestionOptions}
				/>
				{rule.idMatrix === '' && (
					<div className="title-error">
						{intl.formatMessage({ id: 'list_filter.detail.rules.matrix.select_matrix' })}
					</div>
				)}
				{selectedMatrixModule !== undefined &&
					selectedMatrixModule.definition.listSettings.idInputList !== props.idInputList && (
						<div className="title-error">
							{intl.formatMessage({ id: 'list_filter.detail.rules.matrix.input_list_mismatch' })}
						</div>
					)}
				<Label label={intl.formatMessage({ id: 'comparison_rule' })} />
				<div className={classes.split}>
					<Field
						component={Select}
						disabled={props.disabled}
						name={`filterRules[${ruleIndex}].is`}
						options={negationSelectOptions}
					/>
					<Field
						component={Select}
						disabled={props.disabled}
						name={`filterRules[${ruleIndex}].function`}
						options={getConditionSelectionTypeSelectOptions(intl)}
					/>
				</div>
				{selectedMatrixModule !== undefined && (
					<ChoiceAnswerMultiselect
						disabled={props.disabled}
						name={`filterRules[${ruleIndex}].options`}
						onChange={handleSelectedOptionsChange}
						selectionType={'eq'}
						studyObject={selectedMatrixModule}
						value={rule.options}
						shouldValidate={false}
					/>
				)}
				<ListFilterIncludeNoData
					disabled={props.disabled}
					ruleIndex={ruleIndex}
					ruleType={props.rule.ruleType}
				/>
			</div>
		</div>
	)
}

MatrixListRule.propTypes = {
	disabled: PropTypes.bool.isRequired,
	idInputList: PropTypes.string.isRequired,
	rule: PropTypes.object.isRequired,
	ruleIndex: PropTypes.number.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	upperMatrixModules: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}

export default MatrixListRule
