import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'

const isOpenAnswerModule = module => {
	return (
		module.type === MODULE_DEFINITIONS.A_OEQ.type ||
		(module.type === MODULE_DEFINITIONS.A_SNIPPET.type &&
			module.definition.type === MODULE_DEFINITIONS.A_OEQ.type)
	)
}

export default isOpenAnswerModule
