import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import RcSlider, { Handle } from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { Fragment, useEffect, useCallback } from 'react'

import config from 'config'

import { sliderStyles } from 'helpers/sliderStyles'

import { COLORS } from 'constants/colors'

import classes from './Slider.module.scss'

const getScaleNumbersArray = (maxValue, minValue, step, scalePrecision, isScaleVisible) => {
	if (isScaleVisible !== true) {
		return []
	}

	const numberOfSteps = (maxValue + step - minValue) / step
	const scaleNumbers = _.uniq(
		[...Array(numberOfSteps).keys()].map(key => _.round((key + minValue) * step, scalePrecision)),
	)

	return scaleNumbers
}

const Slider = ({
	min,
	max,
	step,
	disabled,
	value,
	onChange,
	onAfterChange,
	name,
	isScaleVisible,
	isMinMaxRangeVisible,
	withValue,
	isSpacerNeeded,
	areButtonsHidden,
	scalePrecision,
	sliderClassName,
	rangeLabelClassName,
	trackStyles,
	railStyles,
	handleStyles,
	dotStyle,
	activeDotStyle,
	minLabel,
	maxLabel,
	...restOfProps
}) => {
	const handleOnChange = useCallback(
		value => {
			if (disabled === true) {
				return
			}

			onChange(name, value)
		},
		[disabled, onChange, name],
	)

	const handleOnAfterChange = useCallback(
		value => {
			if (disabled === true) {
				return
			}

			onAfterChange(name, value)
		},
		[disabled, onAfterChange, name],
	)

	// appActions object is used to expose functions for cypress testing interactions
	useEffect(() => {
		if (config.isTesting === false) {
			return
		}

		const handleSlider = value => {
			handleOnChange(value)
			handleOnAfterChange(value)
		}

		_.set(window.appActions, `sliders[${name}]`, handleSlider)
	}, [name, handleOnChange, handleOnAfterChange])

	const handleButtonClick = action => {
		const newValue = action === 'plus' ? value + step : value - step

		if (newValue >= min && newValue <= max) {
			handleOnChange(newValue)
			handleOnAfterChange(newValue)
		}
	}

	const renderButton = action =>
		areButtonsHidden !== true && (
			<div
				className={classnames('btn', {
					[classes[`btn--${action}`]]: disabled !== true,
					[classes['btn--disabled']]: disabled === true,
				})}
				onClick={() => handleButtonClick(action)}
			/>
		)

	const scaleNumbers = getScaleNumbersArray(max, min, step, scalePrecision, isScaleVisible)

	const marks = scaleNumbers.reduce((marks, number) => ({ ...marks, [number]: number }), {})

	return (
		<Fragment>
			<div
				id={name}
				data-testid={name}
				className={classnames(classes.wrapper, {
					[classes['wrapper--with-scale']]: isScaleVisible === true,
				})}
			>
				{renderButton('minus')}
				<div
					className={classnames(classes['slider-holder'], {
						[classes['slider-holder--spacer']]: isSpacerNeeded === true,
						[classes['slider-holder--disabled']]: disabled === true,
					})}
				>
					<RcSlider
						className={sliderClassName}
						handle={({ dragging, tabIndex, value, style, ...restOfProps }) => (
							<Handle
								tabIndex={tabIndex}
								data-test-value={value}
								data-testid={`sliderHandle-${name}`}
								id={name}
								dragging={dragging.toString()}
								style={{
									...style,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								{...restOfProps}
							>
								{withValue === true && value}
							</Handle>
						)}
						marks={marks}
						min={min}
						max={max}
						step={step}
						value={value}
						// TODO ucomment after classes['rc-slider'] starts working
						// disabled={disabled}
						onChange={handleOnChange}
						onAfterChange={handleOnAfterChange}
						{...sliderStyles(
							disabled,
							trackStyles,
							railStyles,
							handleStyles,
							dotStyle,
							activeDotStyle,
						)}
						{...restOfProps}
					/>
				</div>
				{renderButton('plus')}
			</div>

			{isMinMaxRangeVisible === true && (
				<div
					className={classnames(rangeLabelClassName, classes['rc-slider__range'], {
						[classes['rc-slider__range--are-buttons-hidden']]: areButtonsHidden === true,
						[classes['rc-slider__scale--wrapper']]: isScaleVisible === true,
					})}
				>
					<div>{minLabel}</div>
					<div>{maxLabel}</div>
				</div>
			)}
		</Fragment>
	)
}

Slider.STYLE_PRESETS = {
	light: {
		trackStyles: { backgroundColor: COLORS.GREY_10 },
		handleStyles: { backgroundColor: COLORS.GREY_10, borderColor: COLORS.GREY_10 },
	},
	dark: {
		trackStyles: { backgroundColor: COLORS.WHITE },
		handleStyles: { backgroundColor: COLORS.WHITE, borderColor: COLORS.WHITE },
	},
}

Slider.propTypes = {
	activeDotStyle: PropTypes.object.isRequired,
	areButtonsHidden: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	dotStyle: PropTypes.object.isRequired,
	handleStyles: PropTypes.object,
	isMinMaxRangeVisible: PropTypes.bool.isRequired,
	isScaleVisible: PropTypes.bool.isRequired,
	isSpacerNeeded: PropTypes.bool.isRequired,
	max: PropTypes.number.isRequired,
	maxLabel: PropTypes.string,
	min: PropTypes.number.isRequired,
	minLabel: PropTypes.string,
	name: PropTypes.string.isRequired,
	onAfterChange: PropTypes.func,
	onChange: PropTypes.func,
	railStyles: PropTypes.object,
	rangeLabelClassName: PropTypes.string,
	scalePrecision: PropTypes.number.isRequired,
	sliderClassName: PropTypes.string,
	step: PropTypes.number.isRequired,
	trackStyles: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
	valueStyles: PropTypes.object,
	withValue: PropTypes.bool.isRequired,
}

Slider.defaultProps = {
	activeDotStyle: { display: 'none' },
	areButtonsHidden: false,
	disabled: false,
	dotStyle: { display: 'none' },
	handleStyles: null,
	isMinMaxRangeVisible: false,
	isScaleVisible: false,
	isSpacerNeeded: false,
	maxLabel: null,
	minLabel: null,
	onAfterChange: _.noop,
	onChange: _.noop,
	railStyles: null,
	rangeLabelClassName: null,
	scalePrecision: 0,
	sliderClassName: null,
	trackStyles: null,
	valueStyles: null,
	withValue: false,
}

export default Slider
