import { IPM_SUPPORTED_LANGUAGES } from 'constants/study'

/**
 * @typedef {1 | 2 | 3 | 4 | 5} StudyVersion
 * @typedef {{language: string, version: StudyVersion, isLibrary: boolean, isPostAnalytics: boolean, enableAutoTranslate: boolean}} StudyLike
 */

/**
 * @param {StudyLike}
 *
 * @returns {boolean}
 */
export const getIsIpmClustersCompatible = ({
	isLibrary,
	isPostAnalytics,
	enableAutoTranslate,
	version,
	language,
}) =>
	version >= 4 &&
	isLibrary === false &&
	(IPM_SUPPORTED_LANGUAGES.includes(language) === true ||
		(enableAutoTranslate === true && isPostAnalytics === false))
