import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'

import {
	hasTooFewOptionsSelected,
	hasTooManyOptionsSelected,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateUICommand'
import {
	getQuestionSelectedOptionsValues,
	getQuestionSelectOptions,
} from 'helpers/conditionBuilder/getQuestionOptionsValues'

import IncludedStatements from 'components/_formik/_complex/IncludedStatements'

import classes from './ChoiceAnswerMultiselect.module.scss'

const ChoiceAnswerMultiselect = ({
	disabled,
	name,
	onChange,
	selectionType,
	studyObject,
	value,
	shouldValidate = true,
}) => {
	const intl = useIntl()

	const conditionObject = {
		left: value,
		selection: {
			type: selectionType,
		},
	}

	const isTooManyOptionsSelected =
		shouldValidate === true &&
		studyObject !== undefined &&
		hasTooManyOptionsSelected(conditionObject, studyObject) === true

	const isTooFewOptionsSelected =
		shouldValidate === true &&
		studyObject !== undefined &&
		hasTooFewOptionsSelected(conditionObject, studyObject) === true

	const renderError = idTranslation => (
		<span className="title-error">{intl.formatMessage({ id: idTranslation })}</span>
	)

	return (
		<div data-id={`${name}-selected-options`} className={classes['selected-options']}>
			<IncludedStatements
				disabled={disabled}
				fieldName={name}
				label={{ showLabel: false, errorLabel: intl.formatMessage({ id: 'options' }) }}
				maxHeight={75}
				onChange={onChange}
				options={getQuestionSelectOptions(studyObject)}
				value={getQuestionSelectedOptionsValues(intl, value, studyObject)}
			/>

			{value.length === 0 &&
				renderError(
					studyObject?.isSegmentationTypingTool === true
						? 'choice_options_multiselect.segmentation_typing_tool.no_segments_selected'
						: 'choice_options_multiselect.no_options_selected',
				)}
			{isTooManyOptionsSelected === true &&
				renderError(
					studyObject?.isSegmentationTypingTool === true
						? 'choice_options_multiselect.segmentation_typing_tool.tooManyOptions'
						: 'choice_options_multiselect.tooManyOptions',
				)}
			{value.length !== 0 &&
				isTooFewOptionsSelected === true &&
				renderError('choice_options_multiselect.tooFewOptions')}
		</div>
	)
}

ChoiceAnswerMultiselect.propTypes = {
	disabled: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	selectionType: PropTypes.string.isRequired,
	studyObject: PropTypes.object.isRequired,
	value: PropTypes.array.isRequired,
}

export default ChoiceAnswerMultiselect
