import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Label from 'components/_formik/_base/Label'
import Textarea from 'components/_formik/_base/Textarea'

const Description = props => {
	const intl = useIntl()

	return (
		<Fragment>
			<DatasetShortName
				disabled={props.disabled}
				errorInvalidSimpleName={props.errorInvalidSimpleName}
				errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
				label={intl.formatMessage({ id: 'snippet.name' })}
				values={props.values}
			/>
			<Label label={intl.formatMessage({ id: 'snippet.module.description' })} />
			<Field
				component={Textarea}
				disabled={props.disabled === true || props.shouldShowAdvancedFeatures === false}
				name="description"
				type="text"
			/>
		</Fragment>
	)
}

Description.propTypes = {
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	shouldShowAdvancedFeatures: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
}

export default Description
