import { enTranslator as intl } from 'intl.js'

/**
 * @typedef {{name: string | undefined, themeName: string | undefined, originalLanguageThemeName: string}} StatementThemeLike
 *
 * @param {StatementThemeLike | undefined} statementThemeLike
 * @param {boolean} shouldReturnOriginalLanguageThemeName
 *
 * @returns {string}
 */
export const getStatementThemeName = (
	statementThemeLike,
	shouldReturnOriginalLanguageThemeName,
) => {
	if (shouldReturnOriginalLanguageThemeName === undefined) {
		throw new Error(`Argument "shouldReturnAutoTranslatedName" must be defined!`)
	}

	if (statementThemeLike === undefined) {
		return intl.formatMessage({ id: 'not_available_abbr' })
	}

	if (shouldReturnOriginalLanguageThemeName === false) {
		// NOTE: Only one of these will be present. Different places use different property name, because why not.
		// Later on we should probably unite that somewhat, but for now, we are handling it here.
		return statementThemeLike.name ?? statementThemeLike.themeName
	}

	return statementThemeLike.originalLanguageThemeName
}
