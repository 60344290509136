import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Home = lazy(() => import('./Home'))
const WrappedHome = errorWrapper(Home)

export default () => ({
	path: 'home',
	element: <WrappedHome />,
})
