import React, { useCallback, useState } from 'react'

import config from 'config'

import ContactUsOverlay from 'components/_scaffolding/ContactUsOverlay'

export const useContactUsForm = () => {
	const [isVisible, setIsVisible] = useState(false)

	const showContactUs = useCallback(() => {
		if (config.platformPreset.showGroupSolverContactUs === false) {
			window.location.href = `mailto:${config.platformPreset.supportEmail}`
		} else {
			setIsVisible(true)
		}
	}, [])

	const closeContactUs = useCallback(() => setIsVisible(false), [])

	const ContactUs = () => {
		if (isVisible === false) {
			return null
		}

		return <ContactUsOverlay handleCloseClick={closeContactUs} />
	}

	return [ContactUs, showContactUs]
}
