import _ from 'lodash'

import { REPORT_BLOB_TYPES } from 'constants/reports'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getStatementsWithoutThemes } from 'helpers/reports/getStatementsWithoutThemes'
import { getStatementThemeName } from 'helpers/statementThemes'

import classes from 'routes/_study/QuantQual/QuantQualRoute.module.scss'

const getQuantQualStanceData = (quantQualObject, fills, highlightSignificantDatapoints) => {
	if (quantQualObject === null) {
		return null
	}

	const pValue = quantQualObject.pValue

	// highlightSignificantDatapoints is fadeUnsignificantDatapoints
	const isHighlighted = pValue <= 0.1 || highlightSignificantDatapoints === false

	const presentablePValue = pValue < 0.001 ? '< .001' : _.round(pValue, 3)
	const labelFill = isHighlighted === true ? classes.WHITE : fills.label
	const backgroundFill = isHighlighted === true ? fills.background : classes.WHITE
	const strokeFill = isHighlighted === true ? classes.WHITE : fills.stroke

	return {
		backgroundFill,
		coefficient: _.round(quantQualObject.coefficient, 2),
		labelFill,
		pValue,
		presentablePValue,
		respondentCount: quantQualObject.respondentCount,
		strokeFill,
	}
}

const addValueFields = row => {
	const coefficients = [
		row.dummyData.agree?.coefficient,
		row.dummyData.neutral?.coefficient,
		row.dummyData.disagree?.coefficient,
	].filter(c => c !== undefined)

	const openValue = Math.min(...coefficients)
	const closeValue = Math.max(...coefficients)

	return { ...row, openValue, closeValue }
}

const getTruncatedLabel = (label, respondentCount) =>
	`${_.truncate(label, 25)} (N=${respondentCount})`

const getFullLabel = (label, respondentCount) => `${label} (N=${respondentCount})`

/**
 * @param {{idStatement?: string, idStatementTheme?: string}} row
 * @param {boolean} isThemeViewActive
 * @param {{idStatementTheme: string, themeName: string, originalLanguageThemeName: string}[]} themes
 *
 * @returns {{idStatement: string, themeName: string} | {idStatementTheme: string, themeName: string}}
 */
const appendRowThemeName = (row, isThemeViewActive, themes) => {
	const theme = themes.find(statementTheme => {
		if (isThemeViewActive === false) {
			return statementTheme.statements.includes(row.idStatement)
		}

		return statementTheme.idStatementTheme === row.idStatementTheme
	})

	const themeName = getStatementThemeName(theme, true)

	return { ...row, themeName }
}

const appendDummyData = (
	{
		idStatement,
		idStatementTheme,
		label,
		themeName,
		quantQual: { summaryStatistics, agree, neutral, disagree },
	},
	highlightSignificantDatapoints = true,
) => {
	const calculatedLabel = idStatementTheme === undefined ? label : themeName

	return {
		idStatement: idStatementTheme ?? idStatement,
		id: idStatementTheme ?? idStatement,
		label: getTruncatedLabel(calculatedLabel, summaryStatistics.respondentCount),
		labelFull: getFullLabel(calculatedLabel, summaryStatistics.respondentCount),
		labelOriginal: calculatedLabel,
		dummyData: {
			summaryStatistics: {
				average: _.round(summaryStatistics.average, 2),
				respondentCount: summaryStatistics.respondentCount,
				fill: classes.GREY_60,
			},
			agree: getQuantQualStanceData(
				agree,
				{
					label: classes.GREEN_25,
					background: classes.GREEN_40,
					stroke: classes.GREEN_85,
				},
				highlightSignificantDatapoints,
			),
			neutral: getQuantQualStanceData(
				neutral,
				{
					label: classes.YELLOW_35,
					background: classes.YELLOW_55,
					stroke: classes.YELLOW_85,
				},
				highlightSignificantDatapoints,
			),
			disagree: getQuantQualStanceData(
				disagree,
				{
					label: classes.RED_40,
					background: classes.RED_50,
					stroke: classes.RED_90,
				},
				highlightSignificantDatapoints,
			),
		},
	}
}

const filterValueFieldsByN = (row, settings) => {
	const nFilterValue = Number(settings.nFilterValue)

	const newRow = {
		...row,
		dummyData: {
			...row.dummyData,
		},
	}

	const agreeCount = row.dummyData.agree?.respondentCount ?? 0
	const disagreeCount = row.dummyData.disagree?.respondentCount ?? 0
	const neutralCount = row.dummyData.neutral?.respondentCount ?? 0

	if (agreeCount <= nFilterValue) {
		newRow.dummyData.agree = null
	}

	if (disagreeCount <= nFilterValue) {
		newRow.dummyData.disagree = null
	}

	if (neutralCount <= nFilterValue) {
		newRow.dummyData.neutral = null
	}

	return newRow
}

const getHasRowData = row =>
	row.dummyData.agree !== null || row.dummyData.disagree !== null || row.dummyData.neutral !== null

export const calculateLegendIdeas = (settings, studyObjectData, blobData) => {
	const { idStudy, idAIOpenEndQuestion, idChoiceQuestion, idsSegments } = settings

	const segmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idAIOpenEndQuestion,
				REPORT_BLOB_TYPES.QUANT_QUAL,
				idsSegments[0],
				idChoiceQuestion,
			)
		]

	const chartData =
		settings.themeViewSettings.isActive === true
			? segmentData.data.themes
			: segmentData.data.answers

	const shouldShowUnthemedAnswers =
		settings.themeViewSettings.isActive === true &&
		settings.themeViewSettings.showUnthemedAnswers === true

	const { statements, statementGroups, statementThemes } = studyObjectData

	const unthemedAnswers =
		shouldShowUnthemedAnswers === false
			? []
			: getStatementsWithoutThemes(
					statements.filter(statement => statement.isInvalid === false),
					statementGroups,
					statementThemes,
			  )

	const unthemedAnswersData = unthemedAnswers.map(statement =>
		segmentData.data.answers.find(answer => answer.idStatement === statement.idStatement),
	)

	const data = [...chartData, ...unthemedAnswersData]
		.filter(row => _.get(row, 'quantQual.summaryStatistics', null) !== null)
		.map(row =>
			appendRowThemeName(row, settings.themeViewSettings.isActive, studyObjectData.statementThemes),
		)
		.map(row => appendDummyData(row, settings.highlightSignificantDatapoints))
		.map(row => filterValueFieldsByN(row, settings))
		.filter(getHasRowData)
		.map(addValueFields)

	return data
}
