import PropTypes from 'prop-types'

export const STUDY_LIST_ACTIONS = {
	LAST_UPDATE: 'LAST_UPDATE',
	CREATE: 'CREATE',
	UPDATE: 'UPDATE',
	STATE_CHANGE: 'STATE_CHANGE',
	FLOW_VISUAL_UPDATE: 'FLOW_VISUAL_UPDATE',
	FLOW_PRO_UPDATE: 'FLOW_PRO_UPDATE',
	FLOW_PRO_SET_EDITABLE: 'FLOW_PRO_SET_EDITABLE',
	FLOW_SET_MODE: 'FLOW_SET_MODE',
}

export const STUDY_STATE_ACTION_SOURCES = {
	CLIENT: 'client',
	SERVER: 'server',
}

export const PANEL_ACTIONS = {
	edit: 'edit',
	remove: 'remove',
}

export const STUDY_STATE = {
	DRAFT: {
		status: 'DRAFT',
		title: 'Draft',
		titleLong: 'Draft',
		icon: 'pencil',
	},
	READY_TO_LAUNCH: {
		status: 'READY_TO_LAUNCH',
		title: 'Draft',
		titleLong: 'Draft',
		icon: 'rocket',
	},
	FEEDING_BOTS: {
		status: 'FEEDING_BOTS',
		title: 'Testing',
		titleLong: 'Testing with bots',
		icon: 'bot',
	},
	LAUNCHED: {
		status: 'LAUNCHED',
		title: 'Active',
		titleLong: 'Collecting Data',
		icon: 'flask',
	},
	PAUSED: {
		status: 'PAUSED',
		title: 'Paused',
		titleLong: 'Paused',
		icon: 'pause',
	},
	SOLVED: {
		status: 'SOLVED',
		title: 'Solved',
		titleLong: 'Solved',
		icon: 'check',
	},
	ERROR: {
		status: 'ERROR',
		title: 'Error',
		titleLong: 'Error',
		icon: 'emoji-dead-outline',
	},
	ARCHIVED: {
		status: 'ARCHIVED',
		title: 'Archived',
		titleLong: 'Archived',
		icon: 'archive',
	},
	LIBRARY: {
		status: 'LIBRARY',
		title: 'Library',
		titleLong: 'Library',
		icon: '',
	},
	POST_ANALYTICS: {
		status: 'POST_ANALYTICS',
		title: 'Post Analytics',
		titleLong: 'Post Analytics',
		icon: 'document',
	},
}

export const STUDY_ACTIONS = {
	clientArchive: {
		name: 'clientArchive',
		action: 'ARCHIVE',
		overlay: {
			title: 'archive.overlay_title',
			message: 'archive.overlay_message',
			buttonTitle: 'archive.overlay_button',
		},
		actionButton: {
			icon: 'icon-archive',
			buttonTitle: 'study_status_archive_button',
		},
	},
	clientDeletePreviewData: {
		name: 'clientDeletePreviewData',
		action: 'DELETE_PREVIEW_DATA',
		overlay: {
			title: 'delete_preview_data.overlay_title',
			message: 'delete_preview_data.overlay_message',
			buttonTitle: 'delete_preview_data.overlay_button',
		},
		actionButton: {
			icon: 'icon-trash',
			buttonTitle: 'study_status_delete_preview_data',
		},
	},
	clientStartFeeding: {
		name: 'clientStartFeeding',
		action: 'START_FEEDING_BOTS',
		overlay: {
			title: 'start_feeding_bots.overlay_title',
			message: 'start_feeding_bots.overlay_message',
			buttonTitle: 'start_feeding_bots.overlay_button',
		},
		actionButton: {
			icon: 'icon-clipboard',
			buttonTitle: 'study_status_start_feeding_bots',
		},
	},
	clientLaunch: {
		name: 'clientLaunch',
		action: 'LAUNCH',
		overlay: {
			title: 'launch.overlay_title',
			message: 'launch.overlay_message',
			buttonTitle: 'launch.overlay_button',
		},
		actionButton: {
			icon: 'icon-direction-right',
			buttonTitle: 'study_status_launch_button',
		},
	},
	clientPause: {
		name: 'clientPause',
		action: 'PAUSE',
		overlay: {
			title: 'pause.overlay_title',
			message: 'pause.overlay_message',
			buttonTitle: 'pause.overlay_button',
		},
		actionButton: {
			icon: 'icon-pause',
			buttonTitle: 'study_status_pause_button',
		},
	},
	clientResume: {
		name: 'clientResume',
		action: 'RESUME',
		overlay: {
			title: 'resume_collection.overlay_title',
			message: 'resume_collection.overlay_message',
			buttonTitle: 'resume_collection.overlay_button',
		},
		actionButton: {
			icon: 'icon-direction-right',
			buttonTitle: 'study_status_resume_button',
		},
	},
	clientSolve: {
		name: 'clientSolve',
		action: 'SOLVE',
		overlay: {
			title: 'solve.overlay_title',
			message: 'solve.overlay_message',
			buttonTitle: 'solve.overlay_button',
		},
		actionButton: {
			icon: 'icon-check',
			buttonTitle: 'study_status_solve_button',
		},
	},
}

export const TO_BE_DELETED = 'TO_BE_DELETED'

export const ALLOWED_ADMIN_DASHBOARD_STATE_TRANSITIONS = {
	[STUDY_STATE.DRAFT.status]: [],
	[STUDY_STATE.READY_TO_LAUNCH.status]: [],
	[STUDY_STATE.FEEDING_BOTS.status]: [],
	[STUDY_STATE.LIBRARY.status]: [],
	[STUDY_STATE.LAUNCHED.status]: [STUDY_STATE.READY_TO_LAUNCH, STUDY_STATE.PAUSED],
	[STUDY_STATE.PAUSED.status]: [STUDY_STATE.READY_TO_LAUNCH, STUDY_STATE.LAUNCHED],
	[STUDY_STATE.SOLVED.status]: [
		STUDY_STATE.READY_TO_LAUNCH,
		STUDY_STATE.LAUNCHED,
		STUDY_STATE.PAUSED,
	],
	[STUDY_STATE.ARCHIVED.status]: [],
	[STUDY_STATE.ERROR.status]: [],
	[STUDY_STATE.POST_ANALYTICS.status]: [],
}

export const studyLimitsShape = PropTypes.shape({
	study: PropTypes.shape({
		respondents: PropTypes.number.isRequired,
		previewRespondents: PropTypes.number.isRequired,
		segments: PropTypes.number.isRequired,
	}).isRequired,
	flow: PropTypes.shape({
		visualModules: PropTypes.number.isRequired,
		datasets: PropTypes.number.isRequired,
		oeq: PropTypes.number.isRequired,
		quotas: PropTypes.number.isRequired,
	}).isRequired,
})

export const IPM_SUPPORTED_LANGUAGES = ['en']
