const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	additionalProperties: false,
	definitions: {},
	$id: 'http://groupsolver.com/v1/RESEARCH_LABELING.json',
	properties: {
		class: {
			$id: '/properties/class',
			type: 'string',
		},
		require: {
			$id: '/properties/require',
			type: 'integer',
		},
	},
	required: ['class', 'require'],
}

export default schema
