import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Header from './Header'
import {
	showRelatedMessage,
	hideRelatedMessage,
} from 'routes/_study/StudyDesign/_store/studyDesign'
import { fromStudyDesign } from 'selectors'

// TODO
// figure out why this code runs before dispalying flow detail
// replace bindActionCreators with simple mapActionCreators object
const mapActionCreators = dispatch => ({
	actions: bindActionCreators({ showRelatedMessage, hideRelatedMessage }, dispatch),
})

const mapStateToProps = state => ({
	isRelatedMessageShown: fromStudyDesign.getIsRelatedMessageShown(state),
})

export default connect(mapStateToProps, mapActionCreators)(Header)
