import GitInfo from 'react-git-info/macro'
import _ from 'lodash'

import platformPreset from './platformPreset'

const origin = window.location.origin

const getPlatformPreset = () => {
	if (origin.includes('dashboard.aiq.researchactivate')) {
		return platformPreset.ra
	}

	return platformPreset.gs
}

const appConfig = {
	apiEndpointAdmin: process.env.REACT_APP_API_ENDPOINT_ADMIN,
	apiEndpointUser: process.env.REACT_APP_API_ENDPOINT_USER,
	apiEndpointAuth: process.env.REACT_APP_API_ENDPOINT_AUTH,
	apiEndpointSharePresentation: process.env.REACT_APP_API_ENDPOINT_SHARE_PRESENTATION,
	dashboardUrl: origin,
	froalaKey: process.env.REACT_APP_FROALA_KEY,
	firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
	stripeSubscriptionLink: process.env.REACT_APP_STRIPE_SUBSCRIPTION_LINK,
	idCommit: GitInfo().commit.hash,
	isDev: process.env.REACT_APP_ENV === 'development',
	poolName: process.env.REACT_APP_POOL_NAME,
	language: 'en',
	googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
	googleAnalyticsMeasurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
	logger: process.env.REACT_APP_LOGGER === 'true',
	publicDashboardDataBucketUrl: process.env.REACT_APP_PUBLIC_DASHBOARD_DATA_BUCKET_URL,
	publicLegalDataBucketUrl: process.env.REACT_APP_PUBLIC_LEGAL_DATA_BUCKET_URL,
	publicProjectDataBucketUrl: process.env.REACT_APP_PUBLIC_PROJECT_DATA_BUCKET_URL,
	mediaQueries: {
		mobile: '(max-width: 736px)',
		tablet: '(max-width: 1110px)',
		isPortrait: '(orientation: portrait)',
	},
	isTesting: window.navigator.webdriver === true || _.get(window, 'Cypress.version', null) !== null,
	intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID,
	platformPreset: getPlatformPreset(),
}

export default appConfig
