import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Libraries = lazy(() => import('./Libraries'))
const WrappedLibraries = errorWrapper(Libraries)

export default () => ({
	path: 'libraries',
	element: <WrappedLibraries />,
})
