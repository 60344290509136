import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { INTERACTIVE_SIZE_PRESETS } from 'constants/interactiveSizePresets'
import Icon from 'components/_scaffolding/Icon'

import classes from './Action.module.scss'

const PRESETS = {
	DEFAULT: 'DEFAULT',
	PRIMARY: 'PRIMARY',
	NEGATIVE: 'NEGATIVE',
	SUCCESS: 'SUCCESS',
	TAG: 'TAG',
	WARNING: 'WARNING',
	POSITIVE: 'POSITIVE',
}

const Action = ({
	className = null,
	hasDropdownIndicator = false,
	iconClassName = null,
	iconName = null,
	id,
	idProductTour = null,
	isActive = false,
	isDisabled = false,
	isOpen = false,
	label = null,
	labelClassName = null,
	onClick = _.noop,
	preset = PRESETS.DEFAULT,
	sizePreset = INTERACTIVE_SIZE_PRESETS.DEFAULT,
	title = null,
}) => {
	const iconSize = sizePreset === INTERACTIVE_SIZE_PRESETS.DEFAULT ? 20 : 16

	const isIconOnly =
		(label === null && iconName === null && hasDropdownIndicator === true) ||
		(label === null && iconName !== null && hasDropdownIndicator === false)

	const hasActiveClassName = isActive === true || isOpen === true

	const defaultClassNames = {
		[classes['action--with-icon']]: iconName !== null && label !== null,
		[classes['action--with-dropdown-indicator']]: hasDropdownIndicator === true,
		[classes['action--action--icon-only']]: isIconOnly === true,
		[classes['action--active']]: hasActiveClassName === true,
		[classes['action--disabled']]: isDisabled === true,
	}

	const smallClassNames =
		sizePreset === INTERACTIVE_SIZE_PRESETS.SMALL
			? {
					[classes['action-small']]: true,
					[classes['action-small--with-icon']]: iconName !== null && label !== null,
					[classes['action-small--with-dropdown-indicator']]: hasDropdownIndicator === true,
					[classes['action-small--action--icon-only']]: isIconOnly === true,
			  }
			: {}

	const primaryClassNames =
		preset === PRESETS.PRIMARY
			? {
					[classes['action-primary']]: true,
					[classes['action-primary--active']]: hasActiveClassName === true,
					[classes['action-primary--disabled']]: isDisabled === true,
			  }
			: {}

	const negativeClassNames =
		preset === PRESETS.NEGATIVE
			? {
					[classes['action-negative']]: true,
					[classes['action-negative--active']]: hasActiveClassName === true,
					[classes['action-negative--disabled']]: isDisabled === true,
			  }
			: {}
	const tagClassNames =
		preset === PRESETS.TAG
			? {
					[classes['action-tag']]: true,
			  }
			: {}

	const warningClassNames =
		preset === PRESETS.WARNING
			? {
					[classes['action-warning']]: true,
			  }
			: {}

	const positiveClassNames =
		preset === PRESETS.POSITIVE
			? {
					[classes['action-positive']]: true,
					[classes['action-positive--active']]: hasActiveClassName === true,
					[classes['action-positive--disabled']]: isDisabled === true,
			  }
			: {}

	const handleClick = () => {
		if (isDisabled === true) {
			return
		}

		onClick()
	}

	return (
		<div
			data-testid={id}
			onClick={handleClick}
			title={title ?? label}
			data-intercom-target={idProductTour}
			className={classnames(classes.action, {
				...defaultClassNames,
				...smallClassNames,
				...primaryClassNames,
				...negativeClassNames,
				...tagClassNames,
				...warningClassNames,
				...positiveClassNames,
				[classes['icon-only']]: isIconOnly === true,
				// custom overrides
				[className]: className !== null,
			})}
		>
			{iconName !== null && (
				<Icon name={iconName} size={iconSize} customClassName={iconClassName} />
			)}
			{label !== null && (
				<div
					className={classnames({
						[labelClassName]: labelClassName !== null,
					})}
				>
					{label}
				</div>
			)}
			{hasDropdownIndicator === true && <Icon name={Icon.NAMES.CHEVRON_DOWN} size={iconSize} />}
		</div>
	)
}

Action.PRESETS = PRESETS

Action.SIZE_PRESETS = INTERACTIVE_SIZE_PRESETS

Action.propTypes = {
	className: PropTypes.string,
	hasDropdownIndicator: PropTypes.bool,
	iconClassName: PropTypes.string,
	iconName: PropTypes.string,
	idProductTour: PropTypes.string,
	id: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isOpen: PropTypes.bool,
	label: PropTypes.string,
	labelClassName: PropTypes.string,
	onClick: PropTypes.func,
	preset: PropTypes.oneOf(Object.values(PRESETS)),
	sizePreset: PropTypes.oneOf([INTERACTIVE_SIZE_PRESETS.DEFAULT, INTERACTIVE_SIZE_PRESETS.SMALL]),
	title: PropTypes.string,
}

export default Action
