import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const DataImporter = lazy(() => import('./DataImporterContainer'))
const WrappedDataImporter = errorWrapper(DataImporter)

export default () => ({
	path: 'data-importer',
	element: <WrappedDataImporter />,
})
