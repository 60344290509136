import './styles/core.scss'

import * as Sentry from '@sentry/react'
import App from './App'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import createStore from './store/tools/createStore'
import firebase from 'firebase/app'
import getRoutes from './routes/index'
import isBrowserSupported from 'isBrowserSupported'
import { BrowserTracing } from '@sentry/tracing'

import config from 'config'
import { IGNORED_GRAPHQL_ERRORS } from 'constants/gql'

import * as serviceWorker from './serviceWorker'

// ========================================================
// Redirects users with old browsers
// ========================================================
if (isBrowserSupported() === false) {
	window.location = '/not-supported.html'
}

const firebaseConfig = {
	apiKey: config.firebaseApiKey,
	authDomain: config.firebaseAuthDomain,
}

firebase.initializeApp(firebaseConfig)

const store = createStore({})
const routes = getRoutes(store)

Sentry.init({
	dsn: 'https://04c41dfdf96f4f778806394f8eddf4bb@o43978.ingest.sentry.io/105789',
	integrations: [new BrowserTracing()],
	release: config.idCommit,
	environment: config.poolName,
	enabled: config.logger,
	beforeSend: data => {
		const fingerprint = _.get(data, 'extra.fingerprint', null)

		if (fingerprint === null) {
			return data
		}

		return {
			...data,
			fingerprint: [fingerprint],
		}
	},
	ignoreErrors: [...IGNORED_GRAPHQL_ERRORS, new RegExp(/.*410.*/g)],
	normalizeDepth: 6,
	// record all events
	tracesSampleRate: 1.0,
})

ReactDOM.render(
	<StrictMode>
		<App store={store} routes={routes} />
	</StrictMode>,
	document.getElementById('root'),
)

const APP_ID = config.intercomAppId

window.intercomSettings = {
	app_id: APP_ID,
}
;(function() {
	if (config.platformPreset.hasIntercom === false) {
		return
	}

	var w = window
	var ic = w.Intercom
	if (typeof ic === 'function') {
		ic('reattach_activator')
		ic('update', w.intercomSettings)
	} else {
		var d = document
		var i = function() {
			i.c(arguments)
		}
		i.q = []
		i.c = function(args) {
			i.q.push(args)
		}
		w.Intercom = i
		var l = function() {
			var s = d.createElement('script')
			s.type = 'text/javascript'
			s.async = true
			s.src = 'https://widget.intercom.io/widget/' + APP_ID
			var x = d.getElementsByTagName('script')[0]
			x.parentNode.insertBefore(s, x)
		}
		if (document.readyState === 'complete') {
			l()
		} else if (w.attachEvent) {
			w.attachEvent('onload', l)
		} else {
			w.addEventListener('load', l, false)
		}
	}
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
