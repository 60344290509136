import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import { Field, Formik, Form } from 'formik'
import { useIntl } from 'react-intl'

import { VISUAL_FLOW_MODULE_TYPES, RANKING_SUBTYPE } from 'constants/studyDesign'

import getAllChoiceOptionsRandomized from 'helpers/visualFlowModules/getAllChoiceOptionsRandomized'
import setChoiceOptionsIsRandomized from 'helpers/visualFlowModules/setChoiceOptionsIsRandomized'
import { getNormalOptionsCount } from 'helpers/visualFlowModules/getNormalOptionsCount'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'

import Checkbox from 'components/_formik/_base/Checkbox'
import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'
import ChoiceOptions from 'components/_formik/_custom/_studyDesign/ChoiceOptions'
import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import OptionsFilter from 'components/_formik/_custom/_studyDesign/OptionsFilter'

const createExpandedState = (options, isExpanded) => {
	const state = {}
	options.forEach(option => {
		state[option.id] = isExpanded
	})

	return state
}

const getAreAllCollapsed = state => JSON.stringify(state).includes('true') === false

const getAreAllRandomized = getAllChoiceOptionsRandomized

const getSubtypeOptions = intl => [
	{
		label: intl.formatMessage({ id: 'ranking.subtype.drag_and_drop' }),
		value: RANKING_SUBTYPE.RANKING_DRAG_AND_DROP,
	},
	{
		label: intl.formatMessage({ id: 'ranking.subtype.select' }),
		value: RANKING_SUBTYPE.RANKING_SELECT,
	},
]

const Ranking = props => {
	const intl = useIntl()
	const [expandedState, setExpandedState] = useState(
		createExpandedState(props.initialValues.options, false),
	)

	const toggleOption = idOption => {
		setExpandedState(current => ({
			...current,
			[idOption]: current[idOption] !== true,
		}))
	}

	const expandOption = idOption => {
		setExpandedState(current => ({
			...current,
			[idOption]: true,
		}))
	}

	const toggleAll = () => {
		setExpandedState(current => {
			const newValue = getAreAllCollapsed(current)

			return createExpandedState(props.initialValues.options, newValue)
		})
	}

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ setFieldValue, setValues, values }) => {
				const setRandomizeForAllOptions = value => {
					setValues({
						...values,
						options: setChoiceOptionsIsRandomized(values.options, value),
					})
				}
				const randomizeAll = () => setRandomizeForAllOptions(true)
				const pinAll = () => setRandomizeForAllOptions(false)

				const regenerateCodeValues = () => {
					setValues({
						...values,
						options: values.options.map((o, i) => ({ ...o, code: i + 1 })),
					})
				}

				const additionalActions = [
					{
						isDisabled: false,
						label:
							getAreAllCollapsed(expandedState) === false
								? intl.formatMessage({ id: 'choice.detail.collapse_all' })
								: intl.formatMessage({ id: 'choice.detail.expand_all' }),
						tooltipText: '',
						value: toggleAll,
					},
				]

				if (props.disabled === false) {
					additionalActions.push(
						{
							isDisabled: false,
							label:
								getAreAllRandomized(values.options) === true
									? intl.formatMessage({ id: 'choice.detail.pin_options' })
									: intl.formatMessage({ id: 'choice.detail.randomize_all' }),
							tooltipText: '',
							value: getAreAllRandomized(values.options) === true ? pinAll : randomizeAll,
						},
						{
							isDisabled: false,
							label: intl.formatMessage({ id: 'choice.detail.reset_code_values' }),
							tooltipText: '',
							value: regenerateCodeValues,
						},
					)
				}

				return (
					<Fragment>
						<Header
							additionalActions={additionalActions}
							closeModuleDetail={props.closeModuleDetail}
							copyModule={props.copyModule}
							copyModuleButtonParams={props.copyModuleButtonParams}
							disabled={props.disabled}
							generalDefinition={props.generalDefinition}
							isFlowChanged={props.isFlowChanged}
							isRelatedModuleInvalid={props.isRelatedModuleInvalid}
							moduleDefinition={values}
							openCopyToLibraryForm={props.openCopyToLibraryForm}
							showCopyToLibrary
						/>
						<FormHolder>
							<AutoSubmit
								values={values}
								onSave={props.saveModule}
								formComponent={() => (
									<Form>
										<DatasetShortName
											disabled={props.disabled}
											errorInvalidSimpleName={props.errorInvalidSimpleName}
											errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
											values={values}
										/>
										<Label label={intl.formatMessage({ id: 'ranking.subtype.input_type' })} />
										<Field
											component={Select}
											onChange={newSubtype => {
												setFieldValue('subtype', newSubtype)
												if (newSubtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP) {
													setFieldValue('requireUniqueValues', true)
												}
											}}
											options={getSubtypeOptions(intl)}
											disabled={props.disabled}
											name="subtype"
										/>
										<Field
											component={Checkbox}
											isDarkTheme
											componentProps={{
												label: intl.formatMessage({ id: 'ranking.require_unique_values' }),
											}}
											disabled={
												props.disabled || values.subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP
											}
											name="requireUniqueValues"
										/>
										<ChoiceOptions
											disabled={props.disabled}
											expandOption={expandOption}
											getNormalOptionsCount={getNormalOptionsCount}
											isUnsaved={props.isUnsaved}
											isInternalEmployee={props.isInternalEmployee}
											listState={expandedState}
											moduleType={VISUAL_FLOW_MODULE_TYPES.RANKING}
											openMediaManager={_.noop}
											regenerateCodeValues={regenerateCodeValues}
											setFieldValue={setFieldValue}
											setValues={setValues}
											showNoneOfTheseOption={false}
											toggleOption={toggleOption}
											values={values}
											upperLists={props.upperLists}
										/>
										<OptionsFilter
											disabled={props.disabled}
											isInternalEmployee={props.isInternalEmployee}
											moduleType={VISUAL_FLOW_MODULE_TYPES.RANKING}
											values={values}
										/>
									</Form>
								)}
							/>
						</FormHolder>
					</Fragment>
				)
			}}
		</Formik>
	)
}

Ranking.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	isUnsaved: PropTypes.bool,
	isInternalEmployee: PropTypes.bool.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
	upperLists: PropTypes.array.isRequired,
}

export default Ranking
