export const validate = (module, flow, config = null) => {
	let upperCaseIdRegexp = /[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}/g

	try {
		let jsonFlow = JSON.stringify(flow)
		let upperCaseIds = jsonFlow.match(upperCaseIdRegexp)

		return {
			infos: null,
			warnings: [`Found upper case ID's: `, ...upperCaseIds],
			errors: null,
		}
	} catch (e) {
		return null
	}

	return null
}

export default validate
