import curry from 'lodash/curry'

const debounceAction = (debounceSettings, action) => {
	const actionMeta = action.meta || {}
	const meta = { ...actionMeta, debounce: { ...debounceSettings } }

	action.meta = meta
	return action
}

export default curry(debounceAction)
