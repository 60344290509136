import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { FREE_TEXT_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/FREE_TEXT_TYPES'

const canBeUsedInAllocation = module => {
	if (module.isHidden === true) {
		return false
	}

	if (module.type !== VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT) {
		return false
	}

	const { freetextType } = module.definition

	return (
		freetextType === FREE_TEXT_TYPES.AMOUNT ||
		freetextType === FREE_TEXT_TYPES.INTEGER ||
		freetextType === FREE_TEXT_TYPES.NUMBER
	)
}

export const filterModulesForAllocation = modules => {
	return modules.filter(canBeUsedInAllocation)
}
