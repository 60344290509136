export const validate = module => {
	if (module.definition.imageUrl === '') {
		return {
			infos: null,
			warnings: null,
			errors: ['Heatmap imageUrl cannot be empty'],
		}
	}

	return null
}

export default validate
