import { MODULE_DEFINITIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions'
import getOrderPartByPath from 'routes/_study/StudyDesign/_store/helpers/getOrderPartByPath.js'
import _ from 'lodash'

const addToOrder = (order, newModuleProps) => {
	const updatedOrder = [...order]
	const { path, moduleType, newModuleId } = newModuleProps

	const standardDefinition = MODULE_DEFINITIONS[moduleType]
	const pathArray = path.split('_')
	const indexToInsertModuleBefore = pathArray.pop()
	const currentBranch = getOrderPartByPath(updatedOrder, pathArray.join('_'))

	const newModule = {
		id: newModuleId,
		type: standardDefinition.type,
		title: standardDefinition.title,
		style: standardDefinition.style,
		path: path,
	}
	if (moduleType === MODULE_DEFINITIONS.UI_COMMAND.type) {
		newModule.then = _.get(newModuleProps, 'thenModules', [])
		newModule.else = []
	}

	currentBranch.splice(indexToInsertModuleBefore, 0, newModule)

	return updatedOrder.slice()
}

export default addToOrder
