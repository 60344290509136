import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form, Field } from 'formik'
import { useIntl } from 'react-intl'

import { FREE_TEXT_TYPES } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/FREE_TEXT_TYPES'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import Slider from 'components/_formik/_base/Slider'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'

const FreeText = props => {
	const intl = useIntl()

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values, setValues }) => (
				<Fragment>
					<Header
						closeModuleDetail={props.closeModuleDetail}
						copyModule={props.copyModule}
						copyModuleButtonParams={props.copyModuleButtonParams}
						disabled={props.disabled}
						generalDefinition={props.generalDefinition}
						isFlowChanged={props.isFlowChanged}
						isRelatedModuleInvalid={props.isRelatedModuleInvalid}
						moduleDefinition={values}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						showCopyToLibrary
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => (
								<Form>
									<DatasetShortName
										disabled={props.disabled}
										errorInvalidSimpleName={props.errorInvalidSimpleName}
										errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
										values={values}
									/>
									{values.freetextType !== FREE_TEXT_TYPES.TEXT && (
										<Fragment>
											<Label
												label={intl.formatMessage({ id: 'freetext.detail.expression_hint' })}
											/>
											<Field
												component={Input}
												disabled={props.disabled}
												name="inputHint"
												placeholder={intl.formatMessage({
													id: 'freetext.detail.expression_hint',
												})}
												type="text"
											/>
										</Fragment>
									)}
									<Label label={intl.formatMessage({ id: 'placeholder' })} />
									<Field
										component={Input}
										disabled={props.disabled}
										name="placeholder"
										placeholder={intl.formatMessage({
											id: 'freetext.detail.enter_your_answer',
										})}
										type="text"
										onFocus={() => {
											if (
												values.placeholder ===
												intl.formatMessage({
													id: 'freetext.detail.enter_your_answer',
												})
											) {
												setValues({
													...values,
													placeholder: '',
												})
											}
										}}
									/>
									{values.freetextType === FREE_TEXT_TYPES.TEXT && (
										<Fragment>
											<Field
												name="textareaForm"
												component={Checkbox}
												isDarkTheme
												componentProps={{
													label: intl.formatMessage({ id: 'freetext.detail.use_textarea' }),
												}}
												disabled={props.disabled}
												onChange={value => {
													setValues({
														...values,
														maxInputLength: value === true ? 300 : 100,
														textareaForm: value,
													})
												}}
											/>
											<Label
												label={intl.formatMessage(
													{ id: 'freetext.detail.max_input_length_label' },
													{ count: values.maxInputLength },
												)}
											/>
											<Field
												component={Slider}
												disabled={props.disabled}
												max={1000}
												min={100}
												name="maxInputLength"
												step={100}
												isSpacerNeeded
												{...Slider.STYLE_PRESETS.dark}
											/>
										</Fragment>
									)}
									<Field
										component={Checkbox}
										isDarkTheme
										componentProps={{
											label: intl.formatMessage({ id: 'freetext.detail.skip' }),
										}}
										disabled={props.disabled}
										name="skip"
									/>
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

FreeText.propTypes = {
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
}

export default FreeText
