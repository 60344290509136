export const validate = (module, flow, config = null) => {
	const hasDuplicateOptionLabels =
		module.definition.options.find(option => {
			return (
				module.definition.options.filter(
					opt => opt.simpleName === option.simpleName && option.simpleName.length > 0
				).length > 1
			)
		}) !== undefined

	return hasDuplicateOptionLabels === true
		? {
				infos: null,
				warnings: null,
				errors: ['Choice option SimpleNames have to be unique.'],
		  }
		: null
}

export default validate
