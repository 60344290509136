import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import classnames from 'classnames'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Action from 'components/_scaffolding/_input/Action'
import Icon from 'components/_scaffolding/Icon'
import OverlayHeader from 'components/_scaffolding/OverlayHeader'
import StaticInteractiveOverlay from 'components/_overlay/StaticInteractiveOverlay'
import Checkbox from 'components/_formik/_base/Checkbox'

import AddListColumnOverlay from './_components/AddListColumnOverlay'

import classes from './ListItemsManager.module.scss'

const ListItemsManager = props => {
	const intl = useIntl()

	const [isAddColumnOpen, setIsAddColumnOpen] = useState(false)

	const openAddColumn = () => setIsAddColumnOpen(true)
	const closeAddColumn = () => setIsAddColumnOpen(false)

	const { columns, identifier, items } = props.values

	const areAllItemsRandomized = items.some(item => item.gsTagIsRandomized === false) === false

	const handleRandomizeHeaderClick = () => {
		const newIsRandomizedValue = areAllItemsRandomized === false

		props.setValues({
			...props.values,
			items: items.map(item => ({
				...item,
				gsTagIsRandomized: newIsRandomizedValue,
			})),
		})
	}

	const columnsToRender = [
		{ key: 'gsTagIsHidden', label: '', onClick: _.noop },
		{
			key: 'gsTagIsRandomized',
			label: intl.formatMessage({
				id: areAllItemsRandomized === true ? 'pin_all' : 'randomize_all',
			}),
			onClick: handleRandomizeHeaderClick,
		},
		{
			key: 'gsTagIsAlwaysCarryForward',
			label: intl.formatMessage({ id: 'list.detail.manage_items.always_carry_forward' }),
			onClick: _.noop,
		},
		...columns.map(column => ({ ...column, label: column.key, onClick: _.noop })),
	]

	const toggleItemIsHidden = itemIndex => {
		props.setFieldValue(
			`items[${itemIndex}].gsTagIsHidden`,
			items[itemIndex].gsTagIsHidden === false,
		)
	}

	return (
		<StaticInteractiveOverlay
			contentClassName={classes.overlay}
			handleCloseClick={props.closeItemsManager}
			isCloseButtonHidden
		>
			<OverlayHeader
				handleCloseClick={props.closeItemsManager}
				handleTabClick={_.noop}
				tabs={[]}
				title={intl.formatMessage({ id: 'list.detail.manage_items' })}
				headerAction={
					props.disabled === true ? null : (
						<Action
							onClick={openAddColumn}
							id="open-add-column"
							label={intl.formatMessage({ id: 'list.detail.manage_items.add_column' })}
							iconName={Icon.NAMES.ADD}
							preset={Action.PRESETS.PRIMARY}
						/>
					)
				}
			/>
			<div className={classnames(classes.content, 'scrollbar-thin')}>
				<table className={classes.table}>
					<thead className={classes.table__thead}>
						<tr>
							{columnsToRender.map(column => (
								<th className={classes.table__th} key={column.key}>
									{column.key === 'gsTagIsRandomized' ? (
										<Action
											id="randomize-all"
											className={classes['randomize_all']}
											onClick={column.onClick}
											label={column.label}
										/>
									) : (
										column.label
									)}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{items.map((item, itemIndex) => (
							<tr className={classes.table__tr} key={item[identifier]}>
								{columnsToRender.map(column => {
									if (column.key === 'gsTagIsHidden') {
										return (
											<td className={classes.table__td} key={'gsTagIsHidden'}>
												<div
													className={classes['is-hidden-toggle']}
													onClick={() => toggleItemIsHidden(itemIndex)}
												>
													<Icon
														name={
															item.gsTagIsHidden === true ? Icon.NAMES.EYE_CROSSED : Icon.NAMES.EYE
														}
													/>
												</div>
											</td>
										)
									}

									if (column.key === 'gsTagIsAlwaysCarryForward') {
										return (
											<td className={classes.table__td} key={'gsTagIsAlwaysCarryForward'}>
												<Field
													component={Checkbox}
													componentProps={{}}
													disabled={props.disabled || items[itemIndex].gsTagIsHidden}
													name={`items[${itemIndex}].gsTagIsAlwaysCarryForward`}
												/>
											</td>
										)
									}

									if (column.key === 'gsTagIsRandomized') {
										return (
											<td className={classes.table__td} key={'gsTagIsRandomized'}>
												<Action
													className={classnames(classes['randomize-toggle'], {
														[classes['randomize-toggle--disabled']]: item.gsTagIsHidden === true,
													})}
													id={`${column[identifier]}-randomize`}
													iconName={
														item.gsTagIsRandomized === true ? Icon.NAMES.SHUFFLE : Icon.NAMES.PIN
													}
													isDisabled={item.gsTagIsHidden}
													onClick={() => {
														props.setFieldValue(
															`items[${itemIndex}].gsTagIsRandomized`,
															item.gsTagIsRandomized === false,
														)
													}}
													sizePreset={Action.SIZE_PRESETS.SMALL}
												/>
											</td>
										)
									}

									return (
										<td
											className={classnames(classes.table__td, {
												[classes['table__td--hidden']]: item.gsTagIsHidden === true,
											})}
											key={item[column.key]}
										>
											{item[column.key]}
										</td>
									)
								})}
							</tr>
						))}
					</tbody>
				</table>
				{isAddColumnOpen === true && (
					<AddListColumnOverlay close={closeAddColumn} values={props.values} />
				)}
			</div>
		</StaticInteractiveOverlay>
	)
}

ListItemsManager.propTypes = {
	closeItemsManager: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
	values: PropTypes.shape({
		items: PropTypes.array.isRequired,
	}).isRequired,
}

export default ListItemsManager
