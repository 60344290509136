import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

export default (enabled = false, label = 'Proceed') => ({
	id: uuidv4(),
	label,
	minTimer: enabled === true ? 0 : null,
	maxTimer: null,
	maxTimerEnabled: false,
	enabled,
})

export const proptypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	minTimer: PropTypes.number,
	maxTimer: PropTypes.number,
	maxTimerEnabled: PropTypes.bool.isRequired,
	enabled: PropTypes.bool.isRequired,
}
