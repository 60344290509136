// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_NOTIFICATION = 'notification.show.notification'
export const HIDE_NOTIFICATION = 'notification.hide.notification'
export const TRACK_CALL = 'notification.track.call'

export const BUTTON_TYPES = {
	dismiss: { title: 'Dismiss' },
	okay: { title: 'Okay' },
}

export const STANDARD_NOTIFICATION = () => ({
	id: 'standardNotification',
	type: 'error',
	message: 'Your request failed to complete',
	buttons: [BUTTON_TYPES.dismiss],
	isShown: false,
})

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	notifications: {},
}

// ------------------------------------
// Actions
// ------------------------------------
export const showNotification = (customNotification, startTracking) => ({
	type: SHOW_NOTIFICATION,
	customNotification,
	startTracking,
})

export const hideNotification = notificationId => ({
	type: HIDE_NOTIFICATION,
	notificationId,
})

export const trackErrorIn = (endPoint, extraButtons = []) => ({
	type: TRACK_CALL,
	endPoint,
	extraButtons,
})

export const trackErrorsIn = endPoints => (dispatch, getState) => {
	endPoints.map(endPoint => dispatch(trackErrorIn(endPoint)))
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[TRACK_CALL]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.notifications = Object.assign({}, newState.notifications)
		const notification = Object.assign({}, STANDARD_NOTIFICATION(), {
			id: action.endPoint,
		})
		notification.buttons = notification.buttons.concat(action.extraButtons)
		newState.notifications[action.endPoint] = notification
		return newState
	},
	[SHOW_NOTIFICATION]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.notifications = Object.assign({}, newState.notifications)

		if (action.startTracking) {
			newState.notifications[action.customNotification.id] = Object.assign(
				STANDARD_NOTIFICATION(),
				action.customNotification,
			)
		}

		newState.notifications[action.customNotification.id].isShown = true
		return newState
	},
	[HIDE_NOTIFICATION]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.notifications = Object.assign({}, newState.notifications)
		if (newState.notifications[action.notificationId]) {
			newState.notifications[action.notificationId] = {
				...newState.notifications[action.notificationId],
			}

			newState.notifications[action.notificationId].isShown = false
		}
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function errorReducer(state = initialState, action) {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const isNotificationTracked = (notificationId, state) => {
	return state.notification && state.notification.notifications[notificationId] !== undefined
}
