import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { LIST_FILTER_RULE_TYPES } from 'constants/studyDesign'

import Select from 'components/_formik/_base/Select'

import classes from './ListFilterIncludeNoData.module.scss'

const getOptionLabelTranslations = (ruleType, optionValue, intl) => {
	if (ruleType === LIST_FILTER_RULE_TYPES.COMMUNITY) {
		return optionValue === true
			? intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.community.true' })
			: intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.community.false' })
	}

	if (ruleType === LIST_FILTER_RULE_TYPES.MATRIX) {
		return optionValue === true
			? intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.question.true' })
			: intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.question.false' })
	}
	if (ruleType === LIST_FILTER_RULE_TYPES.QUESTION) {
		return optionValue === true
			? intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.question.true' })
			: intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.question.false' })
	}

	if (
		ruleType === LIST_FILTER_RULE_TYPES.ALLOCATION_QUESTION ||
		ruleType === LIST_FILTER_RULE_TYPES.RANKING_QUESTION
	) {
		return optionValue === true
			? intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.question.true' })
			: intl.formatMessage({ id: 'list_filter.detail.rule.include_no_data.question.false' })
	}

	throw new Error(`unknown ruleType: ${ruleType}`)
}

const ListFilterIncludeNoData = ({ disabled, ruleIndex, ruleType }) => {
	const intl = useIntl()

	const options = [
		{
			value: true,
			label: getOptionLabelTranslations(ruleType, true, intl),
		},
		{
			value: false,
			label: getOptionLabelTranslations(ruleType, false, intl),
		},
	]

	return (
		<div className={classes.wrapper}>
			<Field
				component={Select}
				disabled={disabled}
				name={`filterRules[${ruleIndex}].includeNoData`}
				options={options}
			/>
		</div>
	)
}

ListFilterIncludeNoData.propTypes = {
	disabled: PropTypes.bool.isRequired,
	ruleIndex: PropTypes.number.isRequired,
	ruleType: PropTypes.string.isRequired,
}

export default ListFilterIncludeNoData
