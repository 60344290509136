import PropTypes from 'prop-types'
import React from 'react'

import { prepareStyles } from 'helpers/reactSelect/prepareStyles'
import { reactSelectStyles } from 'helpers/reactSelect/reactSelectStyles'

import { COLORS } from 'constants/colors'
import { INTERACTIVE_SIZE_PRESETS } from 'constants/interactiveSizePresets'
import Action from 'components/_scaffolding/_input/Action'
import Select from 'components/_scaffolding/_input/Select'

import SelectActionControl from './_components/SelectActionControl'

const getOptionColor = state => {
	if (state.isDisabled === true) {
		return COLORS.GREY_60
	}

	if (state.data.isDeleteOption === true) {
		return COLORS.RED_40
	}

	return COLORS.GREY_10
}

const SelectAction = props => {
	const styles = {
		// hide react-select elements
		control: () => ({
			borderRadius: 5,
			overflow: 'hidden',
			height: 'auto',
			width: 'auto',
		}),
		valueContainer: () => ({
			height: 0,
			width: 0,
			// input needs to be displayed because it opens the menu on click
			'input[readonly]': { left: 0, width: 0, height: 0 },
		}),
		dropdownIndicator: () => ({ display: 'none' }),
		indicatorsContainer: () => ({ display: 'none' }),
		placeholder: () => ({ display: 'none' }),
		// style the menu & options
		menu: (base, state) => ({
			...base,
			...reactSelectStyles.menu(base, state),
			margin: 0,
			overflow: 'visible',
			right: 0,
			width: 'auto',
			maxWidth: 'unset',
		}),
		menuList: base => ({
			...base,
			...reactSelectStyles.menuList(base),
			borderRadius: 3,
			overflow: 'visible',
			whiteSpace: 'nowrap',
			maxHeight: '100%',
		}),
		option: (base, state) => ({
			...base,
			...reactSelectStyles.option(base, state),
			borderRadius: 3,
			color: getOptionColor(state),
			cursor: state.isDisabled === true ? 'not-allowed' : 'pointer',
		}),
	}

	const preparedStyles = prepareStyles(styles, props.customStyles)

	return (
		<Select
			{...props}
			isDisabled={props.isDisabled}
			components={{ Control: SelectActionControl, ...props.components }}
			controlClassName={props.controlClassName}
			isClearable={false}
			isSearchable={false}
			isWidthFixed={props.isWidthFixed}
			label={props.label}
			preset={props.preset}
			sizePreset={props.sizePreset}
			styles={preparedStyles}
			value={null}
		/>
	)
}

SelectAction.PRESETS = Action.PRESETS

SelectAction.defaultProps = {
	components: {},
	controlClassName: null,
	customStyles: {},
	isDisabled: false,
	isVertical: false,
	label: null,
	preset: Action.PRESETS.DEFAULT,
	sizePreset: INTERACTIVE_SIZE_PRESETS.DEFAULT,
}

const optionShape = PropTypes.shape({
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
})

SelectAction.propTypes = {
	components: PropTypes.object.isRequired,
	controlClassName: PropTypes.string,
	customStyles: PropTypes.objectOf(PropTypes.func),
	isDisabled: PropTypes.bool.isRequired,
	isVertical: PropTypes.bool.isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([
			optionShape,
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				options: PropTypes.arrayOf(optionShape).isRequired,
			}),
		]),
	).isRequired,
	preset: PropTypes.oneOf(Object.values(Action.PRESETS)).isRequired,
	sizePreset: PropTypes.oneOf([INTERACTIVE_SIZE_PRESETS.DEFAULT, INTERACTIVE_SIZE_PRESETS.SMALL])
		.isRequired,
}

export default SelectAction
