import _ from 'lodash'

import { REPORT_BLOB_TYPES } from 'constants/reports'
import { tools } from 'constants/tools'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'

/**
 * @typedef HeatmapDatapoint
 * @property {number} x
 * @property {number} y
 * @property {number} value
 *
 * @param {Object.<string, unknown>} slideSettings
 * @param {Object.<string, Object.<string, unknown>>} blobData
 * @param {Object.<string, unknown>[]} datasetOptions
 * @returns {HeatmapDatapoint[]}
 */
export const calculateLegendIdeas = (slideSettings, blobData, datasetOptions) => {
	if (slideSettings === undefined) {
		return []
	}

	const { idStudy, idStudyObject, idsSegments } = slideSettings

	const totalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.HEATMAP_STATISTICS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const dataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.HEATMAP_STATISTICS, idSegment)
			],
	)

	if (hasBlobData([...dataArray, totalSegmentData], 'answers') === false) {
		return []
	}

	const dataset = datasetOptions.find(datasetOption => datasetOption.module.id === idStudyObject)

	if (dataset === undefined) {
		return []
	}

	// NOTE: We only have one segment, so accessing first item is fine.
	return dataArray[0].answers.reduce((acc, { answer }) => {
		const divisor = answer.length

		answer.forEach(datapoint => {
			acc.push({
				x: Math.round(datapoint.x),
				y: Math.round(datapoint.y),
				value: _.round(1 / divisor, 2),
			})
		})

		return acc
	}, [])
}
