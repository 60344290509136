import React, { lazy } from 'react'

import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const Dashboard = lazy(() => import('./DashboardComponentContainer'))
const WrappedDashboard = errorWrapper(Dashboard)

export default () => ({
	path: 'dashboard',
	element: <WrappedDashboard />,
})
