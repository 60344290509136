import React, { useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import Tooltip from 'components/_scaffolding/Tooltip'

import classes from './SelectOptionWithTooltip.module.scss'

const SelectOptionWithTooltip = props => {
	const { current: idTooltip } = useRef(uuidv4())

	const isDisabled = props.data.isDisabled ?? false
	const tooltipText = props.data.tooltipText ?? null

	return (
		<div
			className={classes.tooltip}
			data-for={idTooltip}
			data-place="bottom"
			data-tip-disable={tooltipText === null || isDisabled === false}
			data-tip={tooltipText}
		>
			<div
				className={classnames(classes.option, { [classes.disabled]: isDisabled === true })}
				onClick={() => props.selectOption(props)}
			>
				{props.data.label}
			</div>

			{isDisabled === true && <Tooltip id={idTooltip} />}
		</div>
	)
}

SelectOptionWithTooltip.propTypes = {
	data: PropTypes.shape({
		isDisabled: PropTypes.bool,
		label: PropTypes.string.isRequired,
		tooltipText: PropTypes.string,
		value: PropTypes.func.isRequired,
	}).isRequired,
	selectOption: PropTypes.func.isRequired,
}

export default SelectOptionWithTooltip
