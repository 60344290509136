import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import Icon from 'components/_scaffolding/Icon'

import classes from './OverlayCloseButton.module.scss'

const OverlayCloseButton = ({ onClick, closeClassName }) => {
	if (onClick === null) {
		throw new Error('onClick is required')
	}

	return (
		<div onClick={onClick}>
			<Icon
				customClassName={classnames(classes.close, {
					[closeClassName]: closeClassName !== null,
				})}
				name={Icon.NAMES.X_CLOSE}
				size={20}
			/>
		</div>
	)
}

OverlayCloseButton.defaultProps = {
	onClick: null,
	closeClassName: null,
}

OverlayCloseButton.propTypes = {
	closeClassName: PropTypes.string,
	onClick: PropTypes.func.isRequired,
}

export default OverlayCloseButton
