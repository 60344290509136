import { fnList } from 'libs/gs3-libs-flow-validation/src/validators/evaluator/_fnList'

export const SET_FN_TYPES = {
	SET: fnList.set,
	SET_PATH: fnList.setPath,
	SET_LITERAL: fnList.setLiteral,
	SET_PATH_LITERAL: fnList.setPathLiteral,
}

export const SET_FN_CUSTOM_VARIABLES = {
	VARIABLE_VALUE: 'variableValue',
}
