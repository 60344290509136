import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import classes from './DividerLine.module.scss'

const DividerLine = ({ type }) => (
	<div className={classnames(classes.divider, classes[`divider--${type}`])} />
)

DividerLine.TYPES = {
	MESSAGE: 'message',
	QUESTION: 'question',
}

DividerLine.propTypes = {
	type: PropTypes.oneOf(Object.values(DividerLine.TYPES)).isRequired,
}

export default DividerLine
