import validateDatasetMessages from './_validateDatasetMessages'
import validateRelatedMessage from './_validateRelatedMessage'

const validateFreetext = (module, modules, studyTags) => {
	const validationResult = []

	validationResult.push(...validateDatasetMessages(module, studyTags))
	validationResult.push(...validateRelatedMessage(module, modules, studyTags))

	return validationResult
}

export default validateFreetext
