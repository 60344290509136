export const handleActionButtonEnabledChange = (actionButtonEnabled, mediaObject) => ({
	...mediaObject,
	actionButton: {
		...mediaObject.actionButton,
		enabled: actionButtonEnabled,
		maxTimer: null,
		maxTimerEnabled: false,
		minTimer: actionButtonEnabled === false ? null : 0,
	},
	render: {
		...mediaObject.render,
		hideAfter: false,
		forceFullscreen: false,
		blurBefore: false,
	},
})

export const handleMaxTimerEnabledChange = (maxTimerEnabled, mediaObject) => ({
	...mediaObject,
	actionButton: {
		...mediaObject.actionButton,
		maxTimer: maxTimerEnabled === false ? null : 0,
		maxTimerEnabled,
	},
})

export const handleForceFullscreenChange = (forceFullscreen, mediaObject) => ({
	...mediaObject,
	render: {
		...mediaObject.render,
		forceFullscreen,
		blurBefore: false,
	},
})
