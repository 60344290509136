import { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

class AutoSubmit extends Component {
	static propTypes = {
		formComponent: PropTypes.func.isRequired,
		onSave: PropTypes.func.isRequired,
		timeout: PropTypes.number.isRequired,
		values: PropTypes.object.isRequired,
	}

	static defaultProps = {
		timeout: 300,
	}

	constructor(props) {
		super(props)
		this.submitForm = this.submitForm.bind(this)
	}

	state = { isSubmitting: false, shouldSubmitOnUnmount: false }

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.values, this.props.values)) {
			if (prevState.shouldSubmitOnUnmount === false) {
				this.setState({ shouldSubmitOnUnmount: true })
			}
			this.submitForm(true)
		}
	}

	componentWillUnmount() {
		this.submitForm(false)
		if (this.state.shouldSubmitOnUnmount === true) {
			this.props.onSave(this.props.values)
		}
	}

	submitForm = debounce(shouldSubmit => {
		if (shouldSubmit === true) {
			this.setState({ isSubmitting: true })
			this.props.onSave(this.props.values)
			this.setState({ isSubmitting: false, shouldSubmitOnUnmount: false })
		}
	}, this.props.timeout)

	render() {
		const { formComponent } = this.props

		return formComponent(this.state.isSubmitting)
	}
}

export default AutoSubmit
