import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import { CHECKPOINT_HISTORY_OPTIONS } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/UI_COMMAND'

import RadioList from 'components/_formik/_base/RadioList'

const History = ({ disabled = false }) => {
	const intl = useIntl()

	const options = [
		{
			title: intl.formatMessage({ id: 'historyFilter.unblur.title' }),
			value: CHECKPOINT_HISTORY_OPTIONS.UNBLUR,
			description: intl.formatMessage({ id: 'historyFilter.unblur.description' }),
		},
		{
			title: intl.formatMessage({ id: 'historyFilter.blur.title' }),
			value: CHECKPOINT_HISTORY_OPTIONS.BLUR,
			description: intl.formatMessage({ id: 'historyFilter.blur.description' }),
		},
		{
			title: intl.formatMessage({ id: 'historyFilter.clear.title' }),
			value: CHECKPOINT_HISTORY_OPTIONS.CLEAR,
			description: intl.formatMessage({ id: 'historyFilter.clear.description' }),
		},
	]

	return (
		<Field
			name="history"
			component={RadioList}
			listTheme={RadioList.LIST_THEMES.LIGHT}
			options={options}
			disabled={disabled}
		/>
	)
}

History.propTypes = {
	disabled: PropTypes.bool,
}

export default History
