import { enTranslator as intl } from 'intl.js'
import _ from 'lodash'

import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'
import {
	ALLOCATION_SUBTYPE,
	RANKING_SUBTYPE,
	VISUAL_FLOW_MODULE_TYPES,
} from 'constants/studyDesign'

const getNewOptionCode = options => Math.max(...options.map(option => option.code)) + 1

const getLabelBySubType = (subtype, moduleType, index) => {
	if (moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF) {
		return intl.formatMessage({ id: 'maxDiff.option.default_label' }, { index })
	}

	if (subtype === ALLOCATION_SUBTYPE.INPUT) {
		return intl.formatMessage({ id: 'allocation.option.default_label' }, { index })
	}

	if (
		subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP ||
		subtype === RANKING_SUBTYPE.RANKING_SELECT
	) {
		return intl.formatMessage({ id: 'ranking.option.default_label' }, { index })
	}

	return intl.formatMessage({ id: 'choice.option.default_label' }, { index })
}

const getNewOptionLabel = (options, subtype, moduleType) => {
	const labels = options.map(option => option.label)

	const labelNumbers = labels
		.map(label => label.split(' ')[2])
		.filter(number => isNaN(number) === false && number !== null)

	const index =
		labelNumbers.length > 0
			? Math.max(...labelNumbers) + 1
			: options.filter(o => o.isNoneOfThese === false).length + 1

	const label = getLabelBySubType(subtype, moduleType, index)

	return label
}

export const createNewOption = (subtype, isNoneOfThese, options, showLabel, moduleType) => {
	const code = getNewOptionCode(options)
	const label =
		isNoneOfThese === true
			? intl.formatMessage({ id: 'none_of_these' })
			: getNewOptionLabel(options, subtype, moduleType)

	return CHOICE_OPTION(subtype, isNoneOfThese, code, label, showLabel)
}

export const createOptionsOnPaste = (labels, index, allOptions, subtype, showLabel) => {
	const newOptions = _.cloneDeep(allOptions)

	const mutableOptions = newOptions.filter(option => option.isNoneOfThese === false)
	const noneOfTheseOption = newOptions.find(option => option.isNoneOfThese === true)

	const labelsToCreate = labels.filter(label => label.length > 0)

	labelsToCreate.forEach((label, labelIndex) => {
		const optionIndex = index + labelIndex

		// replace option if it exists
		// create new option otherwise
		if (mutableOptions[optionIndex] !== undefined) {
			mutableOptions[optionIndex].label = label
		} else {
			mutableOptions[optionIndex] = CHOICE_OPTION(subtype, false, optionIndex + 1, label, showLabel)
		}
	})

	const optionsToReturn =
		noneOfTheseOption === undefined ? mutableOptions : [...mutableOptions, noneOfTheseOption]

	return optionsToReturn.map((option, optionIndex) => ({
		...option,
		code: optionIndex + 1,
	}))
}
