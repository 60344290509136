import { tools } from 'constants/tools'
import { COLORS } from 'constants/colors'

/**
 * @param {{fill: string | undefined | null, color: string | undefined | null}} statement
 *
 * @returns {boolean}
 */
const getFill = statement => statement.fill || statement.color

/**
 * @param {Object.<string, unknown>} statement
 * @param {Object.<string, unknown>} totalSegmentStatement
 * @param {Object.<string, unknown>[]} statementThemes
 * @param {Object.<string, unknown>[]} ideas
 * @param {boolean | null} useThemes
 * @param {boolean} isThemeViewActive
 *
 * @returns {{color: string, name: string | null, originalLanguageThemeName: string | null}}
 */
export const getThemeParams = (
	statement,
	totalSegmentStatement,
	statementThemes,
	ideas,
	useThemes = null, // NOTE: NEW SLIDE FORMAT HAS SETTING OPTION
	isThemeViewActive = false,
) => {
	if (isThemeViewActive === true) {
		if (totalSegmentStatement.isStatementTheme === true) {
			return { color: getFill(totalSegmentStatement), name: null, originalLanguageThemeName: null }
		}

		return { color: COLORS.REPORT_BUILDER_NO_THEME, name: null, originalLanguageThemeName: null }
	}

	const idsIdeas = ideas.map(statement => statement.idStatement)
	const hasUsedThemes =
		statementThemes.length > 0 &&
		statementThemes.some(theme => idsIdeas.some(id => theme.statements.includes(id) === true))

	if (useThemes === false || (useThemes === null && hasUsedThemes === false)) {
		return { color: getFill(totalSegmentStatement), name: null, originalLanguageThemeName: null }
	}

	const statementTheme = statementThemes.find(theme =>
		theme.statements.includes(statement.idStatement),
	)

	if (statementTheme === undefined) {
		return { color: COLORS.REPORT_BUILDER_NO_THEME, name: null, originalLanguageThemeName: null }
	}

	return {
		color: statementTheme.color,
		name: statementTheme.name,
		originalLanguageThemeName: statementTheme.originalLanguageThemeName,
	}
}

export const getLetterAndThemeParams = (
	statement,
	totalSegmentData,
	statementThemes,
	idSegment,
	ideas,
	useThemes,
) => {
	const totalSegmentStatement =
		idSegment === tools.TOTAL_SEGMENT_UUID
			? statement
			: totalSegmentData.statementSupports.find(s => s.idStatement === statement.idStatement)

	const themeParams = getThemeParams(
		statement,
		totalSegmentStatement,
		statementThemes,
		ideas,
		useThemes,
	)
	const idea = {
		...statement,
		color: themeParams.color,
		themeName: themeParams.name,
		originalLanguageThemeName: themeParams.originalLanguageThemeName,
		letter: totalSegmentStatement.letter,
	}

	return idea
}
