import { prepareQuery } from 'store/tools/graphql'
import { gql } from '@apollo/client'

import { mediaObject } from 'gql/fragments/mediaObject'

import { getIsInPresentationSharePath } from 'helpers/router'

export const GET_PRESENTATIONS_LIST = idAccount => {
	return prepareQuery({
		variables: { idAccount },
		query: gql`
			query GET_PRESENTATIONS_LIST($idAccount: UUID!) {
				presentations(idAccount: $idAccount) {
					idPresentation
					label
					settings
					numberOfSlides
					meta {
						created
						updated
					}
					publicToken
				}
			}
		`,
	})
}

export const GET_PRESENTATION = idPresentation => {
	const isInAuthorizedMode = getIsInPresentationSharePath() === false

	return prepareQuery({
		variables: { idPresentation },
		query: gql`
			query GET_PRESENTATION($idPresentation: UUID!) {
				presentation(idPresentation: $idPresentation) {
					idPresentation
					${isInAuthorizedMode === true ? 'idAccount' : ''}
					label
					meta {
						created
						updated
					}
					settings
					slides
					

					${isInAuthorizedMode === true ? 'numberOfSlides' : ''}
					${isInAuthorizedMode === true ? 'publicToken' : ''}
					${isInAuthorizedMode === true ? `mediaFiles {...mediaObject}` : ''}
				}
			}

			${isInAuthorizedMode === true ? mediaObject : ''}
		`,
	})
}

export const GET_PRESENTATION_MEDIA_FILES = idPresentation =>
	prepareQuery({
		variables: { idPresentation },
		query: gql`
			query GET_PRESENTATION_MEDIA_FILES($idPresentation: UUID!) {
				presentation(idPresentation: $idPresentation) {
					idPresentation
					mediaFiles {
						...mediaObject
					}
				}
			}

			${mediaObject}
		`,
	})

export const GET_AI_ANALYSIS = (variables, onError) =>
	prepareQuery({
		variables,
		query: gql`
			query GET_AI_ANALYSIS($idPresentation: UUID!, $aiAnalysisInput: AiAnalysisInput!) {
				presentation(idPresentation: $idPresentation) {
					idPresentation
					aiAnalysis(aiAnalysisInput: $aiAnalysisInput) {
						shortSummary
						description
					}
				}
			}
		`,
		options: {
			onError,
			fetchPolicy: 'network-only',
		},
	})
