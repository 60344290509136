import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import { fieldShape } from 'components/_formik/_constants'

import classes from './Toggle.module.scss'

const Toggle = ({ field, ...props }) => {
	const handleOnClick = () => {
		if (props.disabled === false) {
			const newValue = !field.value
			if (props.onChange !== undefined) {
				props.onChange(newValue)
			} else {
				props.form.setFieldValue(field.name, newValue)
			}
		}
	}

	const valueBasedClassName = field.value === true ? props.classNameOnTrue : props.classNameOnFalse

	const className =
		valueBasedClassName !== undefined
			? valueBasedClassName
			: classnames(classes.toggle, props.className, {
					[classes[props.toggleStyle]]: props.toggleStyle !== undefined,
			  })

	const label = field.value === true ? props.labelTrue : props.labelFalse

	return (
		<div
			className={classnames(className, {
				[classes.disabled]: props.disabled === true,
			})}
			onClick={handleOnClick}
		>
			{label !== undefined ? label : null}
		</div>
	)
}

Toggle.defaultProps = {
	disabled: false,
}

Toggle.propTypes = {
	className: PropTypes.string,
	classNameOnFalse: PropTypes.string,
	classNameOnTrue: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
	field: PropTypes.oneOfType([
		fieldShape,
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
		}),
	]).isRequired,
	labelFalse: PropTypes.string,
	labelTrue: PropTypes.string,
	onChange: PropTypes.func,
	toggleStyle: PropTypes.string,
	// formik bag
	form: PropTypes.object.isRequired,
}

export default Toggle
