import React, { lazy } from 'react'
import errorWrapper from 'components/_scaffolding/ErrorWrapper'

const PresentationViewLoader = lazy(() => import('./PresentationViewLoader'))
const WrappedPresentationViewLoader = errorWrapper(PresentationViewLoader)

export default () => ({
	path: 'presentation/:idPresentation/view',
	element: <WrappedPresentationViewLoader />,
})
