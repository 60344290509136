import React from 'react'
import PropTypes from 'prop-types'

import { fieldShape } from 'components/_formik/_constants'
import BaseCheckbox from 'components/_scaffolding/_input/Checkbox'

const Checkbox = ({ field, ...props }) => {
	const handleOnChange = (name, isChecked) => {
		if (props.onChange !== undefined) {
			props.onChange(isChecked)
		} else {
			props.form.setFieldValue(name, isChecked)
		}
	}

	return (
		<BaseCheckbox
			{...props.componentProps}
			isDarkTheme={props.isDarkTheme}
			checked={field.value}
			disabled={props.disabled}
			name={field.name}
			onChange={handleOnChange}
			value={field.value}
		/>
	)
}

Checkbox.defaultProps = {
	disabled: false,
	isDarkTheme: false,
}

Checkbox.propTypes = {
	disabled: PropTypes.bool.isRequired,
	field: fieldShape.isRequired,
	componentProps: PropTypes.shape({
		label: PropTypes.string,
		description: PropTypes.string,
		labelClassName: PropTypes.string,
	}).isRequired,
	onChange: PropTypes.func,
	// formik bag
	form: PropTypes.object.isRequired,
	isDarkTheme: PropTypes.bool.isRequired,
}

export default Checkbox
