const schema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	definitions: {},
	$id: 'http://groupsolver.com/v1/EVALUATOR_SET_LITERAL.json',
	properties: {
		setLiteral: {
			$id: '/properties/setLiteral',
			additionalProperties: true,
			type: 'object',
		},
	},
	required: ['setLiteral'],
	type: 'object',
}

export default schema
