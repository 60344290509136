export const fnList = {
	add: 'add',
	agCompletes: 'agCompletes',
	agName: 'agName',
	agQuota: 'agQuota',
	and: 'and',
	append: 'append',
	arrayContainsAny: 'arrayContainsAny',
	arrayContainsEvery: 'arrayContainsEvery',
	arrayEquals: 'arrayEquals',
	arrayLength: 'arrayLength',
	arrayOperation: 'arrayOperation',
	asPrice: 'asPrice',
	choose: 'choose',
	condition: 'condition',
	concatenate: 'concatenate',
	dateAfter: 'dateAfter',
	dateBefore: 'dateBefore',
	dateBetween: 'dateBetween',
	dateEquals: 'dateEquals',
	divide: 'divide',
	firstElement: 'firstElement',
	else: 'else',
	eq: 'eq',
	eqNumber: 'eqNumber',
	filterIdsModulesByList: 'filterIdsModulesByList',
	ge: 'ge',
	gt: 'gt',
	if: 'if',
	in: 'in',
	isIntQuotaTargetGroup: 'isIntQuotaTargetGroup',
	isIdeationClosed: 'isIdeationClosed',
	le: 'le',
	left: 'left',
	logic: 'logic',
	lt: 'lt',
	multiply: 'multiply',
	not: 'not',
	or: 'or',
	randomElement: 'randomElement',
	randomFromRange: 'randomFromRange',
	removeElement: 'removeElement',
	right: 'right',
	round: 'round',
	set: 'set',
	setLiteral: 'setLiteral',
	setPath: 'setPath',
	setPathLiteral: 'setPathLiteral',
	split: 'split',
	stringAnyOf: 'stringAnyOf',
	stringContains: 'stringContains',
	stringEndsWith: 'stringEndsWith',
	stringRegexp: 'stringRegexp',
	stringStartsWith: 'stringStartsWith',
	subString: 'subString',
	then: 'then',
	validateRandomizerModules: 'validateRandomizerModules',
	var: 'var',
	varOrDefault: 'varOrDefault',
	// params
	array1: 'array1',
	array2: 'array2',
	array: 'array',
	datesObject: 'datesObject',
	decimals: 'decimals',
	default: 'default',
	delimiter: 'delimiter',
	denominator: 'denominator',
	distinct: 'distinct',
	elements: 'elements',
	exclude: 'exclude',
	expression: 'expression',
	first: 'first',
	from: 'from',
	idStudyObject: 'idStudyObject',
	idsArray: 'idsArray',
	last: 'last',
	idInputList: 'idInputList',
	number: 'number',
	numerator: 'numerator',
	operation: 'operation',
	options: 'options',
	path: 'path',
	step: 'step',
	string: 'string',
	to: 'to',
	value: 'value',
	variable: 'variable',
	what: 'what',
}

export default fnList
