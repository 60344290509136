import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import { components } from 'react-select'

import { INTERACTIVE_SIZE_PRESETS } from 'constants/interactiveSizePresets'
import Action from 'components/_scaffolding/_input/Action'
import Icon from 'components/_scaffolding/Icon'

const getIconName = (hasIcon, isVertical) => {
	if (hasIcon === false) {
		return null
	}

	return isVertical === true ? Icon.NAMES.MORE_VERTICAL_DOTS : Icon.NAMES.MORE_HORIZONTAL_DOTS
}

const SelectActionControl = ({ children, ...props }) => {
	const {
		controlClassName,
		hasIcon,
		hasDropdownIndicator,
		id,
		iconNameOverride,
		isDisabled,
		isVertical,
		label,
		preset,
		sizePreset,
	} = props.selectProps

	const iconName = iconNameOverride ?? getIconName(hasIcon, isVertical)

	return (
		<components.Control {...props}>
			<Action
				className={controlClassName}
				hasDropdownIndicator={hasDropdownIndicator}
				iconName={iconName}
				id={`${id}-select-action`}
				isDisabled={isDisabled}
				isOpen={props.menuIsOpen}
				label={label}
				onClick={_.noop}
				preset={preset}
				sizePreset={sizePreset}
			/>
			{children}
		</components.Control>
	)
}

SelectActionControl.propTypes = {
	menuIsOpen: PropTypes.bool.isRequired,
	selectProps: PropTypes.shape({
		controlClassName: PropTypes.string,
		hasDropdownIndicator: PropTypes.bool,
		hasIcon: PropTypes.bool,
		id: PropTypes.string.isRequired,
		iconNameOverride: PropTypes.string,
		isDisabled: PropTypes.bool.isRequired,
		isVertical: PropTypes.bool.isRequired,
		label: PropTypes.string,
		preset: PropTypes.oneOf(Object.values(Action.PRESETS)).isRequired,
		sizePreset: PropTypes.oneOf([INTERACTIVE_SIZE_PRESETS.DEFAULT, INTERACTIVE_SIZE_PRESETS.SMALL])
			.isRequired,
	}).isRequired,
}

export default SelectActionControl
