import OBJECT_TYPES from '../OBJECT_TYPES'

export const findNextStepsInEvaluator = (condition, parentSteps = []) => {
	let possibleNextSteps = []

	condition.forEach(command => {
		if (command.if) {
			if (command.if.then && command.if.then.length > 0) {
				possibleNextSteps = possibleNextSteps.concat(
					findNextStepsInEvaluator(command.if.then, parentSteps)
				)
			}
			if (command.if.else && command.if.else.length > 0) {
				possibleNextSteps = possibleNextSteps.concat(
					findNextStepsInEvaluator(command.if.else, parentSteps)
				)
			}
		}
		if (command.set) {
			if (typeof command.set.nextStep === 'string') {
				possibleNextSteps.push(command.set.nextStep)
				return
			}

			if (command.set.nextStep === null) {
				// use string so it's displayed in validation errors
				possibleNextSteps.push('null')
				return
			}

			if (typeof command.set.nextStep === 'object' && command.set.nextStep.choose) {
				let possibleTracks = Object.keys(command.set.nextStep.choose.options).map(key => {
					return command.set.nextStep.choose.options[key]
				})
				possibleNextSteps = possibleNextSteps.concat(possibleTracks)
			}
		}
	})

	return [].concat(parentSteps, possibleNextSteps)
}

export const createModule = (module, flowModules, init = false) => {
	if (!flowModules[module.id]) {
		flowModules[module.id] = {
			didExistsBefore: init,
			nextStepValid: false,
			isReferenced: false,
			isStartModule: module.entryPoint ? module.entryPoint : false,
			module,
		}
	}
}

export const markValidReference = (idModule, flowModules) => {
	if (flowModules[idModule]) {
		flowModules[idModule].isReferenced = true
	} else {
		createModule({id: idModule}, flowModules)
	}
}

export const markValidNextStep = (idModule, flowModules) => {
	if (flowModules[idModule]) {
		flowModules[idModule].nextStepValid = true
	} else {
		createModule({id: idModule}, flowModules)
	}
}

export const validate = (module, flow, config = null) => {
	let flowModules = {}
	let errors = []

	flow.map(module => createModule(module, flowModules, true))

	flow.map(module => {
		// next step exists
		if (module.type === OBJECT_TYPES.END_STUDY) {
			if (flowModules[module.id]) {
				markValidNextStep(module.id, flowModules)
			}
		} else if (module.type === OBJECT_TYPES.EVALUATOR) {
			// find all possible nextSteps add nextStep from MODULE_INTRO as first nextStep
			let nextSteps = findNextStepsInEvaluator(module.definition.logic, [module.nextStep])

			// check if all possible nextSteps exists
			let validNextSteps = nextSteps.every(step => {
				markValidReference(step, flowModules)
				return flowModules[step]
			})

			// if all possible nextSteps exists
			if (validNextSteps) {
				markValidNextStep(module.id, flowModules)
			}
		} else {
			// mark referenced module
			markValidReference(module.nextStep, flowModules)

			// mark nextStepValid if module exists
			if (flowModules[module.nextStep] && flowModules[module.nextStep].didExistsBefore) {
				markValidNextStep(module.id, flowModules)
			}
		}
	})

	Object.keys(flowModules).map(idModule => {
		if (flowModules[idModule].didExistsBefore !== true) {
			errors.push('Module ' + idModule + ' does not exists but is referenced')
		} else if (flowModules[idModule].nextStepValid !== true) {
			errors.push('Module ' + idModule + ' has invalid next step')
		}

		if (
			flowModules[idModule].isReferenced !== true &&
			flowModules[idModule].module.entryPoint === false &&
			flowModules[idModule].module.order.split('_').includes('randomizer') === false &&
			flowModules[idModule].module.order.split('_').includes('matrix') === false
		) {
			errors.push('Module ' + idModule + ' is not referenced as nextStep')
		}
	})

	if (errors.length === 0) {
		return null
	} else {
		return {
			infos: null,
			warnings: null,
			errors,
		}
	}
}

export default validate
